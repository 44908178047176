import { modelValue } from '@/utils/modelValue';
import { numberWithCommas } from '@/utils/format';
import { EditCartType } from '@/services/client/myCartService';
import {
  MsgBonus,
  MsgBonusConverter,
  Msgtier,
  MsgtierConverter,
} from './pdp/GetPDPBadge.model';

export interface ProductModel {
  seq: number;
  minPrice?: string;
  maxPrice?: string;
  prCode?: string;
  sku: string;
  name: string;
  nameEN?: string;
  slugname: string;
  brand: string;
  model?: string;
  unitName?: string;
  priceSort?: number;
  price: string;
  amount?: string;
  originalPrice?: string;
  total?: string;
  totalWithService?: string;
  originalTotal?: string;
  stock?: number;
  discount?: string;
  qty?: number;
  sumQty?: number;
  qtyAvail?: number;
  discountPercent?: number;
  base64?: string;
  pimImage?: string;
  spu?: number;
  avgReview?: number;
  totalReview?: number;
  atbFlag?: string;
  tagNew?: number;
  hugThai?: number;
  isLineButton: boolean;
  skuStatus?: string;
  assemble?: null;
  freegiftMessage?: string;
  freegiftBundle?: string;
  freeInstallBadge?: number;
  warranty?: string;
  warrantyCode?: string;
  perSqmeter?: null;
  minPriceSqmeter?: null;
  maxPriceSqmeter?: null;
  badgeBuyDisc?: number;
  stockAvail?: number;
  clearanceFlag?: number;
  saleFlag?: number;
  homeBranch?: string;
  minQty?: number;
  theOnePromotion?: number;
  backOrderFlag?: boolean;
  packSize?: number;
  remark?: null;
  atbBadges?: AtbBadge[];
  priceSaved?: string;
  skuService?: string;
  isInstall?: boolean;
  weight?: number;
  isFreegift?: boolean;
  canSelectFreegift?: boolean;
  isFreegiftNostock?: boolean;
  isPreOrder?: boolean;
  isShowOriginalPrice?: boolean;
  class?: string;
  skuServiceDiscount?: string;
  buyService?: boolean;
  leadtimeText?: string;
  onWeb?: boolean;
  qntBundle?: number;
  prUname?: string;
  isCrCharge?: boolean;
  serviceTracking?: ServiceTracking;
  bundleDiscount?: number;
  bundleAmount?: number;
  startDate?: string;
  endDate?: string;
  skuCount?: number;
  catName?: string;
  position?: number;
  listName?: string;
  property?: string;
  spuName?: string;
  preorderMsg?: string;
  propFilter?: string;
  propBrand?: string;
  propName?: string;
  shadeCode?: string;
  redCode?: string;
  greenCode?: string;
  blueCode?: string;
  groupId?: number;
  shadeId?: string;
  prop?: string[];
  isFree?: boolean;
  ctlid?: string;
  itemSeq?: number;
  isSelected?: boolean;
  useStock?: string;
  totalPriceShow?: string;
  setId?: string;
  aglPosition?: number;
  bundleReduce?: number;
  itemCartType?: EditCartType;
  pimg?: string;
  sdesp?: string;
  canBulky?: boolean;
  size?: string;
  baseChristmasSku?: string;
  savePrice?: string;
  proDiscountPrice?: string;
  proTotalPrice?: string;
  freeGift?: FreeGiftChristmas[];
  isBuyMore?: boolean;
  isBaseChristmasSku?: boolean;
  sizetext?: string;
  priceMsgTierPlp?: Msgtier[];
  priceMsgBonusPlp?: MsgBonus[];
  isDateBetweenChristmas?: boolean;
}

export class ProductModelConverter {
  public static fromJson(json: any): ProductModel {
    const model: ProductModel = {
      seq: json['sqorder'] ?? json['SEQ'] ?? 9999,
      minPrice: json['min_price']
        ?.toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      maxPrice: json['max_price']
        ?.toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      prCode: json['prCode'] ?? json['PCD'],
      sku: (
        json['skcode'] ??
        json['SKCODE'] ??
        json['SKC'] ??
        json['skc'] ??
        json['sku'] ??
        ''
      )
        .toString()
        .trim(),
      name: (
        json['name'] ??
        json['PRN'] ??
        json['prn'] ??
        json['PRNAME'] ??
        json['PR_NAME'] ??
        json['prname'] ??
        ''
      ).trim(),
      nameEN: json['PRNE']?.trim(),
      slugname: json['slugname'] ?? '',
      brand: (
        json['brand'] ??
        json['BRN'] ??
        json['PRBRAND'] ??
        json['PR_BRAND'] ??
        json['prBrand'] ??
        ''
      ).trim(),
      model: json['model'],
      unitName: (
        json['PR_UNAME'] ??
        json['uname'] ??
        json['UNIT'] ??
        json['unit'] ??
        json['prunit']
      )?.trim(),
      priceSort: json['pricesort'],
      price: (
        json['price'] ??
        json['PRPRICE'] ??
        json['PR_PRICE'] ??
        json['UPCSHOW'] ??
        json['UPC'] ??
        json['upc'] ??
        json['upcshow'] ??
        ''
      )
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      amount: json['amount']
        ?.toString()
        ?.trim()
        ?.replace('฿', '')
        ?.trim()
        ?.replaceAll('.00', ''),
      originalPrice:
        json['PR_ORGPRICE'] ||
        json['ORGPRICE'] ||
        json['org_price'] ||
        json['orgamount']
          ? (
              json['PR_ORGPRICE'] ??
              json['ORGPRICE'] ??
              json['org_price'] ??
              json['orgamount'] ??
              ''
            )
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
          : undefined,
      total:
        json['AMT'] ||
        json['TOT'] ||
        json['AMTSHOW'] ||
        json['amt'] ||
        json['amtshow']
          ? (
              json['AMT'] ??
              json['TOT'] ??
              json['AMTSHOW'] ??
              json['amt'] ??
              json['amtshow'] ??
              ''
            )
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
          : undefined,
      totalWithService: json['TOTSHOW']
        ?.toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      originalTotal:
        json['AMT_ORGPRICE'] || json['AMTORGPRICE']
          ? (json['AMT_ORGPRICE'] ?? json['AMTORGPRICE'] ?? '')
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
          : undefined,
      stock: json['STCK'],
      discount: (json['disc'] ?? json['DISC'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      qty: json['QNT'] ?? json['qty'] ?? json['qnt'] ?? json['QTY'],
      sumQty: Number((json['SUMQNT'] ?? 0).toString().trim().replace(/,/g, '')),
      qtyAvail: json['QNTAVAIL'],
      discountPercent:
        json['per'] || json['PER']
          ? parseInt(
              (json['per'] ?? json['PER'] ?? '')
                .toLowerCase()
                .replace('off', '')
                .replace(/ /g, '')
                .replace('%', ''),
            )
          : undefined,
      base64: json['base64'] ?? json['Base64'] ?? json['BASE64'] ?? json['img'],
      pimImage: json['pimImage'],
      spu: json['SPU'],
      avgReview: json['AVGREVIEW'],
      totalReview: json['TOTALREVIEW'],
      atbFlag: json['ABTFLAG'] ?? json['ATBCODE'] ?? json['ATB'],
      tagNew: json['TAGNEW'],
      hugThai: json['HUGTHAI'],
      isLineButton:
        json['Linebt'] === true || json['Linebt'] === 'Y' ? true : false,
      skuStatus: json['SKUstatus'] ?? json['STATUS'],
      assemble: json['assemble'],
      freegiftMessage: json['FREEGIFTMSG'],
      freegiftBundle: json['FREEGIFTBUNDLE'],
      freeInstallBadge: json['FREEINSTALBADGE'],
      warranty: json['WARRANTY'],
      warrantyCode: json['WARRANTYCODE'],
      perSqmeter: json['PERSQMET'],
      minPriceSqmeter: json['MIN_PRICE_SQMETERS'],
      maxPriceSqmeter: json['MAX_PRICE_SQMETERS'],
      badgeBuyDisc: json['BadgeBuyDisc']
        ? Number(
            json['BadgeBuyDisc']
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
              .replaceAll(',', ''),
          )
        : undefined,
      stockAvail:
        json['STOCKAVAIL'] ?? json['StockAvail'] ?? json['stockavail'],
      clearanceFlag: json['clearanceflag'],
      saleFlag: json['saleflag'],
      homeBranch: json['HOMExBRCH'],
      minQty: json['MINQTY'] ?? json['minqty'],
      theOnePromotion: json['theonepromo'],
      backOrderFlag: json['BackOrderFlag'],
      packSize: json['packSize'],
      remark: json['Remark'] ?? json['remark'],
      atbBadges: json['atbBadges']
        ? json['atbBadges'].map((x: any) => AtbBadgeConverter.fromJson(x))
        : undefined,
      priceSaved: calSavedPrice(json['price'], json['disc']),
      skuService: json['SKUSERVICE']?.toString().trim(),
      isInstall: json['ISINSTALL'] == true ? true : false,
      weight: json['WEIGHT'],
      isFreegift:
        json['AUTOPLPP'] == 1 ||
        json['FREEGIFT'] ||
        (json['autoplpp'] === true) === true
          ? true
          : false,
      canSelectFreegift: json['FREESELECT'] == 1 ? true : false,
      isFreegiftNostock: json['FREENOSTOCK'] == 1 ? true : false,
      isPreOrder: json['PREORDER'] == 1 ? true : false,
      isShowOriginalPrice: json['FlagShow_ORGPRICE'] == 1 ? true : false,
      class: json['CLASS'],
      skuServiceDiscount: (json['SKUSERVICEDISC'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      buyService: json['BUYSERVICE'] == true ? true : false,
      leadtimeText: json['leadtimeText'],
      onWeb: json['onWeb'] !== null ? true : false,
      qntBundle: json['qntBundle'],
      prUname: json['prUname'],
      isCrCharge: json['isCrCharge'] == true ? true : false,
      serviceTracking: json['trackser']
        ? ServiceTrackingConverter.fromJson(json['trackser'])
        : undefined,
      bundleDiscount: json['BUNDLEDISC']
        ? Number(
            json['BUNDLEDISC']
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
              .replaceAll(',', ''),
          )
        : undefined,
      bundleAmount: json['BUNDLEAMOUNT']
        ? Number(
            json['BUNDLEAMOUNT']
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
              .replaceAll(',', ''),
          )
        : undefined,
      startDate: json['SDATE'],
      endDate: json['EDATE'],
      skuCount: json['skcount'],
      catName: json['CATNAME'],
      property: json['PROPERTY'],
      spuName: json['SPUNAME'],
      preorderMsg: json['preorderMsg'],
      propFilter: json['propfilter'],
      propBrand: json['propbrand'],
      propName: json['propname'],
      shadeCode: json['ShadeCode'],
      redCode: json['RedCode'],
      greenCode: json['GreenCode'],
      blueCode: json['BlueCode'],
      groupId: json['GroupID'],
      shadeId: json['ShadeID'],
      prop: json['PROP']
        ? Object.entries(JSON.parse(json['PROP'].toString().trim())).map(
            (e) => {
              return e[1]?.toString().trim() ?? '';
            },
          )
        : undefined,
      isFree: json['isfree'] == true ? true : false,
      ctlid: json['CTLID'],
      itemSeq: json['ITEMSEQ'],
      isSelected: json['isselected'] == true ? true : false,
      useStock: json['USESTOCK'],
      totalPriceShow: json['TOTALPRICETHB'],
      setId: (json['setid'] ?? json['SETID'] ?? '').toString().trim(),
      aglPosition: json['aglposition']
        ? Number(json['aglposition'])
        : undefined,
      bundleReduce: json['reduce'],
      pimg: json['pimg'],
      sdesp: json['sdesp'],
      canBulky: json['canBulky'] === true ? true : false,
      size: json['size'],
      baseChristmasSku: json['baseskcode'],
      savePrice: json['saveprice'],
      proDiscountPrice: json['prodiscountprice'],
      proTotalPrice: json['prototalprice'],
      freeGift: json['freegifts']
        ? json['freegifts'].map((x: any) =>
            FreeGiftChristmasConverter.fromJson(x),
          )
        : undefined,
      isBuyMore: json['isBuyMore'] === true ? true : false,
      isBaseChristmasSku: json['isBaseChristmasSku'] === true ? true : false,
      sizetext: json['sizetext'],
      priceMsgTierPlp: json['priceMsgTierPlp']
        ? json['priceMsgTierPlp'].map((x: any) => MsgtierConverter.fromJson(x))
        : undefined,
      priceMsgBonusPlp: json['priceMsgBonusPlp']
        ? json['priceMsgBonusPlp'].map((x: any) =>
            MsgBonusConverter.fromJson(x),
          )
        : undefined,
      isDateBetweenChristmas:
        json['isDateBetweenChristmas'] === true ? true : false,
    };
    return modelValue(model);
  }
}

const calSavedPrice = (price: string, discount: string) => {
  let savedPrice = 0;
  try {
    const priceNum = parseInt(price.replace(',', '').replace('฿', ''));
    const discountNum = parseInt(discount.replace(',', '').replace('฿', ''));
    savedPrice = discountNum - priceNum;
    return savedPrice > 0
      ? numberWithCommas(savedPrice.toFixed(2)).replaceAll('.00', '')
      : undefined;
  } catch (_) {
    return undefined;
  }
};

export interface AtbBadge {
  base64?: string;
  idx: number;
}

export class AtbBadgeConverter {
  public static fromJson(json: any): AtbBadge {
    const model: AtbBadge = {
      base64: json['base64'] ?? json['IMGbase64'],
      idx: json['idx'] ?? 9,
    };
    return modelValue(model);
  }
}

export interface ServiceTracking {
  carrier?: string;
  trackingNumber?: string;
  deliveryDate?: string;
  statusCode?: string;
  closeJobDate?: string;
}

export class ServiceTrackingConverter {
  public static fromJson(json: any): ServiceTracking {
    const model: ServiceTracking = {
      carrier: json['carrier'],
      trackingNumber: json['trackingNumber'],
      deliveryDate: json['deliveryDate'],
      statusCode: json['statusCode'],
      closeJobDate: json['closeJobDate'],
    };
    return modelValue(model);
  }
}

export interface FreeGiftChristmas {
  sku: string;
  brand: string;
  name: string;
  price: string;
  pimImg: string;
}

export class FreeGiftChristmasConverter {
  public static fromJson(json: any): FreeGiftChristmas {
    const model: FreeGiftChristmas = {
      sku: json['skcode'],
      brand: json['brand'],
      name: json['name'],
      price: json['price'],
      pimImg: json['pimg'],
    };
    return modelValue(model);
  }
}
