import {
  pageToShipCookiePolicyToTop,
  pageToShowCompareList,
} from '@/constants/defaultValue.constant';
import { compareResultsSelector } from '@/store/slices/compareSlice';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppLink from './handler/AppLink';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import CookiePolicyContext from '@/contexts/CookiePolicyContext';
import PageTypeContext from '@/contexts/PageTypeContext';

export default function CookiePolicy() {
  const { isOpen, setIsOpen } = useContext(CookiePolicyContext);
  const { t } = useTranslation();
  const router = useRouter();
  const compareResults = useSelector(compareResultsSelector);
  const currentPage = router.pathname.split('/')[1];
  const pageTypeContext = useContext(PageTypeContext);
  const isShowCompare =
    pageToShowCompareList.includes(currentPage) &&
    pageTypeContext?.isCurtain === false &&
    pageTypeContext?.isSteel === false &&
    pageTypeContext?.isPaint === false;
  const pageToShipToTop = pageToShipCookiePolicyToTop.find(
    (e) => e.page === currentPage,
  );

  useEffect(() => {
    const cookiePolicy = Cookies.get(cookieKey.cookiePolicy) ?? '0';
    if (cookiePolicy !== '1') {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    Cookies.set(cookieKey.cookiePolicy, '1');
    setIsOpen(false);
  };

  return isOpen ? (
    <div
      className={`fixed ${
        pageToShipToTop
          ? compareResults.length > 0 && isShowCompare
            ? 'bottom-[115px] lg:bottom-[60px]'
            : `${
                currentPage === 'cart'
                  ? 'bottom-[67px]'
                  : currentPage === 'checkout'
                  ? 'bottom-[78px]'
                  : 'bottom-[55px]'
              } lg:bottom-[0px]`
          : compareResults.length > 0 && isShowCompare
          ? 'bottom-[60px]'
          : 'bottom-[0px]'
      } w-full`}
      style={{ zIndex: 99 }}
    >
      <div className="py-1 w-full bg-white shadow-[0px_0px_10px_0px_#00000024] relative">
        <div className="absolute top-0 left-0">
          <div
            className="h-4 w-4 cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <i className="bi bi-x font-extrabold text-xl text-black"></i>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-around px-2">
          <div className="leading-4 px-2 text-center lg:text-left text-policy">
            <span className="me-0.5">{t('thiswebsiteusescookies')}</span>
            <AppLink
              target="_blank"
              className="text-blue hover:underline"
              href={`/page/CookiePolicy`}
            >
              {t('here')}
            </AppLink>
          </div>
          <div>
            <Button
              size="small"
              className="text-base bg-primary text-white px-4 py-1"
              style={{
                fontSize: '1rem',
                lineHeight: '1.5rem',
                backgroundColor: '#B8292F',
                color: '#FFFFFF',
                padding: '0.25rem 1rem',
              }}
              onClick={handleClose}
            >
              {t('accept')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
