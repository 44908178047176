import { GetInstallationDetailModel } from '@/models/GetInstallationDetail.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface InstallationDetailState {
  data?: GetInstallationDetailModel;
  isLoading: boolean;
  serSKCODE?: string;
}

const initialState: InstallationDetailState = {
  data: undefined,
  isLoading: false,
  serSKCODE: '',
};

const installationDetailSlice = createSlice({
  name: 'installationDetail',
  initialState,
  reducers: {
    setInstallationDetailValue: (
      state,
      action: PayloadAction<GetInstallationDetailModel | undefined>,
    ) => {
      state.data = action.payload;
      if (action.payload) {
        state.isLoading = false;
      }
    },
    setSerSKCODE: (state, action: PayloadAction<string>) => {
      state.serSKCODE = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setInstallationDetailValue, setSerSKCODE, setLoading } =
  installationDetailSlice.actions;

export const installationDetailDataSelector = (
  store: RootState,
): GetInstallationDetailModel | undefined => store.installationDetail.data;

export const installationDetailLoadingSelector = (store: RootState): boolean =>
  store.installationDetail.isLoading;

export const serSKCODESelector = (store: RootState): string | undefined =>
  store.installationDetail.serSKCODE;

export default installationDetailSlice.reducer;
