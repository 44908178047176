import React from 'react';
import { useTranslation } from 'next-i18next';
import LangHeader from '../LangHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentStoreSelector,
  isHybridSelector,
  openModal,
} from '@/store/slices/storeSlice';
import Image from 'next/image';
import { useRouter } from 'next/router';

export default function TopHeader() {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const currentStore = useSelector(currentStoreSelector);
  const handleClickStore = () => dispatch(openModal());
  const isHybrid = useSelector(isHybridSelector);

  const isPaylaterHideHeader = router.pathname.includes('/paylater');

  return (
    <div className={`block ${isHybrid ? 'bg-secondaryGreen' : 'bg-primary'}`}>
      <div className="flex max-width-container h-4 lg:h-6 items-center text-xs sm:text-sm lg:text-base text-white px-2 xl:px-0">
        <div className="mx-auto lg:ms-0">{t('centerforthaihomeproducts')}</div>
        <div className="hidden lg:flex items-center ms-auto">
          {!isPaylaterHideHeader && (
            <div
              className="me-5 self-center cursor-pointer flex"
              onClick={handleClickStore}
            >
              <Image
                src="/images/svg/header/location_pin_white.svg"
                alt="search"
                width={24}
                height={24}
              />
              {currentStore
                ? i18n.language === 'th'
                  ? currentStore.name
                  : currentStore.nameEN
                : t('selectstore')}
            </div>
          )}
          <LangHeader />
        </div>
      </div>
    </div>
  );
}
