import { modelValue } from '@/utils/modelValue';

export interface Get1010CouponModel {
  iserror?: boolean;
  msg?: string;
  status?: number;
}
export class Get1010CouponModelConverter {
  public static fromJson(json: any): Get1010CouponModel {
    const model: Get1010CouponModel = {
      iserror: json['iserror'],
      msg: json['msg'],
      status: json['status'],
    };
    return modelValue(model);
  }
}

export interface GetTypeCouponModel {
  isError?: boolean;
  errorCode?: string;
  errorMessage?: string;
  data?: GetTypeCouponData[];
  status?: number;
}
export class GetTypeCouponModelConverter {
  public static fromJson(json: any): GetTypeCouponModel {
    const model: GetTypeCouponModel = {
      isError: json['isError'],
      errorCode: json['errorCode'],
      errorMessage: json['errorMessage'],
      data: json['data']
        ? json['data'].map((x: any) => GetTypeCouponDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}

export interface GetTypeCouponData {
  voucherKey?: string;
  reward?: GetTypeCouponReward;
  discountType?: string;
  couponType?: string;
}
export class GetTypeCouponDataConverter {
  public static fromJson(json: any): GetTypeCouponData {
    const model: GetTypeCouponData = {
      voucherKey: json['voucher_key'],
      reward: json['reward']
        ? GetTypeCouponRewardConverter.fromJson(json['reward'])
        : undefined,
      discountType: json['discount_type'],
      couponType: json['coupon_type'],
    };
    return modelValue(model);
  }
}

export interface GetTypeCouponReward {
  amount?: number;
}
export class GetTypeCouponRewardConverter {
  public static fromJson(json: any): GetTypeCouponReward {
    const model: GetTypeCouponReward = {
      amount: json['amount'],
    };
    return modelValue(model);
  }
}
