import Link from 'next/link';
import { useRouter } from 'next/router';

const AppLink = ({
  href,
  children,
  className,
  style,
  target,
  isFullUrl,
  onClick,
  ariaLabel,
  scroll,
}: {
  href?: string;
  children: any;
  className?: string;
  style?: React.CSSProperties;
  target?: string;
  isFullUrl?: boolean;
  onClick?: () => void;
  ariaLabel?: string;
  scroll?: boolean;
}) => {
  const { locale } = useRouter();
  const hrefWithLang = isFullUrl ? `${href}` : `/${locale}${href}`;
  return href ? (
    target === '_blank' ? (
      <a
        target={target}
        className={`${className ?? ''} select-none`}
        style={style}
        href={href.startsWith('http') ? href : hrefWithLang}
        onClick={onClick}
        aria-label={ariaLabel}
      >
        {children}
      </a>
    ) : (
      <Link
        target={target}
        className={`${className ?? ''} select-none`}
        style={style}
        href={hrefWithLang}
        onClick={onClick}
        aria-label={ariaLabel}
        scroll={scroll}
      >
        {children}
      </Link>
    )
  ) : (
    <span
      className={`${className ?? ''} cursor-pointer select-none`}
      style={style}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default AppLink;
