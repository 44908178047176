import React from 'react';

interface CookiePolicyContextProps {
  isOpen: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsOpen: (value: boolean) => void;
}

const CookiePolicyContext = React.createContext<CookiePolicyContextProps>({
  isOpen: false,
  // eslint-disable-next-line no-unused-vars
  setIsOpen: (value: boolean) => {},
});

export default CookiePolicyContext;
