import CustomModal from '@/components/common/CustomModal';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import CheckBoxNotify from '../ProductCard/AddToCartButton/NotifyButton/CheckBoxNotify';
import { Button, Collapse } from '@mui/material';
import { useTranslation } from 'next-i18next';
import * as showDialog from '@/utils/showDialog';
import {
  clearAll,
  errorNotifyMeSelector,
  isLoadingNotifyMeSelector,
  isSuccessNotifyMeSelector,
  notifyQuery,
  setSkuNotify,
  skuNotifyMeSelector,
} from '@/store/slices/notifyMeSlice/notifyMeSlice';
import { useAppDispatch } from '@/store/store';
import { useSelector } from 'react-redux';
import Spinner from '@/components/common/Spinner';
import * as validate from '@/utils/validate';
import { useRouter } from 'next/router';
import { getRedirectQuery } from '@/utils/theone';
import { customerInfoSelector } from '@/store/slices/authenSlice';
import Cookies from 'js-cookie';
import { notifyingSku } from '@/constants/cookieKey.constant';

export default function NotifyModal() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isCheckedApp, setIsCheckedApp] = useState<boolean>(true);
  const [isCheckedEmail, setIsCheckedEmail] = useState<boolean>(false);
  const [collapseStates, setCollapseStates] = useState<boolean>(false);
  const [isInvalidateEmail, setIsInvalidateEmail] = useState<boolean>(false);
  const isLoadingNotifyMe = useSelector(isLoadingNotifyMeSelector);
  const isSuccessNotifyMe = useSelector(isSuccessNotifyMeSelector);
  const skuNotifyMe = useSelector(skuNotifyMeSelector);
  const errorNotifyMe = useSelector(errorNotifyMeSelector);
  const customerInfo = useSelector(customerInfoSelector);
  const [inputValue, setInputValue] = useState<string>('');
  const router = useRouter();
  const toggleEmailCheck = () => {
    setIsCheckedEmail(!isCheckedEmail);
    setCollapseStates(!collapseStates);
  };
  const toggleAppCheck = () => {
    if (customerInfo) {
      setIsCheckedApp(!isCheckedApp);
    }
  };
  const handleInputChange = (e: any) => {
    if (customerInfo === undefined) {
      setIsCheckedApp(false);
    }
    setIsInvalidateEmail(false);
    setInputValue(e.target.value);
  };
  const callbackClearValue = () => {
    setInputValue('');
    dispatch(clearAll());
  };
  const handleOkButton = () => {
    if (isCheckedEmail) {
      if (!inputValue) {
        setIsInvalidateEmail(true);
        return;
      } else if (!validate.isEmail(inputValue)) {
        setIsInvalidateEmail(true);
        return;
      }
    }
    if (skuNotifyMe) {
      dispatch(
        notifyQuery({
          email: inputValue,
          sku: skuNotifyMe,
          isEmail: isCheckedEmail,
          isApp: isCheckedApp,
        }),
      );
    } else {
      showDialog.showErrorWithOkButton({
        text: `${t('productnotfound')}`,
      });
    }
  };

  useEffect(() => {
    if (isSuccessNotifyMe) {
      dispatch(setSkuNotify(undefined));
      showDialog.showMessageWithButton({
        text: `<div>
          <div style="font-size: 1.25rem; font-weight: 700;">${t(
            'productcard:successfully',
          )}</div>
          <div style="font-size:1rem">${t('productcard:wewillnotifyyou')}</div>
          </div>
       `,
        image: '/images/logo/pthainotify.svg',
        primaryButton: {
          name: `${t('completed')}`,
          backgroundColor: '#000000',
          callBack: () => {
            callbackClearValue();
            setInputValue(
              customerInfo?.email
                ? customerInfo?.email === '-'
                  ? ''
                  : customerInfo?.email
                : '',
            );
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessNotifyMe]);

  useEffect(() => {
    if (errorNotifyMe) {
      showDialog.showMessageWithButton({
        text: `<div>
          <div style="font-size: 1.25rem; font-weight: 700;">${t(
            'productcard:mydeepestapologies',
          )}</div>
          <div style="font-size:1rem">${t('productcard:wewillnotifyyou')}</div>
          </div>
       `,
        image: '/images/logo/pthainotify.svg',
        primaryButton: {
          name: `${t('completed')}`,
          backgroundColor: '#000000',
          callBack: () => {
            callbackClearValue();
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorNotifyMe]);

  useEffect(() => {
    dispatch(setSkuNotify(undefined));
  }, [dispatch, router.asPath]);

  useEffect(() => {
    if (customerInfo?.email) {
      setInputValue(
        customerInfo?.email
          ? customerInfo?.email === '-'
            ? ''
            : customerInfo?.email
          : '',
      );
    }
    if (customerInfo === null) {
      setIsCheckedApp(false);
    }
  }, [customerInfo]);

  useEffect(() => {
    const notifySku = Cookies.get(notifyingSku);
    if (customerInfo && notifySku) {
      Cookies.remove(notifyingSku);
      dispatch(
        notifyQuery({
          sku: notifySku,
          isEmail: false,
          isApp: true,
        }),
      );
    }
  }, [customerInfo, dispatch]);

  useEffect(() => {
    if (customerInfo?.isFullMember === false) {
      setIsCheckedApp(false);
    }
  }, [customerInfo]);

  const handleCheckT1Login = () => {
    if (!customerInfo && skuNotifyMe) {
      Cookies.set(notifyingSku, skuNotifyMe);
      router.push(`/${router.locale}/signin${getRedirectQuery(router.asPath)}`);
    }
  };
  return (
    <CustomModal
      open={skuNotifyMe !== undefined}
      onClose={() => {
        dispatch(setSkuNotify(undefined));
      }}
      canClickOutside={false}
      showCloseButton={customerInfo ? false : true}
      closeButtonColor="#000000"
    >
      <div className="my-3 mx-4 leading-3 cursor-pointer">
        <div className="pb-2">
          <Image
            src="/images/logo/notify.svg"
            alt="notify"
            width={88}
            height={88}
            className="mx-auto"
          />
        </div>
        <div className="hidden md:block text-xl text-center font-semibold">
          {t('productcard:notifywhenitemsareinstock')}
        </div>
        <div className="block md:hidden text-xl text-center font-semibold">
          {t('productcard:notification')}
        </div>
        <div className="text-center mb-4 mt-2">
          {t('productcard:selectmethodforustonotify')}
        </div>
        {(customerInfo === null ||
          (customerInfo && customerInfo?.isFullMember === true)) && (
          <>
            <div
              className="mt-2 flex leading-7 border-2 rounded-md"
              onClick={toggleAppCheck}
            >
              <div className="flex item-center justify-center ps-1 my-auto">
                <Image
                  src="/images/logo/thaiwatnotify.svg"
                  alt="thaiwatsadunotify"
                  width={24}
                  height={24}
                  className="mx-auto"
                />
              </div>
              <div className="ps-2" onClick={handleCheckT1Login}>
                {t('productcard:notifyviaapp')}
              </div>
              {customerInfo && (
                <div className="flex items-center mr-2 ml-auto">
                  <CheckBoxNotify value={isCheckedApp} />
                </div>
              )}
            </div>
            <div className="flex items-center my-2">
              <div className="flex-grow border-t border-gray-300"></div>
              <div className="px-2 text-center">{t('and')}</div>
              <div className="flex-grow border-t border-gray-300"></div>
            </div>
          </>
        )}

        <div
          className={`mt-2 flex leading-7 border-2  ${
            collapseStates
              ? 'rounded-t-md bg-background border-background'
              : 'rounded-md '
          }`}
          onClick={toggleEmailCheck}
        >
          <div className="flex item-center justify-center ps-1 my-auto">
            <Image
              src="/images/logo/letternotify.svg"
              alt="letternotify"
              width={24}
              height={24}
              className="mx-auto"
            />
          </div>
          <div className="ps-2">{t('productcard:notifyviaemail')}</div>
          {customerInfo && (
            <div className="flex items-center mr-2 ml-auto">
              <CheckBoxNotify value={isCheckedEmail} />
            </div>
          )}
        </div>
        <Collapse in={collapseStates}>
          <div className="bg-background rounded-b-md px-1.5 py-2">
            <div className="relative">
              <input
                className="border-2 border-primary rounded-lg p-1 pr-8 focus:outline-none focus:ring-4 focus:ring-primaryPink w-full"
                type="text"
                value={inputValue}
                onChange={handleInputChange}
              />
              <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                <Image
                  src="/images/logo/pennotify.svg"
                  alt="pennotify"
                  width={24}
                  height={24}
                  className="mx-auto"
                />
              </div>
            </div>
            <div className="text-primary text-sm">
              {isInvalidateEmail && t('productcard:entervalidemail')}
            </div>
          </div>
        </Collapse>
        <div className="mt-2">{t('productcard:byclicking')}</div>
        <div
          className={`grid ${
            customerInfo ? 'grid-cols-2' : 'grid-cols-1'
          } gap-6 my-4`}
        >
          {customerInfo && (
            <Button
              className="bg-white w-full text-black hover:bg-grayLight"
              style={{ border: '1px solid #000000' }}
              sx={{
                backgroundColor: '#000000',
                width: '100%',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#666',
                },
              }}
              onClick={() => {
                dispatch(setSkuNotify(undefined));
              }}
            >
              {t('cancel')}
            </Button>
          )}
          {isLoadingNotifyMe ? (
            <Spinner size={24} />
          ) : (
            <Collapse in={customerInfo ? true : collapseStates}>
              <Button
                className="w-full text-white hover:bg-grayDark"
                style={{
                  backgroundColor:
                    !isCheckedApp && !isCheckedEmail ? '#ccc' : '#B8292F',
                  color: !isCheckedApp && !isCheckedEmail ? '#fff' : 'white',
                }}
                sx={{
                  backgroundColor: '#000000',
                  width: '100%',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#666',
                  },
                }}
                onClick={() => {
                  handleOkButton();
                }}
                disabled={!isCheckedApp && !isCheckedEmail}
              >
                {t('confirm')}
              </Button>
            </Collapse>
          )}
        </div>
      </div>
    </CustomModal>
  );
}
