import ProductImageBase64 from '@/components/common/ProductImageBase64';
import { Button, Dialog, DialogContent, Grid } from '@mui/material';
import React from 'react';
import { DataOutput } from '@/models/GetVouFromCode.model';
import { useTranslation } from 'next-i18next';

interface prop {
  open: boolean;
  onClose: () => void;
  products?: DataOutput[];
  title?: string;
  subTitle?: string;
}
export default function ModalCheckCode({
  open,
  onClose,
  products,
  title,
  subTitle,
}: prop) {
  const { t: t_voucher } = useTranslation('usevoucher');
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
        <span
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
            cursor: 'pointer',
          }}
        >
          <i className="bi bi-x"></i>
        </span>
        <DialogContent>
          <Grid container justifyContent={'center'}>
            <Grid item xs={12}>
              <div className="text-center">
                <i className="bi bi-exclamation-triangle-fill text-yellow text-3xl"></i>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="text-center ">
                <span className="lg:text-xl text-xs font-bold">{title}</span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="text-center">
                <span className="lg:text-sm text-xs">{subTitle}</span>
              </div>
            </Grid>
          </Grid>
          {products && products.length > 0 && (
            <div className="border border-grayDark1 bg-white">
              <Grid container className="bg-grayLight1">
                <Grid item xs={8} className="text-left font-bold pl-3">
                  {t_voucher('listSku')}
                </Grid>
                <Grid
                  item
                  xs={2}
                  className="text-center font-bold hidden lg:block"
                >
                  {t_voucher('qnt')}
                </Grid>
                <Grid
                  item
                  xs={2}
                  className="text-center font-bold hidden lg:block"
                >
                  {t_voucher('price')}
                </Grid>
              </Grid>
              <div
                style={{
                  maxHeight: '240px',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                }}
                className="show-scrollbar my-0.5 mx-0.5"
              >
                {products?.map((product, i) => (
                  <Grid
                    key={i}
                    container
                    justifyContent={'center'}
                    sx={{
                      marginTop: '10px',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                    }}
                    className="leading-4 border-b"
                  >
                    <Grid
                      item
                      xs={12}
                      lg={8}
                      className="text-left px-1 lg:px-0"
                    >
                      <Grid container>
                        <Grid item xs={2}>
                          <div className="bg-white lg:w-10 lg:h-10 w-8 h-8 text-center m-auto">
                            <ProductImageBase64
                              pimImage={product.pimImage}
                              base64={product.imgbase64}
                              isBoreder
                              alt={product.prName}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={10} className="ps-4 sm:ps-2 lg:ps-0">
                          <span className="text-xs lg:text-sm leading-3">
                            {product.prName}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      lg={2}
                      className="lg:text-center text-left"
                    >
                      <span className="lg:hidden inline text-xs lg:text-sm px-1">
                        {t_voucher('qnt')}
                      </span>
                      <span className="font-bold text-xs lg:text-sm">
                        {product.qnt}
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      lg={2}
                      className="lg:text-center text-right"
                    >
                      <span className="lg:hidden inline text-xs lg:text-sm">
                        {t_voucher('price')}
                      </span>
                      <span className="text-xs lg:text-sm px-1 lg:px-0">
                        {product.totalAmount}
                      </span>
                    </Grid>
                  </Grid>
                ))}
              </div>
            </div>
          )}

          <Grid container>
            <Grid
              item
              xs={12}
              className="text-center"
              sx={{ marginTop: '20px' }}
            >
              <Button
                sx={{
                  backgroundColor: '#B8292F',
                  '&:hover': {
                    backgroundColor: '#B8292F',
                  },
                }}
                className="w-20 p-0 bg-primary text-white disabled:bg-grayLight1 m-auto"
                onClick={onClose}
              >
                <span className="p-1 text-white">{t_voucher('ok')}</span>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
