import { ProductModel } from '@/models/Product.model';
import { ProductDetailPageModel } from '@/models/pdp/ProductDetailPage.model';
import React from 'react';

export enum CurtainStyle {
  // eslint-disable-next-line no-unused-vars
  basketcurtain = 'type4',
  // eslint-disable-next-line no-unused-vars
  chickeniris = 'type5',
  // eslint-disable-next-line no-unused-vars
  pleatedcurtains = 'type3',
  // eslint-disable-next-line no-unused-vars
  curlycurtains = 'type2',
  // eslint-disable-next-line no-unused-vars
  foldingcurtains = 'type1',
}

export enum CurtainInstallationPosition {
  // eslint-disable-next-line no-unused-vars
  window = 1,
  // eslint-disable-next-line no-unused-vars
  door = 2,
}

export enum CurtainInstallationType {
  // eslint-disable-next-line no-unused-vars
  standard = 1,
  // eslint-disable-next-line no-unused-vars
  alwaysontheedgeofthewindow = 2,
  // eslint-disable-next-line no-unused-vars
  floorlength = 3,
  // eslint-disable-next-line no-unused-vars
  coverthefloor = 4,
}

export enum CurtainOpeningStyle {
  // eslint-disable-next-line no-unused-vars
  center = 'open_center',
  // eslint-disable-next-line no-unused-vars
  sidetoside = 'open_side',
}

export enum CurtainAccessory {
  // eslint-disable-next-line no-unused-vars
  eyelet = 'ตาไก่',
  // eslint-disable-next-line no-unused-vars
  curtainHook = 'ตะขอเกี่ยวสายรวบม่าน',
  // eslint-disable-next-line no-unused-vars
  curtainRail = 'รางม่าน',
  // eslint-disable-next-line no-unused-vars
  curtainRodHolder = 'ขาจับรางม่าน',
  // eslint-disable-next-line no-unused-vars
  curtainRodHead = 'หัวท้ายรางม่าน',
  // eslint-disable-next-line no-unused-vars
  curtainTieBacks = 'สายรวบม่านตกแต่ง',
  // eslint-disable-next-line no-unused-vars
  handle = 'ด้ามจูง',
}

export const unitNameSetList = [
  {
    type: CurtainAccessory.eyelet,
    keyName: 'unit',
  },
  {
    type: CurtainAccessory.curtainHook,
    keyName: 'set',
  },
  {
    type: CurtainAccessory.curtainRail,
    keyName: 'product:cm',
  },
  {
    type: CurtainAccessory.curtainRodHolder,
    keyName: 'set',
  },
  {
    type: CurtainAccessory.curtainRodHead,
    keyName: 'set',
  },
  {
    type: CurtainAccessory.curtainTieBacks,
    keyName: 'unit',
  },
  {
    type: CurtainAccessory.curtainTieBacks,
    handle: 'unit',
  },
];

export interface CurtainCalculated {
  calPrice: number;
  sewingCalPrice: number;
  sewingPrice?: number;
  sewingPriceDisc?: number;
  sewingSku?: string;
  calWidth?: number;
  calHeight?: number;
  accessoriesQuantity?: {
    type: CurtainAccessory;
    size?: number;
    quantity: number;
  }[];
  curtainQuantity?: number;
}

export interface CurtainSkuSelected {
  type: CurtainAccessory;
  product?: ProductModel;
}

export enum DiameterType {
  // eslint-disable-next-line no-unused-vars
  mm19 = '19 mm',
  // eslint-disable-next-line no-unused-vars
  mm25 = '25 mm',
}

export interface CurtainCalculatorInput {
  wide?: number;
  high?: number;
  numberOf?: number;
}

interface ProductCurtainPageContextProps {
  productDetailData: ProductDetailPageModel | undefined;
  selectedCurtainStyle?: CurtainStyle;
  // eslint-disable-next-line no-unused-vars
  setSelectedCurtainStyle: (e: CurtainStyle) => void;
  selectedInnerCurtain?: ProductModel;
  // eslint-disable-next-line no-unused-vars
  setSelectedInnerCurtain: (e?: ProductModel) => void;
  installationPosition: CurtainInstallationPosition;
  // eslint-disable-next-line no-unused-vars
  setInstallationPosition: (e: CurtainInstallationPosition) => void;
  selectedInstallCurtainType?: CurtainInstallationType;
  // eslint-disable-next-line no-unused-vars
  setSelectedInstallCurtainType: (e: CurtainInstallationType) => void;
  addingCurtainToCart: boolean;
  // eslint-disable-next-line no-unused-vars
  setAddingCurtainToCart: (e: boolean) => void;
  calculate: { sqm: number; calculated: CurtainCalculated };
  // eslint-disable-next-line no-unused-vars
  setCalculate: (e: { sqm: number; calculated: CurtainCalculated }) => void;
  selectedOpeningStyle: CurtainOpeningStyle;
  // eslint-disable-next-line no-unused-vars
  setSelectedOpeningStyle: (e: CurtainOpeningStyle) => void;
  inputCalculator: { wide?: number; high?: number; numberOf?: number };
  // eslint-disable-next-line no-unused-vars
  setInputCalculator: (e: CurtainCalculatorInput) => void;
  selectedAccessories?: CurtainSkuSelected[];
  // eslint-disable-next-line no-unused-vars
  setSelectedAccessories: (e: CurtainSkuSelected) => void;
  // eslint-disable-next-line no-unused-vars
  setCancelAccessories: (types: CurtainAccessory[]) => void;
  diameter?: DiameterType;
  // eslint-disable-next-line no-unused-vars
  setDiameter: (e: DiameterType) => void;
}

const ProductCurtainPageContext =
  React.createContext<ProductCurtainPageContextProps>({
    productDetailData: undefined,
    selectedCurtainStyle: undefined,
    setSelectedCurtainStyle: () => {},
    selectedInnerCurtain: undefined,
    setSelectedInnerCurtain: () => {},
    selectedInstallCurtainType: undefined,
    setSelectedInstallCurtainType: () => {},
    installationPosition: CurtainInstallationPosition.window,
    setInstallationPosition: () => {},
    addingCurtainToCart: false,
    setAddingCurtainToCart: () => {},
    calculate: {
      sqm: 0,
      calculated: {
        calPrice: 0,
        sewingCalPrice: 0,
      },
    },
    setCalculate: () => {},
    selectedOpeningStyle: CurtainOpeningStyle.center,
    setSelectedOpeningStyle: () => {},
    inputCalculator: {
      wide: undefined,
      high: undefined,
      numberOf: undefined,
    },
    setInputCalculator: () => {},
    selectedAccessories: undefined,
    setSelectedAccessories: () => {},
    setCancelAccessories: () => {},
    diameter: undefined,
    setDiameter: () => {},
  });

export default ProductCurtainPageContext;
