import { RequestEtaxConverter } from '@/models/RequestEtax.model';
import {
  CancelOrderResponse,
  OrderhistoryDetailPageResponse,
  OrderhistoryDetailPageResponseConverter,
  OrderhistoryListPageResponse,
  OrderhistoryListPageResponseConverter,
  ReOrderPageResponse,
  ReOrderPageResponseConverter,
} from '@/models/profile/Orderhistory.model';
import {
  RatingPageResponse,
  RatingPageResponseConverter,
  RatingRequest,
} from '@/models/profile/Rating.model';
import {
  RefundPageResponse,
  RefundPageResponseConverter,
  RefundRequestResponse,
  RefundRequestResponseConverter,
} from '@/models/profile/Refund.model';
import {
  RefundListPageResponse,
  RefundListPageResponseConverter,
} from '@/models/profile/RefundList.model';
import {
  RefundReturnInfoResponse,
  RefundReturnInfoResponseConverter,
  RefundReturnRequestResponse,
  RefundReturnRequestResponseConverter,
  RefundReturnSaveRequest,
  ReturnHomePickupConverter,
} from '@/models/profile/RefundReturnInfo.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { postDataFromAPI } from '@/utils/httpClient';

export const callCancelOrder = async ({
  lang,
  order,
}: {
  lang?: string;
  order?: string;
}): Promise<{
  data: CancelOrderResponse | null | undefined;
  status: number | null;
}> => {
  const getData = await postDataFromAPI({
    path: '/confirmcancelorder',
    lang,
    headers: { ref: order },
  });

  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: CancelOrderResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data && getData.status == 200) {
    response = {
      data: {
        isError: data.iserr,
        message: data.dbmessage,
        isVoidCoupon: data.isvoidcoupon,
      },
      status: getData.status,
    };
  } else {
    response = { data: null, status: getData.status };
  }

  return response;
};

export const getOrderhistoryDetail = async ({
  lang,
  order,
}: {
  lang?: string;
  order?: string;
}): Promise<{
  data: OrderhistoryDetailPageResponse | null | undefined;
  status: number | null;
}> => {
  try {
    const [getData] = await Promise.all([
      postDataFromAPI({
        path: '/profile/orderhistory/detail',
        lang,
        body: { order },
      }),
    ]);
    const data =
      getData.status == 200 ? (getData.data ? getData.data : null) : null;
    let response: {
      data: OrderhistoryDetailPageResponse | null | undefined;
      status: number | null;
    } = { data: data, status: getData.status };
    if (data) {
      try {
        response.data = OrderhistoryDetailPageResponseConverter.fromJson(data);
      } catch (error) {
        console.log(error);
      }
    }
    // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
    await saveClientCookieFromResponse(response.data?.cookies);
    return response;
  } catch (error) {
    console.log(error);
    return { data: null, status: null };
  }
};

export const getProfileOrderhistory = async ({
  lang,
  page,
  mode,
}: {
  lang?: string;
  page?: number;
  mode?: number;
}): Promise<{
  data: OrderhistoryListPageResponse | null | undefined;
  status: number | null;
}> => {
  try {
    const [getData] = await Promise.all([
      postDataFromAPI({
        path: '/profile/orderhistory',
        lang,
        body: {
          page,
          mode,
        },
      }),
    ]);
    const data =
      getData.status == 200 ? (getData.data ? getData.data : null) : null;

    let response: {
      data: OrderhistoryListPageResponse | null | undefined;
      status: number | null;
    } = { data: data, status: getData.status };

    if (data) {
      try {
        response.data = OrderhistoryListPageResponseConverter.fromJson(data);
      } catch (error) {
        console.log(error);
      }
    }
    // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
    await saveClientCookieFromResponse(response.data?.cookies);
    return response;
  } catch (error) {
    console.log(error);
    return { data: null, status: null };
  }
};

export const getReOrder = async ({
  lang,
  order,
}: {
  lang?: string;
  order?: string;
}): Promise<{
  data: OrderhistoryListPageResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/profile/orderhistory/reorder',
      lang,
      body: {
        reorder: order,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: {
    data: ReOrderPageResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = ReOrderPageResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);
  return response;
};

export const getRefund = async ({
  lang,
  order,
}: {
  lang?: string;
  order?: string;
}): Promise<{
  data: OrderhistoryListPageResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/profile/orderhistory/refund',
      lang,
      body: {
        refund: order,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: RefundPageResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = RefundPageResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);
  return response;
};

export const getProfileRefund = async ({
  lang,
}: {
  lang?: string;
}): Promise<{
  data: RefundListPageResponse | null | undefined;
  status: number | null;
}> => {
  try {
    const [getData] = await Promise.all([
      postDataFromAPI({
        path: '/profile/orderrefund',
        lang,
      }),
    ]);
    const data =
      getData.status == 200 ? (getData.data ? getData.data : null) : null;

    let response: {
      data: RefundListPageResponse | null | undefined;
      status: number | null;
    } = { data: data, status: getData.status };

    if (data) {
      try {
        response.data = RefundListPageResponseConverter.fromJson(data);
      } catch (error) {
        console.log(error);
      }
    }
    // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
    //await saveClientCookieFromResponse(response.data?.cookies);
    return response;
  } catch (error) {
    console.log(error);
    return { data: null, status: null };
  }
};

export const getReturnModal = async ({
  order,
  requestId,
  lang,
}: {
  order: string;
  requestId: string;
  lang?: string;
}): Promise<{
  data: RefundReturnInfoResponse | null | undefined;
  status: number | null;
}> => {
  try {
    const [getData] = await Promise.all([
      postDataFromAPI({
        path: '/getmodalreturn',
        headers: { ref: order, requestid: requestId },
        body: { lang },
      }),
    ]);

    const data =
      getData.status == 200 ? (getData.data ? getData.data : null) : null;

    let response: {
      data: RefundReturnInfoResponse | null | undefined;
      status: number | null;
    } = { data: data, status: getData.status };

    if (data) {
      try {
        response.data = RefundReturnInfoResponseConverter.fromJson(data);
      } catch (error) {
        console.log(error);
      }
    }
    // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
    //await saveClientCookieFromResponse(response.data?.cookies);
    return response;
  } catch (error) {
    console.log(error);
    return { data: null, status: null };
  }
};

export const sendRefundRequest = async ({
  order,
  sendData,
}: {
  order: string;
  sendData: FormData;
}): Promise<RefundRequestResponse | null | undefined> => {
  try {
    console.log(sendData);
    const [getData] = await Promise.all([
      postDataFromAPI({
        path: '/orderrefundreq',
        headers: { ref: order },
        form: sendData,
      }),
    ]);
    const data =
      getData.status == 200 ? (getData.data ? getData.data : null) : null;

    let response: RefundRequestResponse | null | undefined = data;

    if (data) {
      try {
        response = RefundRequestResponseConverter.fromJson(data);
      } catch (error) {
        console.log(error);
      }
    }
    // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
    //await saveClientCookieFromResponse(response.data?.cookies);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const submitRefundReturn = async ({
  order,
  sendData,
  lang,
}: {
  order: string;
  sendData: RefundReturnSaveRequest;
  lang?: string;
}): Promise<RefundReturnRequestResponse | null | undefined> => {
  try {
    if (sendData.returnHome) {
      sendData.returnHome = ReturnHomePickupConverter.toJson(
        sendData.returnHome,
      );
    }
    if (sendData.creditNoteAddress) {
      sendData.creditNoteAddress = RequestEtaxConverter.toJson(
        sendData.creditNoteAddress,
      );
    }
    const sendForm = {
      requestid: sendData.requestId,
      creditnote: sendData.creditNote,
      paymentinfo: sendData.paymentInfo,
      jsncreditnote: sendData.creditNoteAddress,
      returnProductMethod: sendData.returnProductMethod,
      reqjsnhome: sendData.returnHome,
      reqjsonpost: sendData.returnPost,
    };
    const form = new FormData();
    form.append('info', JSON.stringify(sendForm));
    if (sendData.images && sendData.images.length > 0) {
      const imageData = sendData.images;
      for (let i = 0; i < imageData.length; i++) {
        form.append('file-' + i, imageData[i]);
      }
    }

    const [getData] = await Promise.all([
      postDataFromAPI({
        path: '/refundreturn',
        lang,
        headers: { ref: order, lang: lang },
        form: form,
      }),
    ]);
    const data =
      getData.status == 200 ? (getData.data ? getData.data : null) : null;

    let response: RefundReturnRequestResponse | null | undefined = data;

    if (data) {
      try {
        response = RefundReturnRequestResponseConverter.fromJson(data);
      } catch (error) {
        console.log(error);
      }
    }
    // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
    //await saveClientCookieFromResponse(response.data?.cookies);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getOrderhistoryRating = async ({
  lang,
  order,
}: {
  lang?: string;
  order?: string;
}): Promise<{
  data: RatingPageResponse | null | undefined;
  status: number | null;
}> => {
  try {
    const [getData] = await Promise.all([
      postDataFromAPI({
        path: '/profile/orderhistory/rating',
        lang,
        body: { rating: order },
      }),
    ]);
    const data =
      getData.status == 200 ? (getData.data ? getData.data : null) : null;

    let response: {
      data: RatingPageResponse | null | undefined;
      status: number | null;
    } = { data: data, status: getData.status };

    if (data) {
      try {
        response.data = RatingPageResponseConverter.fromJson(data);
      } catch (error) {
        console.log(error);
      }
    }
    // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
    await saveClientCookieFromResponse(response.data?.cookies);
    return response;
  } catch (error) {
    console.log(error);
    return { data: null, status: null };
  }
};
export const saveRating = async ({
  order,
  sendData,
  lang,
}: {
  order: string;
  sendData: RatingRequest;
  lang?: string;
}): Promise<{
  data: string | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/saverating',
      headers: { ref: order },
      lang,
      body: sendData,
    }),
  ]);
  const data = getData.data ? getData.data : null;

  let response: {
    data: string | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  //await saveClientCookieFromResponse(response.data?.cookies);
  return response;
};
