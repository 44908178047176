import { modelValue } from '@/utils/modelValue';
import { CookieData } from './Authen.model';

export interface SendLoginRequestModel {
  grant_type?: string;
  scope?: string;
  otp_request_id?: string;
  otp_code?: string;
  username?: string;
  pin?: string;
  password?: string;
  identity?: string;
  identity_code?: string;
}

export enum LoginErrorType {
  // eslint-disable-next-line no-unused-vars
  invalidAuthen = 0, // ให้แสดง Alert ว่า ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง

  // eslint-disable-next-line no-unused-vars
  userAccountNotFound = 1, // Alert ไม่พบบัญชีผู้ใช้ T1

  // eslint-disable-next-line no-unused-vars
  invalidIdentityVerify = 2, // Alert เลขบัตรประชาชนหรือพาสปอร์ตไม่ถูกต้อง

  // eslint-disable-next-line no-unused-vars
  userAccountLock = 3, // Alert ใส่ Password ผิดเกิน 3 ครั้ง และบัญชีถูกล็อค

  // eslint-disable-next-line no-unused-vars
  connectT1Error = 4, // Alert errorMessage

  // eslint-disable-next-line no-unused-vars
  userProfileError = 5, // Alert errorMessage

  // eslint-disable-next-line no-unused-vars
  sendNewLogin = 6, // Call send new login without name

  // eslint-disable-next-line no-unused-vars
  notfoundMobileNumberFromT1 = 7, // Alert errorMessage

  // eslint-disable-next-line no-unused-vars
  defaultOrFrontEndFailed = 9, // Alert errorMessage

  // eslint-disable-next-line no-unused-vars
  getTokenOrConfirmOrderFailed = 10, // Alert errorMessage

  // eslint-disable-next-line no-unused-vars
  catch = 11, // Alert errorMessage

  // eslint-disable-next-line no-unused-vars
  notfoundNameFromT1 = 14, // Call send new login with first_name and last_name
}

export interface SendLoginResponseModel {
  isError: boolean;
  cookies?: CookieData[];
  isbanned: boolean;
  couponPopup?: CouponPopup[];
  errorType?: LoginErrorType;
  errorMessage?: string;
  isSetpin?: boolean;
}

export class SendLoginResponseModelConverter {
  public static fromJson(json: any): SendLoginResponseModel {
    const model: SendLoginResponseModel = {
      isError: json['is_error'] == true ? true : false,
      cookies: json['cookies'],
      isbanned: json['isbanned'] == true ? true : false,
      couponPopup: json['couponPopup']
        ? json['couponPopup'].map((e: CouponPopup) => {
            try {
              e = CouponPopupConverter.fromJson(e);
            } catch (_) {}
            return e;
          })
        : undefined,
      errorType: json['type'],
      errorMessage: json['emsg'],
      isSetpin: json['isSetpin'] ?? undefined,
    };
    return modelValue(model);
  }
}

export interface CouponPopup {
  image?: string | null;
  amount?: string;
  unit?: string;
  endsDate?: string;
}

export class CouponPopupConverter {
  public static fromJson(json: any): CouponPopup {
    const model: CouponPopup = {
      image: json['image'],
      amount: json['amount'],
      unit: json['unit'],
      endsDate: json['endsDate'],
    };
    return modelValue(model);
  }
}
