import {
  MyIdeaDetailPageResponse,
  MyIdeaDetailPageResponseConverter,
  MyIdeaPageResponse,
  MyIdeaPageResponseConverter,
} from '@/models/profile/MyIdea.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { postDataFromAPI } from '@/utils/httpClient';

export const getProfileMyIdea = async ({
  lang,
}: {
  lang?: string;
}): Promise<{
  data: MyIdeaPageResponse | null | undefined;
  status: number | null;
}> => {
  try {
    const [getData] = await Promise.all([
      postDataFromAPI({
        path: '/profile/myidea',
        lang,
      }),
    ]);
    const data =
      getData.status == 200 ? (getData.data ? getData.data : null) : null;
    let response: {
      data: MyIdeaPageResponse | null | undefined;
      status: number | null;
    } = { data: data, status: getData.status };

    if (data) {
      try {
        response.data = MyIdeaPageResponseConverter.fromJson(data);
      } catch (error) {
        console.log(error);
      }
    }
    // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
    await saveClientCookieFromResponse(response.data?.cookies);
    return response;
  } catch (error) {
    console.log(error);
    return { data: null, status: null };
  }
};

export const getProfileMyIdeaDetail = async ({
  lang,
  projectId,
}: {
  lang?: string;
  projectId?: string;
}): Promise<{
  data: MyIdeaDetailPageResponse | null | undefined;
  status: number | null;
}> => {
  try {
    const [getData] = await Promise.all([
      postDataFromAPI({
        path: '/profile/myideacurtaindetail',
        lang,
        body: {
          project_id: projectId,
        },
      }),
    ]);

    const data =
      getData.status == 200 ? (getData.data ? getData.data : null) : null;
    let response: {
      data: MyIdeaDetailPageResponse | null | undefined;
      status: number | null;
    } = { data: data, status: getData.status };

    if (data) {
      try {
        response.data = MyIdeaDetailPageResponseConverter.fromJson(data);
      } catch (error) {
        console.log(error);
      }
    }
    // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
    await saveClientCookieFromResponse(response.data?.cookies);
    return response;
  } catch (error) {
    console.log(error);
    return { data: null, status: null };
  }
};
