import { exceptRedirectList } from '@/constants/defaultValue.constant';

export const getPassportLink = (): string => {
  let noonce = '';
  const arr = '12345abcde';
  for (var i = 50; i > 0; i--) {
    noonce += arr[Math.floor(Math.random() * arr.length)];
  }
  return `https://${
    process.env.NEXT_PUBLIC_THE1_AUTH_URL
  }/oauth/authorize?&client_id=${
    process.env.NEXT_PUBLIC_THE1_CLIENT_ID
  }&response_type=code&state=${Math.floor(
    100000 + Math.random() * 900000,
  )}&noonce=${noonce}&redirect_uri=${
    process.env.NEXT_PUBLIC_BASE_URL_WEB
  }/signin&scope=openid&page=recovery`;
};

export const getRedirectQuery = (asPath: string): string => {
  if (asPath.includes('?redirect=')) {
    return `?redirect=${asPath.split('?redirect=')[1]}`;
  }
  return `${!exceptRedirectList.includes(asPath) ? '?redirect=' + asPath : ''}`;
};
