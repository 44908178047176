import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

interface Props {
  color?: string;
  size?: number;
  borderSize?: number;
}

export default function Spinner({ size, borderSize, color }: Props) {
  return (
    <CircularProgress
      size={size ?? 24}
      thickness={borderSize ?? 4}
      sx={{
        color: color ?? 'white',
      }}
    />
  );
}
