import { Address, AddressConverter } from '@/models/Address.model';
import {
  GeoLocationResponse,
  GeoLocationResponseConverter,
} from '@/models/geoLocation.model';
import {
  AddressPageResponse,
  AddressPageResponseConverter,
} from '@/models/profile/AddressList.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { getDataFromAPI, postDataFromAPI } from '@/utils/httpClient';
import axios from 'axios';

export enum PostAddressStatus {
  // eslint-disable-next-line no-unused-vars
  add = 'N',
  // eslint-disable-next-line no-unused-vars
  edit = 'E',
  // eslint-disable-next-line no-unused-vars
  delete = 'D',
}

export const getAddress = async (
  name?: string,
): Promise<{
  data: Address[] | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/getmultidlv',
      body: {
        anm: name,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: Address[] | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = data.dbitems.map((item: any) =>
        AddressConverter.fromJson(item),
      );
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const postAddress = async (
  address: Address,
  status: PostAddressStatus,
  lang?: string,
): Promise<string | undefined> => {
  const getData = await postDataFromAPI({
    path: '/postmultidlv',
    lang,
    body: {
      nstr: JSON.stringify(AddressConverter.toJson(address)),
      mode: status,
    },
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  if (data?.dbcode === true) {
    return undefined;
  } else {
    return data?.dbmessage && data?.dbmessage !== ''
      ? data?.dbmessage
      : 'Error.';
  }
};

export const getProfileAddressList = async ({
  lang,
}: {
  lang?: string;
}): Promise<{
  data: AddressPageResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/profile/addresslist',
      lang,
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: AddressPageResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = AddressPageResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);
  return response;
};

export const geoLocation = async ({
  zipCode,
  lang,
}: {
  zipCode: string;
  lang?: string;
}): Promise<GeoLocationResponse[] | null | undefined> => {
  const getData = await getDataFromAPI({
    lang,
    path: `/webcall/zipcode/${zipCode}`,
  });
  const data =
    getData.status == 200
      ? getData.data
        ? getData.data
        : undefined
      : undefined;

  let response: GeoLocationResponse[] | null | undefined = data;
  if (data) {
    try {
      response = response?.map((data) =>
        GeoLocationResponseConverter.fromJson(data),
      );
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getPlaceSearch = async ({
  input,
}: {
  input: string;
}): Promise<{ place: string; mainText: string; subText: string }[]> => {
  const getData = await axios.post(`/api/v2/address/getplacesearch`, {
    input,
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: { place: string; mainText: string; subText: string }[] = [];
  if (data) {
    try {
      response = data;
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getMapPlace = async ({
  placeId,
  latitude,
  longitude,
}: {
  placeId?: string;
  latitude?: number;
  longitude?: number;
}): Promise<
  | {
      lat: number;
      lng: number;
      addressText: string;
      locality: string;
      postal: string;
      province: string;
      district: string;
    }
  | undefined
> => {
  const getData = await axios.post(`/api/v2/address/getmapplace`, {
    placeId,
    latitude,
    longitude,
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response:
    | {
        lat: number;
        lng: number;
        addressText: string;
        locality: string;
        postal: string;
        province: string;
        district: string;
      }
    | undefined = undefined;
  if (data) {
    try {
      response = data;
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const savePin = async ({
  addressName,
  lang,
  lat,
  lng,
  postalCode,
  province,
  district,
}: {
  addressName: string;
  lang?: string;
  lat: number;
  lng: number;
  postalCode?: string;
  province?: string;
  district?: string;
}): Promise<{
  isError: boolean;
  message?: string;
}> => {
  const getData = await postDataFromAPI({
    path: '/savepin',
    lang,
    body: {
      addressName,
      lat,
      lng,
      postalCode,
      province,
      district,
    },
  });
  if (getData.status == 200) {
    return { isError: false };
  } else {
    return { isError: true, message: getData.data ?? 'Error' };
  }
};
