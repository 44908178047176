import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getSkuImage } from '@/services/client/skuImageService';
import * as localStorageKey from '@/constants/localStorageKey.constant';

interface MiniCompareProduct {
  sku: string;
  pimImage?: string;
  slugname: string;
  urlimg?: string;
}

interface CompareState {
  result: MiniCompareProduct[];
  isError: boolean;
  isSetStore: boolean;
}

const initialState: CompareState = {
  result: [],
  isError: false,
  isSetStore: false,
};

export const getImageToSetStore = createAsyncThunk<
  MiniCompareProduct[],
  undefined,
  { state: RootState }
>(
  'compare/getImageToSetStore',
  async (_, { getState }: { getState: () => RootState }) => {
    const results: MiniCompareProduct[] = [];
    if (
      getState().compare.isSetStore === false &&
      getState().compare.result.length <= 0
    ) {
      try {
        const compareListStorage = localStorage.getItem(
          localStorageKey.compareList,
        );
        if (compareListStorage) {
          const compareList: { sku: string; slugname: string }[] =
            JSON.parse(compareListStorage);
          for (let i = 0; i < compareList.length; i++) {
            const compareItem = compareList[i];
            const skuImage = await getSkuImage(compareItem.sku);
            results.push({
              sku: compareItem.sku,
              slugname: compareItem.slugname,
              pimImage: skuImage ?? undefined,
              urlimg: skuImage ?? undefined,
            });
          }
        }
      } catch (e: any) {
        throw new Error(e.message ?? '');
      }
    }
    return results;
  },
);

const compareSlice = createSlice({
  name: 'compare',
  initialState: initialState,
  reducers: {
    initCompare: (state) => {
      try {
        const compareListStorage = localStorage.getItem(
          localStorageKey.compareList,
        );
        let results = [];
        if (compareListStorage) {
          const compareList: { sku: string; slugname: string }[] =
            JSON.parse(compareListStorage);
          for (let i = 0; i < compareList.length; i++) {
            const compareItem = compareList[i];
            results.push({
              sku: compareItem.sku,
              slugname: compareItem.slugname,
            });
          }
        }
        state.result = results;
      } catch (_) {}
    },
    resetIsError: (state) => {
      state.isError = false;
    },
    resetCompare: (state) => {
      state.result = [];
      state.isError = false;
      localStorage.removeItem(localStorageKey.compareList);
    },
    mutateCompare: (
      state,
      actions: {
        payload: MiniCompareProduct;
        type: string;
      },
    ) => {
      state.isSetStore = true;
      if (actions.payload.sku) {
        if (state.result.find((e) => e.sku === actions.payload.sku)) {
          state.result = state.result.filter(
            (e) => e.sku !== actions.payload.sku,
          );
          state.isError = false;
          localStorage.setItem(
            localStorageKey.compareList,
            JSON.stringify(
              state.result.map((e) => {
                return { sku: e.sku, slugname: e.slugname };
              }),
            ),
          );
        } else {
          if (state.result.length < 4) {
            state.result.push(actions.payload);
            state.isError = false;
            localStorage.setItem(
              localStorageKey.compareList,
              JSON.stringify(
                state.result.map((e) => {
                  return { sku: e.sku, slugname: e.slugname };
                }),
              ),
            );
          } else {
            state.isError = true;
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getImageToSetStore.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          state.result = action.payload;
        }
        state.isSetStore = true;
      })
      .addCase(getImageToSetStore.rejected, (state) => {
        state.isSetStore = true;
      });
  },
});

export const { initCompare, mutateCompare, resetCompare, resetIsError } =
  compareSlice.actions;

export const compareResultsSelector = (
  store: RootState,
): MiniCompareProduct[] => store.compare.result;

export const isErrorSelector = (store: RootState): boolean =>
  store.compare.isError;

export const isSetStoreSelector = (store: RootState): boolean =>
  store.compare.isSetStore;

export default compareSlice.reducer;
