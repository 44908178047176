import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { RootState } from '@/store/store';
import { ReOrderPageResponse } from '@/models/profile/Orderhistory.model';
import { getReOrder } from '@/services/client/orderhistoryService';

interface OrderhistoryReOrderState {
  result?: ReOrderPageResponse | null;
  isLoading: boolean;
  error?: string;
}

const initialState: OrderhistoryReOrderState = {
  isLoading: false,
};

export const profileOrderhistoryReOrderQuery = createAsyncThunk(
  'orderhistory/reorder/query',
  async ({ lang, order }: { lang?: string; order?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getReOrder({
      lang,
      order,
    });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const OrderhistoryReOrderSlice = createSlice({
  name: 'orderhistoryReOrder',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileOrderhistoryReOrderQuery.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(profileOrderhistoryReOrderQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = action.payload?.data ?? null;
        }
      })
      .addCase(profileOrderhistoryReOrderQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
      });
  },
});

export const { clearError, clearResult } = OrderhistoryReOrderSlice.actions;

export const orderhistoryReOrderResultSelector = (
  store: RootState,
): ReOrderPageResponse | undefined | null => store.orderhistoryReOrder.result;

export const isLoadingOrderhistoryReOrderSelector = (
  store: RootState,
): boolean => store.orderhistoryReOrder.isLoading;

export const errorOrderhistoryReOrderSelector = (
  store: RootState,
): string | undefined => store.orderhistoryReOrder.error;

export default OrderhistoryReOrderSlice.reducer;
