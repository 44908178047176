import { modelValue } from '@/utils/modelValue';

export interface RequestProductRequest {
  subject?: string;
  detail: string;
  name: string;
  email: string;
  mobile: string;
  images?: File[];
}

export interface RequestProductResponse {
  isError: boolean;
  errorMessage?: string;
}
export class RequestProductResponseConverter {
  public static fromJson(json: any): RequestProductResponse {
    const model: RequestProductResponse = {
      isError: json['error'] == true ? true : false,
      errorMessage: json['msg'],
    };
    return modelValue(model);
  }
}
