import { modelValue } from '@/utils/modelValue';

export interface GetUserInfoResponse {
  name: string;
  email: string;
  mobile: string;
}
export class GetUserInfoResponseConverter {
  public static fromJson(json: any): GetUserInfoResponse {
    const model: GetUserInfoResponse = {
      name: json['cname'],
      email: json['email'],
      mobile: json['mob'],
    };
    return modelValue(model);
  }
}
