import {
  ShipmentResponse,
  ShipmentResponseConverter,
} from '@/models/Checkout.model';
import { postServerDataFromAPI } from '@/utils/httpClient';

export enum ShipmentType {
  // eslint-disable-next-line no-unused-vars
  shipment = 0,
  // eslint-disable-next-line no-unused-vars
  repayment = 1,
  // eslint-disable-next-line no-unused-vars
  paylater = 2,
  // eslint-disable-next-line no-unused-vars
  orderhistory = 3,
}

export const getShipmentData = async ({
  lang,
  type,
  ref,
  headers,
}: {
  lang?: string;
  type?: ShipmentType;
  ref?: string;
  headers?: any;
}): Promise<{
  data: ShipmentResponse | null;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postServerDataFromAPI({
      path:
        type === ShipmentType.repayment
          ? '/checkout/paypending'
          : type === ShipmentType.paylater
          ? '/paylater'
          : '/checkout/shipment',
      lang,
      timeout: 100000,
      headers:
        type === ShipmentType.repayment || type === ShipmentType.paylater
          ? { ...headers, ref }
          : {
              ...headers,
              demandslot: true,
            },
    }),
  ]);

  const data = getData.data ? getData.data : null;
  let response: {
    data: ShipmentResponse | null;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = ShipmentResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  if (type !== ShipmentType.repayment && type !== ShipmentType.paylater) {
    // map ExpressFee
    if (response.data) {
      response.data.slots?.map((slot) => {
        try {
          if (slot.sameDayObject) {
            slot.sameDayObject.expressFee = response.data?.expressFee ?? null;
            slot.sameDayObject.oldExpressFee =
              response.data?.oldExpressFee ?? null;
            slot.sameDayObject.bulkyFee = response.data?.bulkyFee ?? null;
            slot.sameDayObject.oldBulkyFee = response.data?.oldBulkyFee ?? null;
          }
        } catch (_) {}
        return slot ?? null;
      });
    }
  }
  return response;
};
