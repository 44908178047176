import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notifyMe } from '@/services/client/notifyMeService';
import { RootState } from '@/store/store';
export interface notifyMeState {
  isSuccess?: boolean;
  isLoading: boolean;
  error?: string;
  sku?: string;
}
const initialState: notifyMeState = {
  isLoading: false,
  sku: undefined,
};

export const notifyQuery = createAsyncThunk(
  'notify/query',
  async ({
    email,
    sku,
    isEmail,
    isApp,
  }: {
    email?: string;
    sku?: string;
    isEmail: boolean;
    isApp: boolean;
  }) => {
    const response = await notifyMe({ email, sku, isEmail, isApp });

    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const notifySlice = createSlice({
  name: 'notify',
  initialState: initialState,
  reducers: {
    clearAll: (state) => {
      state.error = undefined;
      state.isSuccess = undefined;
      state.sku = undefined;
    },
    setSkuNotify: (state, action: { payload: string | undefined }) => {
      state.sku = action.payload;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(notifyQuery.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(notifyQuery.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sku = undefined;
      })
      .addCase(notifyQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.error.message;
        state.sku = undefined;
      });
  },
});

export const { clearAll, setSkuNotify } = notifySlice.actions;

export const isSuccessNotifyMeSelector = (
  store: RootState,
): boolean | undefined | null => store.notifyMe.isSuccess;

export const isLoadingNotifyMeSelector = (store: RootState): boolean =>
  store.notifyMe.isLoading;

export const errorNotifyMeSelector = (
  store: RootState,
): string | undefined | null => store.notifyMe.error;

export const skuNotifyMeSelector = (store: RootState): string | undefined =>
  store.notifyMe.sku;

export default notifySlice.reducer;
