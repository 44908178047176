import {
  ClearCartResponse,
  ClearCartResponseConverter,
  EditCartResponse,
  EditCartResponseConverter,
  MyCartResponse,
  MyCartResponseConverter,
  NextPurchaseCouponResponse,
  NextPurchaseCouponResponseConverter,
  NextPurchaseCouponV2Response,
  NextPurchaseCouponResponseV2Converter,
  SpecialPurchaseResponse,
  SpecialPurchaseResponseConverter,
} from '@/models/MyCart.model';
import { ProductModel } from '@/models/Product.model';
import {
  FreeList,
  FreeListConverter,
  MyUsedVoucherListResponse,
  MyUsedVoucherListResponseConverter,
} from '@/models/profile/MyVoucher.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { getDataFromAPI, postDataFromAPI } from '@/utils/httpClient';

export const getMyCartData = async ({
  lang,
  querySupplier,
}: {
  lang?: string;
  querySupplier?: string;
}): Promise<MyCartResponse | undefined> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/mycart',
      lang,
      body: { query: querySupplier },
      timeout: 30000,
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: MyCartResponse | undefined = data;
  if (data) {
    try {
      response = MyCartResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }

  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response?.cookies);
  return response;
};

export const getClearCart = async (): Promise<
  ClearCartResponse | undefined
> => {
  const [getData] = await Promise.all([getDataFromAPI({ path: '/clearcart' })]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: ClearCartResponse | undefined = data;
  if (data) {
    try {
      response = ClearCartResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export enum EditCartType {
  // eslint-disable-next-line no-unused-vars
  normal = 'normal',
  // eslint-disable-next-line no-unused-vars
  free = 'free',
  // eslint-disable-next-line no-unused-vars
  bundle = 'bundle',
  // eslint-disable-next-line no-unused-vars
  survey = 'survey',
  // eslint-disable-next-line no-unused-vars
  christmas = 'christmas',
}

export const editCartService = async ({
  product,
  qty,
  type,
}: {
  product: ProductModel;
  qty: number;
  type: EditCartType;
}): Promise<EditCartResponse | undefined> => {
  if (type === EditCartType.normal) {
  } else if (type === EditCartType.bundle) {
  }
  let body: { seq: number; qnt: number; issurvey?: boolean } = {
    seq: product.seq,
    qnt: qty,
  };

  if (type === EditCartType.survey) {
    body = {
      seq: product.seq,
      qnt: qty,
      issurvey: true,
    };
  }
  const [getData] = await Promise.all([
    postDataFromAPI({ path: '/editscntrn', body }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: EditCartResponse | undefined = data;
  if (data) {
    try {
      response = EditCartResponseConverter.fromJson(data);
      response.qty = qty;
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const checkSpecialPurchase = async (
  amount: number,
): Promise<SpecialPurchaseResponse | undefined> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/specialpurchase',
      body: {
        amount,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: SpecialPurchaseResponse | undefined = data;
  if (data) {
    try {
      response = SpecialPurchaseResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const checkNextPurchaseCoupon = async (
  orderNumber?: string,
): Promise<NextPurchaseCouponResponse[] | undefined> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/getnextpurchase',
      ...(orderNumber ? { headers: { ref: orderNumber } } : {}),
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: NextPurchaseCouponResponse[] | undefined = data;
  if (data) {
    try {
      response = response?.map((data) =>
        NextPurchaseCouponResponseConverter.fromJson(data),
      );
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getUsedVoucherList = async ({
  lang,
}: {
  lang?: string;
}): Promise<MyUsedVoucherListResponse | null> => {
  const [getData, getDataOmni] = await Promise.all([
    getDataFromAPI({
      path: '/getvouvalidatelist',
      lang,
    }),
    getDataFromAPI({
      path: '/getomnivouvalidatelist',
      lang,
      timeout: 100000,
    }),
  ]);

  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  const dataOmni =
    getDataOmni.status == 200
      ? getDataOmni.data
        ? getDataOmni.data
        : null
      : null;

  let response: MyUsedVoucherListResponse | null = data;
  let responseOmni: MyUsedVoucherListResponse | null = dataOmni;
  if (data) {
    try {
      response = MyUsedVoucherListResponseConverter.fromJson(data);
      response.voucherUsedList = response?.voucherUsedList?.filter(
        (e) => e.isError || (e.maxDiscount && e.maxDiscount !== ''), // เอา Error ออกมาด้วย เพราะ Erro ไม่มี maxDiscount
      );
      // isError จะถูกเคลียค่าก่อนส่ง Ereor มา (หมายถึง getvouvalidatelist เคลียเลย แล้วส่งมาแค่ Error เพราะฉะนั้นไม่ต้อง reload page)
      if (response.voucherUsedList?.length === 0) {
        response = null;
      }
    } catch (error) {
      console.log(error);
    }
  }
  if (dataOmni) {
    try {
      responseOmni = MyUsedVoucherListResponseConverter.fromJson(dataOmni);
      responseOmni.voucherUsedList = responseOmni?.voucherUsedList?.filter(
        (e) => e.isError || (e.maxDiscount && e.maxDiscount !== ''), // เอา Error ออกมาด้วย เพราะ Erro ไม่มี maxDiscount
      );
      // isError จะถูกเคลียค่าก่อนส่ง Ereor มา (หมายถึง getvouvalidatelist เคลียเลย แล้วส่งมาแค่ Error เพราะฉะนั้นไม่ต้อง reload page)
      if (responseOmni.voucherUsedList?.length === 0) {
        responseOmni = null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  return {
    discountVoucher:
      (response?.discountVoucher ?? 0) + (responseOmni?.discountVoucher ?? 0),
    voucherUsedList: [
      ...(response?.voucherUsedList ?? []),
      ...(responseOmni?.voucherUsedList ?? []),
    ],
  };
};

export const getFreeGiftList = async ({
  lang,
  sku,
  ctlId,
  quantity,
  itemSeq,
}: {
  lang?: string;
  sku?: string;
  ctlId?: string;
  quantity?: number;
  itemSeq?: number;
}): Promise<FreeList> => {
  const getData = await postDataFromAPI({
    path: '/freegiftlist',
    lang,
    body: {
      skcode: sku,
      ctlid: ctlId,
      qnt: quantity,
      itmseq: itemSeq,
    },
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: FreeList = data;
  if (data) {
    try {
      response = FreeListConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }

  return response;
};

export const addFreeGift = async ({
  lang,
  seq,
  sku,
  useStock,
  ctlId,
  quantity,
}: {
  lang?: string;
  seq?: number;
  sku?: string;
  useStock?: string;
  ctlId?: string;
  quantity?: number;
}): Promise<{
  dbCode: boolean;
  dbMessage?: string;
}> => {
  const getData = await postDataFromAPI({
    path: '/addfreegift',
    lang,
    body: {
      seq: seq,
      skcode: sku,
      usestock: useStock,
      ctlid: ctlId,
      qnt: quantity,
    },
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response = { dbCode: false, dbMessage: 'Error.' };
  if (data) {
    try {
      response = {
        dbCode: data.dbcode === true ? true : false,
        dbMessage: data.dbmessage,
      };
    } catch (error) {
      console.log(error);
    }
  }

  return response;
};

export const checkNextPurchaseCouponV2 = async (
  orderNumber?: string,
): Promise<NextPurchaseCouponV2Response[] | undefined> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/getnextpurchase/v2',
      ...(orderNumber ? { headers: { ref: orderNumber } } : undefined),
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: NextPurchaseCouponV2Response[] | undefined = data;
  if (data) {
    try {
      response = response?.map((data) =>
        NextPurchaseCouponResponseV2Converter.fromJson(data),
      );
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const editCartChristmasService = async ({
  product,
  qty,
}: {
  product: ProductModel;
  qty: number;
}): Promise<EditCartResponse | undefined> => {
  let body: { seq: number; qnt: number } = {
    seq: product.seq,
    qnt: qty,
  };
  const [getData] = await Promise.all([
    postDataFromAPI({ path: '/editchristmas', body }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: EditCartResponse | undefined = data;
  if (data) {
    try {
      response = EditCartResponseConverter.fromJson(data);
      response.qty = 0;
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
