import { modelValue } from '@/utils/modelValue';
import { CookieData } from './Authen.model';

export interface GetVouValidateResponse {
  response?: GetVouFromCodeResponse;
  secname?: string;
}

export interface GetVouFromCodeResponse {
  dbCode?: boolean;
  dbMessage?: string;
  dbItems?: string[];
  isError?: boolean;
  type?: number;
  fullaVailDate?: string;
  info?: Info;
  // data?: Data;
  ref?: string;
  cookies?: CookieData[];
  secname?: string;
}
export interface Info {
  rewardid?: string;
  ecouponno?: string;
  btype?: string;
  discount?: string;
  vouchertype?: string;
}
export interface DataOutput {
  output?: string;
  seq?: number;
  sku?: string;
  prCode?: string;
  prName?: string;
  qnt?: number;
  upc?: number;
  comment?: string;
  upcshow?: string;
  pimImage?: string;
  imgbase64?: string;
  prn?: string;
  totalAmount?: number;
}

export class GetVouValidateMycartResponseConverter {
  public static fromJson(json: any): GetVouFromCodeResponse {
    const model: GetVouFromCodeResponse = {
      dbCode: json['dbcode'] == true ? true : false,
      dbMessage: json['dbmessage']?.toString().trim(),
      dbItems: json['dbitems'],
      isError: json['is_error'],
      type: json['type'],
      fullaVailDate: json['fullavaildate'],
      info: json['info'] ? InfoConverter.fromJson(json['info']) : undefined,
      // data: json['data'] ? DataConverter.fromJson(json['data']) : undefined,
      ref: json['ref']?.toString().trim(),
      // cookies: json['cookies']
      //   ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
      //   : undefined,
    };
    return modelValue(model);
  }
}
export class InfoConverter {
  public static fromJson(json: any): Info {
    const model: Info = {
      rewardid: json['rewardid'],
      ecouponno: json['ecouponno'],
      btype: json['btype'],
      discount: json['discount'],
      vouchertype: json['vouchertype'],
    };
    return modelValue(model);
  }
}
export class DataOutputConverter {
  public static fromJson(json: any): DataOutput[] {
    const model: DataOutput = {
      output: json['output'],
      seq: json['Seq'],
      sku: json['SKU'],
      prCode: json['PR_CODE'],
      prName: json['PR_NAME'],
      qnt: json['QNT'],
      upc: json['UPC'],
      comment: json['Comment'],
      upcshow: json['UPCSHOW'],
      pimImage: json['pimImage'],
      imgbase64: json['IMGBASE64'],
      prn: json['PRN'],
      totalAmount: json['TotalAmount'],
    };
    return modelValue(model);
  }
}
