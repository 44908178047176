import {
  ConsentInfoResponse,
  ConsentInfoResponseConverter,
  ConsentRequest,
  ConsentRequestConverter,
  ConsentResponse,
  ConsentResponseConverter,
  EditInfoResponse,
  EditInfoResponseConverter,
} from '@/models/profile/EditInfo.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { getDataFromAPI, postDataFromAPI } from '@/utils/httpClient';

export const saveConsent = async (
  req: ConsentRequest,
): Promise<ConsentResponse | null> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/consent',
      body: ConsentRequestConverter.toJson(req),
    }),
  ]);

  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: ConsentResponse | null;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = ConsentResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }

  return response.data;
};

export const getConsentInfo = async (): Promise<{
  data: ConsentInfoResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    getDataFromAPI({
      path: '/consent_info',
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: ConsentInfoResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = ConsentInfoResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }

  return response;
};

export const getProfileEditInfo = async ({
  lang,
}: {
  lang?: string;
}): Promise<{
  data: EditInfoResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/profile/editinfo',
      lang,
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: EditInfoResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = EditInfoResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);
  return response;
};

export const updateCustomer = async ({
  sendData,
  lang,
}: {
  sendData: {
    name: string | null;
    mobile: string | null;
    email: string | null;
  };
  lang?: string;
}): Promise<{
  dbCode: boolean;
  dbMessage: string;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/updatecustomer',
      body: {
        cname: sendData.name,
        mobile: sendData.mobile,
        email: sendData.email,
      },
      lang,
    }),
  ]);
  if (getData.status === 200) {
    if (getData.data.dbcode === true) {
      return {
        dbCode: true,
        dbMessage: '',
      };
    } else {
      return {
        dbCode: false,
        dbMessage: getData.data.dbmessage ? getData.data.dbmessage : '',
      };
    }
  } else {
    return {
      dbCode: false,
      dbMessage: '',
    };
  }
};
