import {
  ConsentInfoResponse,
  EditInfoResponse,
} from '@/models/profile/EditInfo.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as cookieKey from '@/constants/cookieKey.constant';
import {
  getConsentInfo,
  getProfileEditInfo,
  updateCustomer,
} from '@/services/client/editInfoService';
import { RootState } from '@/store/store';
import * as ServerCookies from '@/services/client/cookieService';

interface EditInfoState {
  result?: EditInfoResponse | null;
  resultConsent?: ConsentInfoResponse | null;
  isLoading: boolean;
  error?: string;

  // update customer
  isLoadingUpdate: boolean;
  isUpdateInfoSuccess: boolean;
  errorUpdate?: string;
  isNeedUpdateEmail?: boolean;
}

const initialState: EditInfoState = {
  isLoading: false,
  isLoadingUpdate: false,
  isUpdateInfoSuccess: false,
  isNeedUpdateEmail: false,
};

export const profileEditInfoQuery = createAsyncThunk(
  'editInfo/profile/query',
  async ({ lang }: { lang?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const [response, consent] = await Promise.all([
      getProfileEditInfo({ lang }),
      getConsentInfo(),
    ]);
    // const response = await getProfileEditInfo({ lang });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return { response, consent };
  },
);

export const updateCustomerInfo = createAsyncThunk(
  'editInfo/profile/update',
  async ({
    sendData,
    lang,
  }: {
    sendData: {
      name: string | null;
      mobile: string | null;
      email: string | null;
    };
    lang?: string;
  }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await updateCustomer({ sendData, lang });
    if (response && !response.dbCode) {
      throw new Error(
        `${
          response.dbMessage && response.dbMessage !== ''
            ? response.dbMessage
            : 'Error'
        }`,
      );
    }
    return response;
  },
);

export const editInfoSlice = createSlice({
  name: 'editInfo',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
    clearUpdateSuccess: (state) => {
      state.isUpdateInfoSuccess = false;
    },
    clearErrorUpdate: (state) => {
      state.errorUpdate = undefined;
    },
    setIsNeedUpdateEmail: (state, action) => {
      state.isNeedUpdateEmail = action.payload ?? false;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileEditInfoQuery.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
        state.resultConsent = undefined;
      })
      .addCase(profileEditInfoQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
          };
          state.resultConsent = null;
        } else {
          state.result = action.payload?.response.data ?? null;
          state.resultConsent = action.payload?.consent.data ?? null;
        }
      })
      .addCase(profileEditInfoQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.resultConsent = null;
        state.error = action.error.message;
      });
    builder
      .addCase(updateCustomerInfo.pending, (state) => {
        state.isLoadingUpdate = true;
        state.isUpdateInfoSuccess = false;
      })
      .addCase(updateCustomerInfo.fulfilled, (state, action) => {
        state.isLoadingUpdate = false;
        state.isUpdateInfoSuccess = true;
        if (action.meta.arg.sendData) {
          const updateData = action.meta.arg.sendData;
          if (
            updateData &&
            updateData.email &&
            updateData.email !== '' &&
            updateData.email.substring(
              updateData.email.lastIndexOf('@') + 1,
            ) !== 'dummny.com'
          ) {
            state.isNeedUpdateEmail = false;
          }
          state.result = {
            ...state.result,
            ...(state.result?.info
              ? {
                  info: {
                    ...(state.result?.info ?? {}),
                    email: updateData.email
                      ? updateData.email
                      : state.result?.info?.email,
                    mobile: updateData.mobile
                      ? updateData.mobile
                      : state.result?.info?.mobile ?? '',
                    cname: updateData.name
                      ? updateData.name
                      : state.result?.info?.cname ?? '',
                  },
                }
              : {}),
          };
        }
      })
      .addCase(updateCustomerInfo.rejected, (state, action) => {
        state.isLoadingUpdate = false;
        state.isUpdateInfoSuccess = false;
        state.errorUpdate = action.error.message;
      });
  },
});

export const {
  clearError,
  clearResult,
  clearUpdateSuccess,
  clearErrorUpdate,
  setIsNeedUpdateEmail,
} = editInfoSlice.actions;

export const editInfoProfileResultSelector = (
  store: RootState,
): EditInfoResponse | undefined | null => store.profileEditInfo.result;

export const editInfoProfileResultConsentSelector = (
  store: RootState,
): ConsentInfoResponse | undefined | null =>
  store.profileEditInfo.resultConsent;

export const isLoadingEditInfoProfileSelector = (store: RootState): boolean =>
  store.profileEditInfo.isLoading;

export const errorEditInfoProfileSelector = (
  store: RootState,
): string | undefined => store.profileEditInfo.error;

export const isLoadingUpdateSelector = (store: RootState): boolean =>
  store.profileEditInfo.isLoadingUpdate;

export const isUpdateInfoSuccessSelector = (store: RootState): boolean =>
  store.profileEditInfo.isUpdateInfoSuccess;

export const erroUpdateInfoSelector = (store: RootState): string | undefined =>
  store.profileEditInfo.errorUpdate;

export const isNeedUpdateEmailSelector = (
  store: RootState,
): boolean | undefined => store.profileEditInfo.isNeedUpdateEmail;

export default editInfoSlice.reducer;
