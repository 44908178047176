import {
  checkStockRefService,
  checkStockService,
  getStoreData,
} from '@/services/client/storeService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import * as defaultValue from '@/constants/defaultValue.constant';
import { RootState } from '../store';
import { getDistance } from '@/utils/store';
import { StoreData } from '@/models/Store.model';

interface StoreState {
  isOpenModal: boolean;
  currentStore?: StoreData;
  storeDataList?: StoreData[];
  storeDataWithStockList?: StoreData[];
  isLoading: boolean;
  isCheckStockStore: boolean;
  addToCartSku?: string;
  addToCartQty?: number;
  addToCartWithService?: string;
  isHybrid: boolean;
  isLongtail?: boolean;
  isHomeFlag?: boolean;
  isService?: boolean;
  hasStock?: boolean;
  isOpenHybridModal: boolean;
  // eslint-disable-next-line no-unused-vars
  callBackStoreCode?: (code: string) => void;
}

const initialState: StoreState = {
  isOpenModal: false,
  isLoading: false,
  isHybrid: false,
  isCheckStockStore: false,
  isOpenHybridModal: false,
};

export const openStockModal = createAsyncThunk(
  'store/openStockModal',
  async ({
    sku,
    // eslint-disable-next-line no-unused-vars
    withService,
    type,
    // eslint-disable-next-line no-unused-vars
    qty,
    // eslint-disable-next-line no-unused-vars
    isCheckStockStore,
    // eslint-disable-next-line no-unused-vars
    isLongtail,
    // eslint-disable-next-line no-unused-vars
    isHomeFlag,
    // eslint-disable-next-line no-unused-vars
    hasStock,
    // eslint-disable-next-line no-unused-vars
    isService,
  }: {
    sku?: string;
    withService?: string;
    type: 1 | 2 | 3;
    qty?: number;
    isCheckStockStore?: boolean;
    isLongtail?: boolean;
    isHomeFlag?: boolean;
    hasStock?: boolean;
    isService?: boolean;
  }) => {
    if (sku) {
      const response = await checkStockService(sku, type);
      return response;
    } else {
      return undefined;
    }
  },
);

export const openCheckoutStockModal = createAsyncThunk(
  'store/openCheckoutStockModal',
  async () => {
    const response = await checkStockRefService();
    return response;
  },
);

export const storeQuery = createAsyncThunk<
  StoreData[],
  { position?: { lat: number; lng: number } },
  { state: RootState }
>(
  'store/query',
  async (
    // position: GeolocationPosition ใช้ใน action นะ อย่าลบ
    // eslint-disable-next-line no-unused-vars
    { position }: { position?: { lat: number; lng: number } },
    { getState }: { getState: () => RootState },
  ) => {
    const oldStoreDataList = getState().store.storeDataList;
    let response: StoreData[] = oldStoreDataList ?? [];
    if (oldStoreDataList === undefined || oldStoreDataList.length === 0) {
      response = await getStoreData();
    }
    return response;
  },
);

export const storeSlice = createSlice({
  name: 'store',
  initialState: initialState,
  reducers: {
    saveStoreChanged: (
      state,
      action: { payload: { stcode: string; isHide?: boolean }; type: string },
    ) => {
      const findStore = state.storeDataList?.find(
        (e: any) => e.code === action.payload.stcode,
      );
      if (findStore) {
        // TODO: Show hybrid popup
        state.currentStore = findStore;
        Cookies.set(cookieKey.stcode, action.payload.stcode, {
          secure: process.env.NEXT_PUBLIC_COOKIE_ENV !== 'development',
        });
        if (
          state.currentStore.isHybrid === true &&
          action.payload.isHide !== true
        ) {
          state.isOpenHybridModal = true;
        }
      }
    },
    openModal: (state) => {
      state.storeDataWithStockList = undefined;
      state.addToCartQty = undefined;
      state.addToCartSku = undefined;
      state.addToCartWithService = undefined;
      state.isOpenModal = true;
      state.isCheckStockStore = false;
      state.isLongtail = undefined;
      state.isHomeFlag = undefined;
      state.isService = undefined;
      state.hasStock = undefined;
    },
    openModalCallBack: (
      state,
      // eslint-disable-next-line no-unused-vars
      action: { payload: (code: string) => void },
    ) => {
      state.storeDataWithStockList = undefined;
      state.addToCartQty = undefined;
      state.addToCartSku = undefined;
      state.addToCartWithService = undefined;
      state.isOpenModal = true;
      state.isCheckStockStore = false;
      state.isLongtail = undefined;
      state.isHomeFlag = undefined;
      state.isService = undefined;
      state.hasStock = undefined;
      state.callBackStoreCode = action.payload;
    },
    closeModal: (state) => {
      state.storeDataWithStockList = undefined;
      state.addToCartQty = undefined;
      state.addToCartSku = undefined;
      state.addToCartWithService = undefined;
      state.isOpenModal = false;
      state.callBackStoreCode = undefined;
    },
    setHybrid: (state, action: { payload: boolean }) => {
      state.isHybrid = action.payload === true ? true : false;
    },
    closeHybridModal: (state) => {
      state.isOpenHybridModal = false;
    },
  },
  extraReducers: (builder) => {
    // pending, fullfilled
    builder
      .addCase(openStockModal.pending, (state) => {
        state.addToCartQty = undefined;
        state.addToCartSku = undefined;
        state.addToCartWithService = undefined;
        state.isOpenModal = true;
        state.storeDataWithStockList = [];
        state.isLoading = true;
      })
      .addCase(openStockModal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.storeDataWithStockList = action.payload;
        state.addToCartQty = action.meta.arg.qty;
        state.addToCartSku = action.meta.arg.sku;
        state.addToCartWithService = action.meta.arg.withService;
        state.isCheckStockStore = action.meta.arg.isCheckStockStore === true;
        if (action.meta.arg.isCheckStockStore === true) {
          state.isCheckStockStore = true;
          state.isLongtail = action.meta.arg.isLongtail === true;
          state.isHomeFlag = action.meta.arg.isHomeFlag === true;
          state.hasStock = action.meta.arg.hasStock === true;
          state.isService = action.meta.arg.isService === true;
        } else {
          state.isCheckStockStore = false;
          state.isLongtail = undefined;
          state.isHomeFlag = undefined;
          state.isService = undefined;
          state.hasStock = undefined;
        }
      });
    // fullfilled
    builder.addCase(storeQuery.fulfilled, (state, action) => {
      if (action.payload) {
        const position = action.meta.arg.position;
        const storeCookie = Cookies.get(cookieKey.stcode);
        let storeList: StoreData[] = action.payload;
        let currentStore = null;
        if (position) {
          storeList = storeList
            .map((e) => {
              return {
                ...e,
                distance: getDistance(
                  position.lat,
                  position.lng,
                  e.latitude,
                  e.longitude,
                ),
              };
            })
            .sort((a, b) => a.distance - b.distance);
        }
        if (storeCookie) {
          currentStore = storeList.find((e) => e.code === storeCookie);
        }
        // ปิดไว้เพราะต้องการให้ลูกค้าเลือกสาขาเอง
        // if (!currentStore) {
        //   if (position) {
        //     currentStore = storeList[0];
        //   } else {
        //     currentStore = storeList.find(
        //       (e) => e.code === defaultValue.defaultStoreCode,
        //     );
        //   }
        // }
        // if (!currentStore) {
        //   currentStore = storeList[0];
        // }
        if (currentStore) {
          state.currentStore = currentStore;
          Cookies.set(cookieKey.stcode, currentStore.code, {
            secure: process.env.NEXT_PUBLIC_COOKIE_ENV !== 'development',
          });
        }
        state.storeDataList = storeList;
      }
    });
  },
});

export const {
  openModal,
  openModalCallBack,
  closeModal,
  saveStoreChanged,
  setHybrid,
  closeHybridModal,
} = storeSlice.actions;

export const storeDataListSelector = (
  store: RootState,
): StoreData[] | undefined => store.store.storeDataList;

export const currentStoreSelector = (store: RootState): StoreData | undefined =>
  store.store.currentStore ??
  (store.store.storeDataList ?? []).find(
    (e: StoreData) =>
      e.code === Cookies.get(cookieKey.stcode) ?? defaultValue.defaultStoreCode,
  );

export const addToCartQtySelector = (store: RootState): number | undefined =>
  store.store.addToCartQty;

export const addToCartSkuSelector = (store: RootState): string | undefined =>
  store.store.addToCartSku;

export const addToCartWithServiceSelector = (
  store: RootState,
): string | undefined => store.store.addToCartWithService;

export const isOpenModalSelector = (store: RootState): boolean =>
  store.store.isOpenModal;

export const isHybridSelector = (store: RootState): boolean =>
  store.store.isHybrid;

export const storeDataWithStockListSelector = (
  store: RootState,
): StoreData[] | undefined => store.store.storeDataWithStockList;

export const isCheckStockStoreSelector = (store: RootState): boolean =>
  store.store.isCheckStockStore;

export const isLongtailSelector = (store: RootState): boolean | undefined =>
  store.store.isLongtail;

export const isHomeFlagSelector = (store: RootState): boolean | undefined =>
  store.store.isHomeFlag;

export const isServiceSelector = (store: RootState): boolean | undefined =>
  store.store.isService;

export const hasStockSelector = (store: RootState): boolean | undefined =>
  store.store.hasStock;

export const isLoadingSelector = (store: RootState): boolean =>
  store.store.isLoading;

export const isOpenHybridModalSelector = (store: RootState): boolean =>
  store.store.isOpenHybridModal;

export const callBackStoreCodeSelector = (
  store: RootState,
  // eslint-disable-next-line no-unused-vars
): ((code: string) => void) | undefined => store.store.callBackStoreCode;

export default storeSlice.reducer;
