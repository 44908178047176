import ListItem from '@mui/material/ListItem';
import { useTranslation } from 'next-i18next';
import React from 'react';
import AppLink from '../../Layout/handler/AppLink';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useAppDispatch } from '@/store/store';
import { signOut } from '@/store/slices/authenSlice';
import * as showDialog from '@/utils/showDialog';
import { useRouter } from 'next/router';
// import { getPassportLink } from '@/utils/theone';
import { closeAddressForm } from '@/store/slices/profile/addressSlice';
import ProfileLayoutMenuResponsive from './ProfileLayoutMenuResponsive';
import { useSelector } from 'react-redux';
import { isNeedUpdateEmailSelector } from '@/store/slices/profile/editInfoSlice';
import Image from 'next/image';

interface ProfileMenu {
  textKey: string;
  isHeader?: boolean;
  pathname?: string;
  link?: string;
  onClickSameRoute?: () => void;
  // eslint-disable-next-line no-unused-vars
  onClick?: (e: any) => void;
}

interface Props {
  onlyList?: boolean;
}

export default function ProfileLayoutMenu({ onlyList }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const isNeedUpdateEmail = useSelector(isNeedUpdateEmailSelector);

  const profileMenuList: ProfileMenu[] = [
    {
      textKey: 'myaccount',
      isHeader: true,
    },
    {
      textKey: 'editmyaccount',
      pathname: '/profile/accountsummary',
      link: '/profile/accountsummary',
    },
    {
      textKey: 'orderhistory',
      pathname: '/profile/orderhistory',
      link: '/profile/orderhistory',
    },
    {
      textKey: 'orderrefund',
      pathname: '/profile/orderrefund',
      link: '/profile/orderrefund',
    },
    {
      textKey: 'myreview',
      pathname: '/profile/myreview',
      link: '/profile/myreview',
    },
    {
      textKey: 'wishlist',
      pathname: '/profile/wishlist',
      link: '/profile/wishlist',
    },
    {
      textKey: 'mycoupon',
      pathname: '/profile/myvoucher',
      link: '/profile/myvoucher',
    },
    {
      textKey: 'myreward',
      pathname: '/profile/campaign',
      link: '/profile/campaign',
    },
    {
      textKey: 'myidea',
      pathname: '/profile/myidea',
      link: '/profile/myidea',
    },
    {
      textKey: 'personalinformation',
      isHeader: true,
    },
    {
      textKey: 'editpersonalinformation',
      pathname: '/profile/editinfo',
      link: '/profile/editinfo',
    },
    {
      textKey: 'editmyaddress',
      pathname: '/profile/addresslist',
      link: '/profile/addresslist',
      onClickSameRoute: () => {
        dispatch(closeAddressForm());
      },
    },
    // {
    //   textKey: 'changepassword',
    //   onClick: (e: any) => {
    //     e.preventDefault();
    //     router.push(getPassportLink());
    //   },
    // },
    {
      textKey: 'signout',
      onClick: (e: any) => {
        e.preventDefault();
        showDialog.showMessageWithButton({
          text: t('doyouwattologout'),
          primaryButton: {
            name: t('ok'),
            callBack: () => {
              dispatch(signOut());
              if ((window as any).FB) {
                (window as any).FB.getLoginStatus(function (response: any) {
                  if (response && response.authResponse) {
                    (window as any).FB.logout(function () {});
                  }
                });
              }
            },
          },
          secondaryButton: {
            name: t('cancel'),
          },
        });
      },
    },
  ];

  const handleClickList = (item: ProfileMenu, e: any) => {
    if (router.pathname === item.pathname) {
      if (item.onClickSameRoute) {
        item.onClickSameRoute?.();
      } else {
        e.preventDefault();
      }
    } else if (item.onClick) {
      item.onClick?.(e);
    }
  };
  const MenuList = profileMenuList.map((item, index) => {
    return item.isHeader ? (
      <ListItem key={index} className={`pt-2 ps-2 font-bold`}>
        {t(item.textKey)}
      </ListItem>
    ) : (
      <ListItem
        value={item.pathname}
        className={profileMenuList.length !== index + 1 ? 'border-b' : ''}
        key={index}
        disablePadding
      >
        <AppLink className="w-full h-full bg-opacity-50" href={item.link}>
          <ListItemButton
            onClick={(e: any) => {
              handleClickList(item, e);
            }}
            sx={{
              '&.Mui-selected': {
                xs: {
                  backgroundColor: 'white',
                },
                lg: {
                  backgroundColor: '#e4a3a320',
                },
              },
              '&.Mui-selected:hover': {
                xs: {
                  backgroundColor: 'white',
                },
                lg: {
                  backgroundColor: '#e4a3a320',
                },
              },
              color:
                item.pathname && router.pathname.includes(item.pathname)
                  ? '#B8292F'
                  : '',
            }}
            selected={
              item.pathname !== undefined &&
              item.pathname !== null &&
              router.pathname.includes(item.pathname)
            }
            className={`bg-white selection:bg-white ${
              item.pathname && router.pathname.includes(item.pathname)
                ? 'text-primary font-bold'
                : ''
            }`}
          >
            <ListItemText
              disableTypography
              primary={
                <div className="flex">
                  {t(item.textKey)}
                  {item.textKey === 'editpersonalinformation' &&
                    isNeedUpdateEmail && (
                      <Image
                        src="/images/svg/header/profile_warning.svg"
                        width={16}
                        height={16}
                        className="ms-2"
                        alt="profile_warning"
                      />
                    )}
                </div>
              }
            />
          </ListItemButton>
        </AppLink>
      </ListItem>
    );
  });
  return onlyList === true ? (
    <>{MenuList}</>
  ) : (
    <div className="text-base leading-5 bg-white">
      <nav aria-label="main mailbox folders">
        <ProfileLayoutMenuResponsive
          value={
            (
              profileMenuList.find((obj) => {
                if (
                  obj['pathname'] &&
                  router.pathname.includes(obj['pathname'])
                ) {
                  return true;
                }
                return false;
              }) || {}
            ).pathname || ''
          }
        >
          {MenuList}
        </ProfileLayoutMenuResponsive>
      </nav>
    </div>
  );
}
