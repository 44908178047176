import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

interface Props {
  height?: number;
  color?: string;
  backgroundColor?: string;
  value?: number;
}

export default function StoreProgressBar({
  color,
  backgroundColor,
  height,
  value,
}: Props) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: height ?? 10,
    borderRadius: 5,
    borderWidth: 1,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: backgroundColor ?? theme.palette.grey[200],
      borderColor: '#FFFFFF',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: color ?? '#B8292F',
    },
  }));
  return <BorderLinearProgress variant="determinate" value={value ?? 0} />;
}
