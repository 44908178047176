import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { RootState } from '@/store/store';
import {
  MyIdeaDetailPageResponse,
  MyIdeaPageResponse,
} from '@/models/profile/MyIdea.model';
import {
  getProfileMyIdea,
  getProfileMyIdeaDetail,
} from '@/services/client/myIdeaService';

interface MyIdeaState {
  result?: MyIdeaPageResponse | null;
  isLoading: boolean;
  error?: string;
  //MyIdea Detail
  resultMyIdeaDetail?: MyIdeaDetailPageResponse | null;
  isLoadingMyIdeaDetail: boolean;
  errorMyIdeaDetail?: string;
}

const initialState: MyIdeaState = {
  isLoading: false,
  isLoadingMyIdeaDetail: false,
};

export const profileMyIdeaQuery = createAsyncThunk(
  'profile/myidea/query',
  async ({ lang }: { lang?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileMyIdea({
      lang,
    });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const profileMyIdeaDetailQuery = createAsyncThunk(
  'profile/myidea/detail/query',
  async ({ lang, projectId }: { lang?: string; projectId?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileMyIdeaDetail({
      lang,
      projectId,
    });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const MyIdeaSlice = createSlice({
  name: 'myidea',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
      state.errorMyIdeaDetail = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
      state.resultMyIdeaDetail = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileMyIdeaQuery.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(profileMyIdeaQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
            curtainContents: [],
          };
        } else {
          state.result = action.payload?.data ?? null;
        }
      })
      .addCase(profileMyIdeaQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(profileMyIdeaDetailQuery.pending, (state) => {
        state.isLoadingMyIdeaDetail = true;
        state.resultMyIdeaDetail = undefined;
      })
      .addCase(profileMyIdeaDetailQuery.fulfilled, (state, action) => {
        state.isLoadingMyIdeaDetail = false;
        if (action.payload === null) {
          state.resultMyIdeaDetail = {
            info: null,
          };
        } else {
          state.resultMyIdeaDetail = action.payload?.data ?? null;
        }
      })
      .addCase(profileMyIdeaDetailQuery.rejected, (state, action) => {
        state.isLoadingMyIdeaDetail = false;
        state.resultMyIdeaDetail = null;
        state.errorMyIdeaDetail = action.error.message;
      });
  },
});

export const { clearError, clearResult } = MyIdeaSlice.actions;

export const myIdeaProfileResultSelector = (
  store: RootState,
): MyIdeaPageResponse | undefined | null => store.profileMyIdea.result;

export const isLoadingMyIdeaProfileSelector = (store: RootState): boolean =>
  store.profileMyIdea.isLoading;

export const errorMyIdeaProfileSelector = (
  store: RootState,
): string | undefined => store.profileMyIdea.error;

//MyIdea Detail
export const myIdeaDetailProfileResultSelector = (
  store: RootState,
): MyIdeaDetailPageResponse | undefined | null =>
  store.profileMyIdea.resultMyIdeaDetail;

export const isLoadingMyIdeaDetailProfileSelector = (
  store: RootState,
): boolean => store.profileMyIdea.isLoadingMyIdeaDetail;

export const errorMyIdeaDetailProfileSelector = (
  store: RootState,
): string | undefined => store.profileMyIdea.errorMyIdeaDetail;

export default MyIdeaSlice.reducer;
