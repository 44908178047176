import * as cookieKey from '@/constants/cookieKey.constant';
import Cookies from 'js-cookie';

export const getGuestID = (): string => {
  let guestId = Cookies.get(cookieKey.guestId);
  if (!guestId) {
    guestId = `Guest-${Date.now().toString()}`;
    Cookies.set(cookieKey.guestId, guestId);
  }
  return guestId;
};
