import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { RootState } from '@/store/store';
import {
  CampaignDetailPageResponse,
  CampaignPageResponse,
  CampaignPageState,
} from '@/models/profile/Campaign.model';
import {
  getProfileCampaign,
  getProfileCampaignDetail,
} from '@/services/client/campaignService';

interface CampaignState {
  result?: CampaignPageResponse | null;
  isLoading: boolean;
  error?: string;
  //Campaign Detail
  resultCampaignDetail?: CampaignDetailPageResponse | null;
  isLoadingCampaignDetail: boolean;
  errorCampaignDetail?: string;
}

const initialState: CampaignState = {
  isLoading: false,
  isLoadingCampaignDetail: false,
};

export const profileCampaignQuery = createAsyncThunk(
  'profile/campaign/query',
  async ({ lang, state }: { lang?: string; state?: CampaignPageState }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileCampaign({
      lang,
      state,
    });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const profileCampaignDetailQuery = createAsyncThunk(
  'profile/campaign/detail/query',
  async ({
    lang,
    campaign,
    slug,
  }: {
    lang?: string;
    campaign?: string;
    slug?: string;
  }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileCampaignDetail({
      lang,
      campaign,
      slug,
    });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const CampaignSlice = createSlice({
  name: 'campaign',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
      state.errorCampaignDetail = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
      state.resultCampaignDetail = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileCampaignQuery.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(profileCampaignQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = action.payload?.data ?? null;
        }
      })
      .addCase(profileCampaignQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(profileCampaignDetailQuery.pending, (state) => {
        state.isLoadingCampaignDetail = true;
        state.resultCampaignDetail = undefined;
      })
      .addCase(profileCampaignDetailQuery.fulfilled, (state, action) => {
        state.isLoadingCampaignDetail = false;
        if (action.payload === null) {
          state.resultCampaignDetail = {
            info: null,
          };
        } else {
          state.resultCampaignDetail = action.payload?.data ?? null;
        }
      })
      .addCase(profileCampaignDetailQuery.rejected, (state, action) => {
        state.isLoadingCampaignDetail = false;
        state.resultCampaignDetail = null;
        state.errorCampaignDetail = action.error.message;
      });
  },
});

export const { clearError, clearResult } = CampaignSlice.actions;

export const campaignProfileResultSelector = (
  store: RootState,
): CampaignPageResponse | undefined | null => store.profileCampaign.result;

export const isLoadingCampaignProfileSelector = (store: RootState): boolean =>
  store.profileCampaign.isLoading;

export const errorCampaignProfileSelector = (
  store: RootState,
): string | undefined => store.profileCampaign.error;

//Campaign Detail
export const campaignDetailProfileResultSelector = (
  store: RootState,
): CampaignDetailPageResponse | undefined | null =>
  store.profileCampaign.resultCampaignDetail;

export const isLoadingCampaignDetailProfileSelector = (
  store: RootState,
): boolean => store.profileCampaign.isLoadingCampaignDetail;

export const errorCampaignDetailProfileSelector = (
  store: RootState,
): string | undefined => store.profileCampaign.errorCampaignDetail;

export default CampaignSlice.reducer;
