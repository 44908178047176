import {
  BundleFreegiftModel,
  BundleFreegiftModelConverter,
} from '@/models/pdp/BundleFreeGift.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const getBundleFreeGift = async ({
  lang,
  skcode,
  quantity,
}: {
  lang?: string;
  skcode?: string;
  quantity?: number;
}): Promise<BundleFreegiftModel | null | undefined> => {
  const getData = await postDataFromAPI({
    path: '/bundlefreegift',
    lang,
    body: { lang, skcode, qnt: quantity },
  });

  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: BundleFreegiftModel | null | undefined = data;
  if (data) {
    try {
      response = BundleFreegiftModelConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
