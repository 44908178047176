import { postDataFromAPI } from '@/utils/httpClient';

export const notifyMe = async ({
  email,
  sku,
  isEmail,
  isApp,
}: {
  email?: string;
  sku?: string;
  isEmail?: boolean;
  isApp?: boolean;
}): Promise<{
  data: string | null;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/notifyproduct',
      body: {
        email,
        sku,
        isEmail,
        isApp,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: {
    data: string | null;
    status: number | null;
  } = { data: data, status: getData.status };
  return response;
};
