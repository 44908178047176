import { StoreData } from '@/models/Store.model';
import { branchNameLang } from '@/utils/store';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { useTranslation } from 'next-i18next';
import React from 'react';
import Image from 'next/image';

interface Props {
  store: StoreData;
  isActive?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  onSelectedStore?: () => void;
}

export default function StoreMarker({
  store,
  isActive,
  onClick,
  onClose,
  onSelectedStore,
}: Props) {
  const { t, i18n } = useTranslation('');
  // const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  // const handleResize = () => {
  //   setWindowWidth(window.innerWidth);
  // };

  // useEffect(() => {
  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  return (
    <Marker
      zIndex={isActive === true ? 9990 : 0}
      position={{
        lat: store.latitude,
        lng: store.longitude,
      }}
      icon={{
        url:
          isActive === true
            ? '/images/icons/store_location_pin.png'
            : '/images/icons/store_location_pin.png',
        scaledSize: new window.google.maps.Size(55, 60),
      }}
      onClick={onClick}
    >
      {isActive && (
        <InfoWindow onCloseClick={onClose}>
          <div className="w-auto font-kitti text-xs leading-3">
            <div className="text-primary">
              {branchNameLang({
                store,
                lang: i18n.language,
              })}
            </div>
            <div className="mt-0.5">
              <span className="font-semibold me-1">{t('address')}</span>
              {i18n.language === 'th' ? store.address : store.addressEN}
            </div>
            <div className="mt-0.5">
              <span className="font-semibold me-1">{t('phonenumber')}</span>
              {store.phoneNumber ? store.phoneNumber : '-'}
            </div>
            <div className="mt-0.5">
              <span className="font-semibold me-1">
                {t('operatinghourseveryday')}
              </span>
              {store.openTime} - {store.closeTime} {t('n')}
            </div>
            <div className="mt-0.5">
              <span className="font-semibold me-1">
                {t('pickuptimeeveryday')}
              </span>
              {store.pickupTimeStart} - {store.pickupTimeEnd} {t('n')}
            </div>
            {onSelectedStore !== undefined ? (
              <div className="mt-0.5">
                <div
                  className="select-none cursor-pointer rounded text-white bg-primary hover:bg-secondaryPurple w-14 text-center p-1"
                  onClick={() => {
                    onSelectedStore?.();
                  }}
                >
                  {t('choosethisbranch')}
                </div>
              </div>
            ) : (
              <a
                className="hover:underline mt-1.5"
                href={`https://maps.google.com/?saddr${store.latitude},${store.longitude}&daddr=${store.latitude},${store.longitude}`}
                target="_blank"
              >
                <div className="mt-0.5 text-center flex flex-col justify-center">
                  <div className="mx-auto">
                    <Image
                      src="/images/icons/google-maps.png"
                      alt="google-maps"
                      width={24}
                      height={24}
                    />
                  </div>
                  <div className="text-primary">
                    {t('storelocator:openingooglemap')}
                  </div>
                </div>
              </a>
            )}
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}
