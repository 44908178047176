import {
  closeHybridModal,
  isOpenHybridModalSelector,
} from '@/store/slices/storeSlice';
import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomModal from '../common/CustomModal';
import { useAppDispatch } from '@/store/store';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { exceptHybridPopup } from '@/constants/defaultValue.constant';

interface Props {
  isChanging: boolean;
}

export default function HybridPopup({ isChanging }: Props) {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isOpenHybridModal = useSelector(isOpenHybridModalSelector);
  const handleClose = () => {
    dispatch(closeHybridModal());
  };
  const isHide = exceptHybridPopup.includes(
    router.pathname.toLocaleLowerCase(),
  );

  useEffect(() => {
    if (isOpenHybridModal) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  return (
    <CustomModal
      open={isOpenHybridModal && !isHide && !isChanging}
      onClose={handleClose}
      closeButtonColor="white"
      bgColor="[#00000000]"
      isNoShadow
      isWidthAlwayAuto
    >
      <div className="overflow-hidden min-w-[80vw] max-width-container p-1 lg:p-3">
        <div className="bg-white rounded-t px-6 py-3 flex">
          <Image
            src={`/images/logo/header/hybridlogo.svg`}
            alt="logo"
            className="h-auto md:h-12 w-auto mx-auto"
            width={274}
            height={54}
          />
        </div>
        <div className="rounded-b relative">
          <div className="hidden md:block">
            <Image
              src={`/images/hybrid/popupbg.png`}
              alt="popupbg"
              className="w-full"
              width={1115}
              height={510}
            />
          </div>
          <div className="block md:hidden">
            <Image
              src={`/images/hybrid/popupbg-mobile.png`}
              alt="popupbg mobile"
              className="w-full"
              width={351}
              height={432}
            />
          </div>
          <div className="absolute lg:left-1/2 lg:-translate-x-1/2 top-2 lg:top-4 text-center leading-5">
            <div className="font-bold text-sm lg:text-2xl">
              {t('experience')}
              <span className="text-secondaryGreen">{t('new')}</span>
              {t('homestory')}
              <span className="text-secondaryGreen">{t('noend')}</span>
            </div>
            <div className="text-xs lg:text-base px-1">
              {t('theaestheticsofshopp')}
            </div>
            <div className="pt-2">
              <button
                className="btn bg-black text-white font-bold rounded py-2 p-8"
                onClick={handleClose}
              >
                {t('shopnow')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
