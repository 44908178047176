import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  PostAddressStatus,
  getAddress,
  postAddress,
} from '@/services/client/addressService';
import { Address } from '@/models/Address.model';
import { saveDownloadQuotationLog } from '@/services/client/requestQuotationService';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';

interface QuotationState {
  isLoadingAddress: boolean;
  isLoadingAddressQuotation: boolean;
  isError: boolean;
  addressResult?: Address[] | null;
  addressQuotation?: Address | null;
  isErrorAddressQuotation: boolean;
  //save new address
  isLoadingSaveAddressQuotation: boolean;
  errorSaveAddressQuotation?: string;
  saveAddressQuotationSuccess?: boolean;
}

const initialState: QuotationState = {
  isLoadingAddress: false,
  isLoadingAddressQuotation: false,
  isLoadingSaveAddressQuotation: false,
  isError: false,
  isErrorAddressQuotation: false,
};

export const getAllAddress = createAsyncThunk(
  'quotation/addressQuery',
  async () => {
    const response = await getAddress();
    return response;
  },
);

export const getAddressQuotation = createAsyncThunk(
  'quotation/address',
  async ({ name }: { name: string }) => {
    const response = await getAddress(name);
    return response;
  },
);

export const saveDownloadQuotation = createAsyncThunk(
  'quotation/saveLog',
  async ({ order }: { order: string }) => {
    const response = await saveDownloadQuotationLog({ order });
    return response;
  },
);

export const saveQuotationAddress = createAsyncThunk(
  'quotation/saveAddress',
  async ({ lang, address }: { lang?: string; address: Address }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await postAddress(address, PostAddressStatus.add, lang);
    if (response) {
      throw new Error(`${response}`);
    }
    return response;
  },
);

const quotationSlice = createSlice({
  name: 'quotation',
  initialState: initialState,
  reducers: {
    clearAddressQuotationResult: (state) => {
      state.addressQuotation = undefined;
    },
    clearAllAddressResult: (state) => {
      state.addressResult = undefined;
    },
    clearErrorResult: (state) => {
      state.isError = false;
      state.isErrorAddressQuotation = false;
    },
    clearSaveAddressQuotationSuccess: (state) => {
      state.saveAddressQuotationSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAddress.pending, (state) => {
        state.isLoadingAddress = true;
        state.addressResult = undefined;
      })
      .addCase(getAllAddress.fulfilled, (state, action) => {
        state.isLoadingAddress = false;
        const adressResult = action.payload.data;
        if (
          adressResult &&
          adressResult.length > 0 &&
          adressResult.filter(
            (sttdata) => sttdata.idNumber && sttdata.idNumber != '',
          ).length > 0
        ) {
          state.addressResult = adressResult.filter(
            (sttdata) => sttdata.idNumber && sttdata.idNumber != '',
          );
        } else {
          state.addressResult = [];
        }
      })
      .addCase(getAllAddress.rejected, (state) => {
        state.isLoadingAddress = false;
        state.addressResult = undefined;
      });
    builder
      .addCase(getAddressQuotation.pending, (state) => {
        state.isLoadingAddressQuotation = true;
        state.addressQuotation = undefined;
        state.isErrorAddressQuotation = false;
      })
      .addCase(getAddressQuotation.fulfilled, (state, action) => {
        state.isErrorAddressQuotation = false;
        state.isLoadingAddressQuotation = false;
        state.addressQuotation = action.payload.data?.[0];
      })
      .addCase(getAddressQuotation.rejected, (state) => {
        state.isLoadingAddressQuotation = false;
        state.addressQuotation = undefined;
        state.isErrorAddressQuotation = true;
      });
    builder
      .addCase(saveQuotationAddress.pending, (state) => {
        state.isLoadingSaveAddressQuotation = true;
        state.errorSaveAddressQuotation = undefined;
        state.saveAddressQuotationSuccess = undefined;
      })
      .addCase(saveQuotationAddress.fulfilled, (state) => {
        state.isLoadingSaveAddressQuotation = false;
        state.saveAddressQuotationSuccess = true;
        state.errorSaveAddressQuotation = undefined;
      })
      .addCase(saveQuotationAddress.rejected, (state, action) => {
        state.isLoadingSaveAddressQuotation = false;
        state.errorSaveAddressQuotation = action.error.message;
        state.saveAddressQuotationSuccess = undefined;
      });
  },
});

export const {
  clearAddressQuotationResult,
  clearAllAddressResult,
  clearErrorResult,
  clearSaveAddressQuotationSuccess,
} = quotationSlice.actions;

export const isLoadingAddressSelector = (store: RootState): boolean =>
  store.requestQuotation.isLoadingAddress;

export const allAddressQuotationSelector = (
  store: RootState,
): Address[] | undefined | null => store.requestQuotation.addressResult;

export const isErrorSelector = (store: RootState): boolean =>
  store.requestQuotation.isError;

export const isLoadingAddressQuotationSelector = (store: RootState): boolean =>
  store.requestQuotation.isLoadingAddressQuotation;

export const addressQuotationSelector = (
  store: RootState,
): Address | undefined | null => store.requestQuotation.addressQuotation;

export const isErrorAddressQuotationSelector = (store: RootState): boolean =>
  store.requestQuotation.isErrorAddressQuotation;

export const isLoadingSaveAddressQuotationSelector = (
  store: RootState,
): boolean => store.requestQuotation.isLoadingSaveAddressQuotation;

export const saveAddressQuotationSuccessSelector = (
  store: RootState,
): boolean | undefined => store.requestQuotation.saveAddressQuotationSuccess;

export const errorSaveAddressQuotationSelector = (
  store: RootState,
): string | undefined => store.requestQuotation.errorSaveAddressQuotation;

export default quotationSlice.reducer;
