import { modelValue } from '@/utils/modelValue';
import { CookieData } from './Authen.model';

export interface FacebookLoginRequestModel {
  name: string;
  email?: string | null;
  id: string;
}

export interface FacebookLoginResponseModel {
  isError: boolean;
  cookies?: CookieData[];
  errorType?: number;
  errorMessage?: string;
}

export class FaceebookLoginResponseModelConverter {
  public static fromJson(json: any): FacebookLoginResponseModel {
    const model: FacebookLoginResponseModel = {
      isError: json['is_error'] == true ? true : false,
      cookies: json['cookies'],
      errorType: json['type'],
      errorMessage: json['emsg'],
    };
    return modelValue(model);
  }
}
export interface AuthenInfoError {
  code?: string;
  description?: string;
  name?: string;
}
