import { modelValue } from '@/utils/modelValue';
import { CouponSource, VoucherStateType, VoucherType } from './MyVoucher.model';
import { fullMonth } from '@/constants/checkout.constant';

export interface GetVoucherDetailModel {
  // vdetail?: Vdetail;
  istransport?: boolean;
  rewardId?: string;
  voucherKey?: string;
  state?: VoucherStateType;
  amount?: number;
  unit?: string;
  beginsDate?: string;
  endsDate?: string;
  name?: string;
  displayName?: string;
  description?: string;
  termsCondition?: string;
  image?: string;
  rule?: Rule;
  canchannel?: boolean;
  isvouused?: boolean;
  qrimg?: string;
  couponSource?: CouponSource;
  voucherExpiresAtTH?: string;
  voucherExpiresAtEN?: string;
  discountType?: VoucherType;
}

export interface Rule {
  action?: Action;
}

export interface Action {
  discountType?: string;
  discount?: string;
}

export interface Vdetail {
  voucherCode?: string;
  voucherKey?: string;
  state?: string;
  reservedExpiresAt?: string;
  voucherStartsAt?: string;
  voucherExpiresAt?: Date;
  issuedAt?: Date;
  partnerId?: number;
  redeemDate?: string;
  reward?: Reward;
}

export interface Reward {
  id?: number;
  alias?: string;
  rewardType?: string;
  status?: string;
  beginsDate?: Date;
  endsDate?: Date;
  points?: string;
  name?: string;
  displayName?: string;
  shortDescription?: string;
  description?: string;
  termsCondition?: string;
  couponConditionDescription?: string;
  campaignId?: string;
  partnerId?: number;
  partnerShareId?: string;
  image?: string;
  voucherCustomerLimitQty?: string;
  voucherQuotaQty?: string;
  voucherQuotaRedeemQty?: string;
  voucher?: Voucher;
  voucherCodeType?: string;
  redemptionType?: string;
  redemptionCountdownTimer?: string;
  rule?: Rule;
  isRequiredCitizenId?: boolean;
  tags?: string[];
  category?: any[];
  pos?: Pos;
  startDateShow?: string;
  startMonthShow?: string;
  startYearShow?: number;
  endDateShow?: string;
  endMonthShow?: string;
  endYearShow?: number;
  couponAmount?: string;
  couponUnit?: string;
}

export interface Pos {
  voucherType?: string;
  productId?: string;
}

export interface Voucher {
  status?: string;
  total?: null;
  issued?: number;
  remaining?: null;
}

export class GetVoucherDetailModelConverter {
  public static fromJson(json: any): GetVoucherDetailModel {
    const model: GetVoucherDetailModel = {
      // vdetail: json['vdetail']
      //   ? VdetailConverter.fromJson(json['vdetail'])
      //   : undefined,

      istransport: json['istransport'],
      voucherKey: json['voucher_key'],
      rewardId: json['reward_id']?.toString(),
      state: json['state'],
      amount: json['amount']
        ? Number(json['amount'].toString().replaceAll(',', ''))
        : undefined,
      unit: json['unit'],
      beginsDate: json['begins_date'],
      endsDate: json['ends_date'],
      name: json['name'],
      displayName: json['display_name'],
      description: json['description'],
      termsCondition: json['terms_condition'],
      image: json['image'],
      rule: json['rule'] ? RuleConverter.fromJson(json['rule']) : undefined,
      canchannel: json['canchannel'],
      isvouused: json['isvouused'],
      qrimg: json['qrimg'],
      couponSource: json['coupon_source'],
      voucherExpiresAtTH: json['voucher_expires_at'],
      voucherExpiresAtEN: json['voucher_expires_at'],
      discountType: json['discount_type'],
    };
    try {
      if (model.voucherExpiresAtTH && model.voucherExpiresAtEN) {
        const voucherExpiresDate = new Date(model.voucherExpiresAtTH);
        const hour = voucherExpiresDate.getHours().toString().padStart(2, '0');
        const minute = voucherExpiresDate
          .getMinutes()
          .toString()
          .padStart(2, '0');
        const formattedDateTH = `${voucherExpiresDate.getDate()} ${
          fullMonth.th[voucherExpiresDate.getMonth()]
        } ${voucherExpiresDate.getFullYear()}${
          model.couponSource === CouponSource.camp ? ` ${hour}:${minute}` : ''
        }`;
        model.voucherExpiresAtTH = formattedDateTH;
        const formattedDateEN = `${voucherExpiresDate.getDate()} ${
          fullMonth.en[voucherExpiresDate.getMonth()]
        } ${voucherExpiresDate.getFullYear()}${
          model.couponSource === CouponSource.camp ? ` ${hour}:${minute}` : ''
        }`;
        model.voucherExpiresAtEN = formattedDateEN;
      }
    } catch (e) {
      console.log(e);
    }
    if (model.beginsDate) {
      const beginsDate = new Date(model.beginsDate);
      const formattedDate = `${beginsDate.getDate()}/${
        beginsDate.getMonth() + 1
      }/${beginsDate.getFullYear()} ${beginsDate.getHours()}:${beginsDate.getMinutes()}`;
      model.beginsDate = formattedDate;
    }
    if (model.endsDate) {
      const endsDate = new Date(model.endsDate);
      const formattedDate = `${endsDate.getDate()}/${
        endsDate.getMonth() + 1
      }/${endsDate.getFullYear()} ${endsDate.getHours()}:${endsDate.getMinutes()}`;
      model.endsDate = formattedDate;
    }
    return modelValue(model);
  }
}
export class RuleConverter {
  public static fromJson(json: any): Rule {
    const model: Rule = {
      action: json['action']
        ? ActionConverter.fromJson(json['action'])
        : undefined,
    };
    return modelValue(model);
  }
}
export class ActionConverter {
  public static fromJson(json: any): Action {
    const model: Action = {
      discountType: json['discount_type'],
      discount: json['discount'],
    };
    return modelValue(model);
  }
}
export class VdetailConverter {
  public static fromJson(json: any): Vdetail {
    const model: Vdetail = {
      voucherCode: json['voucher_code'],
      voucherKey: json['voucher_key'],
      state: json['state'],
      reservedExpiresAt: json['reserved_expires_at'],
      voucherStartsAt: json['voucher_starts_at'],
      voucherExpiresAt: json['voucher_expires_at'],
      issuedAt: json['issued_at'],
      partnerId: json['partner_id'],
      redeemDate: json['redeem_date'],
      reward: json['reward']
        ? RewardConverter.fromJson(json['reward'])
        : undefined,
    };
    return modelValue(model);
  }
}
export class RewardConverter {
  public static fromJson(json: any): Reward {
    const model: Reward = {
      id: json['id'],
      alias: json['alias'],
      rewardType: json['reward_type'],
      status: json['status'],
      beginsDate: json['begins_date'],
      endsDate: json['ends_date'],
      points: json['points'],
      name: json['name'],
      displayName: json['display_name'],
      shortDescription: json['short_description'],
      description: json['description'],
      termsCondition: json['terms_condition'],
      couponConditionDescription: json['coupon_condition_description'],
      campaignId: json['campaign_id'],
      partnerId: json['partner_id'],
      partnerShareId: json['partner_share_id'],
      image: json['image'],
      voucherCustomerLimitQty: json['voucher_customer_limit_qty'],
      voucherQuotaQty: json['voucher_quota_qty'],
      voucherQuotaRedeemQty: json['voucher_quota_redeem_qty'],
      voucher: json['voucher']
        ? VoucherConverter.fromJson(json['voucher'])
        : undefined,
      voucherCodeType: json['voucher_code_type'],
      redemptionType: json['redemption_type'],
      redemptionCountdownTimer: json['redemption_countdown_timer'],
      rule: json['rule'] ? RuleConverter.fromJson(json['rule']) : undefined,
      isRequiredCitizenId: json['is_required_citizen_id'],
      tags: json['tags']?.map((e: any) => e?.toString().trim()),
      category: json['category']?.map((e: any) => e?.toString().trim()),
      pos: json['pos'] ? PosConverter.fromJson(json['pos']) : undefined,
      startDateShow: json['startdate_show'],

      startMonthShow: json['startmonth_show'],
      startYearShow: json['startyear_show'],
      endDateShow: json['enddate_show'],
      endMonthShow: json['endmonth_show'],
      endYearShow: json['endyear_show'],
      couponAmount: json['couponamount'],
      couponUnit: json['couponunit'],
    };
    return modelValue(model);
  }
}
export class VoucherConverter {
  public static fromJson(json: any): Voucher {
    const model: Voucher = {
      status: json['status'],
      total: json['total'],
      issued: json['issued'],
      remaining: json['remaining'],
    };
    return modelValue(model);
  }
}
export class PosConverter {
  public static fromJson(json: any): Pos {
    const model: Pos = {
      voucherType: json['voucher_type'],
      productId: json['product_id'],
    };
    return modelValue(model);
  }
}
