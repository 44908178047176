import { getDataFromAPI } from '@/utils/httpClient';

export const mergeStore = async (): Promise<boolean> => {
  const [getData] = await Promise.all([
    getDataFromAPI({
      path: '/mergestore',
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  return data && data.dbcode === true;
};
