import React from 'react';
import { Box } from '@mui/material';
import AppLink from '@/components/Layout/handler/AppLink';
import MenuClass from '@/styles/menu.module.css';
import { useTranslation } from 'next-i18next';
import { AllCat } from '@/models/AllCat.model';

export default function CatSubMenuLvSideBar({
  mainCatName,
  allSubCats,
  node,
  onCloseCatSubMenuLv,
}: {
  mainCatName?: string;
  allSubCats: AllCat[];
  node: string | null;
  // eslint-disable-next-line no-unused-vars
  onCloseCatSubMenuLv: (node: string | null) => void;
}) {
  const { t, i18n } = useTranslation();
  let subcats = allSubCats?.filter((e: AllCat) => e.node === node) ?? [];
  return (
    <Box className="overflow-x-hidden overflow-y-auto">
      <>
        <Box
          className="flex px-2 py-1 self-center text-sm"
          onClick={() => onCloseCatSubMenuLv(null)}
        >
          <div className="my-auto">
            <i
              className="bi bi-chevron-left text-sm me-2"
              style={{ verticalAlign: 'middle' }}
            />
            {mainCatName}
          </div>
        </Box>
        <AppLink
          href={
            subcats[0]?.slugname && `/category/${subcats[0]?.slugname ?? ''}`
          }
          className="flex px-2 py-2 font-bold"
        >
          <Box className="self-center ps-1 text-sm">
            {i18n.language === 'th'
              ? subcats[0]?.catname
              : subcats[0]?.catnameEN ?? subcats[0]?.catname}
          </Box>
          <Box className="ml-auto underline text-sm">{t('seeall')}</Box>
        </AppLink>
        {(subcats[0]?.items ?? []).map((e: AllCat) => (
          <Box key={e.node} className={MenuClass.mainCategoryItem}>
            <AppLink
              href={e.slugname && `/category/${e.slugname}`}
              className="flex px-2 py-2"
            >
              <Box className="self-center ps-1 text-sm">
                {i18n.language === 'th' ? e.catname : e.catnameEN ?? e.catname}
              </Box>
            </AppLink>
          </Box>
        ))}
      </>
    </Box>
  );
}
