export const ref = 'CUSTREF';
export const twdToken = 'TWACCESS_TOKEN';
export const twdRefreshToken = 'TWREFRESH_TOKEN';
export const the1Token = 'ACCESS_TOKEN';
export const toneToken = 'TONE_TOKEN';
// client (not http only)
export const pickupStcode = 'PICKSTORE';
export const stcode = 'DFSTORE';
export const customerName = 'SNAME';
export const cartCount = 'SHOPITEM';
export const shippingType = 'DTYPE';
export const isExpress = 'EXTYPE';
export const isBulky = 'WHSTYPE';
export const lang = 'lang';
export const guestId = 'guestId';
export const cookiePolicy = 'cookiePolicy';
export const transactionId = 'transaction_id';
export const utmSource = 'utm_source';
export const utmMedium = 'utm_medium';
export const utmCampaign = 'utm_campaign';
export const stateToken = 'stateToken';
export const redirect = 'redirect';
export const tier = 'tier';
export const avatar = 'avatar';
export const cartItem = 'CARTITEM';
export const addingItem = 'item';
export const notifyingSku = 'notifyingSku';
export const afterSignin = 'AFS';

export const serverCookieNameList = [
  ref,
  twdToken,
  twdRefreshToken,
  the1Token,
  toneToken,
  stateToken,
];
