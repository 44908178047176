import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CustomerInfo } from '@/models/Authen.model';
import {
  sendNewLogin,
  sendNewLoginRequest,
} from '@/services/self/newLoginService';
import Cookies from 'js-cookie';
import { avatar, customerName, tier } from '@/constants/cookieKey.constant';
import { sendSignOut } from '@/services/self/signOutService';

interface AuthenState {
  isAuthenticating: boolean;
  isSigningOut: boolean;
  isSignOutSuccess: boolean;
  isAuthenticated: boolean;
  customerInfo?: CustomerInfo | null;
  error?: string;
}

const initialState: AuthenState = {
  isAuthenticating: false,
  isSigningOut: false,
  isSignOutSuccess: false,
  isAuthenticated: false,
  customerInfo: Cookies.get(customerName)
    ? {
        cname: Cookies.get(customerName) ?? 'Unknown',
        isFullMember: false,
        tiers: Cookies.get(tier)
          ? [
              {
                name: Cookies.get(tier),
              },
            ]
          : undefined,
        avatar: Cookies.get(avatar) ?? undefined,
      }
    : undefined,
};

export const newLogin = createAsyncThunk(
  'newLogin',
  async (request: sendNewLoginRequest) => {
    const response = await sendNewLogin({
      firstName: request.firstName,
      lastName: request.lastName,
      utmSource: request.utmSource,
      utmMedium: request.utmMedium,
      utmCampaign: request.utmCampaign,
      version: request.version,
      consent: request.consent,
    });
    if (response.error) {
      throw response.error.message;
    }
    return response;
  },
);

export const signOut = createAsyncThunk('signOut', async () => {
  const response = await sendSignOut();
  if (response) {
    return response;
  } else {
    throw new Error('Sign out failed');
  }
});

export const authenSlice = createSlice({
  name: 'authen',
  initialState: initialState,
  reducers: {
    setCustomerInfoAfterLogin: (state, action: { payload: CustomerInfo }) => {
      state.isAuthenticating = false;
      state.customerInfo = action.payload;
      state.isAuthenticated = true;
      state.error = undefined;
    },
    setCustomerInitial: (state) => {
      if (state.customerInfo === undefined) {
        state.customerInfo = null;
      }
    },
    setResetSignOut: (state) => {
      state.isSignOutSuccess = false;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(newLogin.pending, (state) => {
        state.isAuthenticating = true;
        state.isAuthenticated = false;
        state.error = undefined;
      })
      .addCase(newLogin.fulfilled, (state, action) => {
        state.isAuthenticating = false;
        state.isAuthenticated = true;
        state.error = undefined;
        state.customerInfo = action.payload.info;
      })
      .addCase(newLogin.rejected, (state, action) => {
        state.isAuthenticating = false;
        state.isAuthenticated = false;
        state.error = action.error.message;
      });

    // pending, fulfilled, rejected
    builder
      .addCase(signOut.pending, (state) => {
        state.isSigningOut = true;
        state.isAuthenticated = false;
        state.isSignOutSuccess = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.isSigningOut = false;
        state.isAuthenticated = false;
        state.customerInfo = undefined;
        state.isSignOutSuccess = true;
      })
      .addCase(signOut.rejected, (state) => {
        state.isSigningOut = false;
        state.isAuthenticating = false;
        state.isAuthenticated = false;
        state.customerInfo = undefined;
        state.isSignOutSuccess = false;
      });
  },
});

export const {
  setCustomerInfoAfterLogin,
  setCustomerInitial,
  setResetSignOut,
} = authenSlice.actions;

export const isAuthenticatingSelector = (store: RootState): boolean =>
  store.authen.isAuthenticating;

export const isSigningOutSelector = (store: RootState): boolean =>
  store.authen.isSigningOut;

export const isAuthenticatedSelector = (store: RootState): boolean =>
  store.authen.isAuthenticated;

export const customerInfoSelector = (
  store: RootState,
): CustomerInfo | null | undefined => store.authen.customerInfo;

export const authenErrorSelector = (store: RootState): string | undefined =>
  store.authen.error;

export const isSignOutSuccessSelector = (store: RootState): boolean =>
  store.authen.isSignOutSuccess;

export default authenSlice.reducer;
