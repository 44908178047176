import {
  GetVouFromCodeResponse,
  GetVouValidateMycartResponseConverter,
  DataOutput,
  DataOutputConverter,
  Info,
} from '@/models/GetVouFromCode.model';
import {
  UseVoucherPartnerResponse,
  UseVoucherPartnerResponseConverter,
} from '@/models/UseVoucherPartner.model';
import { VoucherSectionName } from '@/models/profile/MyVoucher.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const removeVoucher = async ({
  lang,
  code,
}: {
  lang?: string;
  code?: string;
}): Promise<{ isError?: boolean; message?: string }> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: `/editvouvalidate`,
      lang,
      body: { voucherk: code },
    }),
  ]);

  let response = { isError: true, message: 'Connection Error.' };
  if (getDataAPI.status === 200 && getDataAPI.data) {
    response = {
      isError: getDataAPI.data.dbcode === true ? false : true,
      message: getDataAPI.data.dbmessage,
    };
  }
  return response;
};

export const useVoucher = async ({
  lang,
  code,
  secname,
}: {
  lang?: string;
  code?: string;
  secname?: VoucherSectionName;
}): Promise<GetVouFromCodeResponse> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: `/getvoufromcode`,
      lang,
      body: { code, secname: secname ?? VoucherSectionName.cart },
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : getDataAPI.status == 401
      ? { is_error: true, type: 10 }
      : null;
  let checkErrorData = apiData
    ? apiData.data
      ? apiData.data.dbmessage
      : null
    : null;
  if (checkErrorData) {
    if (checkErrorData === 'This vouchers is out of redemption') {
      apiData.dbmessage =
        lang === 'en' ? checkErrorData : 'ขออภัย สิทธิ์คูปองนี้เต็มแล้ว';
    } else {
      apiData.dbmessage = checkErrorData;
    }
  }

  let response: GetVouFromCodeResponse = apiData
    ? apiData
    : { dbCode: false, dbMessage: 'Connection Error.' };
  try {
    response = GetVouValidateMycartResponseConverter.fromJson(
      apiData ? apiData : { dbCode: false, dbMessage: 'Connection Error.' },
    );
  } catch (e) {
    console.log(e);
  }

  return response;
};

export const checkOnTopXpro = async ({
  lang,
  info,
}: {
  lang?: string;
  info?: Info;
}): Promise<DataOutput> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: `/checkontopxpro`,
      lang,
      body: { info },
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: DataOutput = apiData ? apiData : [];
  try {
    response = apiData
      ? apiData.map((item: any) => DataOutputConverter.fromJson(item))
      : [];
  } catch (e) {
    console.log(e);
  }
  return response;
};

export const redeemVoucherPartner = async ({
  lang,
  code,
}: {
  lang?: string;
  code?: string;
}): Promise<GetVouFromCodeResponse> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: `/redeemcouponpartner`,
      lang,
      body: { voucher_key: code },
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let checkErrorData = apiData
    ? apiData.data
      ? apiData.data.dbmessage
      : null
    : null;
  if (checkErrorData) {
    if (checkErrorData === 'This vouchers is out of redemption') {
      apiData.dbmessage =
        lang === 'en' ? checkErrorData : 'ขออภัย สิทธิ์คูปองนี้เต็มแล้ว';
    } else {
      apiData.dbmessage = checkErrorData;
    }
  }

  let response: GetVouFromCodeResponse = apiData
    ? apiData
    : { dbCode: false, dbMessage: 'Connection Error.' };
  try {
    response = GetVouValidateMycartResponseConverter.fromJson(
      apiData ? apiData : { dbCode: false, dbMessage: 'Connection Error.' },
    );
  } catch (e) {
    console.log(e);
  }

  return response;
};

export const useVoucherPartner = async ({
  lang,
  code,
}: {
  lang?: string;
  code?: string;
}): Promise<UseVoucherPartnerResponse> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: `/usecouponpartner`,
      lang,
      body: { voucher_key: code },
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let checkErrorData = apiData
    ? apiData.data
      ? apiData.data.dbmessage
      : null
    : null;
  if (checkErrorData) {
    if (checkErrorData === 'This vouchers is out of redemption') {
      apiData.dbmessage =
        lang === 'en' ? checkErrorData : 'ขออภัย สิทธิ์คูปองนี้เต็มแล้ว';
    } else {
      apiData.dbmessage = checkErrorData;
    }
  }

  let response: UseVoucherPartnerResponse = apiData
    ? apiData
    : { dbCode: false, dbMessage: 'Connection Error.' };
  try {
    response = UseVoucherPartnerResponseConverter.fromJson(
      apiData ? apiData : { dbCode: false, dbMessage: 'Connection Error.' },
    );
  } catch (e) {
    console.log(e);
  }

  return response;
};
