import React, { useContext } from 'react';
import chat from '@/styles/chat.module.css';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { compareResultsSelector } from '@/store/slices/compareSlice';
import {
  pageToShowCompareList,
  pageToHideChat,
  pageToShipCompareToTop,
  chatAndShopUrlThaiwatsadu,
} from '@/constants/defaultValue.constant';
import PageTypeContext from '@/contexts/PageTypeContext';
import Box from '@mui/material/Box';
import { Fade } from '@mui/material';

interface Props {
  isNoMain?: boolean;
}

export default function Chat({ isNoMain }: Props) {
  const router = useRouter();
  const compareResults = useSelector(compareResultsSelector);
  const currentPage = router.pathname.split('/')[1];
  const isShowChat =
    isNoMain === true ? true : !pageToHideChat.includes(currentPage);
  const pageTypeContext = useContext(PageTypeContext);
  const isShowCompare =
    pageToShowCompareList.includes(currentPage) &&
    pageTypeContext?.isCurtain === false &&
    pageTypeContext?.isSteel === false &&
    pageTypeContext?.isPaint === false;
  const isShipCompare = compareResults.length > 0 && isShowCompare;
  const isPageToShipCompare = pageToShipCompareToTop
    .map((e) => e.page)
    .includes(currentPage);

  let mobileShip = 0;
  let tabletShip = 0;
  let desktopShip = 0;
  if (isShipCompare === true) {
    desktopShip = 55;
    if (isPageToShipCompare) {
      mobileShip = 110;
      tabletShip = 110;
    } else {
      tabletShip = 55;
      if (pageTypeContext?.isFeedback === true) {
        mobileShip = 125;
      } else {
        mobileShip = 55;
      }
    }
  } else if (pageTypeContext?.isSteel === true) {
    mobileShip = 85;
    tabletShip = 70;
    desktopShip = 70;
  } else if (pageTypeContext?.isFeedback === true) {
    mobileShip = 65;
  } else if (isPageToShipCompare) {
    mobileShip = 60;
    tabletShip = 60;
  }
  const handleClose = (e: any) => {
    e.preventDefault();
    pageTypeContext.setIsShow(false);
  };

  return isShowChat ? (
    <div
      className={`${
        pageTypeContext?.isInstall === true && isNoMain !== true
          ? 'hidden lg:block'
          : pageTypeContext?.isCurtain === true && isNoMain !== true
          ? 'hidden'
          : ''
      }`}
    >
      <Fade in={pageTypeContext?.isShow} timeout={1000}>
        <Box
          sx={{
            bottom: {
              xs: mobileShip.toString() + 'px',
              sm: tabletShip.toString() + 'px',
              md:
                (pageTypeContext?.isCurtain === true
                  ? desktopShip
                  : tabletShip
                ).toString() + 'px',
              lg: desktopShip.toString() + 'px',
              xl: desktopShip.toString() + 'px',
            },
          }}
          className={`${isNoMain === true ? '' : 'fixed'} -right-1 z-[999]`}
        >
          <div id="menu" className={chat.menuMain}>
            <a
              className={`${chat.menuButtonTranspaent} relative`}
              id="open-menu"
              href="#menu"
              title="Show navigation"
            >
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full p-[0.1rem]">
                <Image
                  src="/images/svg/chat.svg"
                  alt="chat"
                  width={75}
                  height={75}
                  style={{ width: '100%' }}
                />
              </div>
              <div
                className="absolute top-0 right-0 rounded-full bg-white w-4 h-4 z-60 shadow-sm pointer"
                onClick={handleClose}
              >
                <div className="h-4 w-4 -mt-[1.05rem] ms-[0.1rem]">
                  <i className="bi bi-x text-primary text-sm w-4 h-4"></i>
                </div>
              </div>
            </a>
            <a
              className={`${chat.menuButton} icon-minus`}
              href="#0"
              title="Hide navigation"
            >
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 leading-3">
                <i className="bi bi-x text-primary"></i>
              </div>
            </a>
            <div className={`${chat.menuItem}`}>
              <a href="https://m.me/thaiwatsadu.thai/" target="_blank">
                <div className="p-[0.15rem]">
                  <Image
                    src="/images/icons/messenger.png"
                    alt="messenger"
                    width={84}
                    height={84}
                    className="-rotate-[8deg]"
                    style={{ width: '100%' }}
                  />
                </div>
              </a>
            </div>
            <div className={`${chat.menuItem}`}>
              <a href={chatAndShopUrlThaiwatsadu} target="_blank">
                <div className="p-[0.15rem]">
                  <Image
                    src="/images/icons/line.png"
                    alt="messenger"
                    width={84}
                    height={84}
                    className="rotate-[9deg]"
                    style={{ width: '100%' }}
                  />
                </div>
              </a>
            </div>
          </div>
        </Box>
      </Fade>
    </div>
  ) : (
    <></>
  );
}
