import { postDataFromAPI } from '@/utils/httpClient';

export const saveDownloadQuotationLog = async ({
  order,
}: {
  order: string;
}): Promise<{
  data: string | null;
  status: number | null;
}> => {
  const getData = await postDataFromAPI({
    path: '/downloadqt',
    body: { ref: order },
  });
  console.log(getData);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: string | null;
    status: number | null;
  } = { data: data, status: getData.status };

  return response;
};
