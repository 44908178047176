import {
  CouponSource,
  MyVoucherModel,
  VoucherSortingType,
  VoucherState,
  VoucherType,
} from '@/models/profile/MyVoucher.model';
import { getProfileMyVoucher } from '@/services/client/myVoucherService';
import { RootState } from '@/store/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as cookieKey from '@/constants/cookieKey.constant';
import { getProfileMyVoucherDetail } from '@/services/client/myVoucherDetailService';
import { GetVoucherDetailModel } from '@/models/profile/MyVoucherDetail.model';
import * as ServerCookies from '@/services/client/cookieService';

export interface profileMyVoucherState {
  resultIssued?: MyVoucherModel;
  resultReservedRedeemed?: MyVoucherModel;
  resultExpired?: MyVoucherModel;
  resultCashIssued?: MyVoucherModel;
  resultCashReservedRedeemed?: MyVoucherModel;
  resultCashExpired?: MyVoucherModel;
  resultPartnerIssued?: MyVoucherModel;
  resultPartnerReservedRedeemed?: MyVoucherModel;
  resultPartnerExpired?: MyVoucherModel;
  currentPage: number;
  limit: number;
  state: string;
  isLoading: boolean;
  error?: string;
  isLoadingDetail: boolean;
  resultDetail?: GetVoucherDetailModel | null;
}

const initialState: profileMyVoucherState = {
  currentPage: 1,
  isLoading: false,
  limit: 12,
  state: 'issued',
  isLoadingDetail: false,
  resultDetail: undefined,
};

export const profileMyVoucherQuery = createAsyncThunk(
  'profileMyVoucher/profile/query',
  async ({
    lang,
    page,
    type,
    state,
    sub,
    sort,
  }: {
    lang?: string;
    page?: number;
    type: VoucherType;
    state: VoucherState;
    sub?: string;
    sort?: VoucherSortingType;
  }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileMyVoucher({
      lang,
      page,
      type,
      state,
      sub,
      sort,
    });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const profileMyVoucherDetail = createAsyncThunk(
  'profileMyVoucher/profile/detail',
  async ({
    lang,
    voucherk,
    channel,
  }: {
    lang?: string;
    voucherk?: string;
    channel?: CouponSource;
  }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileMyVoucherDetail({
      lang,
      voucherk,
      channel,
    });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const profileMyVoucherSlice = createSlice({
  name: 'profileMyVoucher',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearResult: (state) => {
      state.resultIssued = undefined;
      state.resultReservedRedeemed = undefined;
      state.resultExpired = undefined;
      state.resultCashIssued = undefined;
      state.resultCashReservedRedeemed = undefined;
      state.resultCashExpired = undefined;
      state.resultPartnerIssued = undefined;
      state.resultPartnerReservedRedeemed = undefined;
      state.resultPartnerExpired = undefined;
    },
    clearResultDetail: (state) => {
      state.resultDetail = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileMyVoucherQuery.pending, (state) => {
        state.isLoading = true;
        // state.resultIssued = null;
        // state.resultReservedRedeemed = null;
        // state.resultExpired = null;
      })
      .addCase(profileMyVoucherQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.resultIssued = {
            info: null,
          };
          state.resultReservedRedeemed = {
            info: null,
          };
          state.resultExpired = {
            info: null,
          };
          state.resultCashIssued = {
            info: null,
          };
          state.resultCashReservedRedeemed = {
            info: null,
          };
          state.resultCashExpired = {
            info: null,
          };
          state.resultPartnerIssued = {
            info: null,
          };
          state.resultPartnerReservedRedeemed = {
            info: null,
          };
          state.resultPartnerExpired = {
            info: null,
          };
        } else {
          const stateRes = action.payload?.data?.state;
          const typeRes = action.payload?.data?.type;
          switch (stateRes) {
            case 'issued,reserved':
              if (typeRes === VoucherType.cash) {
                state.resultCashIssued = action.payload?.data ?? undefined;
              } else if (typeRes === VoucherType.partner) {
                state.resultPartnerIssued = action.payload?.data ?? undefined;
              } else {
                state.resultIssued = action.payload?.data ?? undefined;
              }
              break;
            case 'redeemed':
              if (typeRes === VoucherType.cash) {
                state.resultCashReservedRedeemed =
                  action.payload?.data ?? undefined;
              } else if (typeRes === VoucherType.partner) {
                state.resultPartnerReservedRedeemed =
                  action.payload?.data ?? undefined;
              } else {
                state.resultReservedRedeemed =
                  action.payload?.data ?? undefined;
              }
              break;
            case 'expired':
              if (typeRes === VoucherType.cash) {
                state.resultCashExpired = action.payload?.data ?? undefined;
              } else if (typeRes === VoucherType.partner) {
                state.resultPartnerExpired = action.payload?.data ?? undefined;
              } else {
                state.resultExpired = action.payload?.data ?? undefined;
              }
              break;
            default:
              state.resultIssued = undefined;
              state.resultReservedRedeemed = undefined;
              state.resultExpired = undefined;
              state.resultCashIssued = undefined;
              state.resultCashReservedRedeemed = undefined;
              state.resultCashExpired = undefined;
              state.resultPartnerIssued = undefined;
              state.resultPartnerReservedRedeemed = undefined;
              state.resultPartnerExpired = undefined;
          }
        }
        state.limit = action.payload?.data?.limit ?? 5;
        state.currentPage = 1;
      })
      .addCase(profileMyVoucherQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.resultIssued = undefined;
        state.resultReservedRedeemed = undefined;
        state.resultExpired = undefined;
        state.resultCashIssued = undefined;
        state.resultCashReservedRedeemed = undefined;
        state.resultCashExpired = undefined;
        state.resultPartnerIssued = undefined;
        state.resultPartnerReservedRedeemed = undefined;
        state.resultPartnerExpired = undefined;
        state.error = action.error.message;
        state.currentPage = 1;
      });
    builder
      .addCase(profileMyVoucherDetail.pending, (state) => {
        state.isLoadingDetail = true;
        state.resultDetail = undefined;
      })
      .addCase(profileMyVoucherDetail.fulfilled, (state, action) => {
        state.resultDetail = action.payload?.data ?? null;
        state.isLoadingDetail = false;
      })
      .addCase(profileMyVoucherDetail.rejected, (state) => {
        state.resultDetail = null;
        state.isLoadingDetail = false;
      });
  },
});

export const { clearError, clearResult, clearResultDetail } =
  profileMyVoucherSlice.actions;

export const MyVoucherProfileResultIssuedSelector = (
  store: RootState,
): MyVoucherModel | undefined => store.profileMyVoucher.resultIssued;
export const MyVoucherProfileResultReservedRedeemedSelector = (
  store: RootState,
): MyVoucherModel | undefined => store.profileMyVoucher.resultReservedRedeemed;
export const MyVoucherProfileResultExpiredSelector = (
  store: RootState,
): MyVoucherModel | undefined => store.profileMyVoucher.resultExpired;

export const MyVoucherProfileResultCashIssuedSelector = (
  store: RootState,
): MyVoucherModel | undefined => store.profileMyVoucher.resultCashIssued;
export const MyVoucherProfileResultCashReservedRedeemedSelector = (
  store: RootState,
): MyVoucherModel | undefined =>
  store.profileMyVoucher.resultCashReservedRedeemed;
export const MyVoucherProfileResultCashExpiredSelector = (
  store: RootState,
): MyVoucherModel | undefined => store.profileMyVoucher.resultCashExpired;

export const MyVoucherProfileResultPartnerIssuedSelector = (
  store: RootState,
): MyVoucherModel | undefined => store.profileMyVoucher.resultPartnerIssued;
export const MyVoucherProfileResultPartnerReservedRedeemedSelector = (
  store: RootState,
): MyVoucherModel | undefined =>
  store.profileMyVoucher.resultPartnerReservedRedeemed;
export const MyVoucherProfileResultPartnerExpiredSelector = (
  store: RootState,
): MyVoucherModel | undefined => store.profileMyVoucher.resultPartnerExpired;

export const MyVoucherDetailProfileResultSelector = (
  store: RootState,
): GetVoucherDetailModel | undefined | null =>
  store.profileMyVoucher.resultDetail;

export const isLoadingMyVoucherProfileSelector = (store: RootState): boolean =>
  store.profileMyVoucher.isLoading;

export const errorMyVoucherProfileSelector = (
  store: RootState,
): string | undefined => store.profileMyVoucher.error;

export const currentMyVoucherProfilePageSelector = (store: RootState): number =>
  store.profileMyVoucher.currentPage;

export const limitMyVoucherProfileSelector = (store: RootState): number =>
  store.profileMyVoucher.limit;

export const isLoadingMyVoucherDetailProfileSelector = (
  store: RootState,
): boolean => store.profileMyVoucher.isLoadingDetail;

export default profileMyVoucherSlice.reducer;
