import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from './Authen.model';
import { ProductModel, ProductModelConverter } from './Product.model';
import { numberWithCommas } from '@/utils/format';
import {
  CurtainAccessory,
  CurtainInstallationPosition,
  CurtainInstallationType,
  CurtainOpeningStyle,
  CurtainSkuSelected,
  CurtainStyle,
} from '@/contexts/ProductCurtainPageContext';

export interface MyCartResponse {
  info?: CustomerInfo | null;
  cookies?: CookieData[];
  ref: string;
  freegifts: ProductModel[];
  bundledetail: ProductModel[][];
  normalitem: ProductModel[];
  steelitem: ProductModel[];
  isFreeNotEnough: boolean;
  totalAmount: number;
  totalAmountShow: string;
  totalAmountThaiText: string;
  ontopDiscount: string;
  plppDetail?: PlppDetail[];
  sumTotalShow: string;
  sumPlppShow: string;
  totalVoucher: number;
  isBanned: boolean;
  diffStore?: string;
  checkStockProducts?: ProductModel[];
  surveyDetail?: {
    sku: string;
    price: number;
  };
  mycartDetail?: ProductModel[];
  surveySku?: ProductModel[];
  mycartCurtainDetail: MyCurtainDetail[];
  sumCurtainOriginalPrice?: number;
  sumCurtainPrice?: number;
  installCurtainOriginalPrice?: number;
  installCurtainPrice?: number;
  totallCurtain?: number;
  stcode?: string;
  isChristmas?: boolean;
  christmasStcode?: string[];
  locationChristmasService?: string[];
}

export class MyCartResponseConverter {
  public static fromJson(json: any): MyCartResponse {
    const model: MyCartResponse = {
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
      ref: json['ref'] ?? '',
      freegifts: json['freegifts']
        ? json['freegifts'].map((x: any) => ProductModelConverter.fromJson(x))
        : [],
      bundledetail: json['bundledetail']
        ? json['bundledetail'].map((x: any) =>
            x.map((y: any) => ProductModelConverter.fromJson(y)),
          )
        : [],
      normalitem: json['normalitem']
        ? json['normalitem'].map((x: any) => ProductModelConverter.fromJson(x))
        : [],
      steelitem: json['steelitem']
        ? json['steelitem'].map((x: any) => ProductModelConverter.fromJson(x))
        : [],
      isFreeNotEnough: json['isfree'] == true ? true : false,
      totalAmount: json['totamt'] ?? 0,
      totalAmountShow: (json['totamtshow'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      totalAmountThaiText: json['totamttext'] ?? '',
      ontopDiscount: (json['ontopDiscount'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      plppDetail: json['plppdetail']
        ? json['plppdetail'].map((e: any) => PlppDetailConverter.fromJson(e))
        : undefined,
      sumTotalShow: (json['sumtottxt'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      sumPlppShow: (json['sumplpptxt'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      totalVoucher: json['totvoucher'] ?? 0,
      isBanned: json['qryisbanned'] == 'Y' ? true : false,
      diffStore: json['diffstore'],
      checkStockProducts: json['checkstockskus']
        ? json['checkstockskus'].map((e: any) =>
            ProductModelConverter.fromJson(e),
          )
        : undefined,
      surveyDetail: json['surveydetail']
        ? {
            sku: json['surveydetail']['SKC'],
            price: Number(
              json['surveydetail']['SURVEYPRICE']
                ?.toString()
                ?.trim()
                ?.replace('฿', '')
                ?.trim()
                ?.replaceAll('.00', '')
                .trim()
                .replaceAll(',', ''),
            ),
          }
        : undefined,
      mycartDetail: json['mycartdetail']
        ? json['mycartdetail'].map((x: any) =>
            ProductModelConverter.fromJson(x),
          )
        : [],
      surveySku: json['surveysku']
        ? json['surveysku'].map((x: any) => ProductModelConverter.fromJson(x))
        : [],
      mycartCurtainDetail: json['mycartcurtaindetail']
        ? json['mycartcurtaindetail'].map((x: any) =>
            MyCurtainDetailConverter.fromJson(x),
          )
        : [],
      sumCurtainOriginalPrice: json['sumcurtaintotorg']
        ? Number(json['sumcurtaintotorg'].toString().replaceAll(',', '').trim())
        : undefined,
      sumCurtainPrice: json['sumcurtaintot']
        ? Number(json['sumcurtaintot'].toString().replaceAll(',', '').trim())
        : undefined,
      installCurtainOriginalPrice: json['installcurtainorg']
        ? Number(
            json['installcurtainorg'].toString().replaceAll(',', '').trim(),
          )
        : undefined,
      installCurtainPrice: json['installcurtaintot']
        ? Number(
            json['installcurtaintot'].toString().replaceAll(',', '').trim(),
          )
        : undefined,
      totallCurtain: json['totallcurtain']
        ? Number(json['totallcurtain'].toString().replaceAll(',', '').trim())
        : undefined,
      stcode: json['stcode'],
      isChristmas: json['ischristmas'],
      christmasStcode: json['christmasstcode']?.map((e: any) =>
        e?.toString().trim(),
      ),
      locationChristmasService: json['locationChristmasService']?.map(
        (e: any) => e?.toString().trim(),
      ),
    };
    return modelValue(model);
  }
}

export interface ClearCartResponse {
  data?: ClearCartData;
  isErrorCancelRedeem: boolean;
}

export class ClearCartResponseConverter {
  public static fromJson(json: any): ClearCartResponse {
    const model: ClearCartResponse = {
      data: json['data']
        ? ClearCartDataConverter.fromJson(json['data'])
        : undefined,
      isErrorCancelRedeem: json['iserrcancelredeem'] == true ? true : false,
    };
    return modelValue(model);
  }
}

export interface ClearCartData {
  dbCode: boolean;
  dbMessage: string;
}

export class ClearCartDataConverter {
  public static fromJson(json: any): ClearCartData {
    const model: ClearCartData = {
      dbCode: json['dbcode'] ?? false,
      dbMessage: (json['dbmessage'] ?? '').toString().trim(),
    };
    return modelValue(model);
  }
}

export interface EditCartResponse {
  dbCode: boolean;
  dbItems: ProductModel[];
  dbMessage: string;
  qty?: number;
}

export class EditCartResponseConverter {
  public static fromJson(json: any): EditCartResponse {
    const model: EditCartResponse = {
      dbCode: json['dbcode'] == true ? true : false,
      dbItems: json['dbitems']
        ? json['dbitems'].map((e: any) => ProductModelConverter.fromJson(e))
        : [],
      dbMessage: (json['dbmessage'] ?? '').toString().trim(),
    };
    return modelValue(model);
  }
}

export enum PromotionFlag {
  // eslint-disable-next-line no-unused-vars
  product = 0,
  // eslint-disable-next-line no-unused-vars
  transport = 1,
  // eslint-disable-next-line no-unused-vars
  express = 2,
}

export interface PlppDetail {
  name: string;
  value: string;
  promotionFlag: PromotionFlag;
}

export class PlppDetailConverter {
  public static fromJson(json: any): PlppDetail {
    const model: PlppDetail = {
      name: json['EVNAME'] ?? '',
      value: numberWithCommas((json['bval'] ?? '').toString().trim()),
      promotionFlag: json['tspflag'],
    };
    return modelValue(model);
  }
}

export interface SpecialPurchaseResponse {
  canRedeem?: boolean;
  pathUrl?: string;
}

export class SpecialPurchaseResponseConverter {
  public static fromJson(json: any): SpecialPurchaseResponse {
    const model: SpecialPurchaseResponse = {
      canRedeem: json['canredeem'] == true ? true : false,
      pathUrl: json['pathurl'],
    };
    return modelValue(model);
  }
}
export interface NextPurchaseCouponResponse {
  amount: number;
  type: string;
  totalCoupon: number;
}
export class NextPurchaseCouponResponseConverter {
  public static fromJson(json: any): NextPurchaseCouponResponse {
    const model: NextPurchaseCouponResponse = {
      amount: json['amount'],
      type: json['type'],
      totalCoupon: json['totalCoupon'],
    };
    return modelValue(model);
  }
}
export interface MyCurtainDetail {
  projectId?: string;
  title?: string;
  subTitle?: string;
  pimImage?: string;
  base64?: string;
  width?: number;
  height?: number;
  curtainType?: CurtainStyle;
  jsonData?: CurtainJsonData;
  status?: string;
  setQuantity?: number;
  jsonItemS1Set?: ProductModel[];
  createdDate?: string;
  updatedDate?: string;
  originalPrice?: number;
  totalPrice?: number;
  curtainItems?: ProductModel[];
  images?: string[];
  freeList?: string[];
  thaiDate?: string;
  quantity?: number;
  totalPriceShow?: string;
  products?: ProductModel[];
}

export class MyCurtainDetailConverter {
  public static fromJson(json: any): MyCurtainDetail {
    let jsonItemS1Set = [];
    let jsonData = undefined;
    let images = [];
    try {
      if (json['JSNITEMS1SET']) {
        const jsonItemS1SetParse = JSON.parse(json['JSNITEMS1SET']);
        jsonItemS1Set = jsonItemS1SetParse.map((e: any) =>
          ProductModelConverter.fromJson(e),
        );
      }
    } catch (error) {}
    try {
      if (json['JSNDATA']) {
        const jsonDataParse = JSON.parse(json['JSNDATA']);
        jsonData = CurtainJsonDataConverter.fromJson(jsonDataParse);
      }
    } catch (error) {}
    try {
      if (json['pimg']) {
        images = json['pimg']?.split('|').filter((f: string) => f.trim() != '');
      } else if (json['img']) {
        images = [json['img']];
      }
    } catch (error) {
      console.log(error);
    }
    const model: MyCurtainDetail = {
      projectId: json['PROJECT_ID'],
      title: json['TITLE'],
      subTitle: json['SUB_TITLE'],
      pimImage: json['pimImage'],
      base64: json['IMGbase64'],
      width: json['WIDTH'] ? Number(json['WIDTH']) : undefined,
      height: json['HEIGHT'] ? Number(json['HEIGHT']) : undefined,
      curtainType: json['CURTAIN_TYPE'],
      jsonData,
      status: json['STATUS'],
      setQuantity: json['SETQTY'] ?? json['QNT'],
      jsonItemS1Set,
      createdDate: json['CREATE_DATE'],
      updatedDate: json['LUPD'],
      totalPrice: json['TOTALPRICE']
        ? Number(
            json['TOTALPRICE']
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
              .trim()
              .replaceAll(',', ''),
          )
        : undefined,
      originalPrice: json['TOTALORGPRICE']
        ? Number(
            json['TOTALORGPRICE']
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
              .trim()
              .replaceAll(',', ''),
          )
        : undefined,
      curtainItems: json['curtainitems']
        ? json['curtainitems'].map((e: any) =>
            ProductModelConverter.fromJson(e),
          )
        : [],
      images,
      freeList: json['freelist'],
      thaiDate: json['THAIDATE'],
      quantity: json['ITEMSLENGTH'] ? Number(json['ITEMSLENGTH']) : undefined,
      totalPriceShow: json['TOTALPRICETHB'],
      products: json['PRODUCTS']
        ? json['PRODUCTS'].map((e: any) => ProductModelConverter.fromJson(e))
        : [],
    };
    return modelValue(model);
  }
}

export interface CurtainJsonData {
  sku?: string;
  price?: number;
  originalPrice?: number;
  slug?: string;
  type?: CurtainStyle;
  title?: string;
  imagePreview?: string;
  isInner?: boolean;
  innerSku?: string;
  innerPrice?: number;
  innerOriginalPrice?: number;
  sewingSku?: string;
  sewingPrice?: number;
  width?: number;
  height?: number;
  originalWidth?: number;
  originalHeight?: number;
  quantity?: number;
  diameter?: number;
  installationPosition?: CurtainInstallationPosition;
  installationType?: CurtainInstallationType;
  openingType?: CurtainOpeningStyle;
  cal?: CurtainCal;
  innerCurtain?: ProductModel;
  accessories?: CurtainSkuSelected[];
}

export class CurtainJsonDataConverter {
  public static fromJson(json: any): CurtainJsonData {
    const accessories: CurtainSkuSelected[] = [];
    try {
      const accessoriesJson = json['accessories'];
      if (accessoriesJson['rail']?.['sku']) {
        accessories.push({
          type: CurtainAccessory.curtainRail,
          product: ProductModelConverter.fromJson(accessoriesJson['rail']),
        });
      } else if (accessoriesJson['curtain_rod']?.['sku']) {
        accessories.push({
          type: CurtainAccessory.curtainRail,
          product: ProductModelConverter.fromJson(
            accessoriesJson['curtain_rod'],
          ),
        });
      }
      if (accessoriesJson['curtain_tie']?.['sku']) {
        accessories.push({
          type: CurtainAccessory.curtainTieBacks,
          product: ProductModelConverter.fromJson(
            accessoriesJson['curtain_tie'],
          ),
        });
      }
      if (accessoriesJson['hook']?.['sku']) {
        accessories.push({
          type: CurtainAccessory.curtainHook,
          product: ProductModelConverter.fromJson(accessoriesJson['hook']),
        });
      }
      if (accessoriesJson['eyelet']?.['sku']) {
        accessories.push({
          type: CurtainAccessory.eyelet,
          product: ProductModelConverter.fromJson(accessoriesJson['eyelet']),
        });
      }
      if (accessoriesJson['holder']?.['sku']) {
        accessories.push({
          type: CurtainAccessory.curtainRodHolder,
          product: ProductModelConverter.fromJson(accessoriesJson['holder']),
        });
      }
      if (accessoriesJson['header_rail']?.['sku']) {
        accessories.push({
          type: CurtainAccessory.curtainRodHead,
          product: ProductModelConverter.fromJson(
            accessoriesJson['header_rail'],
          ),
        });
      }
    } catch (error) {}
    let installationType: CurtainInstallationType | undefined = undefined;
    try {
      const measureType = json['type_measure'].split('_')[1];
      if (measureType === 'special') {
        installationType === CurtainInstallationType.coverthefloor;
      } else {
        installationType === measureType;
      }
    } catch (error) {}
    const model: CurtainJsonData = {
      sku: json['sku'],
      price: json['sku_price']
        ? Number(json['sku_price'].toString().replaceAll(',', '').trim())
        : undefined,
      originalPrice: json['sku_org_price']
        ? Number(json['sku_org_price'].toString().replaceAll(',', '').trim())
        : undefined,
      slug: json['slug'],
      type: json['type'],
      title: json['title'],
      imagePreview: json['image_preview'],
      isInner: json['is_inner'] == true ? true : false,
      innerSku: json['inner_sku'],
      innerPrice: json['inner_price']
        ? Number(json['inner_price'].toString().replaceAll(',', '').trim())
        : undefined,
      innerOriginalPrice: json['inner_org_price']
        ? Number(json['inner_org_price'].toString().replaceAll(',', '').trim())
        : undefined,
      sewingSku: json['sewing_sku'],
      sewingPrice: json['sewing_price']
        ? Number(json['sewing_price'].toString().replaceAll(',', '').trim())
        : undefined,
      width: json['width'] ? Number(json['width']) : undefined,
      height: json['height'] ? Number(json['height']) : undefined,
      originalWidth: json['width_original']
        ? Number(json['width_original'])
        : undefined,
      originalHeight: json['height_original']
        ? Number(json['height_original'])
        : undefined,
      quantity: json['qty'] ? Number(json['qty']) : undefined,
      diameter: json['cdiameter'] ? Number(json['cdiameter']) : undefined,
      installationPosition:
        json['is_window'] === 1
          ? CurtainInstallationPosition.window
          : CurtainInstallationPosition.door,
      installationType,
      openingType: json['type_open'],
      cal: json['cal'] ? CurtainCalConverter.fromJson(json['cal']) : undefined,
      innerCurtain:
        json['inner'] && json['inner']?.['skcode'] !== undefined
          ? ProductModelConverter.fromJson(json['inner'])
          : undefined,
      accessories,
    };
    return modelValue(model);
  }
}

export interface CurtainCal {
  curtainQuantity?: number;
  curtainPrice?: number;
  sewingPrice?: number;
  curtainRail?: number;
  curtainRailQuantity?: number;
  curtainEyelet?: number;
}

export class CurtainCalConverter {
  public static fromJson(json: any): CurtainCal {
    const model: CurtainCal = {
      curtainQuantity: json['cal_curtain_qty']
        ? Number(json['cal_curtain_qty'])
        : undefined,
      curtainPrice: json['cal_curtain_price']
        ? Number(json['cal_curtain_price'])
        : undefined,
      sewingPrice: json['cal_sewing_price']
        ? Number(json['cal_sewing_price'])
        : undefined,
      curtainRail: json['cal_curtain_rail']
        ? Number(json['cal_curtain_rail'])
        : undefined,
      curtainRailQuantity: json['cal_curtain_rail_qty']
        ? Number(json['cal_curtain_rail_qty'])
        : undefined,
      curtainEyelet: json['cal_curtain_eyelet']
        ? Number(json['cal_curtain_eyelet'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface NextPurchaseCouponV2Response {
  amount?: number;
  type: string;
  totalCoupon?: number;
  isLimit: string;
  couponLimitedValue?: number;
  couponReceived?: number;
  limitIssueCoupon?: number;
}
export class NextPurchaseCouponResponseV2Converter {
  public static fromJson(json: any): NextPurchaseCouponV2Response {
    const model: NextPurchaseCouponV2Response = {
      amount: json['amount'],
      type: json['type'],
      totalCoupon: json['totalCoupon'],
      isLimit: json['isLimit'],
      couponLimitedValue: json['couponLimitedValue'],
      couponReceived: json['couponReceived'],
      limitIssueCoupon: json['limitIssueCoupon'],
    };
    return modelValue(model);
  }
}
