import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getUserInfo } from '@/services/client/getUserInfoService';
import { GetUserInfoResponse } from '@/models/GetUserInfo.model';
import { RequestProductRequest } from '@/models/RequestProduct.model';
import { requestProduct } from '@/services/client/requestProductService';

interface RequestProductState {
  result?: GetUserInfoResponse | null;
  isLoadingUserInfo: boolean;
  //submit request
  isLoadingRequest: boolean;
  submitRequestSuccess?: boolean;
  errorRequest?: string;
}

const initialState: RequestProductState = {
  isLoadingUserInfo: false,
  isLoadingRequest: false,
};

export const getUserInfoQuery = createAsyncThunk(
  'getUserInfo/query',
  async () => {
    const response = await getUserInfo();
    return response;
  },
);

export const requestProductSubmit = createAsyncThunk(
  'requestProduct/submit',
  async ({
    recapchaToken,
    requestData,
  }: {
    recapchaToken: string;
    requestData: RequestProductRequest;
  }) => {
    const productDetail = requestData.detail;
    requestData.subject = `[Product Request] ${productDetail.substring(
      0,
      100,
    )}`;
    const response = await requestProduct({
      recapchaToken,
      sendData: requestData,
    });
    if (!response || response.isError) {
      throw new Error(`thesystemisfailpleasetryagain`);
    }
    return response;
  },
);

const RequestProductSlice = createSlice({
  name: 'requestProduct',
  initialState: initialState,
  reducers: {
    clearResult: (state) => {
      state.result = undefined;
    },
    clearError: (state) => {
      state.errorRequest = undefined;
    },
    clearSuccess: (state) => {
      state.submitRequestSuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUserInfoQuery.pending, (state) => {
        state.result = undefined;
        state.isLoadingUserInfo = true;
      })
      .addCase(getUserInfoQuery.fulfilled, (state, action) => {
        state.isLoadingUserInfo = false;
        state.result = action.payload;
      })
      .addCase(getUserInfoQuery.rejected, (state) => {
        state.isLoadingUserInfo = false;
        state.result = undefined;
      });
    builder
      .addCase(requestProductSubmit.pending, (state) => {
        state.isLoadingRequest = true;
        state.submitRequestSuccess = false;
      })
      .addCase(requestProductSubmit.fulfilled, (state) => {
        state.isLoadingRequest = false;
        state.submitRequestSuccess = true;
      })
      .addCase(requestProductSubmit.rejected, (state, action) => {
        state.isLoadingRequest = false;
        state.errorRequest = action.error.message;
      });
  },
});

export const { clearResult, clearError, clearSuccess } =
  RequestProductSlice.actions;

export const getUserInfoSelector = (
  store: RootState,
): GetUserInfoResponse | null | undefined => store.requestProduct.result;

export const isLoadingUserInfoSelector = (store: RootState): boolean =>
  store.requestProduct.isLoadingUserInfo;

export const isLoadingRequestSelector = (store: RootState): boolean =>
  store.requestProduct.isLoadingRequest;

export const submitRequestSuccessSelector = (
  store: RootState,
): boolean | undefined => store.requestProduct.submitRequestSuccess;

export const errorRequestSelector = (store: RootState): string | undefined =>
  store.requestProduct.errorRequest;

export default RequestProductSlice.reducer;
