import { Grid } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import footerClass from '@/styles/footer.module.css';
import AppLink from '@/components/Layout/handler/AppLink';
import Image from 'next/image';
import { IconLink } from '@/models/IconLink.model';
import { getConsentInfo } from '@/services/client/editInfoService';
import * as showDialog from '@/utils/showDialog';

export default function CenterFooter() {
  const { t, i18n } = useTranslation();
  const socialLinks: IconLink[] = [
    {
      link: 'https://bit.ly/2X7bAuK',
      logo: '/images/svg/footer/social/line.svg',
      alt: 'line',
    },
    {
      link: 'https://web.facebook.com/thaiwatsadu.thai/',
      logo: '/images/svg/footer/social/facebook.svg',
      alt: 'facebook',
    },
    {
      link: 'https://www.youtube.com/channel/UCqkeU8psATW3SP_xhgeeN8A',
      logo: '/images/svg/footer/social/youtube.svg',
      alt: 'youtube',
    },
  ];
  const otherBrands: IconLink[] = [
    {
      link: 'https://www.bnbhome.com/',
      logo: '/images/logo/footer/bnb.png',
      alt: 'bnbhome',
    },
    {
      link: 'https://www.auto1.co.th/',
      logo: '/images/logo/footer/auto1.png',
      alt: 'auto1',
    },
    {
      link: 'https://www.vfix.co.th/',
      logo: '/images/logo/footer/vfix.png',
      alt: 'vfix',
    },
    {
      link: 'https://www.central.co.th/',
      logo: '/images/logo/footer/central.png',
      alt: 'central',
    },
    {
      link: 'https://www.powerbuy.co.th/',
      logo: '/images/logo/footer/powerbuy.png',
      alt: 'powerbuy',
    },
    {
      link: 'https://www.supersports.co.th/',
      logo: '/images/logo/footer/supersports.png',
      alt: 'supersports',
    },
    {
      link: 'https://www.tops.co.th/',
      logo: '/images/logo/footer/tops.png',
      alt: 'tops',
    },
    {
      link: 'https://www.officemate.co.th/',
      logo: '/images/logo/footer/officemate.png',
      alt: 'officemate',
    },
    {
      link: 'https://www.b2s.co.th/',
      logo: '/images/logo/footer/b2s.png',
      alt: 'b2s',
    },
    {
      link: 'https://www.petnme.co.th/',
      logo: '/images/logo/footer/petnme.png',
      alt: 'petnme',
    },
    {
      link: 'https://www.mebmarket.co.th/',
      logo: '/images/logo/footer/meb.png',
      alt: 'mebmarket',
    },
  ];

  const handlePolicyClick = async (e: any) => {
    e.preventDefault();
    const dataConsent = await getConsentInfo();
    if (!dataConsent?.data?.privacyPolicy) {
      showDialog.showErrorWithOkButton({
        text: t('common:thesystemistemporarilypleasetryagainlater'),
      });
    } else {
      showDialog.showMessageWithButton({
        showCloseButton: true,
        removeAnimation: true,
        text: `<div class="text-left text-sm pt-6">${
          (i18n.language === 'th'
            ? dataConsent?.data?.privacyPolicy?.th
            : dataConsent?.data?.privacyPolicy?.en) ?? ''
        }</div>`,
      });
    }
  };
  const platformStore: IconLink[] = [
    {
      link: 'https://play.google.com/store/search?q=thaiwatsadu&c=apps',
      logo: '/images/logo/footer/googleplay.png',
      alt: 'Google play',
    },
    {
      link: 'https://apps.apple.com/th/app/thaiwatsadu/id1606151700?l=th',
      logo: '/images/logo/footer/appstore.png',
      alt: 'App Store',
    },
  ];

  return (
    <div className="bg-white px-2 select-none">
      <Grid
        container
        className={`${footerClass.centerFooter} max-width-container`}
      >
        <Grid item xs={12} lg={4} className="pt-2">
          <Grid container>
            <Grid item xs={6}>
              <div className="pr-1 flex flex-col h-full">
                <div className="font-bold">{t('policy')}</div>
                <div className="text-sm">
                  <div
                    className="cursor-pointer text-black hover:text-primary"
                    onClick={handlePolicyClick}
                  >
                    {t('privacypolicy')}
                  </div>
                  <AppLink
                    className="hover:text-primary block"
                    href="/page/CookiePolicy"
                    target="_blank"
                  >
                    {t('cookiepolicy')}
                  </AppLink>
                  <a
                    className="hover:text-primary block"
                    href="https://www.centralgroup.com/storage/pdpa/cg-pdpa-brc-intra-group-agreement-th.pdf"
                    target="_blank"
                    rel="noopener"
                  >
                    {t('businessesincentralgroup')}
                  </a>
                  <AppLink
                    className="hover:text-primary block"
                    href="/page/refundpolicy"
                    target="_blank"
                  >
                    {t('exchangereturnpolicy')}
                  </AppLink>
                </div>
                <div className="font-bold mt-auto">{t('customercare')}</div>
                <div className="text-sm">
                  <AppLink
                    className="hover:text-primary block"
                    href="/contact"
                    target="_blank"
                  >
                    {t('contactus')}
                  </AppLink>
                  <div className="h-[30px]"></div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="pr-1 flex flex-col h-full">
                <div className="font-bold">{t('thaiwatsadu')}</div>
                <div className="text-sm">
                  <AppLink
                    className="hover:text-primary block"
                    href="/page/about"
                    target="_blank"
                  >
                    {t('aboutus')}
                  </AppLink>
                  <a
                    className="hover:text-primary block"
                    href="https://www.centralretail.com/en/brands/103/thai-watsadu"
                    target="_blank"
                    rel="noopener"
                  >
                    {t('investorrelation')}
                  </a>
                  <AppLink
                    className="hover:text-primary block"
                    href="/brochures"
                    target="_blank"
                  >
                    {t('downloadcatalog')}
                  </AppLink>
                  <AppLink
                    className="hover:text-primary block"
                    href="/page/buying-policy"
                    target="_blank"
                  >
                    {t('howtoorder')}
                  </AppLink>
                  <AppLink
                    className="hover:text-primary block"
                    href="/page/payment-policy"
                    target="_blank"
                  >
                    {t('onlinepayment')}
                  </AppLink>
                  <AppLink
                    className="hover:text-primary block"
                    href="/page/deliver-policy"
                    target="_blank"
                  >
                    {t('deliverymethods')}
                  </AppLink>
                </div>
                <div className="font-bold">{t('the1')}</div>
                <div className="text-sm">
                  <AppLink
                    className="hover:text-primary block"
                    href="/register"
                  >
                    {t('register')}
                  </AppLink>
                  <a
                    className="hover:text-primary block"
                    href="https://www.the1.co.th/articles/earn-burn-the1-points"
                    target="_blank"
                    rel="noopener"
                  >
                    {t('earnredeem')}
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid display="flex" item xs={12} lg={4} className="pt-2">
          <div className="ml-0 lg:ml-auto">
            <div className="mb-1 font-bold">{t('groupbusiness')}</div>
            <Grid container spacing={1} className="h-auto">
              {otherBrands.map((otherBrand, i) => (
                <Grid key={i} item xs={4} style={{ flexBasis: 'auto' }}>
                  <a href={otherBrand.link} target="_blank" rel="noopener">
                    <Image
                      src={otherBrand.logo}
                      alt={otherBrand.alt ?? ''}
                      width={230}
                      height={115}
                      style={{ maxHeight: '3rem', width: 'auto' }}
                    />
                  </a>
                </Grid>
              ))}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} lg={4} className="pt-2 ps-0 lg:ps-10">
          <div className="mb-1 font-bold">{t('followus')}</div>
          <Grid container spacing={3}>
            {socialLinks.map((socialLink, i) => (
              <Grid key={i} item>
                <a href={socialLink.link} target="_blank" rel="noopener">
                  <Image
                    src={socialLink.logo}
                    alt={socialLink.alt ?? ''}
                    width={24}
                    height={24}
                    style={{ height: '3rem', width: '1rem' }}
                  />
                </a>
              </Grid>
            ))}
            <Image
              src="/images/logo/footer/crc_omni.png"
              alt="crc_omni"
              width={144}
              height={144}
              style={{ height: '6rem', width: 'auto' }}
              className="hidden lg:block"
            />
          </Grid>
          <div className="mb-1 font-bold">{t('downloadapp')}</div>
          <div className="flex gap-2">
            <div className="flex md:flex-col gap-1 lg:gap-2 my-auto">
              {platformStore.map((platform, i) => (
                <React.Fragment key={i}>
                  <AppLink href={`${platform.link}`} target="_blank">
                    <Image
                      src={platform.logo}
                      alt={platform.alt ?? ''}
                      width={135}
                      height={40}
                    />
                  </AppLink>
                </React.Fragment>
              ))}
            </div>
            <div>
              <Image
                src="/images/homepage/qrapp.png"
                alt="qrcode"
                width={90}
                height={90}
                className="hidden sm:block"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
