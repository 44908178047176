export const isNumber = (x: string): boolean => {
  return /^[0-9]+$/.test(x);
};

export const isMobile = (x: string): boolean => {
  return /^\d{10}$/.test(x);
};

export const isEmail = (x: string): boolean => {
  return /^(([^<>()[\]\.,;:\s@'\"]+(\.[^<>()[\]\.,;:\s@'\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@'\"]+\.)+[^<>()[\]\.,;:\s@'\"]{2,})$/i.test(
    x,
  );
};

export const isValidePassword = (x: string): boolean => {
  if (x.length < 6) {
    return false;
  }
  const hasDigit = /[0-9]/.test(x);
  const hasLetter = /[a-zA-Z]/.test(x);

  return hasDigit && hasLetter;
};
