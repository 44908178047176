import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export default function LangHeader() {
  const router = useRouter();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipClick = () => {
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'white',
                color: 'black',
                width: '100%',
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                padding: '0.25rem 0',
              },
              style: {
                marginTop: 6,
              },
            },
          }}
          title={
            router.locale === 'th' ? (
              <Link
                locale="en"
                href={router.asPath}
                onClick={() => setOpen(false)}
              >
                <div className="flex items-center justify-center space-x-1 hover:bg-grayLight py-1 px-2">
                  <div>
                    <Image
                      src="/images/icons/lang/en.png"
                      alt="en"
                      width={25}
                      height={18}
                    />
                  </div>
                  <div>English</div>
                </div>
              </Link>
            ) : (
              <Link
                locale="th"
                href={router.asPath}
                onClick={() => setOpen(false)}
              >
                <div className="flex items-center justify-center space-x-1 hover:bg-grayLight py-1 px-2">
                  <div>
                    <Image
                      src="/images/icons/lang/th.png"
                      alt="th"
                      width={25}
                      height={18}
                    />
                  </div>
                  <div>ภาษาไทย</div>
                </div>
              </Link>
            )
          }
        >
          <div
            className="select-none cursor-pointer flex items-center justify-center space-x-1"
            onClick={handleTooltipClick}
          >
            <div>
              {router.locale === 'th' ? (
                <Image
                  src="/images/icons/lang/th.png"
                  alt="th"
                  width={25}
                  height={18}
                />
              ) : (
                <Image
                  src="/images/icons/lang/en.png"
                  alt="en"
                  width={25}
                  height={18}
                />
              )}
            </div>
            <div className="h-3 leading-3">
              <i className="bi bi-chevron-down text-xs"></i>
            </div>
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
