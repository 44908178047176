import React from 'react';
import Image from 'next/image';

interface Props {
  base64?: string;
  alt?: string;
  width?: number;
  maxHeight?: number;
  isBoreder?: boolean;
  noPadding?: boolean;
  className?: string;
  pimImage?: string;
  shadeCode?: string;
  redCode?: string;
  greenCode?: string;
  blueCode?: string;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
}

export default function ProductImageBase64({
  base64,
  alt,
  width,
  maxHeight,
  isBoreder,
  noPadding,
  className,
  pimImage,
  shadeCode,
  redCode,
  greenCode,
  blueCode,
  objectFit,
}: Props) {
  return (
    <div
      className={`${isBoreder ? `border ${!noPadding && 'p-1'}` : ''} relative`}
    >
      {base64 || pimImage ? (
        <>
          {shadeCode !== undefined &&
            shadeCode !== null &&
            shadeCode !== '' &&
            redCode &&
            greenCode &&
            blueCode && (
              <div
                className="absolute rounded-full w-1/3 h-1/3 left-0 top-1/2 -translate-y-1/2"
                style={{
                  backgroundColor: `rgb(${redCode},${greenCode},${blueCode})`,
                }}
              ></div>
            )}
          <Image
            src={
              pimImage
                ? `https://${process.env.NEXT_PUBLIC_BASE_URL_PIM_DOMAIN}/TWDPIM/web/Thumbnail/${pimImage}`
                : `data:image/jpeg;base64,${base64}`
            }
            alt={alt ?? ''}
            width={width ?? 319}
            height={width ?? 319}
            className={className}
            style={{
              objectFit: objectFit ?? 'contain',
              width: width,
              height: width,
              maxHeight: maxHeight,
            }}
          />
        </>
      ) : (
        <Image
          src={`/images/svg/no-img.svg`}
          alt={alt ?? ''}
          width={width ?? 319}
          height={width ?? 319}
          style={{
            objectFit: objectFit ?? 'contain',
            width: width,
            height: width,
            maxHeight: maxHeight,
          }}
        />
      )}
    </div>
  );
}
