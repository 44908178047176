import { CouponSource } from '@/models/profile/MyVoucher.model';
import {
  GetVoucherDetailModel,
  GetVoucherDetailModelConverter,
} from '@/models/profile/MyVoucherDetail.model';
import { getDataFromCMS, postDataFromAPI } from '@/utils/httpClient';

export const getProfileMyVoucherDetail = async ({
  lang,
  voucherk,
  channel,
}: {
  lang?: string;
  voucherk?: string;
  channel?: CouponSource;
}): Promise<{
  data: GetVoucherDetailModel | null | undefined;
  status: number | null;
}> => {
  const getData = await postDataFromAPI({
    path: '/getvoudetail',
    lang,
    body: {
      voucherk,
      channel,
    },
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: GetVoucherDetailModel | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = GetVoucherDetailModelConverter.fromJson(data);
      if (channel === CouponSource.omni && !response.data.termsCondition) {
        let slug: string = 'condition_coupon';
        if (response.data?.rewardId) {
          try {
            const getDataStaticPageList = await getDataFromCMS({
              path: `/api/staticpage_campaign`,
              lang,
            });
            const dataStaticPageList:
              | {
                  slug: string;
                  campaign_id?: string | null;
                }[]
              | undefined =
              getDataStaticPageList.status == 200
                ? getDataStaticPageList.data
                  ? getDataStaticPageList.data
                  : undefined
                : undefined;
            slug =
              dataStaticPageList?.find(
                (e) => e.campaign_id === response.data?.rewardId,
              )?.slug ?? 'condition_coupon';
          } catch (_) {}
        }
        const getDataStaticPage = await getDataFromCMS({
          path: `/api/getstaticmenu/${slug}`,
          lang,
        });
        const dataStaticPage =
          getDataStaticPage.status == 200
            ? getDataStaticPage.data
              ? getDataStaticPage.data
              : undefined
            : undefined;
        const termsCondition =
          (dataStaticPage?.contents ?? []).length > 0
            ? dataStaticPage?.contents[0].detail ?? ''
            : '';
        response.data = {
          ...response.data,
          termsCondition: termsCondition,
        };
      }
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
