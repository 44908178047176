// To parse this data:
//
//   import { Convert, ProductDetailPageModel } from "./file";
//
//   const productDetailPageModel = Convert.toProductDetailPageModel(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';
import { CatbarModel, CatbarModelConverter } from '../Catbar.model';
import {
  AtbBadge,
  AtbBadgeConverter,
  ProductModel,
  ProductModelConverter,
} from '../Product.model';
import { numberWithCommas } from '@/utils/format';
import {
  GetPDPBadgeModel,
  GetPDPBadgeModelConverter,
} from './GetPDPBadge.model';

export interface ProductDetailPageModel {
  areaPerItem: number;
  pdfmanual?: null;
  title?: string;
  slug?: string;
  productdetail?: ProductDetailModel;
  priceset?: PricesetModel;
  tile?: TileModel;
  totalreview?: TotalreviewModel;
  imageList360?: string;
  linkForVdo?: string;
  linkForVdo2?: string;
  skucartstatus?: number;
  skucartservice?: string;
  pricewithser?: string;
  dfstore?: string;
  services?: ServiceModel[];
  cat3?: string;
  lv2?: string;
  atbBadges?: AtbBadge[];
  spucode?: number;
  spuByCat?: Spubycat;
  spuBySkus?: Spubysku[];
  catbars?: CatbarModel[];
  theonepromo?: TheonepromoModel;
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
  isHybrid?: boolean;
  isCurtain?: boolean;
  curtainTableForm?: CurtainTableForm;
  allSkuCurtain?: ProductModel[];
  formularData?: FormularModel[];
  isPaintCal?: boolean;
  productSkname?: string;
  isRedirect?: boolean;
  paintProperty?: PaintProperty;
  isGroutCal?: boolean;
  groutProperty?: GroutProperty;
  isChristmas?: boolean;
  isBaseChristmasSku?: boolean;
  pdpBadge?: GetPDPBadgeModel;
  isDateBetweenChristmas?: boolean;
}

export class ProductDetailPageModelConverter {
  public static fromJson(json: any): ProductDetailPageModel {
    let spuByCat = undefined;
    let spuBySkus = undefined;
    try {
      spuByCat = json['spubycat']
        ? SpubycatConverter.fromJson(
            JSON.parse(json['spubycat'].toString().trim()),
          )
        : undefined;
      spuBySkus = json['spubysku']
        ? JSON.parse(json['spubysku']).map((e: any) =>
            SpubyskuConverter.fromJson(e),
          )
        : undefined;
    } catch (_) {}

    const model: ProductDetailPageModel = {
      areaPerItem: json['areaPerItem'],
      pdfmanual: json['pdfmanual'],
      title: json['title'],
      slug: json['slug'] || json['slugName'],
      productdetail: json['productdetail']
        ? ProductDetailModelConverter.fromJson(json['productdetail'])
        : undefined,
      priceset: json['priceset']
        ? PricesetModelConverter.fromJson(json['priceset'])
        : undefined,
      tile: json['istiles']
        ? TileModelConverter.fromJson(json['istiles'])
        : undefined,
      totalreview: json['totalreview']
        ? TotalreviewModelConverter.fromJson(json['totalreview'])
        : undefined,
      imageList360: json['imagelist360'],
      linkForVdo: json['linkForVdo'],
      linkForVdo2: json['linkForVdo2'],
      skucartstatus: json['skucartstatus'],
      skucartservice: json['skucartservice'],
      pricewithser:
        json['pricewithser'] === undefined
          ? undefined
          : numberWithCommas(json['pricewithser'].replaceAll(',', '')),
      dfstore: json['DFSTORE'],
      services: json['services']
        ? json['services'].map((x: any) => ServiceModelConverter.fromJson(x))
        : undefined,
      cat3: json['cat3'],
      lv2: json['lv2'],
      atbBadges: json['atbBadge']
        ? json['atbBadge'].map((x: any) => AtbBadgeConverter.fromJson(x))
        : undefined,
      spucode: json['spucode'],
      spuByCat,
      spuBySkus,
      catbars: json['catbars']
        ? json['catbars'].map((x: any) => CatbarModelConverter.fromJson(x))
        : undefined,
      theonepromo: json['theonepromo']
        ? TheonepromoModelConverter.fromJson(json['theonepromo'])
        : undefined,
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
      isHybrid: json['isHybrid'] === true ? true : false,
      isCurtain: json['iscurtain'] === true ? true : false,
      curtainTableForm: json['curtainTableForm']
        ? CurtainTableFormConverter.fromJson(json['curtainTableForm'])
        : undefined,
      allSkuCurtain: json['allskucurtain']
        ? json['allskucurtain'].map((x: any) =>
            ProductModelConverter.fromJson(x),
          )
        : undefined,
      formularData: json['formularData']
        ? json['formularData'].map((x: any) =>
            FormularModelConverter.fromJson(x),
          )
        : undefined,
      isPaintCal: json['isPaintCal'] === true ? true : false,
      productSkname: json['productSkname'] ? json['productSkname'] : undefined,
      isRedirect: json['isRedirect'] === true ? true : false,
      paintProperty: json['paintProperty']
        ? PaintPropertyConverter.fromJson(json['paintProperty'])
        : undefined,
      isGroutCal: json['isGroutCal'] === true ? true : false,
      groutProperty: json['groutProperty']
        ? GroutPropertyConverter.fromJson(json['groutProperty'])
        : undefined,
      isChristmas: json['ischristmas'] === true ? true : false,
      isBaseChristmasSku: json['isBaseChristmasSku'] === true ? true : false,
      pdpBadge: json['pdpBadge']
        ? GetPDPBadgeModelConverter.fromJson(json['pdpBadge'])
        : undefined,
      isDateBetweenChristmas:
        json['isDateBetweenChristmas'] === true ? true : false,
    };
    return modelValue(model);
  }
}

export interface FormularModel {
  groupId: number;
  groupName: string;
  subGroupName: string;
  formular: string;
  image: string;
  coverImage: string;
}

export class FormularModelConverter {
  public static fromJson(json: any): FormularModel {
    const model: FormularModel = {
      groupId: json['groupId'],
      groupName: json['groupName'],
      subGroupName: json['subGroupName'],
      formular: json['formular'],
      image: json['image'],
      coverImage: json['coverImage'],
    };
    return modelValue(model);
  }
}

export interface Spubysku {
  sku?: string;
  slugname?: string;
  prop?: string[];
}

export class SpubyskuConverter {
  public static fromJson(json: any): Spubysku {
    const model: Spubysku = {
      sku: json['SKCODE']?.toString()?.trim(),
      slugname: json['slugname']?.toString()?.trim(),
      prop: json['PROP']
        ? Object.entries(JSON.parse(json['PROP'].toString().trim())).map(
            (e) => {
              return e[1]?.toString().trim() ?? '';
            },
          )
        : undefined,
    };
    return modelValue(model);
  }
}

export interface Spubycat {
  byCode?: string;
  cat?: string;
  properties?: Property[];
}

export class SpubycatConverter {
  public static fromJson(json: any): Spubycat {
    let properties = [];
    try {
      properties = json['PROPERTY']
        ? JSON.parse(json['PROPERTY']).map((x: any, i: number) =>
            PropertyConverter.fromJson(x, i),
          )
        : undefined;
    } catch (_) {}
    const model: Spubycat = {
      byCode: json['BYCODE'],
      cat: json['CLASS'],
      properties,
    };
    return modelValue(model);
  }
}

export interface Property {
  index?: number;
  name?: string;
  value?: string;
  propertyValueList?: string[];
}

export class PropertyConverter {
  public static fromJson(json: any, index: number): Property {
    let propertyValueList: string[] = [];
    try {
      if (json['VAL'].trim() != '') {
        propertyValueList = json['VAL']
          .trim()
          .split('|')
          .map((e: string) => e.trim());
      }
    } catch (_) {}
    const model: Property = {
      index,
      name: json['name'],
      value: json['VAL'],
      propertyValueList,
    };
    return modelValue(model);
  }
}

export interface PricesetModel {
  sku: string;
  prName?: string;
  price?: string;
  prUname?: string;
  stock?: number;
  disc?: string;
  per?: string;
  discountPercent?: number;
  class?: string;
  location?: string;
  stname?: string;
  backORDflag?: boolean;
  preorder?: number;
  preOrderMsg?: string;
  whyshowleadtime?: string;
  abtflag?: string;
  tagnew?: number;
  hb?: string;
  dism?: string;
  isLongtailorDC?: string;
  hugthai?: number;
  linebt?: string;
  serSKCODE?: null;
  serPrice?: number;
  serOrgPrice?: number;
  serATB?: null;
  serDISM?: null;
  discwithser?: string;
  skUstatus?: string;
  assemble?: boolean;
  prname?: string;
  ldesp?: string;
  sdesp?: string;
  attrb?: string;
  pimg?: string;
  cat?: string;
  brand?: string;
  linkForVdo?: string;
  linkForVdo2?: string;
  prskustatus?: string;
  freeInstallBadge?: number;
  warranty?: string;
  warrantycode?: string;
  remarkMessage?: string;
  priceSqmeters?: null;
  persqmet?: string;
  prcode?: string;
  sparepartImage?: string;
  stockavail?: number;
  clearanceflag?: number;
  skuprice?: null;
  skudisc?: null;
  skuservicedisc?: null;
  badgeDiscNotInst?: null;
  badgeDiscInst?: null;
  saleflag?: number;
  freegiftBundle?: null;
  minqty?: number;
  boxWeight?: number;
  boxHeight?: number;
  boxWidth?: number;
  boxLength?: number;
  prHeight?: number;
  prWidth?: number;
  prLength?: number;
  isflagbranch?: number;
  catcode?: string;
  jsndoc?: ProductDoc[];
  packSize?: number;
  remark?: null;
  spu?: number;
  freegiftMessage?: string;
  packQty?: number;
  canBulky: boolean;
}

export interface ProductDetailModel {
  prName?: string;
  ldesp?: string;
  sdesp?: string;
  attrb?: string;
  pimg?: string;
  cat?: string;
  sku?: string;
  brand?: string;
  linkForVdo?: string;
  linkForVdo2?: string;
  prskustatus?: string;
  assemble?: boolean;
  remarkMessage?: string;
  remarkMessageTH?: string;
  remarkMessageEN?: string;
}

export interface TheonepromoModel {
  point?: number;
  period?: string;
  theonepromo?: number;
}

export interface TotalreviewModel {
  avgreview?: number;
  totalreview?: number;
}
export class PricesetModelConverter {
  public static fromJson(json: any): PricesetModel {
    let jsnDocList: ProductDoc[] | undefined = undefined;
    try {
      jsnDocList = json['Jsndoc']
        ? JSON.parse(json['Jsndoc']).map((x: any) =>
            ProductDocConverter.fromJson(x),
          )
        : undefined;
    } catch (_) {}
    const model: PricesetModel = {
      sku: (json['SK_CODE'] ?? '').toString().trim(),
      prName: json['PR_NAME'],
      price:
        json['PRICE'] === undefined
          ? undefined
          : numberWithCommas(json['PRICE'].replaceAll(',', '')),
      prUname: json['PR_UNAME'],
      stock: json['STOCK'],
      disc: json['disc'],
      per: json['per'],
      discountPercent:
        json['per'] || json['PER']
          ? parseInt(
              (json['per'] ?? json['PER'] ?? '')
                .toLowerCase()
                .replace('off', '')
                .replace(/ /g, '')
                .replace('%', ''),
            )
          : undefined,
      class: json['CLASS'],
      location: json['LOCATION'],
      stname: json['stname'],
      backORDflag: json['BackORDflag'],
      preorder: json['PREORDER'],
      preOrderMsg: json['PreOrderMsg'],
      whyshowleadtime: json['whyshowleadtime'],
      abtflag: json['ABTFLAG'],
      tagnew: json['TAGNEW'],
      hb: json['HB'],
      dism: json['DISM'],
      isLongtailorDC: json['isLongtailorDC'],
      hugthai: json['HUGTHAI'],
      linebt: json['Linebt'],
      serSKCODE: json['serSKCODE'],
      serPrice: json['serPrice'],
      serOrgPrice: json['serOrgPrice'],
      serATB: json['serATB'],
      serDISM: json['serDISM'],
      discwithser: json['discwithser'],
      skUstatus: json['SKUstatus'],
      assemble: json['assemble'] === true ? true : false,
      prname: json['prname'],
      ldesp: json['ldesp'],
      sdesp: json['sdesp'],
      attrb: json['attrb'],
      pimg: json['pimg'],
      cat: json['cat'],
      brand: json['brand'],
      linkForVdo: json['linkForVdo']
        ? json['linkForVdo'].toString().replaceAll('"', '')
        : undefined,
      linkForVdo2: json['linkForVdo2']
        ? json['linkForVdo2'].toString().replaceAll('"', '')
        : undefined,
      prskustatus: json['prskustatus'],
      freeInstallBadge: json['FREEINSTALBADGE'],
      warranty: json['WARRANTY'],
      warrantycode: json['WARRANTYCODE'],
      remarkMessage: json['remark_message'],
      priceSqmeters: json['PRICE_SQMETERS'],
      persqmet: json['PERSQMET'],
      prcode: json['PRCODE'],
      sparepartImage: json['SPAREPARTIMG'],
      stockavail: json['STOCKAVAIL'],
      clearanceflag: json['clearanceflag'],
      skuprice: json['SKUPRICE'],
      skudisc: json['SKUDISC'],
      skuservicedisc: json['SKUSERVICEDISC'],
      badgeDiscNotInst: json['BadgeDisc_notInst'],
      badgeDiscInst: json['BadgeDisc_Inst'],
      saleflag: json['saleflag'],
      freegiftBundle: json['FREEGIFTBUNDLE'],
      minqty: json['MINQTY'],
      boxWeight: json['BOX_WEIGHT'],
      boxHeight: json['BOX_HEIGHT'],
      boxWidth: json['BOX_WIDTH'],
      boxLength: json['BOX_LENGTH'],
      prHeight: json['PR_HEIGHT'],
      prWidth: json['PR_WIDTH'],
      prLength: json['PR_LENGTH'],
      isflagbranch: json['isflagbranch'],
      catcode: json['CATCODE'],
      jsndoc: jsnDocList,
      packSize: json['packSize'],
      remark: json['Remark'],
      spu: json['SPU'],
      freegiftMessage: json['FREEGIFTMSG'],
      packQty: json['packQty'],
      canBulky: json['canBulky'] === true ? true : false,
    };
    return modelValue(model);
  }
}

export class ProductDetailModelConverter {
  public static fromJson(json: any): ProductDetailModel {
    const model: ProductDetailModel = {
      prName: json['prname'],
      ldesp: json['ldesp'],
      sdesp: json['sdesp'],
      attrb: json['attrb'],
      pimg: json['pimg'],
      cat: json['cat'],
      sku: json['skc'],
      brand: json['brand'],
      linkForVdo: json['linkForVdo']
        ? json['linkForVdo'].toString().replaceAll('"', '')
        : undefined,
      linkForVdo2: json['linkForVdo2']
        ? json['linkForVdo2'].toString().replaceAll('"', '')
        : undefined,
      prskustatus: json['prskustatus'],
      assemble: json['assemble'] === true ? true : false,
      remarkMessage: json['RemarkMessage'],
      remarkMessageTH: json['RemarkMessage_TH'],
      remarkMessageEN: json['RemarkMessage_EN'],
    };
    return modelValue(model);
  }
}

export class TotalreviewModelConverter {
  public static fromJson(json: any): TotalreviewModel {
    const model: TotalreviewModel = {
      avgreview: json['AVGREVIEW'],
      totalreview: json['TOTALREVIEW'],
    };
    return modelValue(model);
  }
}

export interface ServiceModel {
  serSKCODE?: string;
  serPrice?: number;
  serOrgPrice?: number;
  serATB?: string;
  serDISM?: string;
  skuservicedisc?: number;
  skudisc?: number;
  priceAdd?: number;
  title?: string;
  description?: string;
}

export class ServiceModelConverter {
  public static fromJson(json: any): ServiceModel {
    const model: ServiceModel = {
      serSKCODE: json['serSKCODE']?.toString().trim(),
      serPrice: json['serPrice'],
      serOrgPrice: json['serOrgPrice'],
      serATB: json['serATB'],
      serDISM: json['serDISM'],
      skuservicedisc: json['SKUSERVICEDISC'],
      skudisc: json['SKUDISC'],
      priceAdd: json['PriceAdd'],
      title: json['PR_TITLE'],
      description: json['PR_DESCRIPTION'],
    };
    return modelValue(model);
  }
}
export class TheonepromoModelConverter {
  public static fromJson(json: any): TheonepromoModel {
    const model: TheonepromoModel = {
      point: json['point'],
      period: json['period'],
      theonepromo: json['theonepromo'],
    };
    return modelValue(model);
  }
}

export interface SpuDataToUse {
  index: number;
  lable: string;
  props: PropsDataToUse[];
}

export interface PropsDataToUse {
  propertyIndex: number;
  index: number;
  name: string;
  status: PropStatus;
}

export enum PropStatus {
  // eslint-disable-next-line no-unused-vars
  notSelected = 0,
  // eslint-disable-next-line no-unused-vars
  selected = 1,
  // eslint-disable-next-line no-unused-vars
  disabled = 2,
}

export interface Spubysku {
  sku?: string;
  slugname?: string;
  prop?: string[];
}

export interface TileModel {
  width?: number;
  length?: number;
  height?: number;
  unitName?: string;
}

export class TileModelConverter {
  public static fromJson(json: any): TileModel {
    const model: TileModel = {
      width: json['PR_WIDTH'] ? Number(json['PR_WIDTH']) : undefined,
      length: json['PR_LENGTH'] ? Number(json['PR_LENGTH']) : undefined,
      height: json['PR_HEIGHT'] ? Number(json['PR_HEIGHT']) : undefined,
      unitName: json['PR_UNAME']?.toString()?.trim(),
    };
    return modelValue(model);
  }
}

export interface CurtainTableForm {
  type1?: CurtainTableType;
  type2?: CurtainTableType;
  type3?: CurtainTableType;
  type4?: CurtainTableType;
  type5?: CurtainTableType;
}

export class CurtainTableFormConverter {
  public static fromJson(json: any): CurtainTableForm {
    const model: CurtainTableForm = {
      type1: json['type1']
        ? CurtainTableTypeConverter.fromJson(json['type1'])
        : undefined,
      type2: json['type2']
        ? CurtainTableTypeConverter.fromJson(json['type2'])
        : undefined,
      type3: json['type3']
        ? CurtainTableTypeConverter.fromJson(json['type3'])
        : undefined,
      type4: json['type4']
        ? CurtainTableTypeConverter.fromJson(json['type4'])
        : undefined,
      type5: json['type5']
        ? CurtainTableTypeConverter.fromJson(json['type5'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface CurtainTableType {
  xWidth?: number;
  extraWidth?: number;
  extraWidth2?: number;
  extraHeight?: number;
  sewingPrice?: number;
  sewingPriceDisc?: number;
  sewingSku?: string;
  freeList1: boolean;
  freeList2: boolean;
  freeList3: boolean;
  freeList4: boolean;
  eyelet?: number;
}

export class CurtainTableTypeConverter {
  public static fromJson(json: any): CurtainTableType {
    const model: CurtainTableType = {
      xWidth: json['x_width'] ? Number(json['x_width']) : undefined,
      extraWidth: json['extra_width'] ? Number(json['extra_width']) : undefined,
      extraWidth2: json['extra_width2']
        ? Number(json['extra_width2'])
        : undefined,
      extraHeight: json['extra_height']
        ? Number(json['extra_height'])
        : undefined,
      sewingPrice: json['sewing_price']
        ? Number(json['sewing_price'])
        : undefined,
      sewingPriceDisc: json['sewing_price_disc']
        ? Number(json['sewing_price_disc'])
        : undefined,
      sewingSku: json['sewing_sku']?.toString()?.trim(),
      freeList1: json['freelist-1'] === true ? true : false,
      freeList2: json['freelist-2'] === true ? true : false,
      freeList3: json['freelist-3'] === true ? true : false,
      freeList4: json['freelist-4'] === true ? true : false,
      eyelet: json['eyelet'] ? Number(json['eyelet']) : undefined,
    };
    return modelValue(model);
  }
}

export interface ProductDoc {
  docType?: string;
  docLink?: string;
  docUrl?: string;
}
export class ProductDocConverter {
  public static fromJson(json: any): ProductDoc {
    const model: ProductDoc = {
      docType: json['DOCTYPE'],
      docLink: json['DOCLINK'],
      docUrl: json['DOCURL'],
    };
    return modelValue(model);
  }
}
export interface PaintProperty {
  gallonQnt: string;
  areaQnt: string;
  roundQnt: string;
}
export class PaintPropertyConverter {
  public static fromJson(json: any): PaintProperty {
    const model: PaintProperty = {
      gallonQnt: json['gallonQnt'],
      areaQnt: json['areaQnt'],
      roundQnt: json['roundQnt'],
    };
    return modelValue(model);
  }
}
export interface GroutProperty {
  gallonQnt: string;
  areaQnt: string;
  roundQnt: string;
}
export class GroutPropertyConverter {
  public static fromJson(json: any): GroutProperty {
    const model: GroutProperty = {
      gallonQnt: json['gallonQnt'],
      areaQnt: json['areaQnt'],
      roundQnt: json['roundQnt'],
    };
    return modelValue(model);
  }
}

export enum GroutType {
  // eslint-disable-next-line no-unused-vars
  area = '1',
  // eslint-disable-next-line no-unused-vars
  size = '2',
}

export enum TileType {
  // eslint-disable-next-line no-unused-vars
  area = '1',
  // eslint-disable-next-line no-unused-vars
  size = '2',
}

export enum TileTypeToolTip {
  // eslint-disable-next-line no-unused-vars
  length = 'groutlength',
  // eslint-disable-next-line no-unused-vars
  width = 'groutwidth',
  // eslint-disable-next-line no-unused-vars
  thick = 'groutthick',
  // eslint-disable-next-line no-unused-vars
  lines = 'groutlines',
}
