import { modelValue } from '@/utils/modelValue';

export interface StoreData {
  code: string;
  name: string;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  openTime: string;
  closeTime: string;
  address: string;
  addressEN: string;
  pickupTimeStart: string;
  pickupTimeEnd: string;
  nameEN: string;
  distance?: number;
  newStoreOpenDate?: string;
  flag?: number;
  stock?: number;
  averageSalePerDay?: number;
  isHybrid?: boolean;
}

export class StoreDataConverter {
  public static fromJson(json: any): StoreData {
    const model: StoreData = {
      code: (json['STCODE'] ?? '').toString().trim(),
      name: (json['SnameTH'] ?? '').toString().trim(),
      nameEN: (json['SName'] ?? json['SnameTH'] ?? '').toString().trim(),
      latitude: json['LAT'] ?? 0,
      longitude: json['LNG'] ?? 0,
      phoneNumber: (json['STTEL'] ?? '').toString().trim(),
      openTime: (json['OPENTIME'] ?? '').toString().trim(),
      closeTime: (json['CLOSETIME'] ?? '').toString().trim(),
      address: (json['ADDS'] ?? '').toString().trim(),
      addressEN: (json['ADDSEN'] ?? json['ADDS'] ?? '').toString().trim(),
      pickupTimeStart: (json['RCVOPENTIME'] ?? '').toString().trim(),
      pickupTimeEnd: (json['RCVCLOSETIME'] ?? '').toString().trim(),
      newStoreOpenDate: (json['newStoreOpendate'] ?? '').toString().trim(),
      flag: json['flag'] ?? 0,
      stock: json['STOCK'],
      averageSalePerDay: json['AVGSALEDAY'],
    };
    return modelValue(model);
  }
}

export interface CheckStockResponse {
  dbcode: boolean;
  stocks: StoreData[];
  dbmessage: string;
}

export class CheckStockResponseConverter {
  public static fromJson(json: any): CheckStockResponse {
    const model: CheckStockResponse = {
      dbcode: json['dbcode'] == true ? true : false,
      stocks: json['dbitems']
        ? json['dbitems'].map((e: any) => StoreDataConverter.fromJson(e))
        : [],
      dbmessage: json['dbmessage'] ? json['dbmessage'] : '',
    };
    return modelValue(model);
  }
}
