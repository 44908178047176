import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';

interface Props {
  // eslint-disable-next-line no-unused-vars
  setMyPosition: (position: { lat: number; lng: number }) => void;
}

export default function InputStore({ setMyPosition }: Props) {
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    const options = {
      types: [],
      componentRestrictions: {
        country: ['th'],
      },
      fields: ['geometry'],
      //z-index 999
    };
    const autoComplete = new window.google.maps.places.Autocomplete(
      inputRef.current as HTMLInputElement,
      options,
    );
    autoComplete.addListener('place_changed', () => {
      const place = autoComplete.getPlace();
      if (!place.geometry) {
        alert('this location not available');
      }
      const lat = place.geometry?.location?.lat();
      const lng = place.geometry?.location?.lng();

      if (lat && lng) setMyPosition({ lat, lng });
    });

    return () => {
      window.google.maps.event.clearListeners(autoComplete, 'place_changed');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  return (
    <div className="relative">
      <input
        ref={inputRef}
        // id="searchStore"
        type="text"
        // value={searchStoreText}
        className="h-full w-full px-2 bg-grayLight focus:outline-none text-sm py-1.5 z-50"
        placeholder={`${t('enterpostcodedistanctprovince')}`}
        onFocus={handleClearInput}
        // onChange={(e) => setSearchStoreText(e.target.value)}
      />
    </div>
  );
}
