import {
  AddToCartRequest,
  AddToCartResponse,
  SkuWithQty,
} from '@/models/AddToCart.model';
import * as addToCartService from '@/services/client/addToCartService';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import * as ServerCookies from '@/services/client/cookieService';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import { AddToCartErrorHandleInput } from '@/utils/addToCart';
import { CookieData } from '@/models/Authen.model';
import { ProductModel } from '@/models/Product.model';
import thText from '@/locales/th/addtocart.json';
import enText from '@/locales/en/addtocart.json';
import { mapSkuAddingToCart } from '@/utils/format';
import { getBundleFreeGift } from '@/services/client/pdp/getBundleFreegiftService';
import { BundleFreegiftModel } from '@/models/pdp/BundleFreeGift.model';

interface AddToCartState {
  addingToCart: boolean;
  addingToCartSku?: string;
  addingToCartWithService?: string;
  error?: AddToCartErrorHandleInput;
  isConfirm?: boolean;
  cartCount: number;
  addToCartSuccessSku?: ProductModel[];
  addingMultiToCart: boolean;
  addingMultiToCartSuccess: boolean;
  bundleFreegiftResult?: BundleFreegiftModel | null;
  addingChristmasToCart?: boolean;
  addingChristmasToCartSuccess?: boolean;
}

const initialState: AddToCartState = {
  addingToCart: false,
  cartCount: 0,
  addingMultiToCart: false,
  addingMultiToCartSuccess: false,
};

export const addToCart = createAsyncThunk(
  'addToCart/addToCart',
  async (request: AddToCartRequest) => {
    if (Cookies.get(cookieKey.stcode) === undefined) {
      return {
        dbCode: false,
        dbMessage: 'selectstore|',
        sku: request.sku,
        qty: request.qty,
        withService: request.withService,
      };
    }
    const response = await addToCartService.addToCart(request);

    return response;
  },
);

export const addToCartMultiItems = createAsyncThunk(
  'addToCart/addToCartMultiItems',
  async ({
    request,
    type,
  }: {
    request: SkuWithQty[];
    type?: addToCartService.AddToCartMultiItemsType;
  }) => {
    if (Cookies.get(cookieKey.stcode) === undefined) {
      return {
        dbCode: false,
        dbMessage: 'selectstore|',
      };
    }
    const response = await addToCartService.addToCartMultiItems(request, type);
    return response;
  },
);

export const consentAddToCart = createAsyncThunk<
  AddToCartResponse,
  {
    sku?: string;
    qty?: number;
    setId?: string;
    skuQtyList?: SkuWithQty[];
    withService?: string;
  },
  { state: RootState }
>(
  'addToCart/consentAddToCart',
  async (
    // ห้ามลบ arg ออก เพราะเอาไปใช้ action.meta.arg.sku
    {
      // eslint-disable-next-line no-unused-vars
      sku,
      qty,
      // eslint-disable-next-line no-unused-vars
      setId,
      // eslint-disable-next-line no-unused-vars
      skuQtyList,
      // eslint-disable-next-line no-unused-vars
      withService,
    }: {
      sku?: string;
      qty?: number;
      setId?: string;
      skuQtyList?: SkuWithQty[];
      withService?: string;
    },
    { getState }: { getState: () => RootState },
  ) => {
    const oldStateError: AddToCartErrorHandleInput | undefined =
      getState().addToCart.error;
    if (!oldStateError?.sku || (!oldStateError?.qty && !qty)) {
      throw new Error('error');
    }
    const response = await addToCartService.addToCart({
      sku: oldStateError.sku,
      qty: qty ?? oldStateError.qty,
      isConfirm: true,
      isSelectBundle: oldStateError.isSelectBundle,
      setId: oldStateError.setId,
      withService: oldStateError.withService,
      skuQtyList: oldStateError.skuQtyList,
      lang: oldStateError.lang,
      type: oldStateError.type,
    });
    return response;
  },
);

export const reNewServiceAndAddToCart = createAsyncThunk<
  AddToCartResponse,
  { lang?: string; sku?: string },
  { state: RootState }
>(
  'addToCart/reNewServiceAndAddToCart',
  async (
    // ห้ามลบ arg ออก เพราะเอาไปใช้ action.meta.arg.sku
    // eslint-disable-next-line no-unused-vars
    { sku, lang }: { lang?: string; sku?: string },
    { getState }: { getState: () => RootState },
  ) => {
    const oldStateError: AddToCartErrorHandleInput | undefined =
      getState().addToCart.error;
    if (!oldStateError?.sku || !oldStateError?.qty) {
      throw new Error('error');
    }
    const reNewResponse = await addToCartService.reNewService({
      lang,
      sku: oldStateError.sku,
      skuService: oldStateError.withService,
    });

    if (reNewResponse.dbCode === false) {
      throw new Error(
        reNewResponse.dbMessage ??
          (lang === 'th'
            ? thText.unabletochangeinstallationservice
            : enText.unabletochangeinstallationservice),
      );
    }

    const response = await addToCartService.addToCart({
      sku: oldStateError.sku,
      qty: oldStateError.qty,
      isConfirm: true,
      isSelectBundle: oldStateError.isSelectBundle,
      setId: oldStateError.setId,
      withService: oldStateError.withService,
      lang: oldStateError.lang,
    });
    return response;
  },
);

export const getBundleFreeGiftQuery = createAsyncThunk(
  'addToCart/getBundleFreeGift',
  async ({
    lang,
    sku,
    quantity,
  }: {
    lang?: string;
    sku?: string;
    quantity?: number;
  }) => {
    const response = await getBundleFreeGift({
      lang,
      skcode: sku,
      quantity,
    });
    return response;
  },
);
export const addToCartSelectBundle = createAsyncThunk<
  AddToCartResponse,
  {
    sku?: string;
    qty?: number;
    isfreebundle?: boolean;
    isselectbundle?: boolean;
    setId?: string;
  },
  { state: RootState }
>(
  'addToCart/addToCartSelectBundle',
  async (
    // ห้ามลบ arg ออก เพราะเอาไปใช้ action.meta.arg.sku
    {
      // eslint-disable-next-line no-unused-vars
      sku,
      qty,
      // eslint-disable-next-line no-unused-vars
      isfreebundle,
      // eslint-disable-next-line no-unused-vars
      isselectbundle,
      setId,
    }: {
      sku?: string;
      qty?: number;
      isfreebundle?: boolean;
      isselectbundle?: boolean;
      // eslint-disable-next-line no-unused-vars
      setId?: string;
    },
    { getState }: { getState: () => RootState },
  ) => {
    if (Cookies.get(cookieKey.stcode) === undefined) {
      return {
        dbCode: false,
        dbMessage: 'selectstore|',
        sku: sku,
        qty: sku ? qty ?? 1 : undefined,
      };
    }
    const oldStateError: AddToCartErrorHandleInput | undefined =
      getState().addToCart.error;
    if (!oldStateError?.sku || (!oldStateError?.qty && !qty)) {
      throw new Error('error');
    }
    const response = await addToCartService.addToCart({
      sku: oldStateError.sku,
      qty: qty ?? oldStateError.qty,
      isOk: false,
      isConfirm: true,
      isFreeBundle: true,
      isSelectBundle: false,
      setId: setId,
      lang: oldStateError.lang,
    });
    return response;
  },
);

export const addToCartChristmas = createAsyncThunk(
  'addToCart/addToCartChristmas',
  async ({ request }: { request: AddToCartRequest }) => {
    if (Cookies.get(cookieKey.stcode) === undefined) {
      return {
        dbCode: false,
        dbMessage: 'selectstore|',
      };
    }
    const response = await addToCartService.addToCartChristmasItem(request);
    return response;
  },
);

const handleFulfilled = async (
  state: AddToCartState,
  action: PayloadAction<
    AddToCartResponse,
    string,
    {
      requestId: string;
      requestStatus: 'fulfilled';
    },
    never
  >,
) => {
  state.addingToCart = false;
  state.addingMultiToCart = false;
  state.addingToCartSku = undefined;
  state.addingToCartWithService = undefined;
  state.bundleFreegiftResult = undefined;
  state.addingChristmasToCart = false;
  if (action.payload.dbCode === true) {
    state.error = undefined;
    const ref = action.payload.cookies?.find(
      (e: CookieData) => e.name === cookieKey.ref,
    )?.val;
    if (ref) {
      ServerCookies.set(cookieKey.ref, ref.toString());
    }
    if (action.payload.dbItems && action.payload.dbItems.length > 0) {
      state.cartCount = action.payload.dbItems[0].sumQty ?? 0;
      Cookies.set(
        cookieKey.cartCount,
        (action.payload.dbItems[0].sumQty ?? 0).toString(),
        {
          secure: process.env.NEXT_PUBLIC_COOKIE_ENV !== 'development',
        },
      );
      state.addToCartSuccessSku = action.payload.dbItems.map((e) => {
        e.qty = action.payload.qty;
        return e;
      });
      state.addingChristmasToCartSuccess = true;
    } else if ((action.payload.sumQuantity ?? 0) > 0) {
      state.cartCount = action.payload.sumQuantity ?? 0;
      Cookies.set(
        cookieKey.cartCount,
        (action.payload.sumQuantity ?? 0).toString(),
        {
          secure: process.env.NEXT_PUBLIC_COOKIE_ENV !== 'development',
        },
      );
      state.addingMultiToCartSuccess = true;
    }
  } else {
    state.addToCartSuccessSku = undefined;
    state.error = {
      text: action.payload.dbMessage,
      type: action.payload.type,
      svgImage: action.payload.svgImage,
      messages: action.payload.messages,
      isNotRefundMessage: action.payload.isNotRefundMessage,
      productName: action.payload.productName,
      skuService: action.payload.skuService,
      ref: action.payload.ref,
      serviceNotAvail: action.payload.serviceNotAvail,
      sku: action.payload.sku,
      qty: action.payload.qty,
      isSelectBundle: action.payload.isSelectBundle,
      setId: action.payload.setId,
      withService: action.payload.withService,
      skuQtyList: action.payload.skuQtyList,
      serviceType: action.payload.serviceType,
      addToCartMultiType: action.payload.addToCartMultiType,
      storeCode: action.payload.storeCode,
    };
  }
};

const addToCartSlice = createSlice({
  name: 'addToCart',
  initialState: initialState,
  reducers: {
    refreshCartCount: (state) => {
      state.cartCount = Number(Cookies.get(cookieKey.cartCount)) ?? 0;
    },
    setError: (state, action: { payload: AddToCartErrorHandleInput }) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    clearAddToCartSuccessSku: (state) => {
      state.addToCartSuccessSku = undefined;
      state.addingMultiToCartSuccess = false;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled
    builder
      .addCase(addToCart.pending, (state, action) => {
        state.addingToCart = true;
        state.addingToCartSku = mapSkuAddingToCart({
          sku: action.meta.arg.sku,
          setId: action.meta.arg.setId,
          skuQtyList: action.meta.arg.skuQtyList,
        });
        state.addingToCartWithService = action.meta.arg.withService;
        state.error = undefined;
        state.addToCartSuccessSku = undefined;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      });
    // pending, fulfilled
    builder
      .addCase(addToCartMultiItems.pending, (state) => {
        state.addingMultiToCart = true;
        state.addingMultiToCartSuccess = false;
        state.error = undefined;
      })
      .addCase(addToCartMultiItems.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      });
    // pending, fulfilled, rejected
    builder
      .addCase(consentAddToCart.pending, (state, action) => {
        state.addingToCart = true;
        state.addingToCartSku = mapSkuAddingToCart({
          sku: action.meta.arg.sku,
          setId: action.meta.arg.setId,
          skuQtyList: action.meta.arg.skuQtyList,
        });
        state.addingToCartWithService = action.meta.arg.withService;
        state.addToCartSuccessSku = undefined;
      })
      .addCase(consentAddToCart.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      })
      .addCase(consentAddToCart.rejected, (state) => {
        state.addingToCart = false;
        state.addingToCartSku = undefined;
        state.addingToCartWithService = undefined;
        state.error = { text: 'Error.' };
        state.addToCartSuccessSku = undefined;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(reNewServiceAndAddToCart.pending, (state, action) => {
        state.addingToCart = true;
        state.addingToCartSku = action.meta.arg.sku;
        state.addToCartSuccessSku = undefined;
      })
      .addCase(reNewServiceAndAddToCart.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      })
      .addCase(reNewServiceAndAddToCart.rejected, (state, action) => {
        state.addingToCart = false;
        state.addingToCartSku = undefined;
        state.addingToCartWithService = undefined;
        state.error = { text: action.error.message ?? 'Error' };
        state.addToCartSuccessSku = undefined;
      });
    builder
      .addCase(getBundleFreeGiftQuery.pending, (state) => {
        state.addingToCart = true;
        state.addToCartSuccessSku = undefined;
      })
      .addCase(getBundleFreeGiftQuery.fulfilled, (state, action) => {
        state.bundleFreegiftResult = action.payload;
      })
      .addCase(getBundleFreeGiftQuery.rejected, (state, action) => {
        state.addingToCart = false;
        state.error = { text: action.error.message ?? 'Error' };
        state.addToCartSuccessSku = undefined;
      });
    builder
      .addCase(addToCartSelectBundle.pending, (state, action) => {
        state.addingToCart = true;
        state.addingToCartSku = action.meta.arg.sku;
        state.addToCartSuccessSku = undefined;
      })
      .addCase(addToCartSelectBundle.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      })
      .addCase(addToCartSelectBundle.rejected, (state, action) => {
        state.addingToCart = false;
        state.addingToCartSku = undefined;
        state.addingToCartWithService = undefined;
        state.error = { text: action.error.message ?? 'Error' };
        state.addToCartSuccessSku = undefined;
        state.bundleFreegiftResult = undefined;
      });
    builder
      .addCase(addToCartChristmas.pending, (state) => {
        state.addingChristmasToCart = true;
        state.addingChristmasToCartSuccess = false;
        state.error = undefined;
      })
      .addCase(addToCartChristmas.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      });
  },
});

export const {
  setError,
  clearError,
  clearAddToCartSuccessSku,
  refreshCartCount,
} = addToCartSlice.actions;

export const cartCountSelector = (store: RootState): number =>
  store.addToCart.cartCount ?? Number(Cookies.get(cookieKey.cartCount)) ?? 0;

export const addingToCartSkuSelector = (store: RootState): string | undefined =>
  store.addToCart.addingToCartSku;

export const addingToCartWithServiceSelector = (
  store: RootState,
): string | undefined => store.addToCart.addingToCartWithService;

export const addToCartSuccessSkuSelector = (
  store: RootState,
): ProductModel[] | undefined => store.addToCart.addToCartSuccessSku;

export const addingToCartSelector = (store: RootState): boolean =>
  store.addToCart.addingToCart;

export const addingMultiToCartSelector = (store: RootState): boolean =>
  store.addToCart.addingMultiToCart;

export const addingMultiToCartSuccessSelector = (store: RootState): boolean =>
  store.addToCart.addingMultiToCartSuccess;

export const errorSelector = (
  store: RootState,
): AddToCartErrorHandleInput | undefined => store.addToCart.error;

export const BundleFreegiftResultSelector = (
  store: RootState,
): BundleFreegiftModel | undefined | null =>
  store.addToCart.bundleFreegiftResult;

export const addingChristmasToCartSelector = (
  store: RootState,
): boolean | undefined => store.addToCart.addingChristmasToCart;

export const addingChristmasToCartSuccessSelector = (
  store: RootState,
): boolean | undefined => store.addToCart.addingChristmasToCart;

export default addToCartSlice.reducer;
