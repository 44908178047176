import { modelValue } from '@/utils/modelValue';

export interface UseVoucherPartnerResponse {
  dbCode?: boolean;
  dbMessage?: string;
  dbItems?: string[];
  isError?: boolean;
  type?: number;
  expiredDate?: string;
}

export class UseVoucherPartnerResponseConverter {
  public static fromJson(json: any): UseVoucherPartnerResponse {
    const model: UseVoucherPartnerResponse = {
      dbCode: json['dbcode'] == true ? true : false,
      dbMessage: json['dbmessage']?.toString().trim(),
      dbItems: json['dbitems'],
      isError: json['is_error'],
      type: json['type'],
      expiredDate: json['expiredDate'],
    };
    return modelValue(model);
  }
}
