import {
  GetVouFromCodeResponse,
  GetVouValidateResponse,
  DataOutput,
  Info,
} from '@/models/GetVouFromCode.model';
import * as useVoucherService from '@/services/client/useVoucherService';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { UseVoucherErrorHandleInput } from '@/utils/useVoucher';
import { CookieData } from '@/models/Authen.model';
import { VoucherSectionName } from '@/models/profile/MyVoucher.model';

interface UseVoucherState {
  useVoucher: boolean;
  secname?: string;
  voucherk?: string;
  result?: GetVouFromCodeResponse | null;
  isLoading: boolean;
  error?: UseVoucherErrorHandleInput;
  isSuccess: boolean;
  resultCheckOnTop?: DataOutput | [] | undefined;
  isLoadingCheckOnTop: boolean;
  isSuccessCheckOnTop: boolean;
  isSelectVoucherPartner: boolean;
  isLoadingUsePartner: boolean;
  expiredDatePartner?: string;
}
const initialState: UseVoucherState = {
  isLoading: false,
  useVoucher: false,
  isSuccess: false,
  isLoadingCheckOnTop: false,
  isSuccessCheckOnTop: false,
  isSelectVoucherPartner: false,
  isLoadingUsePartner: false,
};

export const UseVoucher = createAsyncThunk(
  'useVoucher/useVoucher',
  async ({
    lang,
    code,
    secname,
  }: {
    lang?: string;
    code?: string;
    secname?: VoucherSectionName;
  }) => {
    const response = await useVoucherService.useVoucher({
      lang,
      code,
      secname,
    });
    return { response, secname };
  },
);
export const CheckOnTop = createAsyncThunk(
  'useVoucher/checkOnTop',
  async ({ lang, info }: { lang?: string; info: Info }) => {
    const response = await useVoucherService.checkOnTopXpro({
      lang,
      info,
    });
    return { response };
  },
);
export const UseVoucherPartner = createAsyncThunk(
  'useVoucher/useVoucherPartner',
  async ({ lang, code }: { lang?: string; code?: string }) => {
    const response = await useVoucherService.useVoucherPartner({
      lang,
      code,
    });
    return { response };
  },
);
export const RedeemVoucherPartner = createAsyncThunk(
  'useVoucher/redeemVoucherPartner',
  async ({ lang, code }: { lang?: string; code?: string }) => {
    const response = await useVoucherService.redeemVoucherPartner({
      lang,
      code,
    });
    return { response };
  },
);
const handleFulfilled = (
  state: UseVoucherState,
  action: PayloadAction<
    GetVouValidateResponse,
    string,
    {
      requestId: string;
      requestStatus: 'fulfilled';
    },
    never
  >,
) => {
  state.useVoucher = false;
  state.isLoading = false;
  if (action.payload.response?.dbCode === true) {
    state.error = undefined;
    state.isSuccess = true;
    const ref = action.payload.response.cookies?.find(
      (e: CookieData) => e.name === cookieKey.ref,
    )?.val;
    if (ref) {
      ServerCookies.set(cookieKey.ref, ref.toString());
    }
  } else {
    state.error = {
      type: action.payload.response?.type,
      secname: action.payload.secname,
      fullaVaildate: action.payload.response?.fullaVailDate,
      infoRes: action.payload.response?.info,
      dbMessage: action.payload.response?.dbMessage,
    };
  }
};
export const useMyVoucher = createSlice({
  name: 'useMyVoucher',
  initialState: initialState,
  reducers: {
    setError: (state, action: { payload: UseVoucherErrorHandleInput }) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
    clearSuccess: (state) => {
      state.isSuccess = false;
    },
    clearResultCheckOnTop: (state) => {
      state.resultCheckOnTop = [];
    },
    clearSuccessCheckOnTop: (state) => {
      state.isSuccessCheckOnTop = false;
    },
    setSelectVoucherPartner: (state) => {
      state.isSelectVoucherPartner = true;
    },
    clearSelectVoucherPartner: (state) => {
      state.isSelectVoucherPartner = false;
    },
    clearExpiredDatePartner: (state) => {
      state.expiredDatePartner = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(UseVoucher.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(UseVoucher.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      })
      .addCase(UseVoucher.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = { text: action.error.message };
      });
    builder
      .addCase(CheckOnTop.pending, (state) => {
        state.isLoadingCheckOnTop = true;
        state.resultCheckOnTop = [];
      })
      .addCase(CheckOnTop.fulfilled, (state, action) => {
        state.isLoadingCheckOnTop = true;
        state.resultCheckOnTop = action.payload.response
          ? action.payload.response
          : [];
        state.isSuccessCheckOnTop = true;
      })
      .addCase(CheckOnTop.rejected, (state) => {
        state.isLoadingCheckOnTop = false;
        state.resultCheckOnTop = [];
        state.isSuccessCheckOnTop = false;
      });
    builder
      .addCase(UseVoucherPartner.pending, (state) => {
        state.isLoadingUsePartner = true;
        state.expiredDatePartner = undefined;
      })
      .addCase(UseVoucherPartner.fulfilled, (state, action) => {
        state.isLoadingUsePartner = false;
        if (action.payload.response.expiredDate) {
          state.expiredDatePartner = action.payload.response.expiredDate;
        } else {
          handleFulfilled(state, action);
        }
      })
      .addCase(UseVoucherPartner.rejected, (state, action) => {
        state.isLoadingUsePartner = false;
        state.expiredDatePartner = undefined;
        state.error = { text: action.error.message };
      });
    builder
      .addCase(RedeemVoucherPartner.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(RedeemVoucherPartner.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      })
      .addCase(RedeemVoucherPartner.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = { text: action.error.message };
      });
  },
});
export const {
  clearError,
  clearResult,
  clearSuccess,
  clearResultCheckOnTop,
  clearSuccessCheckOnTop,
  setSelectVoucherPartner,
  clearSelectVoucherPartner,
  clearExpiredDatePartner,
} = useMyVoucher.actions;

export const UseMyVoucherResultSelector = (
  store: RootState,
): GetVouFromCodeResponse | undefined | null => store.useMyVoucher.result;

export const errorSelector = (
  store: RootState,
): UseVoucherErrorHandleInput | undefined => store.useMyVoucher.error;

export const successSelector = (store: RootState): boolean =>
  store.useMyVoucher.isSuccess;

export const CheckOnTopResultSelector = (
  store: RootState,
): DataOutput | undefined | [] => store.useMyVoucher.resultCheckOnTop;

export const isSuccessCheckOnTopSelector = (store: RootState): boolean =>
  store.useMyVoucher.isSuccessCheckOnTop;

export const isLoadingUseVoucherSelector = (store: RootState): boolean =>
  store.useMyVoucher.isLoading;

export const isSelectVoucherPartnerSelector = (store: RootState): boolean =>
  store.useMyVoucher.isSelectVoucherPartner;

export const isLoadingUsePartnerSelector = (store: RootState): boolean =>
  store.useMyVoucher.isLoadingUsePartner;

export const expiredDatePartnerSelector = (
  store: RootState,
): string | undefined => store.useMyVoucher.expiredDatePartner;

export default useMyVoucher.reducer;
