import React from 'react';

interface Props {
  value?: boolean;
}

export default function CheckBoxNotify({ value }: Props) {
  return (
    <div
      className={`relative rounded-sm border border-primary ${
        value ? 'bg-primary' : 'bg-white'
      } flex`}
      style={{
        width: '0.75rem',
        height: '0.75rem',
      }}
    >
      {value && (
        <i
          className="bi bi-check-lg self-center mt-0.5 text-white"
          style={{
            marginLeft: -1,
            lineHeight: '0.75rem',
            fontSize: '0.75rem',
          }}
        />
      )}
    </div>
  );
}
