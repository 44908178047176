import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ContactUsRequest } from '@/models/ContactUs.model';
import { requestContact } from '@/services/client/contactusService';

interface ContactState {
  isLoading: boolean;
  isSuccess?: boolean;
  isError?: boolean;
}

const initialState: ContactState = {
  isLoading: false,
};

export const submitContact = createAsyncThunk(
  'contact/submit',
  async ({ data }: { data: ContactUsRequest }) => {
    const sendData = {
      ...data,
    };
    const response = await requestContact({ sendData });
    return response;
  },
);

const contactSlice = createSlice({
  name: 'contact',
  initialState: initialState,
  reducers: {
    clearSuccess: (state) => {
      state.isSuccess = undefined;
    },
    clearError: (state) => {
      state.isError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitContact.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = undefined;
        state.isError = undefined;
      })
      .addCase(submitContact.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(submitContact.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { clearSuccess } = contactSlice.actions;

export const isSuccessContactSelector = (
  store: RootState,
): boolean | undefined => store.contact.isSuccess;

export const isLoadingContactSelector = (store: RootState): boolean =>
  store.contact.isLoading;

export const isErrorContactSelector = (store: RootState): boolean | undefined =>
  store.contact.isError;

export default contactSlice.reducer;
