import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import Cookies from 'js-cookie';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import * as defaultValue from '@/constants/defaultValue.constant';

/////CLIENT/////
function createClientAxiosInstance(): AxiosInstance {
  // let retried = false;
  const instance: AxiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL_API,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    // config = { ...config, timeout: 30000 };
    return config;
  });
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response?.data.status === 401) {
        response.status = 401;
      }
      // retried = false;
      return response;
    },
    async (error: AxiosError) => {
      // if (
      //   !defaultValue.notRetryStatusCode.includes(
      //     error.response?.status ?? 504,
      //   ) &&
      //   error.message !== 'Unauthorized'
      // ) {
      //   if (!retried && error.config) {
      //     retried = true;
      //     return instance(error.config);
      //   }
      // }

      // retried = false;
      return Promise.reject(error);
    },
  );

  return instance;
}

export const httpClientAPI = createClientAxiosInstance();
export const httpServerAPI = createServerAxiosInstance();

export const getDataFromAPIWithNoServerCookie = async ({
  path,
  headers,
  checkInfo,
  timeout,
  // eslint-disable-next-line no-unused-vars
  lang, // อนาคตใช้
}: {
  path: string;
  headers?: any;
  checkInfo?: boolean;
  timeout?: number;
  lang?: string;
}): Promise<{ data: any; status: number | null }> => {
  try {
    const response = await httpServerAPI.get(`${path}`, {
      timeout: timeout ?? 30000,
      headers: {
        stcode: Cookies.get(cookieKey.stcode),
        ...headers,
      },
    });
    let { data, status } = response;
    if (checkInfo == true && !data.info) {
      status = 401;
    }
    return { data, status };
  } catch (error: any) {
    return {
      data: error.response?.data,
      status: error.response?.status ?? null,
    };
  }
};

export const getDataFromAPI = async ({
  path,
  headers,
  checkInfo,
  timeout,
  // eslint-disable-next-line no-unused-vars
  lang, // อนาคตใช้
}: {
  path: string;
  headers?: any;
  checkInfo?: boolean;
  timeout?: number;
  lang?: string;
}): Promise<{ data: any; status: number | null }> => {
  try {
    const getServerCookies = await ServerCookies.list([
      cookieKey.twdToken,
      cookieKey.twdRefreshToken,
      cookieKey.the1Token,
      cookieKey.ref,
    ]);
    const twdToken = getServerCookies?.find(
      (f) => f.name === cookieKey.twdToken,
    )?.value;
    const twdRefreshToken = getServerCookies?.find(
      (f) => f.name === cookieKey.twdRefreshToken,
    )?.value;
    const the1Token = getServerCookies?.find(
      (f) => f.name === cookieKey.the1Token,
    )?.value;
    const ref = getServerCookies?.find((f) => f.name === cookieKey.ref)?.value;
    const response = await httpClientAPI.get(`${path}`, {
      timeout: timeout ?? 30000,
      headers: {
        ...headers,
        authorization: twdToken ? `Bearer ${twdToken}` : undefined,
        refreshtoken: twdRefreshToken,
        accesstoken: the1Token,
        stcode:
          headers?.stcode ??
          Cookies.get(cookieKey.stcode) ??
          defaultValue.defaultStoreCode,
        ref: headers?.ref ?? ref,
      },
    });
    let { data, status } = response;
    if (checkInfo == true && !data.info) {
      status = 401;
    }
    return { data, status };
  } catch (error: any) {
    return {
      data: error.response?.data,
      status: error.response?.status ?? null,
    };
  }
};

export const postDataFromAPI = async ({
  path,
  lang,
  body,
  form,
  headers,
  checkInfo,
  timeout,
}: {
  path: string;
  lang?: string;
  body?: any;
  form?: any;
  headers?: any;
  checkInfo?: boolean;
  timeout?: number;
}): Promise<{ data: any; status: number | null }> => {
  try {
    let langSend = lang;
    try {
      langSend = lang || Cookies.get(cookieKey.lang) || 'th';
    } catch (_) {}
    const getServerCookies = await ServerCookies.list([
      cookieKey.twdToken,
      cookieKey.twdRefreshToken,
      cookieKey.the1Token,
      cookieKey.ref,
    ]);
    const twdToken = getServerCookies?.find(
      (f) => f.name === cookieKey.twdToken,
    )?.value;
    const twdRefreshToken = getServerCookies?.find(
      (f) => f.name === cookieKey.twdRefreshToken,
    )?.value;
    const the1Token = getServerCookies?.find(
      (f) => f.name === cookieKey.the1Token,
    )?.value;
    const ref = getServerCookies?.find((f) => f.name === cookieKey.ref)?.value;
    const response = await httpClientAPI.post(
      `${path}`,
      form ?? {
        lang: langSend,
        ...body,
      },
      {
        timeout: timeout ?? 30000,
        headers: {
          'Content-Type': form ? 'multipart/form-data' : undefined,
          authorization: twdToken ? `Bearer ${twdToken}` : undefined,
          refreshtoken: twdRefreshToken,
          accesstoken: the1Token,
          stcode:
            headers?.stcode ??
            Cookies.get(cookieKey.stcode) ??
            defaultValue.defaultStoreCode,
          ref: headers?.ref ?? ref,
          ...headers,
        },
      },
    );
    let { data, status } = response;
    if (checkInfo == true && !data.info) {
      status = 401;
    }
    return { data, status };
  } catch (error: any) {
    return {
      data: error.response?.data,
      status: error.response?.status ?? null,
    };
  }
};

/////SERVER/////

function createServerAxiosInstance(): AxiosInstance {
  // let retried = false;
  const instance: AxiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL_API,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response?.data.status === 401) {
        response.status = 401;
      }
      // retried = false;
      return response;
    },
    async (error: AxiosError) => {
      // if (
      //   !defaultValue.notRetryStatusCode.includes(
      //     error.response?.status ?? 504,
      //   ) &&
      //   error.message !== 'Unauthorized'
      // ) {
      //   if (!retried && error.config) {
      //     retried = true;
      //     return instance(error.config);
      //   }
      // }

      // retried = false;
      return Promise.reject(error);
    },
  );

  return instance;
}

export const getServerDataFromAPI = async ({
  path,
  headers,
  timeout,
}: {
  path: string;
  headers?: any;
  timeout?: number;
}): Promise<{ data: any; status: number | null }> => {
  try {
    const response = await httpServerAPI.get(`${path}`, {
      timeout: timeout ?? 30000,
      headers: { ...headers },
    });
    let { data, status } = response;
    return { data, status };
  } catch (error: any) {
    return {
      data: error.response?.data,
      status: error.response?.status ?? null,
    };
  }
};

export const postServerDataFromAPI = async ({
  path,
  lang,
  body,
  headers,
  timeout,
}: {
  path: string;
  lang?: string;
  body?: any;
  headers?: any;
  timeout?: number;
}): Promise<{ data: any; status: number | null }> => {
  try {
    let langSend = lang;
    try {
      langSend = lang || Cookies.get(cookieKey.lang) || 'th';
    } catch (_) {}
    const response = await httpServerAPI.post(
      `${path}`,
      {
        lang: langSend,
        ...body,
      },
      {
        timeout: timeout ?? 30000,
        headers: {
          ...headers,
        },
      },
    );
    let { data, status } = response;
    return { data, status };
  } catch (error: any) {
    return {
      data: error.response?.data,
      status: error.response?.status ?? null,
    };
  }
};

///CMS SERVER////
export const httpClientServerCMS = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL_SERVER_CMS,
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
    'Content-Type': 'application/json',
    'Cache-Control': 'public, max-age=28800',
  },
});
export const getDataFromServerCMS = async ({
  path,
  lang,
  param,
}: {
  path: string;
  lang?: string;
  param?: any;
}): Promise<{ data: any; status: number | null }> => {
  try {
    let langSend = lang;
    try {
      langSend = lang || Cookies.get(cookieKey.lang) || 'th';
    } catch (_) {}
    const response = await httpClientServerCMS.get(`${path}`, {
      headers: {
        authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
      },
      params: {
        lang: langSend,
        ...param,
      },
    });
    const { data, status } = response;
    return {
      data,
      status,
    };
  } catch (error: any) {
    return { data: null, status: error.response?.status ?? null };
  }
};

////CMS/////
export const httpClientCMS = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL_CMS,
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
    'Content-Type': 'application/json',
  },
});

export const getDataFromCMS = async ({
  path,
  lang,
  param,
}: {
  path: string;
  lang?: string;
  param?: any;
}): Promise<{ data: any; status: number | null }> => {
  try {
    let langSend = lang;
    try {
      langSend = lang || Cookies.get(cookieKey.lang) || 'th';
    } catch (_) {}
    const response = await httpClientCMS.get(`${path}`, {
      headers: {
        authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
      },
      params: {
        lang: langSend,
        ...param,
      },
    });
    const { data, status } = response;
    return { data, status };
  } catch (error: any) {
    return { data: null, status: error.response?.status ?? null };
  }
};

////NEWCMS/////
export const httpClientNewCMS = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL_NEW_CMS,
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
    'Content-Type': 'application/json',
  },
});

export const getDataFromNewCMS = async ({
  path,
  lang,
  param,
}: {
  path: string;
  lang?: string;
  param?: any;
}): Promise<{ data: any; status: number | null }> => {
  try {
    let langSend = lang;
    try {
      langSend = lang || Cookies.get(cookieKey.lang) || 'th';
    } catch (_) {}
    const response = await httpClientNewCMS.get(`${path}`, {
      headers: {
        authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
      },
      params: {
        lang: langSend,
        ...param,
      },
    });
    const { data, status } = response;
    return { data, status };
  } catch (error: any) {
    return { data: null, status: error.response?.status ?? null };
  }
};

export const getData = async ({
  path,
  headers,
  timeout,
}: {
  path: string;
  headers?: any;
  timeout?: number;
}): Promise<{ data: any; status: number | null }> => {
  try {
    const response = await axios.get(`${path}`, {
      timeout: timeout ?? 30000,
      headers: {
        ...headers,
      },
    });
    let { data, status } = response;
    return { data, status };
  } catch (error: any) {
    return {
      data: error.response?.data,
      status: error.response?.status ?? null,
    };
  }
};

export const postData = async ({
  path,
  body,
  headers,
  timeout,
}: {
  path: string;
  body?: any;
  headers?: any;
  timeout?: number;
}): Promise<{ data: any; status: number | null }> => {
  try {
    const response = await axios.post(
      `${path}`,
      {
        ...body,
      },
      {
        timeout: timeout ?? 30000,
        headers: {
          ...headers,
        },
      },
    );
    let { data, status } = response;
    return { data, status };
  } catch (error: any) {
    return {
      data: error.response?.data,
      status: error.response?.status ?? null,
    };
  }
};
