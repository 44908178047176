import {
  addToCartSuccessSkuSelector,
  clearError,
  consentAddToCart,
  addToCart,
  errorSelector,
  clearAddToCartSuccessSku,
  refreshCartCount,
  reNewServiceAndAddToCart,
  getBundleFreeGiftQuery,
  addToCartMultiItems,
  addToCartChristmas,
} from '@/store/slices/addToCartSlice';
import { useAppDispatch } from '@/store/store';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  AddToCartErrorHandleOutput,
  errorMessageHandler,
  AddToCartHandleType,
} from '@/utils/addToCart';
import { useTranslation } from 'next-i18next';
import { openStockModal, saveStoreChanged } from '@/store/slices/storeSlice';
import { useRouter } from 'next/router';
import * as ServerCookies from '@/services/client/cookieService';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import { customerInfoSelector } from '@/store/slices/authenSlice';
import {
  chatAndShopUrlThaiwatsadu,
  currencyCode,
} from '@/constants/defaultValue.constant';
import { showMessageSuccessAutoHide } from '@/utils/showDialog';
import { AddToCartServiceType } from '@/services/client/addToCartService';
import { EditCartType, editCartService } from '@/services/client/myCartService';
import { mergeStore } from '@/services/client/mergeStoreService';
import * as showDialog from '@/utils/showDialog';

interface Props {
  children: ReactNode;
}

export default function AddToCartLayout({ children }: Props): ReactElement {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const error = useSelector(errorSelector);
  const addToCartSuccessSku = useSelector(addToCartSuccessSkuSelector);
  const customerInfo = useSelector(customerInfoSelector);
  const customerId = customerInfo?.custId;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (error) {
      errorMessageHandler({ ...error, lang: i18n.language }).then(
        async (handle: AddToCartErrorHandleOutput) => {
          if (handle.handleType === AddToCartHandleType.error) {
            dispatch(clearError());
          } else if (handle.handleType === AddToCartHandleType.consent) {
            dispatch(consentAddToCart({ sku: error.sku, qty: handle.qty }));
          } else if (handle.handleType === AddToCartHandleType.changeStore) {
            dispatch(clearError());
            dispatch(
              openStockModal({
                sku: error.sku,
                type: 1,
                qty: error.sku ? error.qty ?? 1 : undefined,
                withService: error.withService,
              }),
            );
          } else if (handle.handleType === AddToCartHandleType.refresh) {
            dispatch(clearError());
          } else if (
            handle.handleType === AddToCartHandleType.clearCartAndAddToCart
          ) {
            Cookies.remove(cookieKey.cartCount);
            await ServerCookies.remove(cookieKey.ref);
            dispatch(refreshCartCount());
            // console.log(error.sku);
            // console.log(error.skuQtyList);
            if (
              error.serviceType === AddToCartServiceType.addToCartMultiItems &&
              error.addToCartMultiType !== undefined &&
              (error.skuQtyList ?? []).length > 0
            ) {
              dispatch(
                addToCartMultiItems({
                  request: error.skuQtyList ?? [],
                  type: error.addToCartMultiType,
                }),
              );
            } else {
              dispatch(consentAddToCart({ sku: error.sku }));
            }
            dispatch(clearError());
          } else if (handle.handleType === AddToCartHandleType.mergeStore) {
            if (handle.storeCode) {
              dispatch(saveStoreChanged({ stcode: handle.storeCode }));
            }
            const mergeStoreResponse = await mergeStore();
            dispatch(clearError());
            if (mergeStoreResponse === true) {
              if (error.sku) {
                dispatch(addToCart({ sku: error.sku, qty: error.qty ?? 1 }));
              }
            } else {
              showDialog.showErrorWithOkButton({
                text: `${t('thisbranchcannotbeselect')}`,
              });
            }
          } else if (
            handle.handleType === AddToCartHandleType.autoChangeStore
          ) {
            try {
              if (handle.storeCode) {
                dispatch(saveStoreChanged({ stcode: handle.storeCode }));
                dispatch(clearError());
                if (error.sku) {
                  dispatch(addToCart({ sku: error.sku, qty: error.qty ?? 1 }));
                }
              }
            } catch (_) {}
          } else if (
            handle.handleType ===
            AddToCartHandleType.clearCartAndGotoOrderhistory
          ) {
            Cookies.remove(cookieKey.cartCount);
            await ServerCookies.remove(cookieKey.ref);
            dispatch(refreshCartCount());
            router.push(`/${router.locale}/profile/orderhistory`);
            dispatch(clearError());
          } else if (handle.handleType === AddToCartHandleType.clearCart) {
            Cookies.remove(cookieKey.cartCount);
            await ServerCookies.remove(cookieKey.ref);
            dispatch(refreshCartCount());
            dispatch(clearError());
          } else if (
            handle.handleType === AddToCartHandleType.reNewServiceAndAddToCart
          ) {
            dispatch(reNewServiceAndAddToCart({ sku: error.sku }));
            dispatch(clearError());
          } else if (handle.handleType === AddToCartHandleType.selectBundle) {
            dispatch(
              getBundleFreeGiftQuery({
                lang: error.lang,
                sku: error.sku,
                quantity: error.qty,
              }),
            );
          } else if (
            handle.handleType ===
            AddToCartHandleType.clearSurveyItemsAndAddToCart
          ) {
            await editCartService({
              product: {
                seq: 1,
                slugname: '',
                brand: '',
                sku: '',
                name: '',
                price: '',
                isLineButton: false,
              },
              qty: 0,
              type: EditCartType.survey,
            });
            if (
              error.serviceType === AddToCartServiceType.addToCartMultiItems &&
              error.addToCartMultiType !== undefined &&
              (error.skuQtyList ?? []).length > 0
            ) {
              dispatch(
                addToCartMultiItems({
                  request: error.skuQtyList ?? [],
                  type: error.addToCartMultiType,
                }),
              );
            } else {
              dispatch(consentAddToCart({ sku: error.sku }));
            }
            dispatch(clearError());
          } else if (handle.handleType === AddToCartHandleType.goToCart) {
            router.push(`/${router.locale}/cart`);
            dispatch(clearError());
          } else if (
            handle.handleType === AddToCartHandleType.christmasConsent
          ) {
            dispatch(
              addToCartChristmas({
                request: { sku: `${error.sku}`, qty: error.qty, isOk: true },
              }),
            );
          } else if (handle.handleType === AddToCartHandleType.chatandshop) {
            window.open(`${chatAndShopUrlThaiwatsadu}`);
          }
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, dispatch, i18n.language]);

  useEffect(() => {
    if (addToCartSuccessSku && addToCartSuccessSku.length > 0) {
      try {
        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({
            event: 'addToCart',
            userId: customerId,
            ecommerce: {
              currencyCode: currencyCode, // Local currency is optional.
              value: addToCartSuccessSku[0].price
                ? addToCartSuccessSku[0].price
                    ?.replaceAll(',', '')
                    ?.replaceAll('.00', '')
                : '0',
              products: addToCartSuccessSku.map((e) => {
                return {
                  name: e.name,
                  id: e.sku,
                  price: e.price
                    ? e.price?.replaceAll(',', '')?.replaceAll('.00', '')
                    : '0',
                  brand: e.brand ?? '',
                  category: e.class ?? '',
                  quantity: e.qty,
                };
              }),
            },
          });
        }
      } catch (_) {}
      try {
        if ((window as any).appier) {
          for (let i = 0; i < addToCartSuccessSku.length; i++) {
            const productSend = addToCartSuccessSku[i];
            (window as any).appier('event', 'product_added_to_cart', {
              product_id: productSend.sku,
              category_name: productSend.catName ?? productSend.class,
              product_name: productSend.name,
              product_quantity: productSend.qty,
              product_brand: productSend.brand,
              product_image: productSend.pimImage
                ? `https://${process.env.NEXT_PUBLIC_BASE_URL_PIM_DOMAIN}/TWDPIM/web/Thumbnail/${productSend.pimImage}`
                : undefined,
              product_price: Number(
                productSend.price
                  ? productSend.price
                      ?.replaceAll(',', '')
                      ?.replaceAll('.00', '')
                  : '0',
              ),
              product_link: productSend?.slugname
                ? `${process.env.NEXT_PUBLIC_BASE_URL_WEB}/product/${productSend?.slugname}`
                : undefined,
              env: process.env.NEXT_PUBLIC_NODE_ENV,
            });
          }
        }
      } catch (_) {}
      try {
        const body = document.querySelector('#addtocart-main') as HTMLElement;
        const mainCart = document.querySelector('.main-cart') as HTMLElement;
        const mainCartHasNoShake = mainCart?.classList.contains('noshake');
        mainCart.classList.remove('shake');
        const modalImage = document.querySelector(
          `#product-image-modal-${addToCartSuccessSku[0].sku}`,
        ) as HTMLElement;
        const isVisibleModalImage =
          modalImage &&
          modalImage.offsetWidth > 0 &&
          modalImage.offsetHeight > 0;
        const modalImageMobile = document.querySelector(
          `#product-image-modal-mobile-${addToCartSuccessSku[0].sku}`,
        ) as HTMLElement;
        const isVisibleModalImageMobile =
          modalImageMobile &&
          modalImageMobile.offsetWidth > 0 &&
          modalImageMobile.offsetHeight > 0;
        const originalImage = isVisibleModalImage
          ? modalImage
          : isVisibleModalImageMobile
          ? modalImageMobile
          : (document.querySelector(
              `#product-image-${addToCartSuccessSku[0].sku}`,
            ) as HTMLElement);
        // Clone the originalImage to clonedImage
        const clonedImage = originalImage?.firstElementChild?.cloneNode(
          true,
        ) as HTMLElement;
        if (mainCart && body && clonedImage) {
          clonedImage?.classList.add('cloned-product-image');
          clonedImage.id = `cloned-product-image-${addToCartSuccessSku[0].sku}`;
          // Append the clonedImage(absolute) to body
          body.appendChild(clonedImage);
          // Calculate initial position of originalImage by parentElement in case because originalImage is absolute in relative parentElement for badge
          const initialX =
            (originalImage.getBoundingClientRect()?.left ?? 0) + window.scrollX;
          const initialY =
            (originalImage.getBoundingClientRect()?.top ?? 0) + window.scrollY;
          // Set the initial position of clonedImage
          clonedImage.style.left = `${initialX}px`;
          clonedImage.style.top = `${initialY}px`;
          // Calculate the position difference to main-cart
          const xOffset =
            mainCart.offsetLeft -
            initialX -
            clonedImage.offsetWidth / 2 +
            mainCart.offsetWidth / 2;
          const yOffset =
            mainCart.offsetTop -
            initialY -
            clonedImage.offsetHeight / 2 +
            mainCart.offsetHeight / 2 +
            // Add scrollY to yOffset because the position of main-cart is relative to window
            window.scrollY;
          // Move the clonedImage to main-cart
          clonedImage.style.transition = 'transform 0.8s ease';
          clonedImage.style.transform = `translate(${xOffset}px, ${yOffset}px) scale(0)`;
          // Remove the clonedImage after transition
          clonedImage.addEventListener('transitionend', () => {
            body.removeChild(clonedImage);
            if (mainCartHasNoShake !== true) {
              mainCart.classList.add('shake');
            }
            dispatch(clearAddToCartSuccessSku());
          });
        } else {
          showMessageSuccessAutoHide({
            text: `${t('productaddedtocart')}`,
          });
        }
      } catch (_) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToCartSuccessSku, dispatch]);

  return <div id="addtocart-main">{children}</div>;
}
