import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import LangHeader from '../../Layout/Header/LangHeader';
import CatMenuSideBar from '../CatMenuSideBar';
import { useSelector } from 'react-redux';
import { customerInfoSelector } from '@/store/slices/authenSlice';
import AppLink from '@/components/Layout/handler/AppLink';
import ProfileLayoutMenu from '@/components/profile/ProfileLayout/ProfileLayoutMenu';
import { isNeedUpdateEmailSelector } from '@/store/slices/profile/editInfoSlice';

export default function MenuHeaderSideBar({
  onCloseDrawer,
}: {
  onCloseDrawer: () => void;
}) {
  const { t } = useTranslation();
  const [isCatMenuOpen, setIsCatMenuOpen] = React.useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = React.useState(false);

  const SetOpenCatMenu = () => {
    setIsCatMenuOpen(!isCatMenuOpen);
  };

  const customerInfo = useSelector(customerInfoSelector);
  const [customerName, setCustomerName] = useState<string | null | undefined>(
    undefined,
  );
  const isNeedUpdateEmail = useSelector(isNeedUpdateEmailSelector);

  useEffect(() => {
    setCustomerName(customerInfo?.cname);
  }, [customerInfo?.cname]);

  return (
    <>
      <div className="bg-primary px-3 py-3 flex justify-between">
        <Image
          src="/images/logo/header/twdlogo_mobile.png"
          alt="logo_mobile"
          className="inline px-0 sm:px-2"
          width={53}
          height={48}
          style={{ maxHeight: 48, width: 'auto' }}
        />
        <div onClick={onCloseDrawer}>
          <i className="bi bi-x-square text-white" />
        </div>
      </div>
      {!isProfileMenuOpen && customerName ? (
        <div className="flex items-center px-2 pt-2 font-bold">
          <div onClick={() => setIsProfileMenuOpen(true)}>
            <span>{t('account')}</span>
            <span className="ms-1">{customerName}</span>
          </div>
          <div className="ms-2">
            {isNeedUpdateEmail && (
              <Image
                src="/images/svg/header/profile_warning.svg"
                width={16}
                height={16}
                alt="profile_warning"
              />
            )}
          </div>
          <div className="ms-auto pt-0.5">
            <i className="bi bi-chevron-right text-sm" />
          </div>
        </div>
      ) : !customerName ? (
        <div className="flex px-2 py-2 font-bold">
          <AppLink className="hover:text-info" href="/signin">
            <span className="cursor-pointer">{t('signin')}</span>
          </AppLink>

          <span className="mx-2">|</span>
          <AppLink className="hover:text-info" href="/register">
            <span className="cursor-pointer">{t('register')}</span>
          </AppLink>
        </div>
      ) : (
        <></>
      )}
      <div className="overflow-x-hidden overflow-y-auto">
        {isProfileMenuOpen && customerName ? (
          <>
            <div
              className="flex px-2 pt-1 self-center cursor-pointer text-sm mt-2"
              onClick={() => {
                setIsProfileMenuOpen(false);
              }}
            >
              <div className="my-auto">
                <i
                  className="bi bi-chevron-left text-sm me-2"
                  style={{ verticalAlign: 'middle' }}
                />
                {t('backtomainmenu')}
              </div>
            </div>
            <ProfileLayoutMenu onlyList={true} />
          </>
        ) : isCatMenuOpen ? (
          <CatMenuSideBar onCloseCatMenu={SetOpenCatMenu} />
        ) : (
          <>
            <div
              className="flex px-2 py-1 self-center cursor-pointer"
              onClick={SetOpenCatMenu}
            >
              <div className="self-center text-sm">{t('allcategories')}</div>
              <div className="ml-auto">
                <i className="bi bi-chevron-right text-sm my-auto" />
              </div>
            </div>
            <AppLink href="/brand">
              <div className="flex px-2 py-1 self-center cursor-pointer">
                <div className="self-center text-sm">{t('shopbybrands')}</div>
              </div>
            </AppLink>
            <AppLink href="/store">
              <div className="flex px-2 py-1 self-center cursor-pointer">
                <div className="self-center text-sm">{t('storelocations')}</div>
              </div>
            </AppLink>
            <AppLink href="/brochures">
              <div className="flex px-2 py-1 self-center cursor-pointer">
                <div className="self-center text-sm">{t('catalogs')}</div>
              </div>
            </AppLink>
            <AppLink href="/category/บริการติดตั้ง-vFIX-ช่างมือ-1-96">
              <div className="flex px-2 py-1 self-center cursor-pointer">
                <div className="self-center text-sm">
                  {t('vfixhomeservices')}
                </div>
              </div>
            </AppLink>
            <AppLink href="/page/credit-card-promotions">
              <div className="flex px-2 py-1 self-center cursor-pointer">
                <div className="self-center text-sm">
                  {t('creditcardpromotions')}
                </div>
              </div>
            </AppLink>
            <AppLink href="/page/promotion-monthly">
              <div className="flex px-2 py-1 self-center cursor-pointer">
                <div className="self-center text-sm">{t('allpromotions')}</div>
              </div>
            </AppLink>
            <div className="flex px-2 py-1 self-center cursor-pointer">
              <div className="self-center text-sm">{t('เลือกภาษา')}</div>
              <div className="ml-auto">
                <LangHeader />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
