import { modelValue } from '@/utils/modelValue';
import { CookieData } from './Authen.model';

export interface SendRegisterRequestModel {
  requestID?: string;
  firstName: NameData;
  lastName: NameData;
  mobile?: string;
  mobileCountry?: string;
  email?: string;
  pin?: string;
  documentCountryCode?: string;
  nationalityID?: string;
  dateOfBirth?: string;
  tc?: string;
  consentFlag?: string;
  consentVersion?: string;
  transactionChannel?: string;
  partnerCode?: string;
  branchCode?: string;
  staffID?: string;
  languagePreference?: string;
  firstnamelang: string;
  lastnamelang: string;
  version?: string;
  ischkmarket?: boolean;
  member: number;
  mode: string;
}

export interface NameData {
  en?: string;
  th?: string;
}
export interface SendRegisterResponseModel {
  isError: boolean;
  cookies?: CookieData[];
  isbanned: boolean;
  couponPopup?: CouponPopup[];
  errorType?: number;
  errorMessage?: string;
}

export class SendRegisterResponseModelConverter {
  public static fromJson(json: any): SendRegisterResponseModel {
    const model: SendRegisterResponseModel = {
      isError: json['is_error'] == true ? true : false,
      cookies: json['cookies'],
      isbanned: json['isbanned'] == true ? true : false,
      couponPopup: json['couponPopup']
        ? json['couponPopup'].map((e: CouponPopup) => {
            try {
              e = CouponPopupConverter.fromJson(e);
            } catch (_) {}
            return e;
          })
        : undefined,
      errorType: json['type'],
      errorMessage: json['emsg'],
    };
    return modelValue(model);
  }
}

export interface CouponPopup {
  image?: string | null;
  amount?: string;
  unit?: string;
  endsDate?: string;
}

export class CouponPopupConverter {
  public static fromJson(json: any): CouponPopup {
    const model: CouponPopup = {
      image: json['image'],
      amount: json['amount'],
      unit: json['unit'],
      endsDate: json['endsDate'],
    };
    return modelValue(model);
  }
}
