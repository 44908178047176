import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import thText from '@/locales/th/usevoucher.json';
import enText from '@/locales/en/usevoucher.json';
import { Info } from '@/models/GetVouFromCode.model';
export interface UseVoucherErrorHandleInput {
  type?: number;
  lang?: string;
  text?: string;
  secname?: string;
  fullaVaildate?: string;
  infoRes?: Info;
  dbMessage?: string;
}

export interface UseVoucherErrorHandleOutput {
  errMsg?: string;
  errSubtitle: string;
  infoRes?: Info;
}
export const errorMessageHandler = async ({
  type,
  lang,
  secname,
  fullaVaildate,
  infoRes,
  dbMessage,
}: UseVoucherErrorHandleInput): Promise<UseVoucherErrorHandleOutput> => {
  const t = lang === 'th' ? thText : enText;
  let errMsg: string = '';
  let errSubtitle: string = '';
  const dlvtype = Cookies.get(cookieKey.shippingType);

  switch (type) {
    case 0:
      errMsg = t.youdonothavethiscoup_1371;
      break;
    case 1:
      errMsg = t.couponscannotbeused_1372;
      break;
    case 2:
      errMsg = t.yourcouponhasexpired_1373;
      break;
    case 3:
      errMsg = t.thiscouponhasalready_1374;
      break;
    case 4:
      errMsg = t.unfortunatelyyoucano_675;
      break;
    case 5:
      errMsg = t.couponcannotbeverifi_674;
      break;
    case 6:
      if (dlvtype && dlvtype == 'Pickup' && secname !== 'cart') {
        errMsg = t.couponscannotbeusedb_930;
        errSubtitle = t.thiscouponcannotbeus_931;
      }
      if (fullaVaildate) {
        errMsg = t.cannotuse_coupon_1573;
        errSubtitle = `${t.cannotuse_coupon_1573} ${fullaVaildate}`;
      }
      break;
    case 7:
      errMsg = dbMessage ? dbMessage : '';
      break;
    case 8:
      errMsg = t.pleaseenterthecodeco_1375;
      break;
    case 9:
      errMsg = `${t.couponswillbeavailab_1376}${
        secname && secname === 'cart'
          ? t.chooseshippingmethod_1377
          : t.mybasket_1378
      }`;
      break;
    case 10:
      errMsg = t.pleaseloginbeforeusecoupon;
      break;
    case 11:
      errMsg = t.reserveredeemvouchererror;
      break;
    case 12:
      errMsg = t.notpartnercoupon;
      break;
    case 13:
      errMsg = t.couponcannotbeused;
      errSubtitle = t.checkthecouponusageterms;
      break;
    default:
      errMsg = t.error;
      break;
  }
  return { errMsg, infoRes, errSubtitle };
};
