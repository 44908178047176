import {
  AddToCartRequest,
  AddToCartRequestConverter,
  AddToCartResponse,
  AddToCartResponseConverter,
  SkuWithQty,
} from '@/models/AddToCart.model';
import { postDataFromAPI } from '@/utils/httpClient';

export enum AddToCartServiceType {
  // eslint-disable-next-line no-unused-vars
  reNewService = 'reNewService',
  // eslint-disable-next-line no-unused-vars
  addToCart = 'addToCart',
  // eslint-disable-next-line no-unused-vars
  addToCartMultiItems = 'addToCartMultiItems',
}

export const reNewService = async ({
  lang,
  sku,
  skuService,
}: {
  lang?: string;
  sku: string;
  skuService?: string;
}): Promise<{
  dbCode: boolean;
  status?: number | null;
  dbMessage: string;
  serviceType: AddToCartServiceType;
}> => {
  const getDataAPI = await postDataFromAPI({
    path: `/cartRenewService`,
    lang,
    body: { skcode: sku, skuservice: skuService },
  });
  if (getDataAPI.status === 200) {
    if (getDataAPI.data.dbcode === true) {
      return {
        dbCode: true,
        status: getDataAPI.status,
        dbMessage: '',
        serviceType: AddToCartServiceType.reNewService,
      };
    } else {
      return {
        dbCode: false,
        status: getDataAPI.status,
        dbMessage: getDataAPI.data.dbmessage,
        serviceType: AddToCartServiceType.reNewService,
      };
    }
  } else {
    return {
      dbCode: false,
      status: getDataAPI.status,
      dbMessage: 'Connection Error.',
      serviceType: AddToCartServiceType.reNewService,
    };
  }
};

export const addToCart = async (
  request: AddToCartRequest,
): Promise<AddToCartResponse> => {
  let path = `/addtocart`;
  if (
    request.setId !== undefined ||
    (request.skuQtyList && request.skuQtyList.length > 0)
  ) {
    path = `addmoretocart`;
  }
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: path,
      body: AddToCartRequestConverter.toJson(request),
      headers: request.storeCode ? { stcode: request.storeCode } : undefined,
    }),
  ]);

  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: AddToCartResponse = apiData
    ? apiData
    : { dbCode: false, dbMessage: 'Connection Error.' };
  try {
    response = AddToCartResponseConverter.fromJson(
      apiData ? apiData : { dbCode: false, dbMessage: 'Connection Error.' },
    );
  } catch (e) {
    console.log(e);
  }
  response.sku = request.sku;
  response.qty = request.qty;
  response.isSelectBundle = request.isSelectBundle;
  response.setId = request.setId;
  response.withService = request.withService;
  response.skuQtyList = request.skuQtyList;
  response.serviceType = AddToCartServiceType.addToCart;
  response.storeCode = request.storeCode;
  return response;
};

export enum AddToCartMultiItemsType {
  // eslint-disable-next-line no-unused-vars
  steel = 0,
  // eslint-disable-next-line no-unused-vars
  reorder = 1,
  // eslint-disable-next-line no-unused-vars
  paint = 2,
}

export const addToCartMultiItems = async (
  request: SkuWithQty[],
  type?: AddToCartMultiItemsType,
  isOk?: boolean,
): Promise<AddToCartResponse> => {
  let path =
    type === AddToCartMultiItemsType.reorder
      ? `/addtocartreorder`
      : type === AddToCartMultiItemsType.paint
      ? `/addProdSet2CartShowStockPaint`
      : `/addProdSet2CartShowStock`;

  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: path,
      body:
        type === AddToCartMultiItemsType.reorder
          ? {
              skuarr: request.map((e) => {
                return {
                  SKCODE: e.sku,
                  QNT: e.qty,
                  GroupID: e.groupId,
                  ShadeID: e.shadeId,
                };
              }),
              isOk: isOk,
            }
          : type === AddToCartMultiItemsType.paint
          ? {
              items: request.map((e) => {
                return {
                  SKCODE: e.sku,
                  QNT: e.qty,
                  GroupID: e.groupId,
                  ShadeID: e.shadeId,
                };
              }),
              isOk: isOk,
            }
          : {
              items: request.map((e) => {
                return {
                  SKCODE: e.sku,
                  QNT: e.qty,
                };
              }),
            },
    }),
  ]);

  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: AddToCartResponse = apiData
    ? apiData
    : { dbCode: false, dbMessage: 'Connection Error.' };
  try {
    response = AddToCartResponseConverter.fromJson(
      apiData ? apiData : { dbCode: false, dbMessage: 'Connection Error.' },
    );
  } catch (e) {
    console.log(e);
  }

  response.skuQtyList = request;
  response.serviceType = AddToCartServiceType.addToCartMultiItems;
  response.addToCartMultiType = type;
  return response;
};

export const addToCartChristmasItem = async (
  request: AddToCartRequest,
): Promise<AddToCartResponse> => {
  let path = '/addtocartchristmas';

  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: path,
      body: {
        skcode: request.sku.split(','),
        qty: request.qty,
        isok: request.isOk,
      },
    }),
  ]);

  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: AddToCartResponse = apiData
    ? apiData
    : { dbCode: false, dbMessage: 'Connection Error.' };
  try {
    response = AddToCartResponseConverter.fromJson(
      apiData ? apiData : { dbCode: false, dbMessage: 'Connection Error.' },
    );
  } catch (e) {
    console.log(e);
  }
  response.sku = request.sku;
  response.qty = request.qty;
  return response;
};
