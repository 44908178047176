import OptionParser from './optionparser.js';
import Detector from './detector.js';
import Bakery from './bakery.js';

const DEFAULT_PLATFORMS = 'android,ios';
const DEFAULT_CLOSE_LABEL = 'Close';
const DEFAULT_BUTTON_LABEL = 'View';

let datas = {
  originalTop: 'data-smartbanner-original-top',
  originalMarginTop: 'data-smartbanner-original-margin-top',
};

function handleExitClick(event, self) {
  self.exit();
  event.preventDefault();
}

function handleClickout(event, self) {
  self.clickout();
}

function handleJQueryMobilePageLoad(event) {
  if (!this.positioningDisabled) {
    setContentPosition(event.data.height);
  }
}

function addEventListeners(self) {
  let closeIcon = document.querySelector('.js_smartbanner__exit');
  closeIcon.addEventListener('click', (event) => handleExitClick(event, self));

  let button = document.querySelector('.js_smartbanner__button');
  button.addEventListener('click', (event) => handleClickout(event, self));

  if (Detector.jQueryMobilePage()) {
    $(document).on('pagebeforeshow', self, handleJQueryMobilePageLoad);
  }
}

function removeEventListeners() {
  if (Detector.jQueryMobilePage()) {
    $(document).off('pagebeforeshow', handleJQueryMobilePageLoad);
  }
}

function setContentPosition(value) {
  let wrappers = Detector.wrapperElement();
  for (let i = 0, l = wrappers.length, wrapper; i < l; i++) {
    wrapper = wrappers[i];
    if (Detector.jQueryMobilePage()) {
      if (wrapper.getAttribute(datas.originalTop)) {
        continue;
      }
      let top = parseFloat(getComputedStyle(wrapper).top);
      wrapper.setAttribute(datas.originalTop, isNaN(top) ? 0 : top);
      wrapper.style.top = value + 'px';
    } else {
      // if (wrapper.getAttribute(datas.originalMarginTop)) {
      //   continue;
      // }
      let margin = parseFloat(getComputedStyle(wrapper).marginTop);
      wrapper.setAttribute(datas.originalMarginTop, isNaN(margin) ? 0 : margin);
      wrapper.style.marginTop = value + 'px';
    }
  }
}

function restoreContentPosition() {
  let wrappers = Detector.wrapperElement();
  for (let i = 0, l = wrappers.length, wrapper; i < l; i++) {
    wrapper = wrappers[i];
    if (
      Detector.jQueryMobilePage() &&
      wrapper.getAttribute(datas.originalTop)
    ) {
      wrapper.style.top = wrapper.getAttribute(datas.originalTop) + 'px';
    } else if (wrapper.getAttribute(datas.originalMarginTop)) {
      wrapper.style.marginTop =
        wrapper.getAttribute(datas.originalMarginTop) + 'px';
    }
  }
}

export default class SmartBanner {
  constructor() {
    let parser = new OptionParser();
    this.options = parser.parse();
    this.platform = Detector.platform();
    let event = new Event('smartbanner.init');
    document.dispatchEvent(event);
  }

  // DEPRECATED. Will be removed.
  get originalTop() {
    let wrapper = Detector.wrapperElement()[0];
    return parseFloat(wrapper.getAttribute(datas.originalTop));
  }

  // DEPRECATED. Will be removed.
  get originalTopMargin() {
    let wrapper = Detector.wrapperElement()[0];
    return parseFloat(wrapper.getAttribute(datas.originalMarginTop));
  }

  get priceSuffix() {
    if (this.platform === 'ios' && this.options.priceSuffixApple) {
      return this.options.priceSuffixApple;
    } else if (this.platform === 'android' && this.options.priceSuffixGoogle) {
      return this.options.priceSuffixGoogle;
    }
    return '';
  }

  get price() {
    if (this.options.price && this.options.price !== '') {
      return this.options.price;
    } else {
      return '';
    }
  }

  get icon() {
    if (this.platform === 'android') {
      return this.options.iconGoogle;
    } else {
      return this.options.iconApple;
    }
  }

  get buttonUrl() {
    if (this.platform === 'android') {
      return this.options.buttonUrlGoogle;
    } else if (this.platform === 'ios') {
      return this.options.buttonUrlApple;
    }
    return '#';
  }

  get closeLabel() {
    return this.options.closeLabel !== undefined
      ? this.options.closeLabel
      : DEFAULT_CLOSE_LABEL;
  }

  get buttonLabel() {
    let buttonLabel = this.options.button;
    if (this.platform === 'android' && this.options.buttonGoogle) {
      buttonLabel = this.options.buttonGoogle;
    } else if (this.platform === 'ios' && this.options.buttonApple) {
      buttonLabel = this.options.buttonApple;
    }

    return buttonLabel || DEFAULT_BUTTON_LABEL;
  }

  // เรียกใช้ฟังก์ชัน isAppInstalled() และส่ง URL scheme ของแอปพลิเคชันไปด้วย
  // isAppInstalled("yourapp");

  get html() {
    // For desktop browser, remember to pass though any metadata on the link for deep linking
    var fallbackLink = 'https://www.thaiwatsadu.com';

    // Simple device detection
    var isiOS =
        navigator.userAgent.match('iPad') ||
        navigator.userAgent.match('iPhone') ||
        navigator.userAgent.match('iPod'),
      isAndroid = navigator.userAgent.match('Android');

    // Mobile
    if (isiOS || isAndroid) {
      fallbackLink = isAndroid
        ? 'https://play.google.com/store/apps/details?id=com.thaiwatsadu.ecommerce'
        : 'https://apps.apple.com/th/app/thaiwatsadu/id1606151700?l=th';
    }

    // Now we just wait for everything to execute, if the user is redirected to your custom app
    // the timeout below will never fire, if a custom app is not present (or the user is on the Desktop)
    // we will replace the current URL with the fallbackLink (store URL or desktop URL as appropriate)
    let modifier = !this.options.customDesignModifier
      ? this.platform
      : this.options.customDesignModifier;
    return `<div class="smartbanner smartbanner--${modifier} js_smartbanner">
      <a href="javascript:void(0);" class="smartbanner__exit js_smartbanner__exit" aria-label="${this.closeLabel}"></a>
      <div class="smartbanner__icon" style="background-image: url(${this.icon});"></div>
      <div class="smartbanner__info">
        <div>
          <div class="smartbanner__info__title">${this.options.title}</div>
          <div class="smartbanner__info__author">${this.options.author}</div>
        </div>
      </div>
      <a href="${fallbackLink}" target="_blank" class="smartbanner__button js_smartbanner__button" rel="noopener" aria-label="${this.buttonLabel}"><span class="smartbanner__button__label">${this.buttonLabel}</span></a>
    </div>`;
  }

  get height() {
    try {
      return document.querySelector('.js_smartbanner').offsetHeight;
    } catch (error) {
      return 0;
    }
  }

  get platformEnabled() {
    let enabledPlatforms = this.options.enabledPlatforms || DEFAULT_PLATFORMS;
    return (
      enabledPlatforms &&
      enabledPlatforms.replace(/\s+/g, '').split(',').indexOf(this.platform) !==
        -1
    );
  }

  get positioningDisabled() {
    return this.options.disablePositioning === 'true';
  }

  get apiEnabled() {
    return this.options.api === 'true';
  }

  get userAgentExcluded() {
    if (!this.options.excludeUserAgentRegex) {
      return false;
    }
    return Detector.userAgentMatchesRegex(this.options.excludeUserAgentRegex);
  }

  get userAgentIncluded() {
    if (!this.options.includeUserAgentRegex) {
      return false;
    }
    return Detector.userAgentMatchesRegex(this.options.includeUserAgentRegex);
  }

  get hideTtl() {
    return this.options.hideTtl ? parseInt(this.options.hideTtl) : false;
  }

  get hidePath() {
    return this.options.hidePath ? this.options.hidePath : '/';
  }

  publish() {
    if (Object.keys(this.options).length === 0) {
      throw new Error('No options detected. Please consult documentation.');
    }
    let bannerSelector = document.querySelector('.js_smartbanner');
    try {
      const pageToHideSmartBanner = [
        'cart',
        'checkout',
        'paylater',
        'repayment',
        'thank',
      ];
      const getPath = window.location.pathname;
      const getPathSplit = getPath.split('/');
      const asPath = getPathSplit[getPathSplit.length - 1];
      let isAsPath2IsInList = false;
      try {
        if (getPathSplit.length > 2) {
          const asPath2 = getPathSplit[getPathSplit.length - 2];
          isAsPath2IsInList = pageToHideSmartBanner.includes(asPath2);
        }
      } catch (_) {}
      const isHideSmartBanner =
        pageToHideSmartBanner.includes(asPath) || isAsPath2IsInList;
      if (isHideSmartBanner) {
        if (!this.positioningDisabled) {
          restoreContentPosition();
        }
        if (bannerSelector) {
          document.querySelector('body').removeChild(bannerSelector);
        }
        return false;
      }
    } catch (_) {}

    if (Bakery.baked) {
      return false;
    }

    // User Agent was explicetely excluded by defined excludeUserAgentRegex
    if (this.userAgentExcluded) {
      return false;
    }

    // User agent was neither included by platformEnabled,
    // nor by defined includeUserAgentRegex
    if (!(this.platformEnabled || this.userAgentIncluded)) {
      return false;
    }

    let userAgent = navigator.userAgent;
    let browser = 'Unknown';
    let isChrome = !!window.chrome;
    // Detect Chrome

    if (/Chrome/.test(userAgent) && !/Chromium/.test(userAgent)) {
      browser = 'Google Chrome';
    } else if (isChrome === true) {
      browser = 'Google Chrome';
    } else if (userAgent.match('CriOS')) {
      browser = 'Google Chrome';
    } else if (userAgent.match('/FBAN|FBAV/i')) {
      browser = 'Facebook browser';
    } else if (userAgent.includes('Line')) {
      browser = 'Line browser';
    }
    // Detect Chromium-based Edge
    else if (/Edg/.test(userAgent)) {
      browser = 'Microsoft Edge';
    }
    // Detect Firefox
    else if (/Firefox/.test(userAgent)) {
      browser = 'Mozilla Firefox';
    }
    // Detect Safari
    else if (/Safari/.test(userAgent)) {
      browser = 'Apple Safari';
    }
    // Detect Internet Explorer
    else if (/Trident/.test(userAgent)) {
      browser = 'Internet Explorer';
    }

    if (browser != 'Apple Safari' && !bannerSelector) {
      let bannerDiv = document.createElement('div');
      document.querySelector('body').prepend(bannerDiv);
      bannerDiv.outerHTML = this.html;
      let event = new Event('smartbanner.view');
      document.dispatchEvent(event);
      if (!this.positioningDisabled) {
        setContentPosition(this.height);
      }
      addEventListeners(this);
    }
  }

  exit() {
    removeEventListeners();
    if (!this.positioningDisabled) {
      restoreContentPosition();
    }
    let banner = document.querySelector('.js_smartbanner');
    document.querySelector('body').removeChild(banner);
    let event = new Event('smartbanner.exit');
    document.dispatchEvent(event);
    Bakery.bake(this.hideTtl, this.hidePath);
  }

  clickout() {
    let event = new Event('smartbanner.clickout');
    document.dispatchEvent(event);
  }
  async checkApp() {
    if ('getInstalledRelatedApps' in navigator) {
      navigator
        .getInstalledRelatedApps()
        .then(function (relatedApps) {
          relatedApps.forEach(function (app) {
            alert('Related app detected:', app.id, app.platform);
          });
        })
        .catch(function (error) {
          alert('Error retrieving related apps:', error);
        });
    } else {
      alert('getInstalledRelatedApps is not supported');
    }
  }
  checkAppInstalled(uriScheme) {
    var timeout,
      hasApp = true;

    // Create an iframe with the custom URI scheme
    var iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = uriScheme;
    // Set up a timeout in case the app is not installed
    timeout = setTimeout(function () {
      hasApp = false;
      document.body.removeChild(iframe);
    }, 1000);

    // Listen for page visibility change
    iframe.onload = function () {
      clearTimeout(timeout);
      if (!hasApp) {
        document.body.removeChild(iframe);
        // Do something if the app is not installed
        alert('App is not installed.');
      } else {
        // Do something if the app is installed
        alert('App is installed.');
      }
    };

    document.body.appendChild(iframe);
  }
}
