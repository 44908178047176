import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface Get1010CouponState {
  selectedArea?: string;
  isChangeStore?: boolean;
  isLoading?: boolean;
  error?: string;
}

const initialState: Get1010CouponState = {
  isLoading: false,
};

const christmasSlice = createSlice({
  name: 'christmas',
  initialState: initialState,
  reducers: {
    setSelectedArea: (state, action) => {
      state.selectedArea = action.payload;
    },
  },
});

export const { setSelectedArea } = christmasSlice.actions;

export const selectedAreaSelector = (store: RootState): string | undefined =>
  store.christmas.selectedArea;

export const errorSelector = (store: RootState): string | undefined =>
  store.christmas.error;

export const isChangeStoreSelector = (store: RootState): boolean | undefined =>
  store.christmas.isChangeStore;

export default christmasSlice.reducer;
