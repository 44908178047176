import { EditCartResponse, MyCartResponse } from '@/models/MyCart.model';
import {
  EditCartType,
  addFreeGift,
  editCartChristmasService,
  editCartService,
  getClearCart,
  getMyCartData,
  getUsedVoucherList,
} from '@/services/client/myCartService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import * as ServerCookies from '@/services/client/cookieService';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import { ProductModel } from '@/models/Product.model';
import { MyUsedVoucherListResponse } from '@/models/profile/MyVoucher.model';
import { skuCheckStock } from '@/services/client/checkoutService';
import { CheckStockSkuResponse } from '@/models/Stock.model';

interface MyCartState {
  ref: string;
  result?: MyCartResponse;
  isLoadingCart: boolean;
  errorCart?: string;
  isLoadingClearCart: boolean;
  errorClearCart?: string;
  isLoadingEditCart: boolean;
  errorEditCart?: string;
  editCartSuccessSku?: ProductModel[];
  voucherUsedResult?: MyUsedVoucherListResponse | null;
  isLoadingGoToCheckOut: boolean;
  errorGoToCheckOut?: CheckStockSkuResponse | null;
  errorAddFreeGiftCart?: string;
  successAddFreeGiftCart?: boolean;
}

const initialState: MyCartState = {
  ref: '',
  result: undefined,
  isLoadingCart: false,
  errorCart: undefined,
  isLoadingClearCart: false,
  errorClearCart: undefined,
  isLoadingEditCart: false,
  errorEditCart: undefined,
  voucherUsedResult: undefined,
  isLoadingGoToCheckOut: false,
  errorAddFreeGiftCart: undefined,
  successAddFreeGiftCart: undefined,
};

export const queryMyCartUsedVoucher = createAsyncThunk(
  'mycart/queryMyCartUsedVoucher',
  async ({ lang }: { lang?: string }) => {
    const voucherUsed = await getUsedVoucherList({ lang });
    return voucherUsed;
  },
);

export const myCartQuery = createAsyncThunk(
  'mycart/query',
  async ({
    lang,
    querySupplier,
  }: {
    lang?: string;
    querySupplier?: string;
  }) => {
    const [response, voucherUsed] = await Promise.all([
      getMyCartData({ lang, querySupplier }),
      getUsedVoucherList({ lang }),
    ]);
    return { response, voucherUsed };
  },
);

export const clearCart = createAsyncThunk(
  'mycart/clearCart',
  async ({ dispatch, lang }: { dispatch: any; lang?: string }) => {
    const response = await getClearCart();
    await ServerCookies.remove(cookieKey.ref);
    Cookies.remove(cookieKey.cartCount);
    if (!response?.data) {
      throw new Error('Clear cart failed');
    }
    if (response?.data.dbCode != true) {
      throw new Error(response?.data.dbMessage);
    }
    dispatch(myCartQuery({ lang: lang }));
    return response;
  },
);

export const editCart = createAsyncThunk<
  EditCartResponse,
  {
    product: ProductModel;
    qty: number;
    type: EditCartType;
    dispatch: any;
    lang?: string;
  },
  { state: RootState }
>(
  'mycart/editCart',
  async (
    {
      product,
      qty,
      type,
      dispatch,
      lang,
    }: {
      product: ProductModel;
      qty: number;
      type: EditCartType;
      dispatch: any;
      lang?: string;
    },
    { getState }: { getState: () => RootState },
  ) => {
    const cartResult = getState().myCart.result;
    const items: ProductModel[] = [
      ...(cartResult?.normalitem ?? []).map((e) => {
        return {
          ...e,
          itemCartType: EditCartType.normal,
        };
      }),
    ];
    for (let i = 0; i < (cartResult?.bundledetail ?? []).length; i++) {
      const bundles = cartResult?.bundledetail[i];
      for (let j = 0; j < (bundles ?? []).length; j++) {
        const bundle = (bundles ?? [])[j];
        if (bundle) {
          items.push({ ...bundle, itemCartType: EditCartType.bundle });
        }
      }
    }
    const findSameSku = items.find(
      (e) => e.sku === product.sku && e.itemCartType !== type,
    );
    if (findSameSku !== undefined) {
      qty += findSameSku?.qty ?? 0;
    }
    const response = await editCartService({
      product,
      qty,
      type,
    });
    if (!response?.dbCode) {
      throw new Error(response?.dbMessage);
    }
    dispatch(myCartQuery({ lang: lang }));
    return response;
  },
);

export const addFreeGiftCart = createAsyncThunk(
  'mycart/addFreeGiftCart',
  async ({
    lang,
    seq,
    sku,
    useStock,
    ctlId,
    quantity,
    dispatch,
  }: {
    lang?: string;
    seq?: number;
    sku?: string;
    useStock?: string;
    ctlId?: string;
    quantity?: number;
    dispatch: any;
  }) => {
    const response = await addFreeGift({
      lang,
      seq,
      sku,
      useStock,
      ctlId,
      quantity,
    });
    if (!response?.dbCode) {
      throw new Error(response?.dbMessage);
    }
    dispatch(myCartQuery({ lang: lang }));
    return response;
  },
);

export const goToCheckOut = createAsyncThunk(
  'mycart/goToCheckOut',
  async ({ lang }: { lang?: string }) => {
    const checkStockData = await skuCheckStock({ lang });
    return checkStockData.data.products;
  },
);

export const editCartSet = createAsyncThunk<
  EditCartResponse,
  {
    product: ProductModel;
    qty: number;
    type: EditCartType;
    dispatch: any;
    lang?: string;
  },
  { state: RootState }
>(
  'mycart/editCartSet',
  async (
    {
      product,
      qty,
      type,
      dispatch,
      lang,
    }: {
      product: ProductModel;
      qty: number;
      type: EditCartType;
      dispatch: any;
      lang?: string;
    },
    { getState }: { getState: () => RootState },
  ) => {
    const cartResult = getState().myCart.result;
    const items: ProductModel[] = [
      ...(cartResult?.normalitem ?? []).map((e) => {
        return { ...e, itemCartType: EditCartType.christmas };
      }),
    ];

    const findSameSku = items.find(
      (e) => e.sku === product.sku && e.itemCartType !== type,
    );
    if (findSameSku !== undefined) {
      qty += findSameSku?.qty ?? 0;
    }
    const response = await editCartChristmasService({
      product,
      qty,
    });
    if (!response?.dbCode) {
      throw new Error(response?.dbMessage);
    }
    dispatch(myCartQuery({ lang: lang }));
    return response;
  },
);

const myCartSlice = createSlice({
  name: 'mycart',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.errorCart = undefined;
      state.errorClearCart = undefined;
      state.errorEditCart = undefined;
      state.errorGoToCheckOut = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
  },
  extraReducers: (builder) => {
    // fulfilled, rejected
    builder
      .addCase(queryMyCartUsedVoucher.pending, (state) => {
        state.isLoadingEditCart = true;
      })
      .addCase(queryMyCartUsedVoucher.fulfilled, (state, action) => {
        state.voucherUsedResult = action.payload;
        state.isLoadingEditCart = false;
      })
      .addCase(queryMyCartUsedVoucher.rejected, (state) => {
        state.voucherUsedResult = undefined;
        state.isLoadingEditCart = false;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(myCartQuery.pending, (state) => {
        state.errorCart = undefined;
        state.isLoadingCart = true;
      })
      .addCase(myCartQuery.fulfilled, (state, action) => {
        if (action.payload.response?.diffStore) {
          state.errorCart = action.payload.response?.diffStore;
        } else {
          state.errorCart = undefined;
        }
        state.result = action.payload.response;
        state.voucherUsedResult = action.payload.voucherUsed;
        state.isLoadingCart = false;
        state.ref = action.payload.response?.ref ?? '';
        state.isLoadingEditCart = false;
        state.errorEditCart = undefined;
      })
      .addCase(myCartQuery.rejected, (state, action) => {
        state.errorCart = action.error.message;
        state.result = undefined;
        state.isLoadingCart = false;
        Cookies.remove(cookieKey.cartCount);
      });
    // pending, fulfilled, rejected
    builder
      .addCase(clearCart.pending, (state) => {
        state.isLoadingClearCart = true;
        state.result = undefined;
        state.isLoadingCart = true;
        state.errorClearCart = undefined;
      })
      .addCase(clearCart.fulfilled, (state) => {
        state.isLoadingClearCart = false;
        state.errorClearCart = undefined;
        state.result = undefined;
        state.isLoadingCart = false;
      })
      .addCase(clearCart.rejected, (state) => {
        state.isLoadingClearCart = false;
        state.errorClearCart = undefined;
        state.result = undefined;
        state.isLoadingCart = false;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(editCart.pending, (state) => {
        state.isLoadingEditCart = true;
        state.errorEditCart = undefined;
        state.editCartSuccessSku = undefined;
      })
      .addCase(editCart.fulfilled, (state, action) => {
        state.editCartSuccessSku = action.payload.dbItems.map((e) => {
          e.qty = action.payload.qty;
          return e;
        });
      })
      .addCase(editCart.rejected, (state, action) => {
        state.isLoadingEditCart = false;
        state.errorEditCart = action.error.message;
        state.editCartSuccessSku = undefined;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(addFreeGiftCart.pending, (state) => {
        state.isLoadingEditCart = true;
        state.errorAddFreeGiftCart = undefined;
        state.successAddFreeGiftCart = undefined;
      })
      .addCase(addFreeGiftCart.fulfilled, (state, action) => {
        state.successAddFreeGiftCart = action.payload.dbCode;
      })
      .addCase(addFreeGiftCart.rejected, (state, action) => {
        state.isLoadingEditCart = false;
        state.errorAddFreeGiftCart = action.error.message;
        state.successAddFreeGiftCart = undefined;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(goToCheckOut.pending, (state) => {
        state.isLoadingGoToCheckOut = true;
        state.errorGoToCheckOut = undefined;
      })
      .addCase(goToCheckOut.fulfilled, (state, action) => {
        state.isLoadingGoToCheckOut = false;
        if (action.payload) {
          state.errorGoToCheckOut = {
            products: action.payload,
          };
        } else {
          state.errorGoToCheckOut = null;
        }
      })
      .addCase(goToCheckOut.rejected, (state) => {
        state.isLoadingGoToCheckOut = false;
        state.errorGoToCheckOut = null;
      });
    builder
      .addCase(editCartSet.pending, (state) => {
        state.isLoadingEditCart = true;
        state.errorEditCart = undefined;
        state.editCartSuccessSku = undefined;
      })
      .addCase(editCartSet.fulfilled, (state, action) => {
        state.editCartSuccessSku = action.payload.dbItems.map((e) => {
          e.qty = action.payload.qty;
          return e;
        });
      })
      .addCase(editCartSet.rejected, (state, action) => {
        state.isLoadingEditCart = false;
        state.errorEditCart = action.error.message;
        state.editCartSuccessSku = undefined;
      });
  },
});

export const { clearError, clearResult } = myCartSlice.actions;

export const myCartResultSelector = (
  store: RootState,
): MyCartResponse | undefined => store.myCart.result;

export const isLoadingCartSelector = (store: RootState): boolean =>
  store.myCart.isLoadingCart;

export const errorCartSelector = (store: RootState): string | undefined =>
  store.myCart.errorCart;

export const isLoadingClearCartSelector = (store: RootState): boolean =>
  store.myCart.isLoadingClearCart;

export const errorClearCartSelector = (store: RootState): string | undefined =>
  store.myCart.errorClearCart;

export const isLoadingEditCartSelector = (store: RootState): boolean =>
  store.myCart.isLoadingEditCart;

export const errorEditCartSelector = (store: RootState): string | undefined =>
  store.myCart.errorEditCart;

export const editCartSuccessSkuSelector = (
  store: RootState,
): ProductModel[] | undefined => store.myCart.editCartSuccessSku;

export const voucherUsedResultSelector = (
  store: RootState,
): MyUsedVoucherListResponse | undefined | null =>
  store.myCart.voucherUsedResult;

export const isLoadingGoToCheckOutSelector = (store: RootState): boolean =>
  store.myCart.isLoadingGoToCheckOut;

export const errorGoToCheckOutSelector = (
  store: RootState,
): CheckStockSkuResponse | undefined | null => store.myCart.errorGoToCheckOut;

export const errorAddFreeGiftCartSelector = (
  store: RootState,
): string | undefined => store.myCart.errorAddFreeGiftCart;

export const successAddFreeGiftCartSelector = (
  store: RootState,
): boolean | undefined => store.myCart.successAddFreeGiftCart;

export default myCartSlice.reducer;
