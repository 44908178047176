import React from 'react';

interface Props {
  value: string;
  matcher?: string;
  minimum?: number;
}

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escapes special characters
};

export default function TextMatching({ value, matcher, minimum }: Props) {
  const matchText = (matcher ?? '').trim();
  return (
    <span
      dangerouslySetInnerHTML={{
        __html:
          matchText !== '' && matchText.length >= (minimum ?? 0)
            ? value?.replace(
                new RegExp(`(${escapeRegExp(matcher?.trim() ?? '')})`, 'gi'),
                (match) => `<span class="text-primary">${match}</span>`,
              )
            : value,
      }}
    />
  );
}
