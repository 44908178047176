import { CookieData } from '@/models/Authen.model';
import * as cookieKey from '@/constants/cookieKey.constant';
import * as ServerCookies from '@/services/client/cookieService';
import Cookies from 'js-cookie';
import { serialize } from 'cookie';

export const saveClientCookieFromResponse = async (
  cookies?: CookieData[] | null,
) => {
  if (cookies && cookies.length > 0) {
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      if (cookie.name && cookie.val != null && cookie.val != undefined) {
        if (cookieKey.serverCookieNameList.includes(cookie.name)) {
          await ServerCookies.set(cookie.name, cookie.val.toString().trim());
        } else {
          Cookies.set(`${cookie.name}`, cookie.val.toString().trim(), {
            secure: process.env.NEXT_PUBLIC_COOKIE_ENV !== 'development',
          });
        }
      }
    }
  }
};

export const mapCookiesToSerialize = (cookies?: CookieData[]): string[] => {
  return (
    cookies
      ?.filter((e) => e.val)
      ?.map((cookie) =>
        serialize(cookie.name, cookie.val!, {
          path: '/',
          httpOnly: cookieKey.serverCookieNameList.includes(cookie.name)
            ? process.env.NEXT_PUBLIC_COOKIE_ENV !== 'development'
            : false,
          secure: process.env.NEXT_PUBLIC_COOKIE_ENV !== 'development',
          expires: new Date(
            Date.now() + 1000 * 60 * 60 * 24 * (cookie.days ?? 7),
          ),
        }),
      ) ?? []
  );
};
