import React from 'react';
import CenterFooter from './CenterFooter';
import BottomFooter from './BottomFooter';
import TopFooter from './TopFooter';
import { useRouter } from 'next/router';
import { pageToShowTopFooter } from '@/constants/defaultValue.constant';

export default function Footer() {
  const router = useRouter();

  return (
    <>
      {pageToShowTopFooter.includes(router.asPath) && <TopFooter></TopFooter>}
      <CenterFooter></CenterFooter>
      <BottomFooter></BottomFooter>
    </>
  );
}
