import { Button, Grid, IconButton, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useRef, useState } from 'react';
import headerClass from '@/styles/header.module.css';
import { useTranslation } from 'next-i18next';
import AppLink from '@/components/Layout/handler/AppLink';
import Image from 'next/image';
import MenuHeaderSideBar from '../../../homepage/HeroMenu/MenuHeaderSideBar';
import { useSelector } from 'react-redux';
import { cartCountSelector } from '@/store/slices/addToCartSlice';
import * as cookieKey from '@/constants/cookieKey.constant';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import * as showDialog from '@/utils/showDialog';
import SearchSuggestBox from './SearchSuggestBox';
import { useAppDispatch } from '@/store/store';
import {
  searchTextSelector,
  setHistoryResult,
  setSearchText,
} from '@/store/slices/searchSuggestSlice';
import { currentStoreSelector, openModal } from '@/store/slices/storeSlice';
import { customerInfoSelector } from '@/store/slices/authenSlice';
import CustomerNameHover from '../TopHeader/CustomerNameHover';
import { getRedirectQuery } from '@/utils/theone';
import { isNeedUpdateEmailSelector } from '@/store/slices/profile/editInfoSlice';
import UpdateCustomerEmailHover from '../TopHeader/UpdateCustomerEmailHover';

export default function CenterHeader() {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [cartCount, setCartCount] = useState<number>(0);
  const cartCountStore = useSelector(cartCountSelector);
  const searchRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isOpenSearchSuggest, setIsOpenSearchSuggest] =
    useState<boolean>(false);
  const [customerName, setCustomerName] = useState<string | null | undefined>(
    undefined,
  );
  const searchText = useSelector(searchTextSelector);
  const currentStore = useSelector(currentStoreSelector);
  const customerInfo = useSelector(customerInfoSelector);
  const isNeedUpdateEmail = useSelector(isNeedUpdateEmailSelector);

  useEffect(() => {
    setCustomerName(customerInfo?.cname);
  }, [customerInfo?.cname]);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsOpenSearchSuggest(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setIsOpenSearchSuggest(false);
    if (mobileOpen) {
      setMobileOpen(false);
    }
    router.query.srct && dispatch(setSearchText(String(router.query.srct)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, router.query.srct, dispatch]);

  useEffect(() => {
    if (cartCountStore) {
      setCartCount(cartCountStore);
    } else {
      setCartCount(Number(Cookies.get(cookieKey.cartCount) ?? 0));
    }
  }, [cartCountStore]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMobileSearchToggle = (e: any) => {
    e.preventDefault();
    setMobileSearch(!mobileSearch);
  };

  const handleSearchText = (e: any) => {
    e.preventDefault();
    dispatch(setSearchText(e.target.value));
  };

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();
    if (`/search/${searchText}` !== decodeURIComponent(router.asPath)) {
      searchInputRef.current?.blur();
      setIsOpenSearchSuggest(false);
      if (searchText.trim().length < 2) {
        showDialog.showErrorWithOkButton({ text: t('searchempty') });
        return;
      }
      dispatch(setHistoryResult(searchText));
      router.push(`/search/${searchText}`);
    }
  };

  const handleClickStore = () => dispatch(openModal());

  const isPaylaterHideHeader = router.pathname.includes('/paylater');

  return (
    <div className="bg-white">
      <div className="h-8 lg:h-12 relative flex">
        <Grid
          container
          columnSpacing={1}
          className="self-center max-width-container"
        >
          <Grid item xs={2} sm={1} className="flex lg:hidden">
            <div className="self-center ps-1 md:ps-2 relative">
              <IconButton
                sx={{ padding: 0 }}
                size="small"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              {isNeedUpdateEmail && (
                <Image
                  src="/images/svg/header/profile_warning.svg"
                  width={16}
                  height={16}
                  className="absolute bottom-0 right-0"
                  alt="profile_warning"
                />
              )}
              <Drawer
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{
                  sx: { width: '100%' },
                }}
              >
                <MenuHeaderSideBar onCloseDrawer={handleDrawerToggle} />
              </Drawer>
            </div>
          </Grid>
          <Grid item xs={2} md={2} className="flex mt-0">
            <div className="ps-0 lg:ps-1 self-center w-full h-auto lg:h-8 flex items-center">
              <AppLink href="/">
                <Image
                  src={`/images/logo/header/${
                    currentStore?.isHybrid === true
                      ? 'hybridlogo.svg'
                      : 'twdlogo.png'
                  }`}
                  alt="logo"
                  className="hidden md:inline"
                  width={currentStore?.isHybrid === true ? 274 : 187}
                  height={54}
                  style={{ maxHeight: 54 }}
                />
                <Image
                  src={`/images/logo/header/${
                    currentStore?.isHybrid === true
                      ? 'hybridlogo-mobile.svg'
                      : 'twdlogo_mobile.png'
                  }`}
                  alt="logo_mobile"
                  className="inline md:hidden px-0 sm:px-2 w-auto py-0.5"
                  width={currentStore?.isHybrid === true ? 101 : 53}
                  height={48}
                  style={{ maxHeight: 48 }}
                />
              </AppLink>
            </div>
          </Grid>
          <Grid
            item
            xs={mobileSearch ? 6 : 4}
            sm={mobileSearch ? 7 : 5}
            md={mobileSearch ? 8 : 6}
            lg={6}
            className="flex pt-0"
          >
            <div
              ref={searchRef}
              className={`w-full h-full ${
                mobileSearch ? 'block' : 'hidden'
              } lg:block self-center bg-white max-h-6 lg:max-h-8 static md:relative ms-0 lg:ms-2`}
            >
              <form
                className={`${headerClass.searchDesktop}`}
                onSubmit={handleSearchSubmit}
              >
                <input
                  ref={searchInputRef}
                  type="text"
                  value={searchText}
                  className="h-full w-full px-2 text-center focus:outline-none bg-grayLight1"
                  placeholder={`${t('search')}`}
                  id="search-input"
                  name="search-input"
                  aria-label="Search"
                  autoComplete="off"
                  onChange={handleSearchText}
                  onFocus={() => {
                    setIsOpenSearchSuggest(true);
                  }}
                />
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: '#EBEBEB',
                    xs: {
                      display: 'none',
                    },
                    lg: {
                      display: 'flex',
                    },
                  }}
                  className="hidden lg:flex px-2 h-full cursor-pointer bg-grayLight1"
                >
                  <Image
                    src="/images/svg/header/search.svg"
                    alt="search"
                    width={22}
                    height={22}
                    className="self-center"
                    style={{
                      width: 'auto',
                      height: 'auto',
                    }}
                  />
                </Button>
              </form>
              {isOpenSearchSuggest && (
                <div className="absolute z-10 left-1/2 -translate-x-1/2 w-full h-auto">
                  <SearchSuggestBox />
                </div>
              )}
            </div>
            {!mobileSearch && (
              <div
                onClick={handleClickStore}
                className="mx-auto self-center lg:hidden flex cursor-pointer items-center"
              >
                <Image
                  src="/images/svg/header/location_pin_black.svg"
                  alt="search"
                  width={24}
                  height={24}
                />
                <div className="ps-1 text-black text-sm sm:text-base leading-3 text-center">
                  {currentStore
                    ? i18n.language === 'th'
                      ? currentStore.name
                      : currentStore.nameEN
                    : t('selectstore')}
                </div>
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={3}
            className="hidden lg:flex items-center justify-center"
          >
            {!isPaylaterHideHeader && (
              <div className="cursor-pointer line-clamp-1">
                <div>
                  {customerName !== null && customerName !== undefined ? (
                    <div className="flex space-x-1">
                      <UpdateCustomerEmailHover
                        isNeedUpdateEmail={isNeedUpdateEmail}
                      />
                      <CustomerNameHover customerName={customerName} />
                    </div>
                  ) : (
                    <AppLink href={`/signin${getRedirectQuery(router.asPath)}`}>
                      <div className="flex items-center space-x-1">
                        <div className="h-4">
                          <Image
                            src="/images/svg/header/profile_black.svg"
                            width={24}
                            height={24}
                            alt="profile"
                          />
                        </div>
                        <div
                          style={{
                            paddingTop: 2,
                          }}
                        >
                          {t('signin')}
                        </div>
                      </div>
                    </AppLink>
                  )}
                </div>
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={mobileSearch ? 2 : 4}
            md={mobileSearch ? 1 : 3}
            lg={1}
            className="self-center inline-flex pt-0 w-full justify-around"
          >
            <div
              className="lg:hidden inline-flex h-8 w-full"
              onClick={handleMobileSearchToggle}
            >
              {mobileSearch ? (
                <Image
                  className="mx-auto my-auto main-cart noshake"
                  src="/images/svg/header/close_black.svg"
                  alt="profile"
                  width={22}
                  height={22}
                />
              ) : (
                <Image
                  className="my-auto"
                  src="/images/svg/header/search_black.svg"
                  alt="profile"
                  width={22}
                  height={22}
                />
              )}
            </div>
            {!isPaylaterHideHeader && (
              <AppLink
                href="/cart"
                className={`${mobileSearch ? 'hidden' : 'flex'} lg:flex`}
              >
                <div className="flex items-center ml-auto px-1 main-cart">
                  <div className="h-4 leading-4">
                    <i className="bi bi-cart text-black mr-1 my-auto"></i>
                  </div>
                  <div
                    className="bg-primary rounded-full flex items-center justify-center"
                    style={{
                      width: cartCount > 99 ? 28 : 25,
                      height: cartCount > 99 ? 28 : 25,
                    }}
                  >
                    <div className="text-white font-bold text-xs leading-3 h-3">
                      {cartCount > 99 ? `99+` : cartCount}
                    </div>
                  </div>
                </div>
              </AppLink>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
