import { modelValue } from '@/utils/modelValue';

export enum ErrorSavePin {
  // eslint-disable-next-line no-unused-vars
  unauthorized = 'Unauthorized',
  // eslint-disable-next-line no-unused-vars
  areaMismatch = 'area_mismatch',
  // eslint-disable-next-line no-unused-vars
  alreadyPinned = 'already_pinned',
  // eslint-disable-next-line no-unused-vars
  addressNotFound = 'address_not_found',
  // eslint-disable-next-line no-unused-vars
  other = 'other',
}

export interface Address {
  addressName?: string;
  cname?: string;
  mobile?: string;
  address?: string;
  name?: string;
  lastName?: string;
  isCompany?: boolean;
  company?: string;
  isHq?: boolean;
  nameNotCompany?: string;
  lastNameNotCompany?: string;
  idNumber?: string;
  idBranch?: string;
  phone?: string;
  phone2?: string;
  addressNumber?: string;
  building?: string;
  floor?: string;
  room?: string;
  moo?: string;
  soi?: string;
  road?: string;
  zipcode?: string;
  subDistrict?: string;
  district?: string;
  province?: string;
  lat?: number;
  lng?: number;
  typeOfAddress?: string;
  otherText?: string;
  haveElevator?: string;
  canDeliveryCarType?: string;
  isDefaultShip?: boolean;
  isDefaultBill?: boolean;
  isPin?: boolean;
  originalStt?: string;
  etaxLink?: string;
  etaxStatus?: string;
  nameReturnSender?: string;
  contactReturnSender?: string;
}

export class AddressConverter {
  public static toJson(model: Address): any {
    const json: any = {
      adrname: model.addressName,
      cname: `${model.name} ${model.lastName}`,
      name: model.name,
      lname: model.lastName,
      iscom: model.isCompany ? 1 : 0,
      ishq: model.isHq,
      company: model.company,
      namenocom: model.nameNotCompany,
      lnamenocom: model.lastNameNotCompany,
      idnum: model.idNumber,
      idbranch: model.idBranch,
      phone: model.phone,
      phone2: model.phone2,
      adrnum: model.addressNumber,
      build: model.building,
      floor: model.floor,
      room: model.room,
      moo: model.moo,
      soi: model.soi,
      road: model.road,
      subdistrict: model.subDistrict,
      district: model.district,
      province: model.province,
      zipcode: model.zipcode,
      ulat: model.lat,
      ulong: model.lng,
      typeofadr: model.typeOfAddress,
      othertxt: model.otherText,
      havele: model.haveElevator,
      can: model.canDeliveryCarType,
      adrstt: model.originalStt,
      ispin: model.isPin,
    };
    return modelValue(json);
  }
  public static fromJson(json: any): Address {
    const model: Address = {
      addressName: (json['ANM'] ?? json['adrname'])?.toString().trim(),
      cname: (json['CNM'] ?? json['cname'])?.toString().trim(),
      mobile: (json['MBL'] ?? json['telephone'])?.toString().trim(),
      address: (json['ADR'] ?? json['address'])?.toString().trim(),
      name: (json['NME'] ?? json['name'])?.toString().trim(),
      lastName: (json['LNME'] ?? json['lname'])?.toString().trim(),
      isCompany: Number(json['ISCOM'] ?? json['iscom']) === 1 ? true : false,
      company: (json['COM'] ?? json['company'])?.toString().trim(),
      isHq:
        (json['ISHQ'] ?? json['ishq']) === 'Y' || json['ishq'] === 'true'
          ? true
          : false,
      nameNotCompany: (json['NNC'] ?? json['namenocom'])?.toString().trim(),
      lastNameNotCompany: (json['LNNC'] ?? json['lnamenocom'])
        ?.toString()
        .trim(),
      idNumber: (json['IDN'] ?? json['idnum'])?.toString().trim(),
      idBranch: (json['IDB'] ?? json['idbranch'])?.toString().trim(),
      phone: (json['PHO'] ?? json['phone'])?.toString().trim(),
      phone2: (json['PHO2'] ?? json['phone2'])?.toString().trim(),
      addressNumber: (json['ADNUM'] ?? json['adrnum'])?.toString().trim(),
      building: (json['BLD'] ?? json['build'])?.toString().trim(),
      floor: (json['FLR'] ?? json['floor'])?.toString().trim(),
      room: (json['ROM'] ?? json['room'])?.toString().trim(),
      moo: (json['MOO'] ?? json['moo'])?.toString().trim(),
      soi: (json['SOI'] ?? json['soi'])?.toString().trim(),
      road: (json['ROAD'] ?? json['road'])?.toString().trim(),
      zipcode: (json['ZIP'] ?? json['zipcode'])?.toString().trim(),
      subDistrict: (json['TBN'] ?? json['subdistrict'])?.toString().trim(),
      district: (json['DST'] ?? json['district'])?.toString().trim(),
      province: (json['PVN'] ?? json['province'])?.toString().trim(),
      lat:
        json['LAT'] || json['ulat']
          ? Number(json['LAT'] ?? json['ulat'])
          : undefined,
      lng:
        json['LNG'] || json['ulong']
          ? Number(json['LNG'] ?? json['ulong'])
          : undefined,
      typeOfAddress: (json['TOA'] ?? json['typeofadr'])?.toString().trim(),
      otherText: (json['OTT'] ?? json['othertxt'])?.toString().trim(),
      haveElevator: (json['HVEL'] ?? json['havele'])?.toString().trim(),
      canDeliveryCarType: (json['CAN'] ?? json['can'])?.toString().trim(),
      isDefaultShip: json['STT']
        ? json['STT'].toString().toLowerCase() === 'ship' ||
          json['STT'].toString().toLowerCase() === 'all'
          ? true
          : false
        : false,
      isDefaultBill: json['STT']
        ? json['STT'].toString().toLowerCase() === 'bill' ||
          json['STT'].toString().toLowerCase() === 'all'
          ? true
          : false
        : false,
      isPin: json['isPin'] === true ? true : false,
      originalStt: json['STT']?.toString().trim(),
      etaxLink: json['etaxlink']?.toString().trim(),
      etaxStatus: json['etaxstatus']?.toString().trim(),
    };
    return modelValue(model);
  }
}
