import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import TopBannerContext from '@/contexts/TopBannerContext';
import { Topbanner } from '@/models/VersionLoad.model';
import CompareLayout from './Compare';
import AddToCartLayout from './AddToCart';
import StoreLayout from './Store';
import { useAppDispatch } from '@/store/store';
import { clearProfileInfo } from '@/store/slices/profile/profileSlice';
import { useRouter } from 'next/router';
import { consentAuth, onlyAuth } from '@/services/client/authService';
import { useSelector } from 'react-redux';
import Loading from './handler/Loading';
import { isLoadingPageSelector, setLoading } from '@/store/slices/loadingSlice';
import { Toaster } from 'react-hot-toast';
import { refreshCartCount } from '@/store/slices/addToCartSlice';
import UseVoucher from './UseVoucher';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import {
  exceptLayoutList,
  requiredLoginPathList,
} from '@/constants/defaultValue.constant';
import ProductListGtmWrapper from '../common/ProductListGtmWrapper';
import CookiePolicy from './CookiePolicy';
import CookiePolicyContext from '@/contexts/CookiePolicyContext';
import Chat from './Chat';
import PageTypeContext from '@/contexts/PageTypeContext';
import { setIsNeedUpdateEmail } from '@/store/slices/profile/editInfoSlice';
import {
  customerInfoSelector,
  isSignOutSuccessSelector,
  setCustomerInfoAfterLogin,
  setCustomerInitial,
  setResetSignOut,
} from '@/store/slices/authenSlice';
import NotifyModal from './NotifyModal';

interface Props {
  children: ReactNode;
}

export default function Layout({ children }: Props): ReactElement {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [topBanner, setTopBanner] = useState<Topbanner | undefined>(undefined);
  const customerInfo = useSelector(customerInfoSelector);
  const customerIdStore = customerInfo?.custId;
  const isLoadingPage = useSelector(isLoadingPageSelector);
  const isSignOutSuccess = useSelector(isSignOutSuccessSelector);
  const [cookieOpen, setCookieOpen] = useState<boolean>(false);
  const [isShowChat, setIsShowChat] = useState<boolean>(true);
  const [isCurtain, setIsCurtain] = useState<boolean>(false);
  const [isSteel, setIsSteel] = useState<boolean>(false);
  const [isPaint, setIsPaint] = useState<boolean>(false);
  const [isFeedback, setIsFeedback] = useState<boolean>(false);
  const [isInstall, setIsInstall] = useState<boolean>(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // Save cookie transactionId
    if (urlParams.has(cookieKey.transactionId)) {
      const transactionId = urlParams.get(cookieKey.transactionId);
      if (transactionId) {
        Cookies.set(cookieKey.transactionId, transactionId);
      }
    }
    // Save cookie utmSource
    if (urlParams.has(cookieKey.utmSource)) {
      const utmSource = urlParams.get(cookieKey.utmSource);
      if (utmSource) {
        Cookies.set(cookieKey.utmSource, utmSource);
      }
    }
    // Save cookie utmMedium
    if (urlParams.has(cookieKey.utmMedium)) {
      const utmMedium = urlParams.get(cookieKey.utmMedium);
      if (utmMedium) {
        Cookies.set(cookieKey.utmMedium, utmMedium);
      }
    }
    // Save cookie utmCampaign
    if (urlParams.has(cookieKey.utmCampaign)) {
      const utmCampaign = urlParams.get(cookieKey.utmCampaign);
      if (utmCampaign) {
        Cookies.set(cookieKey.utmCampaign, utmCampaign);
      }
    }
    const localLang = Cookies.get(cookieKey.lang) ?? 'th';
    if (localLang != router.locale) {
      Cookies.set(cookieKey.lang, router.locale ?? 'th');
    }
    const handleStart = (url: any) => {
      if (
        !url.includes('/profile/') ||
        !router.pathname.includes('/profile/')
      ) {
        dispatch(setLoading(true));
      }
    };
    const handleComplete = () =>
      setTimeout(() => dispatch(setLoading(false)), 0);
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  useEffect(() => {
    if (
      (customerInfo?.cname && !customerInfo?.custId) ||
      (!customerInfo && Cookies.get(cookieKey.customerName))
    ) {
      onlyAuth().then((res) => {
        if (res.info) {
          dispatch(setCustomerInfoAfterLogin(res.info));
        } else {
          Cookies.remove(cookieKey.customerName);
          router.push('/signin');
        }
        // dispatch(refreshCartCount());
      });
    } else if (customerInfo === undefined) {
      dispatch(setCustomerInitial());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerInfo?.custId, customerInfo?.cname]);

  useEffect(() => {
    try {
      if (customerIdStore !== undefined) {
        (window as any).dataLayer.push(
          customerIdStore
            ? {
                event: 'page_metadata',
                userId: customerIdStore,
              }
            : undefined,
        );
        if (Cookies.get(cookieKey.afterSignin) === '1') {
          Cookies.remove(cookieKey.afterSignin);
          consentAuth().then((res) => {
            try {
              if ((window as any).dataLayer) {
                (window as any).dataLayer.push({
                  event: 'login',
                  userId: res.info?.custId,
                  ecommerce: {
                    method: 'The1',
                  },
                });
              }
            } catch (_) {}
            try {
              if ((window as any).appier) {
                (window as any).appier('event', 'login', {
                  env: process.env.NEXT_PUBLIC_NODE_ENV,
                });
                (window as any).appier('identify', {
                  user_id: res.info?.custId,
                  email: res.info?.email,
                  phoneNo: res.info?.mobile,
                  name: res.info?.cname,
                  consent_flag: res.consentMarketingStatus,
                  env: process.env.NEXT_PUBLIC_NODE_ENV,
                });
              }
            } catch (_) {}
          });
        }
      }
      if (!router.pathname.includes('/profile/')) {
        dispatch(clearProfileInfo());
      }
    } catch (_) {}
  }, [router.pathname, dispatch, customerIdStore]);

  useEffect(() => {
    if (customerIdStore !== null) {
      onlyAuth().then((res) => {
        dispatch(
          setIsNeedUpdateEmail(
            !res ||
              res.info === undefined ||
              (res.info?.email && res.info?.email !== '-')
              ? false
              : true,
          ),
        );
        dispatch(refreshCartCount());
      });
    }
  }, [dispatch, customerIdStore]);

  useEffect(() => {
    if (isSignOutSuccess) {
      dispatch(setResetSignOut());
      if (requiredLoginPathList.find((e) => router.pathname.includes(e))) {
        setTimeout(() => {
          router.push(`/${router.locale}`);
        }, 100);
      } else {
        dispatch(setLoading(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignOutSuccess]);

  return exceptLayoutList.includes(router.pathname.toLocaleLowerCase()) ? (
    <>{children}</>
  ) : (
    <PageTypeContext.Provider
      value={{
        isShow: isShowChat,
        setIsShow: setIsShowChat,
        isCurtain,
        setIsCurtain,
        isSteel,
        setIsSteel,
        isPaint,
        setIsPaint,
        isFeedback,
        setIsFeedback,
        isInstall,
        setIsInstall,
      }}
    >
      <TopBannerContext.Provider value={{ topBanner, setTopBanner }}>
        {isLoadingPage && <Loading />}
        <Toaster
          toastOptions={{
            style: {
              width: 'auto',
              fontSize: '.75rem',
            },
          }}
        />
        <StoreLayout />
        <ProductListGtmWrapper />
        <NotifyModal />
        <AddToCartLayout>
          <Header />
          <CookiePolicyContext.Provider
            value={{
              isOpen: cookieOpen,
              setIsOpen: (value: boolean) => {
                setCookieOpen(value);
              },
            }}
          >
            <main className="content-body">{children}</main>
            <CookiePolicy />
          </CookiePolicyContext.Provider>
          <CompareLayout />
          <Footer />
          <UseVoucher />
        </AddToCartLayout>
        <Chat />
      </TopBannerContext.Provider>
    </PageTypeContext.Provider>
  );
}
