import {
  addToCartQtySelector,
  closeModal,
  isOpenModalSelector,
  storeDataListSelector,
  saveStoreChanged,
  storeDataWithStockListSelector,
  storeQuery,
  isCheckStockStoreSelector,
  isLongtailSelector,
  isHomeFlagSelector,
  hasStockSelector,
  isLoadingSelector,
  currentStoreSelector,
  addToCartSkuSelector,
  callBackStoreCodeSelector,
  isServiceSelector,
  addToCartWithServiceSelector,
} from '@/store/slices/storeSlice';
import { useAppDispatch } from '@/store/store';
import Cookies from 'js-cookie';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import * as cookieKey from '@/constants/cookieKey.constant';
import { useSelector } from 'react-redux';
import CustomModal from '../../common/CustomModal';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import * as showDialog from '@/utils/showDialog';
import Spinner from '../../common/Spinner';
import { Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
// import TextMatching from '../../common/TextMatching';
import { callForChangeStore } from '@/services/client/storeService';
import {
  GoogleMap,
  Libraries,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import StoreMarker from './StoreMarker';
import InputStore from './InputStore';
import StoreProgressBar from './StoreProgressBar';
import Image from 'next/image';
import { defaultMapInit } from '@/constants/defaultValue.constant';
import { myCartResultSelector } from '@/store/slices/myCartSlice';
import HybridPopup from '@/components/homepage/HybridPopup';
import { addToCart } from '@/store/slices/addToCartSlice';
import { tranDate } from '@/utils/format';
import { shipmentResultSelector } from '@/store/slices/checkout/shipmentSlice';

const libraries: Libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

export default function StoreLayout(): ReactElement {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const storeDataList = useSelector(storeDataListSelector);
  const storeDataWithStockList = useSelector(storeDataWithStockListSelector);
  const addToCartQty = useSelector(addToCartQtySelector);
  const addToCartSku = useSelector(addToCartSkuSelector);
  const addToCartWithService = useSelector(addToCartWithServiceSelector);
  const isOpenModal = useSelector(isOpenModalSelector);
  const isCheckStockStore = useSelector(isCheckStockStoreSelector);
  const isLongtail = useSelector(isLongtailSelector);
  const isHomeFlag = useSelector(isHomeFlagSelector);
  const hasStock = useSelector(hasStockSelector);
  const handleClose = () => dispatch(closeModal());
  const isLoadingStore = useSelector(isLoadingSelector);
  const currentStore = useSelector(currentStoreSelector);
  const myCartResult = useSelector(myCartResultSelector);
  const mapScrollRef = useRef<HTMLDivElement>(null);
  const callBackStoreCode = useSelector(callBackStoreCodeSelector);
  const isService = useSelector(isServiceSelector);
  const shipmentResult = useSelector(shipmentResultSelector);

  const isNotShowList = isLongtail === true && isHomeFlag === false;
  const isOnlineOnly = hasStock === true && isHomeFlag === true;

  // const [searchStoreText, setSearchStoreText] = useState<string>('');
  const [changingStore, setChangingStore] = useState<string | undefined>(
    undefined,
  );
  const [getCurrenting, setGetCurrenting] = useState<boolean>(false);

  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY ?? '',
    libraries,
  });
  const [currentPosition, setCurrentPosition] = useState<
    { lat: number; lng: number } | undefined
  >(undefined);
  const [myPosition, setMyPosition] = useState<
    { lat: number; lng: number } | undefined
  >(undefined);
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const [activeStcode, setActiveStcode] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    if (
      currentStore === undefined &&
      Cookies.get(cookieKey.stcode) !== undefined
    ) {
      dispatch(
        storeQuery({
          position: undefined,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Cookies.get(cookieKey.stcode), myCartResult]);

  useEffect(() => {
    const handleStart = () => {
      dispatch(closeModal());
    };

    router.events.on('routeChangeStart', handleStart);

    return () => {
      router.events.off('routeChangeStart', handleStart);
    };
  });

  useEffect(() => {
    if (isOpenModal) {
      setActiveStcode(undefined);
      if (myPosition === undefined && !isNotShowList) {
        handleClickFindNearest();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal]);

  const handleClickFindNearest = (isSetPosition?: boolean) => {
    setGetCurrenting(true);
    navigator.geolocation.getCurrentPosition(
      (position?: GeolocationPosition | null) => {
        if (position?.coords?.latitude && position?.coords?.longitude) {
          if (
            currentPosition?.lat !== undefined &&
            currentPosition?.lng !== undefined &&
            isSetPosition === true
          ) {
            setCurrentPosition({
              lat: position?.coords?.latitude,
              lng: position?.coords?.longitude,
            });
          }
          setMyPosition({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          });
        }
        dispatch(
          storeQuery({
            position:
              position?.coords.latitude !== undefined &&
              position?.coords.longitude !== undefined
                ? {
                    lat: position?.coords.latitude,
                    lng: position?.coords.longitude,
                  }
                : undefined,
          }),
        ).then(() => {
          setGetCurrenting(false);
        });
      },
      () => {
        dispatch(
          storeQuery({
            position: undefined,
          }),
        ).then(() => {
          setGetCurrenting(false);
        });
        if (isSetPosition === true) {
          showDialog.showErrorWithOkButton({
            text: t('unfortunatelywewereunabletolocate'),
          });
        }
      },
    );
  };
  const handleChangeStore = async ({ stcode }: { stcode: string }) => {
    if (callBackStoreCode !== undefined) {
      callBackStoreCode(stcode);
      handleClose();
    } else {
      if (
        (myCartResult?.isChristmas === true &&
          (myCartResult.christmasStcode?.length ?? 0) > 0 &&
          !myCartResult.christmasStcode?.includes(`60${stcode}`)) ||
        (shipmentResult?.isChristmas === true &&
          (shipmentResult?.christmasStcode?.length ?? 0) > 0 &&
          !shipmentResult?.christmasStcode?.includes(`60${stcode}`))
      ) {
        await showDialog.showMessageWithButton({
          image: '/images/svg/christmas/christmaserror.svg',
          text: `<div><div class="font-semibold leading-4">${t(
            'addtocart:branchselectednotinservice',
          )}<br/>${t('addtocart:pleasechangebranchorcontactstaff')}</div>
          <div class="bg-grayLight2 text-grayDark p-2 rounded-lg mt-2 font-semibold">${t(
            'addtocart:locationofferchristmastree',
          )}: ${(
            myCartResult?.locationChristmasService ??
            shipmentResult?.locationChristmasService
          )?.join(', ')}</div></div>`,
          primaryButton: {
            name: t('addtocart:changebranch'),
            backgroundColor: '#000000',
            callBack: () => {},
          },
          secondaryButton: {
            name: '<div class="text-white"><i class="bi bi-line text-xxs"></i> Chat & Shop</div>',
            backgroundColor: '#00B901',
            callBack: () => {},
          },
        });
      } else {

        showDialog.showMessageWithButton({
          text: t('changebranchwarning'),
          primaryButton: {
            name: t('ok'),
            callBack: async () => {
              setChangingStore(stcode);
              await callForChangeStore(stcode);
              dispatch(
                saveStoreChanged({
                  stcode,
                  isHide: addToCartSku && addToCartQty ? true : false,
                }),
              );
              router
                .replace(
                  `/${router.locale}${
                    router.asPath.includes('stcode=')
                      ? router.asPath.replace(
                          /(stcode=)[^&]+/,
                          `stcode=${Cookies.get(cookieKey.stcode)}`,
                        )
                      : `${router.asPath}${
                          router.asPath.includes('?') ? '&' : '?'
                        }stcode=${Cookies.get(cookieKey.stcode)}`
                  }`,
                )
                .then(() => {
                  setChangingStore(undefined);
                  if (addToCartSku && addToCartQty) {
                    dispatch(
                      addToCart({
                        sku: addToCartSku,
                        qty: addToCartQty,
                        withService: addToCartWithService,
                      }),
                    );
                  }
                });
              handleClose();
            },
          },
          secondaryButton: {
            name: t('cancel'),
          },
        });
      }
    }
  };

  const handleOnLoad = (map: google.maps.Map) => {
    setMapRef(map);
  };

  const handleSetCurrentPosition = () => {
    if (mapRef && isDrag) {
      const center = mapRef.getCenter()?.toJSON();
      if (center?.lat && center?.lng) {
        // To be handle.
      }
    }
    setIsDrag(false);
  };

  const handleSelectedStore = (stcode?: string) => {
    if (stcode) {
      const store = (storeDataWithStockListMap ?? storeDataList ?? []).find(
        (s) => s.code === stcode,
      );
      if (store) {
        setActiveStcode(store.code);
        setCurrentPosition({
          lat: store.latitude,
          lng: store.longitude,
        });

        // setTimeout(() => {
        //   mapScrollRef.current?.scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'start',
        //     inline: 'nearest',
        //   });
        // }, 100);
      }
    }
  };

  // ไม่หา distance ใหม่เพราะว่ายังไง storeDataList ก็ต้องมีสาขา Cover อยู่แล้ว
  const storeDataWithStockListMap = storeDataWithStockList?.map((store) => {
    const findStoreData = storeDataList?.find(
      (storeData) => storeData.code === store.code,
    );
    return { ...store, distance: findStoreData?.distance };
  });

  return (
    <>
      {/* HybridPopup */}
      <HybridPopup isChanging={changingStore !== undefined} />
      <CustomModal
        open={isOpenModal}
        onClose={handleClose}
        showCloseButton
        showScrollBar
      >
        <div className="min-w-[20vw] flex flex-col md:flex-row">
          <div
            className={`flex flex-col ${
              isNotShowList ||
              ((storeDataWithStockListMap ?? []).length <= 0 &&
                isCheckStockStore === true &&
                isLoadingStore !== true)
                ? 'h-auto'
                : 'h-[80vh] sm:h-[92vh]'
            } w-full`}
          >
            <div className="px-2 h-[8.5rem] sm:h-[7.5rem]">
              <div className="text-xl text-primary">{t('selectstore')}</div>
              <div className="leading-3">{t('apply')}</div>
              <div className="mt-1 border-b w-full z-20">
                {isLoaded && (
                  <InputStore
                    setMyPosition={(position: { lat: number; lng: number }) => {
                      setMyPosition(position);
                      setCurrentPosition(position);
                      dispatch(
                        storeQuery({
                          position,
                        }),
                      );
                    }}
                  />
                )}
                <div className="text-sm flex items-center my-0.5 h-5">
                  {(storeDataWithStockListMap ?? []).length === 0 &&
                  (storeDataList ?? []).length === 0 ? (
                    <Skeleton variant="text" width="50%" />
                  ) : (
                    <>
                      <div>{t('or')}</div>
                      {getCurrenting ? (
                        <div className="ms-2 mt-1.5">
                          <Spinner color="primary" />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            handleClickFindNearest(true);
                          }}
                          className="flex items-center ms-1 cursor-pointer select-none text-secondaryPurple hover:text-primary"
                        >
                          <i className="bi bi-geo-alt leading-3"></i>
                          <div className="leading-3 text-xs sm:text-sm">
                            {t('clicktofindthestorenearest')}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="mt-1 leading-3 whitespace-normal sm:whitespace-nowrap">
                <span className="text-xs text-grayDark">{t('allstores')}</span>
                <span className="text-xs ps-1 text-redPrice">*</span>
                <span className="text-xs">{t('distancesareapproximated')}</span>
              </div>
            </div>
            {isNotShowList ? (
              <div className="px-1">
                <div className="rounded p-2 flex flex-col sm:flex-row items-center justify-center bg-grayLight1">
                  <div className="me-2 w-10">
                    <Image
                      src="/images/svg/cart/warning_icon.svg"
                      alt="warning"
                      width={70}
                      height={70}
                    />
                  </div>
                  <div className="text-sm sm:text-lg whitespace-normal sm:whitespace-nowrap leading-3 sm:leading-5 text-center mt-1.5 sm:mt-0">
                    {t('specialordernotavailableinstore')}
                  </div>
                </div>
              </div>
            ) : (storeDataWithStockListMap ?? []).length <= 0 &&
              isCheckStockStore === true &&
              isLoadingStore !== true ? (
              <div className="px-1">
                <div className="rounded p-2 flex flex-col sm:flex-row items-center justify-center bg-grayLight1">
                  {isService === true ? (
                    <div className="text-sm sm:text-lg whitespace-normal sm:whitespace-nowrap break-all leading-3 sm:leading-5 text-center flex flex-col">
                      <div className="mx-auto mb-2">
                        <Image
                          src="/images/svg/cart/warning_icon.svg"
                          alt="warning"
                          width={70}
                          height={70}
                        />
                      </div>
                      <div>{t('youcancheckthecoverageareaat')}</div>
                      <div>{t('shippingpagebyspecifyyouraddress')}</div>
                    </div>
                  ) : (
                    <>
                      <div className="me-2 w-10">
                        <Image
                          src="/images/svg/cart/warning_icon.svg"
                          alt="warning"
                          width={70}
                          height={70}
                        />
                      </div>
                      <div className="text-sm sm:text-lg whitespace-normal sm:whitespace-nowrap leading-3 sm:leading-5 text-center mt-1.5 sm:mt-0">
                        {t('temporarilyoutofstock')}
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                {isOnlineOnly === true && (
                  <div className="px-1 mb-1">
                    <div className="rounded p-2 flex flex-col sm:flex-row items-center justify-center bg-grayLight1">
                      <div className="me-2 w-10">
                        <Image
                          src="/images/svg/cart/warning_icon.svg"
                          alt="warning"
                          width={70}
                          height={70}
                        />
                      </div>
                      <div className="text-sm sm:text-lg whitespace-normal sm:whitespace-nowrap leading-3 sm:leading-5 text-center mt-1.5 sm:mt-0">
                        {t('currentlyoutofstockinstore')}
                      </div>
                    </div>
                  </div>
                )}
                <div className="overflow-y-scroll flex-grow px-0.5 show-scrollbar">
                  {(storeDataWithStockListMap ?? storeDataList ?? []).length ===
                  0
                    ? [...Array(8)].map((_, index) => (
                        <div key={index} className="py-1.5 border-b flex">
                          <div className="w-40">
                            <div className="h-6">
                              <Skeleton variant="text" width="40%" />
                            </div>
                            <div className="h-6 flex items-center">
                              <Skeleton variant="text" width="90%" />
                            </div>
                            <div className="h-6 flex items-center">
                              <Skeleton variant="text" width="70%" />
                            </div>
                          </div>
                          <div className="ms-auto text-xs flex flex-col">
                            <div className="flex items-center">
                              <div>
                                <Skeleton
                                  variant="rounded"
                                  width={98}
                                  height="1.75rem"
                                />
                              </div>
                              <div className="ms-1">
                                <Skeleton
                                  variant="rounded"
                                  width={98}
                                  height="1.75rem"
                                />
                              </div>
                            </div>
                            <div className="mt-1 ms-auto">
                              <Skeleton variant="text" width={100} />
                            </div>
                          </div>
                        </div>
                      ))
                    : [...(storeDataWithStockListMap ?? storeDataList ?? [])]
                        .sort((a, b) => {
                          // if (searchStoreText.trim().length > 0) {
                          //   const aIndex = (
                          //     i18n.language === 'th' ? a.name : a.nameEN
                          //   )
                          //     .toLowerCase()
                          //     .indexOf(searchStoreText.toLowerCase());
                          //   const bIndex = (
                          //     i18n.language === 'th' ? b.name : b.nameEN
                          //   )
                          //     .toLowerCase()
                          //     .indexOf(searchStoreText.toLowerCase());
                          //   if (aIndex !== -1 && bIndex !== -1) {
                          //     return aIndex - bIndex;
                          //   } else if (aIndex !== -1) {
                          //     return -1;
                          //   } else if (bIndex !== -1) {
                          //     return 1;
                          //   }
                          // }
                          // if (storeDataWithStockListMap !== undefined) {
                          //   return (b.stock ?? 0) - (a.stock ?? 0);
                          // } else {
                          return (a.distance ?? 999) - (b.distance ?? 999);
                          // }
                        })
                        .map((store, index) => (
                          <div
                            key={index}
                            className={`py-1.5 border-b flex px-1.5 ${
                              isCheckStockStore === true
                                ? `select-none cursor-pointer ${
                                    activeStcode === store.code
                                      ? 'bg-primary'
                                      : ''
                                  }`
                                : ''
                            }`}
                            onClick={(e: any) => {
                              e.preventDefault();
                              if (
                                isCheckStockStore === true &&
                                activeStcode !== store.code
                              ) {
                                if (!changingStore) {
                                  handleSelectedStore(store.code);
                                }
                              }
                            }}
                          >
                            <div>
                              <div
                                className={`font-semibold whitespace-nowrap ${
                                  isCheckStockStore === true &&
                                  activeStcode === store.code
                                    ? 'text-white'
                                    : ''
                                }`}
                              >
                                {/* <TextMatching
                            value= */}
                                {i18n.language === 'th'
                                  ? store.name
                                  : store.nameEN}
                                {/* matcher={searchStoreText}
                          /> */}
                              </div>
                              {storeDataWithStockListMap !== undefined && (
                                <div className="text-xs flex items-center">
                                  {(store.stock ?? 0) <=
                                  (addToCartQty ? addToCartQty - 1 : 0) ? (
                                    <>
                                      <div className="w-16 pe-1.5 h-2">
                                        <StoreProgressBar
                                          backgroundColor={
                                            isCheckStockStore === true &&
                                            activeStcode === store.code
                                              ? '#B8292F'
                                              : undefined
                                          }
                                          color={
                                            isCheckStockStore === true &&
                                            activeStcode === store.code
                                              ? '#FFFFFF'
                                              : ''
                                          }
                                        />
                                      </div>
                                      <div
                                        className={`${
                                          isCheckStockStore === true &&
                                          activeStcode === store.code
                                            ? 'text-white'
                                            : 'text-redPrice'
                                        }`}
                                      >
                                        {t('notenoughproductsasordered')}
                                      </div>
                                    </>
                                  ) : (store.stock ?? 0) >=
                                      (store.averageSalePerDay ?? 0) &&
                                    (store.stock ?? 0) > 2 ? (
                                    <>
                                      <div className="w-16 pe-1.5 h-2">
                                        <StoreProgressBar
                                          backgroundColor={
                                            isCheckStockStore === true &&
                                            activeStcode === store.code
                                              ? '#B8292F'
                                              : undefined
                                          }
                                          color={
                                            isCheckStockStore === true &&
                                            activeStcode === store.code
                                              ? '#FFFFFF'
                                              : '#4DAD18'
                                          }
                                          value={80}
                                        />
                                      </div>
                                      <div
                                        className={`${
                                          isCheckStockStore === true &&
                                          activeStcode === store.code
                                            ? 'text-white'
                                            : 'text-green'
                                        }`}
                                      >
                                        {t('instock')}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="w-16 pe-1.5 h-2">
                                        <StoreProgressBar
                                          backgroundColor={
                                            isCheckStockStore === true &&
                                            activeStcode === store.code
                                              ? '#B8292F'
                                              : undefined
                                          }
                                          color={
                                            isCheckStockStore === true &&
                                            activeStcode === store.code
                                              ? '#FFFFFF'
                                              : '#EAAD21'
                                          }
                                          value={30}
                                        />
                                      </div>
                                      <div
                                        className={`${
                                          isCheckStockStore === true &&
                                          activeStcode === store.code
                                            ? 'text-white'
                                            : 'text-yellow'
                                        }`}
                                      >
                                        {t('limitedstock')}
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                              {isCheckStockStore !== true && (
                                <>
                                  <div className="leading-4 whitespace-normal xl:whitespace-nowrap">
                                    <span>{t('operatinghourssince')}</span>
                                    <span className="ps-1">
                                      {store.openTime} - {store.closeTime}{' '}
                                      {t('n')}
                                    </span>
                                  </div>
                                  <div className="leading-5 whitespace-normal sm:whitespace-nowrap">
                                    <span>{t('phonenumber')}</span>
                                    <span className="ps-1">
                                      {store.phoneNumber}
                                    </span>
                                  </div>
                                </>
                              )}
                              {store.flag === 0 && (
                                <div className="leading-3 text-sm text-primary font-semibold">
                                  {t(
                                    'availableforsalethroughonlinechannelsfrom',
                                  )}{' '}
                                  {tranDate({
                                    dateString: store.newStoreOpenDate,
                                    isShowYear: true,
                                  })}
                                </div>
                              )}
                            </div>
                            <div className="ms-auto text-xs text-grayDark text-right">
                              <div className="flex flex-col sm:flex-row items-center">
                                {isCheckStockStore !== true && (
                                  <div>
                                    <Button
                                      size="small"
                                      className="w-full h-7 bg-black hover:bg-grayDark text-white rounded min-w-[98px]"
                                      sx={{
                                        width: '100%',
                                        minWidth: 98,
                                        backgroundColor: '#000000',
                                        color: 'white',
                                        height: '1.75rem',
                                        borderRadius: 0,
                                        '&:hover': {
                                          backgroundColor: '#666',
                                        },
                                        '&:disabled': {
                                          backgroundColor: '#B4B4B4 !important',
                                          color: '#EBEBEB !important',
                                          border:
                                            '1px solid #B4B4B4 !important',
                                        },
                                      }}
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        if (!changingStore) {
                                          handleSelectedStore(store.code);
                                        }
                                      }}
                                    >
                                      {t('showonmap')}
                                    </Button>
                                  </div>
                                )}
                                {((Cookies.get(cookieKey.stcode) !==
                                  store.code &&
                                  (store.stock ?? 0) >=
                                    (addToCartQty ? addToCartQty : 0) &&
                                  isCheckStockStore !== true) ||
                                  callBackStoreCode !== undefined) && (
                                  <div className="ms-0 sm:ms-1 mt-0.5 sm:mt-0">
                                    <Button
                                      disabled={
                                        changingStore !== undefined ||
                                        store.flag === 0
                                      }
                                      size="small"
                                      className="w-full h-7 bg-primary hover:bg-secondaryPurple text-white rounded min-w-[98px]"
                                      sx={{
                                        width: '100%',
                                        minWidth: 98,
                                        backgroundColor: '#B8292F',
                                        color: 'white',
                                        height: '1.75rem',
                                        borderRadius: 0,
                                        '&:hover': {
                                          backgroundColor: '#B8292FD0',
                                        },
                                        '&:disabled': {
                                          backgroundColor: '#B4B4B4 !important',
                                          color: '#EBEBEB !important',
                                          border:
                                            '1px solid #B4B4B4 !important',
                                        },
                                      }}
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        if (
                                          !changingStore &&
                                          store.flag !== 0
                                        ) {
                                          handleChangeStore({
                                            stcode: store.code,
                                          });
                                        }
                                      }}
                                    >
                                      {changingStore === store.code ? (
                                        <div className="pt-2">
                                          <Spinner borderSize={2} size={28} />
                                        </div>
                                      ) : (
                                        t('selectstore')
                                      )}
                                    </Button>
                                  </div>
                                )}
                              </div>
                              {store.distance && (
                                <div
                                  className={`mt-1 ${
                                    isCheckStockStore === true &&
                                    activeStcode === store.code
                                      ? 'text-white'
                                      : ''
                                  }`}
                                >
                                  {t('withinaradius')}
                                  <span className="px-1">
                                    {store.distance?.toFixed(2)}
                                  </span>
                                  {t('km')}
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                </div>
              </>
            )}
          </div>
          {process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY && !isNotShowList ? (
            <>
              {loadError ? (
                <div className="h-full flex items-center justify-center">
                  <div>Error loading maps</div>
                </div>
              ) : !isLoaded ||
                currentPosition?.lat === undefined ||
                currentPosition?.lng === undefined ? (
                <div className="h-full flex items-center justify-center">
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                </div>
              ) : (
                <div
                  className="p-2 w-full md:w-[40vw] h-[80vh] sm:h-[92vh]"
                  ref={mapScrollRef}
                >
                  <GoogleMap
                    onLoad={handleOnLoad}
                    mapContainerStyle={mapContainerStyle}
                    zoom={defaultMapInit.zoom}
                    options={{
                      disableDefaultUI: true,
                      zoomControl: true,
                    }}
                    center={currentPosition}
                    onDragStart={() => setIsDrag(true)}
                    onIdle={handleSetCurrentPosition}
                    mapContainerClassName="w-full"
                  >
                    {myPosition?.lat !== undefined &&
                      myPosition?.lng !== undefined && (
                        <Marker
                          zIndex={9999}
                          position={{
                            lat: myPosition?.lat,
                            lng: myPosition?.lng,
                          }}
                          icon={{
                            url: '/images/icons/my_location_pin.png',
                            scaledSize: new window.google.maps.Size(55, 60),
                          }}
                        ></Marker>
                      )}
                    {(storeDataWithStockListMap ?? storeDataList ?? []).map(
                      (store) => (
                        <StoreMarker
                          key={store.code}
                          store={store}
                          isActive={activeStcode === store.code}
                          onClick={() => {
                            handleSelectedStore(store.code);
                          }}
                          onClose={() => {
                            setActiveStcode(undefined);
                          }}
                          onSelectedStore={() => {
                            handleChangeStore({ stcode: store.code });
                          }}
                        />
                      ),
                    )}
                  </GoogleMap>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </CustomModal>
    </>
  );
}
