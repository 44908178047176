import { Banner } from '@/models/HomePage.model';
import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import AppLink from '@/components/Layout/handler/AppLink';
import ImageWithFallback from '../../Layout/handler/ImageWithFallback';
import MenuClass from '@/styles/menu.module.css';
import { useTranslation } from 'next-i18next';
import AllCatContext from '@/contexts/AllCatContext';
import HeroBanner from './HeroBanner';
import { AllCat } from '@/models/AllCat.model';

interface HeroMenuProps {
  banners?: Banner[];
  noSkeleton?: boolean;
  // eslint-disable-next-line no-unused-vars
  mouseLeave?: (isForce?: boolean) => void;
}

export default function HeroMenu({
  banners,
  noSkeleton,
  mouseLeave,
}: HeroMenuProps) {
  const { t, i18n } = useTranslation();
  const [currentNode, setCurrentNode] = useState<string | null>(null);

  const handleMouseEnter = (node: string) => {
    setCurrentNode(node);
  };

  const handleMouseLeave = () => {
    if (currentNode != null) {
      setCurrentNode(null);
      if (mouseLeave) {
        mouseLeave(true);
      }
    }
  };

  return (
    <AllCatContext.Consumer>
      {(allcats) => {
        if (!allcats) {
          return;
        }
        return (
          <Grid
            container
            className="justify-center"
            onMouseLeave={handleMouseLeave}
          >
            <Grid
              item
              xs={3}
              className={`hidden lg:flex bg-white ${
                banners ? '' : 'shadow-md'
              }`}
              sx={{ overflow: 'scroll' }}
            >
              <Box className="w-full h-0 pt-0.5">
                {allcats.map((e: AllCat) => (
                  <Box
                    key={e.node}
                    className={MenuClass.mainCategoryItem}
                    onMouseEnter={() => handleMouseEnter(e.node)}
                  >
                    <AppLink
                      href={e.slugname && `/category/${e.slugname}`}
                      className="flex px-2 py-0.5"
                    >
                      <Box className="self-center">
                        <ImageWithFallback
                          src={`/images/logo/menu/${e.node}.png`}
                          alt={`cat-${e.node}`}
                          width={24}
                          height={24}
                        />
                      </Box>
                      <Box className="self-center ps-1 text-sm font-semibold">
                        {i18n.language === 'th'
                          ? e.catname
                          : e.catnameEN ?? e.catname}
                      </Box>
                    </AppLink>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} lg={9} className="relative">
              {currentNode && (
                <Box
                  className={`w-full h-full absolute pl-4 pt-2 pb-2 ${
                    banners ? '' : 'shadow-md'
                  }`}
                  sx={{
                    zIndex: 11,
                    overflowY: 'scroll',
                    background: '#f1f1f1',
                  }}
                >
                  <Grid container>
                    {allcats?.find((e) => e.node == currentNode) &&
                      (
                        allcats?.find((e) => e.node == currentNode)?.items || []
                      ).map((e: AllCat) => (
                        <Grid key={e.node} item xs={4}>
                          <Box className={MenuClass.lv2CategoryBox}>
                            <AppLink
                              href={e.slugname && `/category/${e.slugname}`}
                              className={MenuClass.lv2CategoryText}
                            >
                              {i18n.language === 'th'
                                ? e.catname
                                : e.catnameEN ?? e.catname}
                            </AppLink>
                            {(e.items || [])
                              .slice(0, 7)
                              .map((r: AllCat, i: number) => (
                                <AppLink
                                  key={`allcat-${i}`}
                                  className={MenuClass.lv3CategoryText}
                                  href={e.slugname && `/category/${r.slugname}`}
                                >
                                  {i18n.language === 'th'
                                    ? r.catname
                                    : r.catnameEN ?? r.catname}
                                </AppLink>
                              ))}
                            {(e.items || []).length > 7 && (
                              <AppLink
                                className={MenuClass.seeMore}
                                href={e.slugname && `/category/${e.slugname}`}
                              >
                                {t('viewall')}
                              </AppLink>
                            )}
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              )}
              <HeroBanner
                name="hero"
                banners={banners}
                desktopRatio={{
                  width: 1170,
                  height: 538,
                  ratio: (1170 / 538) * (12 / 9),
                }}
                mobileRatio={{
                  width: 1280,
                  height: 720,
                  ratio: 1280 / 720,
                }}
                noSkeleton={noSkeleton}
              />
            </Grid>
          </Grid>
        );
      }}
    </AllCatContext.Consumer>
  );
}
