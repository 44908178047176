import {
  RequestProductRequest,
  RequestProductResponse,
  RequestProductResponseConverter,
} from '@/models/RequestProduct.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const requestProduct = async ({
  recapchaToken,
  sendData,
}: {
  recapchaToken: string;
  sendData: RequestProductRequest;
}): Promise<RequestProductResponse | null | undefined> => {
  const form = new FormData();
  form.append('pdata', JSON.stringify(sendData));
  if (sendData.images && sendData.images.length > 0) {
    const imageData = sendData.images;
    for (let i = 0; i < imageData.length; i++) {
      form.append('file-' + i, imageData[i]);
    }
  }
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/productrequest',
      headers: { recaptchatoken: recapchaToken },
      form: form,
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: RequestProductResponse | null | undefined = data;

  if (data && data.info) {
    try {
      response = RequestProductResponseConverter.fromJson(data.info);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
