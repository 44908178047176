import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import AppLink from '../../handler/AppLink';
import { useTranslation } from 'next-i18next';

export default function PromotionHover() {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  return (
    <Tooltip
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      leaveTouchDelay={0}
      placement="bottom"
      leaveDelay={150}
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
            backgroundColor: 'white',
            color: 'black',
            width: '100%',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
          },
          style: {
            marginTop: 0,
          },
        },
      }}
      title={
        <div className="shadow-md bg-transparent w-full max-w-[360]">
          <nav aria-label="main mailbox folders">
            <List>
              <ListItem disablePadding>
                <AppLink
                  className="w-full h-full"
                  href="/page/credit-card-promotions"
                >
                  <ListItemButton>
                    <ListItemText
                      disableTypography
                      primary={t('creditcardpromotions')}
                    />
                  </ListItemButton>
                </AppLink>
              </ListItem>
              <ListItem disablePadding>
                <AppLink
                  className="w-full h-full"
                  href="/page/promotion-monthly"
                >
                  <ListItemButton>
                    <ListItemText
                      disableTypography
                      primary={t('allpromotions')}
                    />
                  </ListItemButton>
                </AppLink>
              </ListItem>
            </List>
          </nav>
        </div>
      }
    >
      <div className="cursor-pointer select-none">{t('hotpromotions')}</div>
    </Tooltip>
  );
}
