import {
  AuthenInfoResponseModel,
  AuthenInfoResponseModelConverter,
} from '@/models/AuthenInfo.model';
import {
  SendLoginRequestModel,
  SendLoginResponseModel,
  SendLoginResponseModelConverter,
} from '@/models/SendLogin.model';
import {
  RequestOtpResponseModel,
  RequestOtpResponsConvert,
} from '@/models/RequestOtp.model';
import {
  RegisterInitiateResponseModel,
  RegisterInitiateResponseModelConvert,
} from '@/models/RegisterInitiate.model';
import {
  getData,
  postDataFromAPI,
  postServerDataFromAPI,
} from '@/utils/httpClient';
import { isMobile } from '@/utils/validate';
import { SendNewLoginRequestModel } from '@/models/SendNewLogin.model';
import {
  SendRegisterRequestModel,
  SendRegisterResponseModel,
  SendRegisterResponseModelConverter,
} from '@/models/SendRegister.model';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '@/models/Authen.model';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import {
  SetupPinResponseModel,
  SetupPinResponseModelConverter,
} from '@/models/SetupPin.model';
import {
  FacebookLoginResponseModel,
  FaceebookLoginResponseModelConverter,
} from '@/models/FacebookLogin.model';
import {
  UsernameLoginResponseModel,
  UsernameLoginResponseModelConverter,
} from '@/models/UsernameLogin.model';
import { t1Timeout } from '@/constants/defaultValue.constant';
import Cookies from 'js-cookie';

export const consentAuth = async (
  twdToken?: string,
  refreshToken?: string,
  isFromServer?: boolean,
): Promise<{
  consentMarketingStatus: boolean;
  cookies?: CookieData[];
  info?: CustomerInfo;
}> => {
  const token = twdToken ?? (await ServerCookies.get(cookieKey.twdToken));
  const rToken =
    refreshToken ?? (await ServerCookies.get(cookieKey.twdRefreshToken));
  if (!token) {
    return {
      info: undefined,
      cookies: undefined,
      consentMarketingStatus: false,
    };
  }
  const getData = isFromServer
    ? await postServerDataFromAPI({
        path: '/consentauth',
        headers: {
          Authorization: `Bearer ${token}`,
          refreshtoken: rToken,
        },
      })
    : await postDataFromAPI({
        path: '/consentauth',
        headers: {
          Authorization: `Bearer ${token}`,
          refreshtoken: rToken,
        },
      });
  const data =
    getData.status == 200
      ? getData.data
        ? getData.data
        : undefined
      : undefined;

  if (data?.cookies && !isFromServer) {
    await saveClientCookieFromResponse(data?.cookies);
  }
  return {
    info: data?.info ? CustomerInfoConverter.fromJson(data?.info) : undefined,
    cookies: data?.cookies
      ? data?.cookies.map((e: any) => CookieDataConverter.fromJson(e))
      : undefined,
    consentMarketingStatus: data?.consent_marketing_status === true,
  };
};

export const onlyAuth = async (
  twdToken?: string,
  refreshToken?: string,
  isFromServer?: boolean,
): Promise<{
  cookies?: CookieData[];
  info?: CustomerInfo;
}> => {
  const token = twdToken;
  const rToken = refreshToken;
  if (!token && isFromServer) {
    return {
      info: undefined,
      cookies: undefined,
    };
  }
  const getAuthData = isFromServer
    ? await postServerDataFromAPI({
        path: '/onlyauth',
        headers: {
          Authorization: `Bearer ${token}`,
          refreshtoken: rToken,
        },
      })
    : await getData({ path: '/api/v2/auth/onlyauth' });

  const data =
    getAuthData.status == 200
      ? getAuthData.data
        ? getAuthData.data
        : undefined
      : undefined;

  if (data?.cookies && !isFromServer) {
    await saveClientCookieFromResponse(data?.cookies);
  }

  return {
    info: data?.info
      ? isFromServer
        ? CustomerInfoConverter.fromJson(data?.info)
        : data?.info
      : undefined,
    cookies: data?.cookies
      ? data?.cookies.map((e: any) => CookieDataConverter.fromJson(e))
      : undefined,
  };
};

export const authenInfo = async ({
  username,
}: {
  username: string;
}): Promise<AuthenInfoResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/checkusernameauth',
      body: {
        username: {
          value: username,
        },
        logintype: isMobile(username) ? 'mobile' : 'email',
      },
      timeout: t1Timeout,
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: AuthenInfoResponseModel | null | undefined = apiData;
  if (apiData) {
    try {
      response = AuthenInfoResponseModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const sendLogin = async (
  sendData: SendLoginRequestModel,
  lang?: string,
): Promise<SendLoginResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/sendlogin',
      body: { ...sendData, lang },
      timeout: t1Timeout,
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: SendLoginResponseModel | null | undefined = apiData;
  try {
    response = SendLoginResponseModelConverter.fromJson(apiData);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const requestOtp = async ({
  username,
  type,
}: {
  username: string;
  type: string;
}): Promise<RequestOtpResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/requestotp',
      body: {
        on: {
          value: username,
        },
        type,
      },
      timeout: t1Timeout,
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: RequestOtpResponseModel | null | undefined = apiData;
  if (apiData) {
    try {
      response = RequestOtpResponsConvert.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const sendNewLogin = async (
  sendData: SendNewLoginRequestModel,
  the1AccessToken?: string,
  lang?: string,
): Promise<SendLoginResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/sendnewlogin',
      headers: { tonetoken: the1AccessToken },
      body: { ...sendData, lang },
      timeout: t1Timeout,
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: SendLoginResponseModel | null | undefined = apiData;
  try {
    response = SendLoginResponseModelConverter.fromJson(apiData);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const authenRegisterInitiate = async ({
  username,
  type,
}: {
  username: string;
  type: string;
}): Promise<RegisterInitiateResponseModel | null | undefined> => {
  const authenApiData = await authenInfo({ username });
  if (authenApiData?.error) {
    let apiData = null;
    if (authenApiData.error.name === 'user_account_not_found') {
      const [getDataAPI] = await Promise.all([
        postDataFromAPI({
          path: '/registration_initiate',
          body: {
            on: {
              country: '66',
              value: username,
            },
            type,
          },
          timeout: t1Timeout,
        }),
      ]);
      apiData =
        getDataAPI.status == 200
          ? getDataAPI.data
            ? getDataAPI.data
            : null
          : null;
    }
    let response: RegisterInitiateResponseModel | null | undefined = apiData;
    if (apiData) {
      try {
        response = RegisterInitiateResponseModelConvert.fromJson(apiData);
      } catch (error) {
        console.log(error);
      }
    }
    if (response) {
      response.mobile = username;
      response.type = type;
    }
    return response;
  } else {
    let response: RegisterInitiateResponseModel | null | undefined =
      authenApiData;
    if (authenApiData) {
      try {
        response = RegisterInitiateResponseModelConvert.fromJson(authenApiData);
      } catch (error) {
        console.log(error);
      }
    }
    return response;
  }
};

export const registerInitiate = async ({
  username,
  type,
}: {
  username: string;
  type: string;
}): Promise<RegisterInitiateResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/registration_initiate',
      body: {
        on: {
          country: '66',
          value: username,
        },
        type,
      },
      timeout: t1Timeout,
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;

  let response: RegisterInitiateResponseModel | null | undefined = apiData;
  if (apiData) {
    try {
      response = RegisterInitiateResponseModelConvert.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const otpVerify = async ({
  requestId,
  input,
  lang,
}: {
  requestId: string;
  input: string;
  lang?: string;
}): Promise<RegisterInitiateResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/otp_verify',
      lang,
      body: {
        requestID: requestId,
        code: input,
      },
      timeout: t1Timeout,
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: RegisterInitiateResponseModel | null | undefined = apiData;
  if (apiData) {
    try {
      response = RegisterInitiateResponseModelConvert.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const sendRegister = async (
  sendData: SendRegisterRequestModel,
  lang?: string,
): Promise<SendRegisterResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/sendregister',
      body: {
        ...sendData,
        lang,
        utm_source: Cookies.get(cookieKey.utmSource),
        utm_medium: Cookies.get(cookieKey.utmMedium),
        utm_campaign: Cookies.get(cookieKey.utmCampaign),
      },
      timeout: t1Timeout,
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: SendRegisterResponseModel | null | undefined = apiData;
  if (apiData) {
    try {
      response = SendRegisterResponseModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const setupPin = async ({
  confirmPin,
}: {
  confirmPin: string;
}): Promise<SetupPinResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/setuppin',
      body: {
        payload: {
          pin: confirmPin,
        },
      },
      timeout: t1Timeout,
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: SetupPinResponseModel | null | undefined = apiData;
  if (apiData) {
    try {
      response = SetupPinResponseModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const facebookLogin = async ({
  name,
  email,
  id,
}: {
  name: string;
  email?: string | null;
  id: string;
}): Promise<FacebookLoginResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/facebooklogin',
      body: {
        name,
        email,
        id,
      },
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: FacebookLoginResponseModel | null | undefined = apiData;
  if (apiData) {
    try {
      response = FaceebookLoginResponseModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const usernameLogin = async ({
  email,
  password,
}: {
  email: string;
  password?: string;
}): Promise<UsernameLoginResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/oldcforder',
      body: {
        email,
        passw: password,
      },
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: UsernameLoginResponseModel | null | undefined = apiData;
  if (apiData) {
    try {
      response = UsernameLoginResponseModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
