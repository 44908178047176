import {
  Get1010CouponModel,
  Get1010CouponModelConverter,
  GetTypeCouponModel,
  GetTypeCouponModelConverter,
} from '@/models/Get1010Coupon.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const get1010Coupon = async ({
  lang,
  rewardid,
}: {
  lang?: string;
  rewardid: string;
}): Promise<Get1010CouponModel> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/get1010coupon',
      lang,
      body: { lang, rewardid },
    }),
  ]);
  const apiData =
    getApiData.status === 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : getApiData.status === 401
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;

  let response: Get1010CouponModel = apiData;

  if (apiData) {
    try {
      response = Get1010CouponModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getTypeCoupon = async ({
  lang,
  campaignId,
  type,
}: {
  lang?: string;
  campaignId: string;
  type: string;
}): Promise<GetTypeCouponModel> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/omnicoupon/issue',
      lang,
      body: { lang, campaignId, type },
    }),
  ]);
  const apiData =
    getApiData.status === 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : getApiData.status === 401
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;

  let response: GetTypeCouponModel = apiData;

  if (apiData) {
    try {
      response = GetTypeCouponModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  response.status = getApiData.status ?? undefined;
  return response;
};
