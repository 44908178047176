import * as showDialog from '@/utils/showDialog';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import thText from '@/locales/th/addtocart.json';
import enText from '@/locales/en/addtocart.json';
import { SkuWithQty } from '@/models/AddToCart.model';
import {
  AddToCartMultiItemsType,
  AddToCartServiceType,
} from '@/services/client/addToCartService';

export enum AddToCartHandleType {
  // eslint-disable-next-line no-unused-vars
  consent = 'consent', // add to cart อีกรอบโดนใช้ state value
  // eslint-disable-next-line no-unused-vars
  error = 'error', // clear error state
  // eslint-disable-next-line no-unused-vars
  changeStore = 'changeStore', // แสดง stock เพื่อเปลี่ยนสาขา
  // eslint-disable-next-line no-unused-vars
  autoChangeStore = 'autoChangeStore', // เปลี่ยนสาขา auto แล้ว add to cart
  // eslint-disable-next-line no-unused-vars
  refresh = 'refresh', // refresh page
  // eslint-disable-next-line no-unused-vars
  clearCartAndAddToCart = 'clearCartAndAddToCart', // clearcart and add to cart
  // eslint-disable-next-line no-unused-vars
  clearCartAndGotoOrderhistory = 'clearCartAndGotoOrderhistory', // clearcart and go to orderhistory page
  // eslint-disable-next-line no-unused-vars
  clearCart = 'clearCart', // clearcart
  // eslint-disable-next-line no-unused-vars
  reNewServiceAndAddToCart = 'reNewServiceAndAddToCart', // reNewServiceAndAddToCart
  // eslint-disable-next-line no-unused-vars
  selectBundle = 'selectBundle', // hasselectBundle
  // eslint-disable-next-line no-unused-vars
  goToCart = 'goToCart', // ไปหน้าตะกร้า
  // eslint-disable-next-line no-unused-vars
  clearSurveyItemsAndAddToCart = 'clearSurveyItemsAndAddToCart', // clearcart survey and add to cart
  // eslint-disable-next-line no-unused-vars
  mergeStore = 'mergeStore', // merge store
  // eslint-disable-next-line no-unused-vars
  christmasConsent = 'christmasConsent', // christmasConsent
  // eslint-disable-next-line no-unused-vars
  chatandshop = 'chatandshop', // chatandshop
}

export interface AddToCartErrorHandleInput {
  text?: string;
  type?: string;
  svgImage?: string;
  messages?: string[];
  isNotRefundMessage?: boolean;
  productName?: string;
  skuService?: string;
  ref?: string;
  serviceNotAvail?: boolean;
  sku?: string;
  qty?: number;
  lang?: string;
  isSelectBundle?: boolean;
  setId?: string;
  withService?: string;
  skuQtyList?: SkuWithQty[];
  serviceType?: AddToCartServiceType;
  addToCartMultiType?: AddToCartMultiItemsType;
  storeCode?: string;
}

export interface AddToCartErrorHandleOutput {
  text?: string;
  handleType: AddToCartHandleType;
  storeCode?: string;
  qty?: number;
}

export const errorMessageHandler = async ({
  text,
  type,
  svgImage,
  messages,
  isNotRefundMessage,
  productName,
  qty,
  lang,
  storeCode: responseStoreCode,
}: AddToCartErrorHandleInput): Promise<AddToCartErrorHandleOutput> => {
  const t = lang === 'th' ? thText : enText;
  const textSplit: string[] = (text ?? '').toString().split('|');
  let responseType: AddToCartHandleType = AddToCartHandleType.error;
  let responseQty: number | undefined = undefined;
  let storeCode = undefined;
  if (type === 'consent') {
    await showDialog.showMessageWithButton({
      imageTag: svgImage,
      text: `${(messages ?? []).map((e) => `<div>${e}</div>`).join('')}${
        isNotRefundMessage ? `<div>${t.iftheorderhasbeenpro}</div>` : ''
      }`,
      primaryButton: {
        name: t.cancel,
        backgroundColor: '#000000',
      },
      secondaryButton: {
        name: t.accept,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.consent;
        },
      },
    });
  } else if (text === 'Stock Avail is Not Enough') {
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/warning_icon.svg',
      text: `<div>${t.sorry}</div><div>${t.thisbranchisoutofstore}</div>`,
      primaryButton: {
        name: t.changebranch,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.changeStore;
        },
      },
    });
  } else if (text?.includes('Stock Avail is Not Enough|')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/warning_icon.svg',
      text: `<div>${t.sorry}</div><div>${
        textSplit.length > 1 && (Number(textSplit[1]) ?? 0) >= 1
          ? `${t.thisbranchhasinventories} ${textSplit[1]} ${t.unit} ${t.tryanotherbranch}`
          : t.thisbranchisoutofstore
      }</div>`,
      primaryButton: {
        name: t.changebranch,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.changeStore;
        },
      },
    });
  } else if (text?.includes('toomanystore|')) {
    try {
      const oldStoreName: string = textSplit.length > 1 ? textSplit[1] : '';
      const newStoreName: string = textSplit.length > 2 ? textSplit[2] : '';
      await showDialog.showMessageWithButton({
        text: `<div class="leading-6 text-xl"><div>${t.haveyouevershoppedatcranch}</div><div>"${oldStoreName}"</div><div>${t.wanttochangetoshopatbranch}</div><div>"${newStoreName}" ${t.isitright}</div></div>`,
        primaryButton: {
          name: t.yes,
          backgroundColor: '#B8292F',
          callBack: () => {
            responseType = AddToCartHandleType.mergeStore;
            storeCode =
              responseStoreCode !==
              (textSplit.length >= 3 ? textSplit[3] : undefined)
                ? responseStoreCode
                : undefined;
          },
        },
        secondaryButton: {
          name: t.no,
          backgroundColor: '#000000',
          callBack: () => {
            responseType = AddToCartHandleType.autoChangeStore;
            storeCode = textSplit.length >= 3 ? textSplit[3] : undefined;
          },
        },
      });
    } catch (_) {}
  } else if (text?.includes('ไม่พบสาขา|')) {
    const oldStoreName: string = textSplit.length > 1 ? textSplit[1] : '';
    await showDialog.showMessageWithButton({
      text: `<div>${t.branch} ${oldStoreName}</div><div>${t.temporarilyclosed}</div><div>${t.pleaseselectanewbranch}</div>`,
      primaryButton: {
        name: t.ok,
        backgroundColor: '#B8292F',
      },
    });
    responseType = AddToCartHandleType.changeStore;
  } else if (text?.includes('alreadypaid|')) {
    responseType = AddToCartHandleType.clearCartAndAddToCart;
  } else if (text?.includes('hasother')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/christmas/christmaserror.svg',
      text: `<div><div class="font-semibold" style="line-height:1rem;">${t.youcannotcombineotheritem}<br>${t.thesystemwillclearcart}</div><div style="line-height:1rem;">${t.becausethechristmastispreorder}<br>${t.youcannotcombineotheritem}</div></div>`,
      primaryButton: {
        name: `<div class="font-semibold" style="line-height:1rem;">${t.confirmchristmasorder}<br>${t.readytowashthebasket}</div>`,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.christmasConsent;
        },
      },
    });
  } else if (text?.includes('haschristmas')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/christmas/christmaserror.svg',
      text: `<div><div class="font-semibold" style="line-height:1rem;">${t.younowhaveachristmasitem}<br>${t.pleasecompleteyourorder}</div><div style="line-height:1rem;">${t.becausethechristmastispreorder}<br>${t.youcannotcombineotheritem}</div></div>`,
      primaryButton: {
        name: `<div class="font-semibold" style="line-height:1rem;">${t.gotothecarttocheckout}</div>`,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.goToCart;
        },
      },
    });
  } else if (text?.includes('hascurtain')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/pdp/curtain_icon.svg',
      text: `<div>
        <div class="font-semibold text-xl">${t.younowhavecustomcurtainsinyourbasket}</div>
        <div class="font-semibold text-xl">${t.pleasecompletetheordercurtainsfirst}</div>
        <div>${t.becausethecurtainmadetoorderisapre}</div>
      </div>`,
      primaryButton: {
        name: t.addproduct,
        backgroundColor: '#000000',
        callBack: () => {
          responseType = AddToCartHandleType.clearSurveyItemsAndAddToCart;
        },
      },
      secondaryButton: {
        name: t.ordercurtains,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.goToCart;
        },
      },
    });
  } else if (text?.includes('longtailconsent|')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/backconsent_icon.svg',
      text: `<div>${t.specialorder} ${
        textSplit.length > 1
          ? `${textSplit[1]} ${t.day}</div><div>${t.iftheorderhasbeenpro} <a href="/page/refundpolicy" target="_blank">${t.readterms}</a></div>`
          : ''
      }`,
      primaryButton: {
        name: t.cancel,
        backgroundColor: '#000000',
      },
      secondaryButton: {
        name: t.accept,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.consent;
        },
      },
    });
  } else if (text?.includes('backorderconsent|')) {
    const dateshow: string = textSplit.length > 1 ? textSplit[1] : '';
    let stockshow: number = 0;
    try {
      stockshow = Number(textSplit[2]) ?? 0;
    } catch (_) {}
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/backconsent_icon.svg',
      text:
        stockshow > 0 && (qty ?? 1) > 0
          ? `${`<div class="text-base font-bold">${
              t.thisbranchhasproductreadyforshiping
            } ${stockshow.toString()} ${t.unit}</div>
            <div class="text-base font-bold">${
              t.ifyouwanttoordermorethanthiswewill
            }<div>
            <div class="text-sm">${t.wewill}${
              t.itwilltaketimetoprepare
            } ${dateshow} ${t.day}</div>`}`
          : `<div class="text-base font-bold">${t.temporarilyoutofstock} ${t.ifyouwanttoorder}</div>
            <div class="text-sm">${t.itwilltaketimetoprepare} ${dateshow} ${t.day}</div>`,
      primaryButton: {
        name: t.cancel,
        backgroundColor: '#000000',
      },
      secondaryButton: {
        name: t.accept,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.consent;
        },
      },
    });
  } else if (text?.includes('Stock Avail is Disabled|')) {
    await showDialog.showMessageWithButton({
      image: '/images/p_thai/thai_cry_ciecle.svg',
      text: `<div>${t.sorrythisproductiste}`,
      primaryButton: {
        name: t.ok,
        backgroundColor: '#000000',
      },
    });
  } else if (text?.includes('alreadyvoid|') || text?.includes('ยกเลิกแล้ว')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/warning_icon.svg',
      text: `<div>${t.thiscarthasbeencancel}</div><div>${t.productsinthiscartcancel}</div>`,
      primaryButton: {
        name: t.addproduct,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.clearCartAndAddToCart;
        },
      },
      secondaryButton: {
        name: t.gototheorderlistpage,
        backgroundColor: '#000000',
        callBack: () => {
          responseType = AddToCartHandleType.clearCartAndGotoOrderhistory;
        },
      },
    });
  } else if (text?.includes('pendingorder|')) {
    responseType = AddToCartHandleType.clearCart;
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/warning_icon.svg',
      text: `<div>${t.productsthatyouhavealready}</div>`,
      primaryButton: {
        name: t.gototheorderlistpage,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.clearCartAndGotoOrderhistory;
        },
      },
    });
  } else if (text?.includes('hasselectbundle')) {
    responseType = AddToCartHandleType.selectBundle;
  } else if (text?.includes('dcconsent|')) {
    const dateshow: string = textSplit.length > 1 ? textSplit[1] : '';
    let stockshow: number = 0;
    let flagbranch: number = 1;
    let btntext: string = t.buy;
    let btnCancel: string = t.cancel;
    let cancelCallBack: (() => void) | undefined = undefined;
    let isLongtail: boolean = false;
    try {
      stockshow = Number(textSplit[2]) ?? 0;
    } catch (_) {}
    try {
      flagbranch = Number(textSplit[3]) ?? 1;
      if (flagbranch === 2 || flagbranch === 5) {
        btntext = t.buyallavailable;
      } else if (flagbranch === 3) {
        btntext = t.buyallthatthisbranchhas;
        btnCancel = t.changebranch;
        cancelCallBack = () => {
          responseType = AddToCartHandleType.changeStore;
        };
      }
      if (textSplit[4] === 'ER') {
        isLongtail = true;
      }
    } catch (_) {}
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/backconsent_icon.svg',
      text:
        stockshow > 0 && (qty ?? 1) > 0
          ? `${`<div class="text-base font-bold">${
              [2, 4, 5].includes(flagbranch)
                ? t.productsareinstockandreadytosell
                : t.thisbranchhasproductreadyforshiping
            } ${stockshow.toString()} ${t.unit}</div>`}
            ${
              ![2, 3].includes(flagbranch)
                ? `${
                    flagbranch === 1
                      ? `<div class="text-sm">${t.ifyouwanttoordermorethanthiswewill}<div>`
                      : ``
                  }
                  <div class="text-sm">${flagbranch === 1 ? t.wewill : ``}${
                    t.itwilltaketimetoprepare
                  } ${dateshow} ${t.day}</div>`
                : ``
            }
            ${isLongtail ? `<div>${t.iftheorderhasbeenpro}</div>` : ''}`
          : `<div class="text-xl font-bold">${t.temporarilyoutofstock}</div>
            <div class="text-base">${t.itwilltaketimetoprepare} ${dateshow} ${
              t.day
            }</div>
            ${isLongtail ? `<div>${t.iftheorderhasbeenpro}</div>` : ''}`,
      primaryButton: {
        name: btnCancel,
        backgroundColor: '#000000',
        callBack: cancelCallBack,
      },
      secondaryButton: {
        name: btntext,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.consent;
          if (![1, 4].includes(flagbranch)) {
            responseQty = stockshow;
          }
        },
      },
    });
  } else if (text?.includes('servicenotsame')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/warning_icon.svg',
      text: `<div class="text-base font-bold text-black">${t.doyouwanttochangetheinstallationservicefor}</div><div class="text-sm">${productName}</div>`,
      primaryButton: {
        name: t.cancel,
        backgroundColor: '#000000',
      },
      secondaryButton: {
        name: t.ok,
        backgroundColor: '#B8292F',
        callBack: () => {
          responseType = AddToCartHandleType.reNewServiceAndAddToCart;
        },
      },
    });
  } else if (text?.includes('selectstore|')) {
    responseType = AddToCartHandleType.changeStore;
  } else if (text?.includes('storeisnotexist|')) {
    const locationService: string = textSplit.length > 1 ? textSplit[1] : '';
    await showDialog.showMessageWithButton({
      image: '/images/svg/christmas/christmaserror.svg',
      text: `<div><div class="font-semibold leading-4">${t.branchselectednotinservice}<br/>${t.pleasechangebranchorcontactstaff}</div>
      <div class="bg-grayLight2 text-grayDark p-2 rounded-lg mt-2 font-semibold">${t.locationofferchristmastree}: ${locationService}</div></div>`,
      primaryButton: {
        name: t.changebranch,
        backgroundColor: '#000000',
        callBack: () => {
          responseType = AddToCartHandleType.changeStore;
        },
      },
      secondaryButton: {
        name: '<div class="text-white"><i class="bi bi-line text-xxs"></i> Chat & Shop</div>',
        backgroundColor: '#00B901',
        callBack: () => {
          responseType = AddToCartHandleType.chatandshop;
        },
      },
    });
  } else {
    const oldStcode: string | undefined = Cookies.get(cookieKey.stcode);
    if (!oldStcode) {
    } else {
      await showDialog.showMessageWithButton({
        text: text ?? '',
        primaryButton: {
          name: thText.ok,
        },
      });
    }
  }
  return { handleType: responseType, storeCode: storeCode, qty: responseQty };
};
