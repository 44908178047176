import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export enum AddressModalActionType {
  // eslint-disable-next-line no-unused-vars
  delivery = 'delivery',
  // eslint-disable-next-line no-unused-vars
  taxinvoice = 'taxinvoice',
  // eslint-disable-next-line no-unused-vars
  service = 'service',
  // eslint-disable-next-line no-unused-vars
  homepickup = 'homepickup',
}

interface AddressModalState {
  isOpen: boolean;
  isHide: boolean;
  currentAction: AddressModalActionType;
}

const initialState: AddressModalState = {
  isOpen: false,
  isHide: false,
  currentAction: AddressModalActionType.delivery,
};

export const addressModalSlice = createSlice({
  name: 'addressModal',
  initialState: initialState,
  reducers: {
    openAddressModal: (
      state,
      action: { payload: AddressModalActionType | undefined },
    ) => {
      state.isOpen = true;
      state.currentAction = action.payload ?? AddressModalActionType.delivery;
    },
    handleCloseAddressModal: (state) => {
      state.isOpen = false;
      state.currentAction = AddressModalActionType.delivery;
    },
    handleHideAddressModal: (state) => {
      state.isHide = true;
      state.currentAction = AddressModalActionType.delivery;
    },
    handleShowAddressModal: (state) => {
      state.isHide = false;
      state.currentAction = AddressModalActionType.delivery;
    },
  },
  extraReducers: () => {},
});

export const {
  openAddressModal,
  handleCloseAddressModal,
  handleHideAddressModal,
  handleShowAddressModal,
} = addressModalSlice.actions;

export const isOpenAddressModalSelector = (store: RootState): boolean =>
  store.addressModal.isOpen;

export const currentActionAddressModalSelector = (
  store: RootState,
): AddressModalActionType => store.addressModal.currentAction;

export const isHideAddressModalSelector = (store: RootState): boolean =>
  store.addressModal.isHide;

export default addressModalSlice.reducer;
