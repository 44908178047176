import { modelValue } from '@/utils/modelValue';
import { ProductModel, ProductModelConverter } from '../Product.model';

export interface BundleFreegiftModel {
  skcode?: string;
  products?: ProductModel[];
  quantity?: number;
}
export class BundleFreegiftModelConverter {
  public static fromJson(json: any): BundleFreegiftModel {
    const model: BundleFreegiftModel = {
      skcode: json['sku'],
      products: json['products']
        ? json['products'].map((x: any) => ProductModelConverter.fromJson(x))
        : undefined,
      quantity: json['qnt'],
    };
    return modelValue(model);
  }
}
