import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import AppLink from '../../handler/AppLink';
import Image from 'next/image';

interface Props {
  isNeedUpdateEmail?: boolean;
}

export default function UpdateCustomerEmailHover({ isNeedUpdateEmail }: Props) {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <Tooltip
      open={showTooltip && isNeedUpdateEmail}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      leaveTouchDelay={0}
      arrow
      placement="bottom"
      leaveDelay={150}
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
            backgroundColor: 'white',
            color: 'black',
            width: '100%',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            '.MuiTooltip-arrow': {
              color: '#B8292F',
            },
          },
          style: {
            marginTop: 0,
          },
        },
      }}
      title={
        <Box
          className="shadow-md bg-transparent w-full mt-2 rounded-lg border-primary"
          sx={{
            width: '100%',
            maxWidth: 360,
            borderColor: '#B8292F',
            borderRadius: '12px',
            borderTopWidth: '6px',
          }}
        >
          <>
            <div className="leading-5 px-4 p-3">
              <div className="font-semibold">
                {t('common:pleaseupdateemail')}
                <br />
                {t('common:forconfirmmember')}
              </div>
              <div>{t('common:foryoursafety')}</div>
              <div className="flex justify-center mt-2">
                <AppLink href={`/profile/editinfo`} className="w-1/2">
                  <Button
                    className="bg-primary md:w-auto py-2 px-4 text-white hover:bg-secondaryPurple font-bold h-full leading-3 rounded-full"
                    sx={{
                      borderRadius: '99px',
                      lineHeight: '.75rem',
                      backgroundColor: '#B8292F',
                      color: 'white',
                      height: '100%',
                      padding: '0.5rem 1rem',
                      '&:hover': {
                        backgroundColor: '#B8292FD0',
                      },
                    }}
                  >
                    {t('common:updateemail')}
                  </Button>
                </AppLink>
              </div>
            </div>
          </>
        </Box>
      }
    >
      <div className="my-auto">
        <div className="flex items-center">
          <div className="relative">
            <Image
              src="/images/svg/header/profile_black.svg"
              width={32}
              height={32}
              className="relative"
              alt="profile"
            />
            {isNeedUpdateEmail && (
              <Image
                src="/images/svg/header/profile_warning.svg"
                width={16}
                height={16}
                className="absolute bottom-0 right-0"
                alt="profile_warning"
              />
            )}
          </div>
        </div>
      </div>
    </Tooltip>
  );
}
