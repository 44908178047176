import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';

export interface RatingPageResponse {
  title?: string;
  ref?: string;
  errorMessage?: string | null;
  canRate?: boolean;
  storeName?: string;
  waitingMessageList?: [];
  cookies?: CookieData[] | null;
  info?: CustomerInfo | null;
}

export class RatingPageResponseConverter {
  public static fromJson(json: any): RatingPageResponse {
    const model: RatingPageResponse = {
      title: json['title'],
      ref: json['refshow'],
      errorMessage: json['errorMessage'],
      canRate: json['canRate'],
      storeName: json['storeName'],
      waitingMessageList: json['waitingMessageList'],
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}

export interface RatingRequest {
  rating: number;
  wating: number;
  isGetCompleteProduct: boolean;
  comment?: string;
  order: string;
}
