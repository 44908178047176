import React from 'react';

const PageTypeContext = React.createContext<{
  isShow: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  isCurtain: boolean;
  setIsCurtain: React.Dispatch<React.SetStateAction<boolean>>;
  isSteel: boolean;
  setIsSteel: React.Dispatch<React.SetStateAction<boolean>>;
  isPaint: boolean;
  setIsPaint: React.Dispatch<React.SetStateAction<boolean>>;
  isFeedback: boolean;
  setIsFeedback: React.Dispatch<React.SetStateAction<boolean>>;
  isInstall: boolean;
  setIsInstall: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isShow: false,
  setIsShow: () => {},
  isCurtain: false,
  setIsCurtain: () => {},
  isSteel: false,
  setIsSteel: () => {},
  isPaint: false,
  setIsPaint: () => {},
  isFeedback: false,
  setIsFeedback: () => {},
  isInstall: false,
  setIsInstall: () => {},
});

export default PageTypeContext;
