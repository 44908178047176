import {
  GetUserInfoResponse,
  GetUserInfoResponseConverter,
} from '@/models/GetUserInfo.model';
import { getDataFromAPI } from '@/utils/httpClient';

export const getUserInfo = async (): Promise<
  GetUserInfoResponse | null | undefined
> => {
  const [getData] = await Promise.all([
    getDataFromAPI({
      path: '/getuserinfo',
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: GetUserInfoResponse | null | undefined = data;

  if (data && data.info) {
    try {
      response = GetUserInfoResponseConverter.fromJson(data.info);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
