import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as authService from '@/services/client/authService';
import { RootState } from '../store';
import {
  AuthenInfoData,
  Authenticate,
  Identity,
} from '@/models/AuthenInfo.model';
import {
  LoginErrorType,
  SendLoginRequestModel,
  SendLoginResponseModel,
} from '@/models/SendLogin.model';
import * as ServerCookies from '@/services/client/cookieService';
import Cookies from 'js-cookie';
import { CookieData } from '@/models/Authen.model';
import * as cookieKey from '@/constants/cookieKey.constant';
import {
  RequestOtpData,
  RequestOtpResponseModel,
} from '@/models/RequestOtp.model';
import { FacebookLoginResponseModel } from '@/models/FacebookLogin.model';
import { SendNewLoginRequestModel } from '@/models/SendNewLogin.model';

export enum SetUpPinStep {
  // eslint-disable-next-line no-unused-vars
  password = 0,
  // eslint-disable-next-line no-unused-vars
  setnewpin = 1,
}

interface LoginState {
  twdAccessToken?: string;
  the1AccessToken?: string;
  isLoading: boolean;
  isAuthenticated: boolean;
  error?: string;
  authenInfoData?: AuthenInfoData;
  customerName?: string;
  requestOtpData?: RequestOtpData;
  isNewLoginUser: boolean;
  isNewLoginRequestName: boolean;
  isSetupPin?: boolean;
  currentSetUpPinStep: SetUpPinStep;
  customerId?: string | null;
  isUsernamePasswordSingin: boolean;
  loginMethod?: string;
  isBannedUser?: boolean;
}

const initialState: LoginState = {
  isLoading: false,
  isAuthenticated: false,
  isNewLoginUser: false,
  isNewLoginRequestName: false,
  isSetupPin: undefined,
  currentSetUpPinStep: SetUpPinStep.password,
  customerName: Cookies.get(cookieKey.customerName),
  customerId: undefined,
  isUsernamePasswordSingin: false,
};

export const authenInfo = createAsyncThunk(
  'user/authenInfo',
  async (username: string) => {
    const response = await authService.authenInfo({ username });
    if (!response || response.error) {
      throw new Error(response?.error?.name || 'error');
    }
    return response;
  },
);

export const sendLogin = createAsyncThunk<
  SendLoginResponseModel,
  {
    input: string;
    type: string;
    lang?: string | undefined;
    identity?: string | undefined;
  },
  { state: RootState }
>(
  'user/sendLogin',
  async (
    {
      input,
      type,
      lang,
      identity,
    }: {
      input: string;
      type: string;
      lang?: string;
      identity?: string;
    },
    { getState }: { getState: () => RootState },
  ) => {
    let sendData: SendLoginRequestModel | undefined;
    let fistDigitMobile =
      getState().user.authenInfoData?.username?.country === '66' ? '0' : '';
    switch (type) {
      case '1':
        sendData = {
          grant_type: 'pin',
          scope: 'openid',
          username:
            fistDigitMobile + getState().user.authenInfoData?.username?.value,
          pin: input,
        };
        break;
      case '2':
        sendData = {
          grant_type: 'password',
          scope: 'openid',
          username:
            fistDigitMobile + getState().user.authenInfoData?.username?.value,
          password: input,
        };
        break;
      case '3':
        sendData = {
          username:
            fistDigitMobile + getState().user.authenInfoData?.username?.value,
          grant_type: 'otp',
          scope: 'openid',
          otp_request_id: getState().user.requestOtpData?.requestID,
          otp_code: input,
          identity_code: identity,
        };
        break;
    }
    if (!sendData) {
      throw new Error('9|type_is_not_match');
    }
    const response = await authService.sendLogin(sendData, lang);
    if (!response || response.isError) {
      let toneToken: string | undefined = undefined;
      if (
        response?.errorType === LoginErrorType.sendNewLogin ||
        response?.errorType === LoginErrorType.notfoundNameFromT1
      ) {
        toneToken =
          response?.cookies
            ?.find((e) => e.name === cookieKey.toneToken)
            ?.val?.toString() ?? undefined;
      }
      throw new Error(
        `${response?.errorType ?? '9'}|${response?.errorMessage ?? 'Error.'}|${
          toneToken ?? undefined
        }`,
      );
    }
    let responseCheckPin = null;
    if (getState().user.isSetupPin) {
      responseCheckPin = await authService.authenInfo({
        username:
          fistDigitMobile + getState().user.authenInfoData?.username?.value,
      });
      if (!responseCheckPin) {
        response.isSetpin = false;
      } else {
        const hasPin = responseCheckPin.data?.authenticate?.pin ?? false;
        response.isSetpin = hasPin ? false : true;
      }
    }
    return response;
  },
);

export const requestOtp = createAsyncThunk<
  RequestOtpResponseModel,
  undefined,
  { state: RootState }
>('user/requestOtp', async (_, { getState }: { getState: () => RootState }) => {
  let fistDigitMobile =
    getState().user.authenInfoData?.username?.country === '66' ? '0' : '';
  let username =
    fistDigitMobile + getState().user.authenInfoData?.username?.value;
  let type = getState().user.authenInfoData?.username?.type;
  if (!username) {
    throw new Error('username_not_found');
  }
  if (!type) {
    throw new Error('type_not_found');
  }
  const response = await authService.requestOtp({ username, type });
  if (!response || response.error) {
    throw new Error(response?.error?.name || 'error');
  }
  return response;
});

export const sendNewLogin = createAsyncThunk<
  SendLoginResponseModel,
  {
    lang?: string | undefined;
    isConsentMarketing: boolean;
    version?: string;
    firstName?: string;
    lastName?: string;
  },
  { state: RootState }
>(
  'user/sendNewLogin',
  async (
    {
      lang,
      isConsentMarketing,
      version,
      firstName,
      lastName,
    }: {
      lang?: string;
      isConsentMarketing: boolean;
      version?: string;
      firstName?: string;
      lastName?: string;
    },
    { getState }: { getState: () => RootState },
  ) => {
    let fistDigitMobile =
      getState().user.authenInfoData?.username?.country === '66' ? '0' : '';
    let username =
      fistDigitMobile + getState().user.authenInfoData?.username?.value;
    let the1AccessToken = getState().user.the1AccessToken;
    let sendData: SendNewLoginRequestModel = {
      mobile: username,
      consent: isConsentMarketing,
      version: version,
      first_name: firstName,
      last_name: lastName,
    };
    const response = await authService.sendNewLogin(
      sendData,
      the1AccessToken,
      lang,
    );
    if (!response || response.isError) {
      throw new Error(
        `${response?.errorType ?? '9'}|${response?.errorMessage ?? 'Error.'}`,
      );
    }
    let responseCheckPin = null;
    if (getState().user.isSetupPin) {
      responseCheckPin = await authService.authenInfo({
        username:
          fistDigitMobile + getState().user.authenInfoData?.username?.value,
      });
      if (!responseCheckPin) {
        response.isSetpin = false;
      } else {
        const hasPin = responseCheckPin.data?.authenticate?.pin ?? false;
        response.isSetpin = hasPin ? false : true;
      }
    }
    return response;
  },
);

export const setupPin = createAsyncThunk(
  'user/setupPin',
  async (confirmPin: string) => {
    const response = await authService.setupPin({ confirmPin });
    if (!response || response.isError) {
      throw new Error(`${response?.errorType ?? '9'}`);
    }
    return response;
  },
);

export const facebookLogin = createAsyncThunk<
  FacebookLoginResponseModel,
  {
    name: string;
    email?: string;
    id: string;
  }
>(
  'user/facebookLogin',
  async ({
    name,
    email,
    id,
  }: {
    name: string;
    email?: string;
    id?: string;
  }) => {
    if (!name) {
      throw new Error('username_not_found');
    }
    if (!id) {
      throw new Error('id_not_found');
    }
    const response = await authService.facebookLogin({
      name,
      email: email ?? null,
      id,
    });
    if (!response || response.isError) {
      throw new Error(response?.errorMessage ?? 'error');
    }
    return response;
  },
);

export const usernameLogin = createAsyncThunk(
  'user/usernameLogin',
  async ({ email, password }: { email: string; password: string }) => {
    if (!email) {
      throw new Error('email_not_found');
    }
    if (!password) {
      throw new Error('password_not_found');
    }
    const response = await authService.usernameLogin({
      email,
      password,
    });
    if (!response || response.isError) {
      throw new Error(`${response?.errorType ?? '9'}`);
    }
    return response;
  },
);

const setAPIToken = async ({
  twdAccessToken,
  the1AccessToken,
  customerName,
  ref,
  twdRefreshToken,
}: {
  twdAccessToken: CookieData;
  the1AccessToken?: CookieData;
  customerName?: CookieData | null;
  ref?: CookieData | null;
  twdRefreshToken?: CookieData | null;
}) => {
  try {
    if (customerName) {
      Cookies.set(
        cookieKey.customerName,
        (customerName?.val ?? 'unknown').toString(),
        {
          expires: customerName.days ?? 7,
          secure: process.env.NEXT_PUBLIC_COOKIE_ENV !== 'development',
        },
      );
    }
    if (twdAccessToken.val) {
      await ServerCookies.setMaxAge(
        cookieKey.twdToken,
        twdAccessToken.val.toString(),
        twdAccessToken.days ?? 7,
      );
    }
    if (twdRefreshToken?.val) {
      await ServerCookies.setMaxAge(
        cookieKey.twdRefreshToken,
        twdRefreshToken?.val.toString(),
        twdRefreshToken?.days ?? 7,
      );
    }
    if (the1AccessToken?.val) {
      await ServerCookies.setMaxAge(
        cookieKey.the1Token,
        the1AccessToken.val.toString(),
        the1AccessToken.days ?? 7,
      );
    }
    if (ref?.val) {
      await ServerCookies.set(cookieKey.ref, ref.val.toString());
    }
  } catch (e) {
    console.log(e);
  }
};

const setSignOut = async () => {
  try {
    Cookies.remove(cookieKey.customerName);
    await ServerCookies.remove(cookieKey.twdToken);
    await ServerCookies.remove(cookieKey.twdRefreshToken);
    await ServerCookies.remove(cookieKey.the1Token);
  } catch (e) {
    console.log(e);
  }
};

const loginSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setCustomerId: (state, action) => {
      state.customerId = action.payload ?? null;
      state.customerName = Cookies.get(cookieKey.customerName);
    },
    clearError: (state) => {
      state.error = undefined;
    },
    initialLoginForm: (state) => {
      state.isLoading = false;
      state.error = undefined;
      state.authenInfoData = undefined;
      state.requestOtpData = undefined;
      state.isNewLoginUser = false;
      state.isNewLoginRequestName = false;
      state.currentSetUpPinStep = SetUpPinStep.password;
      state.isSetupPin = undefined;
      state.isUsernamePasswordSingin = false;
    },
    signOut: (state) => {
      setSignOut();
      state.twdAccessToken = undefined;
      state.the1AccessToken = undefined;
      state.isLoading = false;
      state.isAuthenticated = false;
      state.error = undefined;
      state.authenInfoData = undefined;
      state.customerName = undefined;
      state.requestOtpData = undefined;
      state.isNewLoginUser = false;
      state.isNewLoginRequestName = false;
      state.customerId = null;
    },
    clearOtpRequestId: (state) => {
      state.requestOtpData = undefined;
    },
    clearIsAuthenticated: (state) => {
      state.isAuthenticated = false;
    },
    setIsSigninWithUsername: (state, action) => {
      state.isUsernamePasswordSingin = action.payload ?? false;
    },
    clearIsBannedUser: (state) => {
      state.isBannedUser = false;
    },
  },
  extraReducers: (builder) => {
    // fullfiled, pending, rejected
    builder
      .addCase(authenInfo.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(authenInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.authenInfoData = action.payload.data;
        state.isSetupPin = action.payload.data?.authenticate?.pin
          ? false
          : true;
      })
      .addCase(authenInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    // fullfiled, pending, rejected
    builder
      .addCase(sendLogin.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(sendLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        const cookies = action.payload.cookies;
        state.isSetupPin = action.payload.isSetpin;
        state.isBannedUser = action.payload.isbanned;
        if (cookies && cookies.length > 0) {
          const the1AccessToken = cookies.find(
            (e) => e.name == cookieKey.the1Token,
          );
          const twdAccessToken = cookies.find(
            (e) => e.name == cookieKey.twdToken,
          );
          if (the1AccessToken && twdAccessToken) {
            const customerName = cookies.find(
              (e) => e.name == cookieKey.customerName,
            );
            const twdRefreshToken = cookies.find(
              (e) => e.name == cookieKey.twdRefreshToken,
            );
            const ref = cookies.find((e) => e.name == cookieKey.ref);
            setAPIToken({
              twdAccessToken: twdAccessToken,
              the1AccessToken: the1AccessToken,
              customerName: customerName,
              ref: ref,
              twdRefreshToken: twdRefreshToken,
            });
            state.the1AccessToken = (the1AccessToken.val ?? '').toString();
            state.twdAccessToken = (twdAccessToken.val ?? '').toString();
            state.customerName = (customerName?.val ?? 'unknown').toString();
            state.isAuthenticated = true;
            state.loginMethod = 'The1';
            if (state.isSetupPin) {
              state.currentSetUpPinStep = SetUpPinStep.setnewpin;
            }
          } else {
            state.error = action.payload.errorMessage;
          }
        } else {
          state.error = action.payload.errorMessage;
        }
      })
      .addCase(sendLogin.rejected, (state, action) => {
        let errorType: LoginErrorType = LoginErrorType.defaultOrFrontEndFailed;
        let errorMessage: string = 'Error';
        let cookies: string | undefined = undefined;
        try {
          errorType = action.error.message?.split('|')?.[0]
            ? Number(action.error.message?.split('|')[0])
            : LoginErrorType.defaultOrFrontEndFailed;
        } catch (_) {}
        try {
          errorMessage = action.error.message?.split('|')[1] ?? 'Error.';
        } catch (_) {}
        try {
          cookies = action.error.message?.split('|')[2] ?? undefined;
        } catch (_) {}
        switch (errorType) {
          case LoginErrorType.invalidAuthen:
            state.error = 'invalid_authen';
            break;
          case LoginErrorType.userAccountNotFound:
            state.error = 'user_account_not_found';
            break;
          case LoginErrorType.invalidIdentityVerify:
            state.error = 'invalid_identity_verify';
            break;
          case LoginErrorType.userAccountLock:
            state.error = 'user_account_lock';
            break;
          case LoginErrorType.sendNewLogin:
            {
              state.isNewLoginUser = true;
              const the1AccessToken = cookies;
              if (the1AccessToken) {
                state.the1AccessToken = (the1AccessToken ?? '').toString();
              }
            }
            break;
          case LoginErrorType.notfoundNameFromT1:
            {
              state.isNewLoginRequestName = true;
              const the1AccessToken = cookies;
              if (the1AccessToken) {
                state.the1AccessToken = (the1AccessToken ?? '').toString();
              }
            }
            break;
          default:
            state.error = errorMessage;
        }
        state.isLoading = false;
        state.isAuthenticated = false;
        state.customerName = undefined;
        state.customerId = null;
      });
    builder
      .addCase(sendNewLogin.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
        state.isNewLoginUser = false;
        state.isNewLoginRequestName = false;
      })
      .addCase(sendNewLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSetupPin = action.payload.isSetpin;
        const cookies = action.payload.cookies;
        if (cookies && cookies.length > 0) {
          const the1AccessToken = cookies.find(
            (e) => e.name == cookieKey.the1Token,
          );
          const twdAccessToken = cookies.find(
            (e) => e.name == cookieKey.twdToken,
          );
          if (the1AccessToken && twdAccessToken) {
            const customerName = cookies.find(
              (e) => e.name == cookieKey.customerName,
            );
            const twdRefreshToken = cookies.find(
              (e) => e.name == cookieKey.twdRefreshToken,
            );
            const ref = cookies.find((e) => e.name == cookieKey.ref);
            setAPIToken({
              twdAccessToken: twdAccessToken,
              the1AccessToken: the1AccessToken,
              customerName: customerName,
              ref: ref,
              twdRefreshToken: twdRefreshToken,
            });
            state.the1AccessToken = (the1AccessToken.val ?? '').toString();
            state.twdAccessToken = (twdAccessToken.val ?? '').toString();
            state.customerName = (customerName?.val ?? 'unknown').toString();
            state.isAuthenticated = true;
            state.loginMethod = 'The1';
            if (state.isSetupPin) {
              state.currentSetUpPinStep = SetUpPinStep.setnewpin;
            }
          } else {
            state.error = action.payload.errorMessage;
          }
        } else {
          state.error = action.payload.errorMessage;
        }
      })
      .addCase(sendNewLogin.rejected, (state, action) => {
        let errorType: string = '9';
        let errorMessage: string = 'Error';
        try {
          errorType = action.error.message?.split('|')[0] ?? '9';
          errorMessage = action.error.message?.split('|')[1] ?? 'Error.';
        } catch (_) {}
        switch (errorType) {
          case '4':
            state.error = 'signin_unsucessful';
            break;
          default:
            state.error = errorMessage;
        }
        state.isLoading = false;
        state.isAuthenticated = false;
        state.customerName = undefined;
        state.customerId = null;
      });
    builder
      .addCase(requestOtp.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(requestOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.requestOtpData = action.payload.data;
      })
      .addCase(requestOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    // fullfiled, pending, rejected
    builder
      .addCase(setupPin.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(setupPin.fulfilled, (state) => {
        state.isLoading = false;
        state.error = undefined;
        state.isSetupPin = false;
      })
      .addCase(setupPin.rejected, (state, action) => {
        state.isLoading = false;
        let errorType: string = action.error.message ?? '9';
        state.error = 'systemloginerror';
        switch (errorType) {
          case '1':
            state.error = 'systemloginerror';
            break;
          case '2':
            state.error = 'inputpinincorrect';
            break;
          default:
            state.error = 'systemloginerror';
        }
      });
    // fullfiled, pending, rejected
    builder
      .addCase(facebookLogin.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(facebookLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        const cookies = action.payload.cookies;
        if (cookies && cookies.length > 0) {
          const twdAccessToken = cookies.find(
            (e) => e.name == cookieKey.twdToken,
          );
          if (twdAccessToken) {
            const customerName = cookies.find(
              (e) => e.name == cookieKey.customerName,
            );
            const twdRefreshToken = cookies.find(
              (e) => e.name == cookieKey.twdRefreshToken,
            );
            const ref = cookies.find((e) => e.name == cookieKey.ref);
            setAPIToken({
              twdAccessToken: twdAccessToken,
              customerName: customerName,
              ref: ref,
              twdRefreshToken: twdRefreshToken,
            });
            state.twdAccessToken = (twdAccessToken.val ?? '').toString();
            state.customerName = (customerName?.val ?? 'unknown').toString();
            state.isAuthenticated = true;
            state.loginMethod = 'Facebook';
          } else {
            state.error = action.payload.errorMessage;
          }
        } else {
          state.error = action.payload.errorMessage;
        }
        state.error = undefined;
      })
      .addCase(facebookLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'systemloginerror';
      });
    builder
      .addCase(usernameLogin.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(usernameLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        const cookies = action.payload.cookies;
        if (cookies && cookies.length > 0) {
          const twdAccessToken = cookies.find(
            (e) => e.name == cookieKey.twdToken,
          );
          if (twdAccessToken) {
            const customerName = cookies.find(
              (e) => e.name == cookieKey.customerName,
            );
            const twdRefreshToken = cookies.find(
              (e) => e.name == cookieKey.twdRefreshToken,
            );
            const ref = cookies.find((e) => e.name == cookieKey.ref);
            setAPIToken({
              twdAccessToken: twdAccessToken,
              customerName: customerName,
              ref: ref,
              twdRefreshToken: twdRefreshToken,
            });
            state.twdAccessToken = (twdAccessToken.val ?? '').toString();
            state.customerName = (customerName?.val ?? 'unknown').toString();
            state.isAuthenticated = true;
            state.loginMethod = 'UsernamePassword';
          } else {
            state.error = 'systemloginerror';
          }
        } else {
          state.error = 'systemloginerror';
        }
      })
      .addCase(usernameLogin.rejected, (state, action) => {
        let errorType: string = '9';
        let errorMessage: string = 'systemloginerror';
        try {
          errorType = action.error.message ?? '9';
        } catch (_) {}
        switch (errorType) {
          case '1':
            state.error = 'signin_unsuccessful';
            break;
          case '2':
            state.error = 'systemloginerror';
            break;
          default:
            state.error = errorMessage;
        }
        state.isLoading = false;
        state.isAuthenticated = false;
        state.customerName = undefined;
        state.customerId = null;
      });
  },
});

export const {
  setCustomerId,
  initialLoginForm,
  signOut,
  clearError,
  clearOtpRequestId,
  clearIsAuthenticated,
  setIsSigninWithUsername,
  clearIsBannedUser,
} = loginSlice.actions;

export const customerNameSelector = (
  store: RootState,
): string | undefined | null => {
  return store.user.customerName;
};

export const customerIdSelector = (
  store: RootState,
): string | undefined | null => {
  return store.user.customerId;
};

export const usernameSelector = (store: RootState): string | undefined =>
  store.user.authenInfoData?.username?.value;

export const isLoadingSelector = (store: RootState): boolean =>
  store.user.isLoading;

export const isAuthenticatedSelector = (store: RootState): boolean =>
  store.user.isAuthenticated;

export const errorCodeSelector = (store: RootState): string | undefined =>
  store.user.error;

export const authenMethodSelector = (
  store: RootState,
): Authenticate | undefined => store.user.authenInfoData?.authenticate;

export const isIdentitySelector = (store: RootState): Identity | undefined =>
  store.user.authenInfoData?.identity;

export const isRequestOtpSelector = (store: RootState): string | undefined =>
  store.user.requestOtpData?.requestID;

export const isNewLoginUserSelector = (store: RootState): boolean | undefined =>
  store.user.isNewLoginUser;

export const isNewLoginRequestNameSelector = (
  store: RootState,
): boolean | undefined => store.user.isNewLoginRequestName;

export const isSetupPinSelector = (store: RootState): boolean | undefined =>
  store.user.isSetupPin;

export const currentSetUpPinStepSelector = (store: RootState): SetUpPinStep =>
  store.user.currentSetUpPinStep;

export const isUsernamePasswordSinginSelector = (store: RootState): boolean =>
  store.user.isUsernamePasswordSingin;

export const loginMethodSelector = (store: RootState): string | undefined =>
  store.user.loginMethod;

export const isBannedUserSelector = (store: RootState): boolean | undefined =>
  store.user.isBannedUser;

export default loginSlice.reducer;
