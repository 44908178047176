import { ContactUsRequest } from '@/models/ContactUs.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const requestContact = async ({
  sendData,
}: {
  sendData: ContactUsRequest;
}): Promise<{
  error: boolean;
}> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({ path: '/postcontact', body: sendData }),
  ]);
  if (getApiData.status === 200) {
    if (getApiData.data.error === true) {
      return { error: true };
    } else {
      return { error: false };
    }
  } else {
    return { error: false };
  }
};
