export const defaultStoreCode = '920';
export const defaultPickupStoreCode = '920';
export const pageToShowCompareList = [
  '',
  'product',
  'category',
  'page',
  'search',
  'clearance',
];
export const pageToHideChat = ['cart', 'checkout', 'paylater', 'repayment'];
export const pageToShipCompareToTop = [
  {
    page: 'product',
  },
];
export const pageToShipCookiePolicyToTop = [
  {
    page: 'product',
    height: '55px',
  },
  {
    page: 'cart',
    height: '67px',
  },
  {
    page: 'checkout',
    height: '78px',
  },
];
export const pageToShowTopFooter = ['/'];
export const exceptRedirectList = ['/', '/signin', '/register'];
export const exceptHybridPopup = ['/cart', '/checkout', '/christmas'];
export const exceptStaticHeader = [
  '/brand',
  '/search',
  '/category',
  '/signin',
  '/register',
  '/cart',
  '/checkout',
  '/thank',
  '/profile',
  '/paymentfail',
  '/repayment',
];
export const serviceAtbFlags = ['V', 'XV'];
export const currencyCode = 'THB';
export const notRetryStatusCode = [203, 204, 401, 500];
export const notRefreshError = [
  'incorrectcardnumber',
  'incorrectcardholdername',
  'incorrectexpirydate',
  'incorrectexpirydate',
  'incorrectcvv',
  'qrwillavailableinfuture',
];

export const fcmServerKey =
  process.env.NEXT_PUBLIC_NODE_ENV === 'development'
    ? {
        type: 'service_account',
        project_id: 'twd-app-uat',
        private_key_id: 'ca6772d0a0af9755078ba0a57884c609ef5d6e98',
        private_key:
          '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDS+wIR3ZUv7xSW\nLMW/tdvEjncAikZczInVKmUe0WDHdIzpJ8KGtXuCXy4mDXJbPHX7qGvh0uYYGknM\np16hIIK+W4r5qYUsMJ5wWKu7wPTd6c5X4muaWOuUPjllfTe8xyy7whS4jA/FF3kJ\n92qT0/qNjCrmU9/LHQnPte55Oyh5mnreqF/cyzK5ovFFgQ4V2RWfqw/qLt4MIB57\nc8h+F57VKCNV0Q398MKcMDZVXR4QPtwIFKh08U9YnpWDK5SneB36BD847biUmZzw\n59WJu6oyW4nEhy+MMHzuVtKahaHpKt3Xl1TA1+Q0OE+uywXtLRjDq/x5Odo3mB7t\n8VciuNg5AgMBAAECggEAHy/CaRCnhlvhX6eDnaiMsVvbxGUsLHiBoVRweSM7GpCk\naayaaR43gRh/sllHf6wKxqXpOIrbv1FXelx9NPUgznGqs+f2iwcuzG2XCRxnNcxj\nV4NdI4YkYJfoFRPy4sFQX/q65GKzzA8BsdL0WqC5hyXii4R3bsc+lYkPlLUFcR/7\nLhjoNxgJ+Wu2Chgpnoe0sIZr6j35VDbHmS3jNx2xpjmlrBkyX6l4MLcDHUjI+wLW\nGGgk+NhxdYHpVnVuM74QWp/Jc+Aun7LHWYqczDoEW+u1iqhoIVpIxopxHyg6uVAs\nT5KVmMDrhzym4bqj8C0BhU+ab9y9avkBW+IzWS6fpwKBgQD+ouSRYH/AAIeqeamO\nQVlv9PlJGdYRtY8DM+uWgt3h5NJPJ471EL4IXGvZ/IK/fpRuLBElEjb9hl6TkMFY\n/YTbbGqWqt8luniUn8BquLvfpHu5BP0voRcUXN3YOt0wMF9GgAzOzmD4mmpwv+ZG\nVupnPay9oIqgonWp8jbaWldYfwKBgQDUHENUSuXufUluJkCaMqvuVOZufpaelxHA\nmbCyETASiLz/da1pznS0FkXuVCAdAxBPnzOchFE3VKowykRftD/QH9miHa141snr\nJc2t8TQ0XWGRLzdEHdNqlMvl3VfSffYAVqHW64pgHwnQjCcm6q3icUNCS8A/u/Fj\ngW2ikyIzRwKBgEbIrVUkSB7thskC7PlYfS48IRgLEJD3sXuocTLzyg5Dd5r9WiYR\ns2HLn0eHhCkLXOOLaHCvsY2kTqIiuTytrML6Tl73vPRV87MM/YYtODyhEquKEX+t\n/iZw8lJrepUsvS9x5R2QDTDObAxPrAmdm6d7Y4uLFz40KxVE+a6VMnatAoGAJZNk\nMhiapyc2zPal1ytG3g/YSpbOZAXrNS2E6ea1l3L12GOmduoGLqkLdm8cyUbzqsdP\nQ1kFckHjbIVIm5O5AkvCKEANrSyP1MJ7r2bdkrFASZINvllyTJ1TicMufbGm1OSE\nAyjy+JhHCtTpUrGcYFMkUShORHwtBS22mGhsVJ0CgYAOQST8LISoegd+PVpew60M\nbNb/QaAM6B5PNAzkDqhuR3nJL53skZ1tWJ8WkNqtKALhloIIoVguvV7vpBDCo/B9\nZxC0qTB8n6yFyrejfSeZDanLmDyJL6AFs1YLzFdYMPuCqebPKQaw5sxgMPhZecvF\nkETCw+OW6Q7/9PuD7EwDRQ==\n-----END PRIVATE KEY-----\n',
        client_email:
          'firebase-adminsdk-de7ud@twd-app-uat.iam.gserviceaccount.com',
        client_id: '113564546529320136392',
        auth_uri: 'https://accounts.google.com/o/oauth2/auth',
        token_uri: 'https://oauth2.googleapis.com/token',
        auth_provider_x509_cert_url:
          'https://www.googleapis.com/oauth2/v1/certs',
        client_x509_cert_url:
          'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-de7ud%40twd-app-uat.iam.gserviceaccount.com',
      }
    : {
        type: 'service_account',
        project_id: 'twd-app-prod',
        private_key_id: 'd54656a47998ac2929efc876eac787e22a67bf1a',
        private_key:
          '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCx2JyPjCOgOPQV\nslebQ70qxUuEpKe+cHokUl0S0q94sPjbHdQyMtB0mCq3Gnsz0IyX3Ce93oAWucu3\nP7vkRbCA8l2lxPTuitd5LP0CNBZw6xKz+N/7vE+tfYyigZ2Of1woUmSxZUYgzoVZ\nlDy9gTDzAvgEEkGaekw52vsWZ3B8ipwEhwVFu3czq2NIwNVlUYdwbPPMqquFvQqx\nbVVniMSlUsq2FMar6SG/Ag+eDwwPGbPbwfx1p3p8/1ssohYzxOgjEwjIfJ4sd5D4\nqjlKZW+bsKv8XeqBMiyyuRBcnQTvqB5STe15U72mjESXtdlS53He3WrXc/at4cdg\nG1meoP4VAgMBAAECggEAGzYc8lvLYasFMcKpY+DP81jfrqpw3hMgpSkTA5vH7GwL\nj583Rfz/Zy+XLY/4NNvefyfYztTZ/WAtOtseGRXOpVQwHvqNTWhiArDu/2TP4DQf\nlvjn8IEGIfH5UC4L2O9EvwioTnpD6iYfQi8LwiUfw7b7EshmUEgRHLMUQv/NtshP\nGUxlKNOTQgoXRmrOypml5qxfpKpWdCOm5Y1kRv44L3KB6RdzpsA+lNb8cvdpOz64\nGLUWyVZkPYFHiI0qWgdhJJb8eaneMtrLhV/UoC+dItY7rVIrESD2mdFS/bYRkmIP\nQ6punnHmTu8w95zV3ywLM7qTOwkaUsc8KzetsfjDAQKBgQDkBuMQmVWydolQyhB2\nzjdcBguFpMc65aVAIt+OloZ5qoILJk6cSo2POBDsS2Gqup7rUwVad73KUGs8IbU8\nXXwaOSwsZHIDUJNgxWj/OvI26mnVqMlfxINfO/yqcyIZsQl9IHgxnXDGRvRnyw0L\nf1RtJuKrlrwySD5KhLroDBUogQKBgQDHqdBDh4BA5mfmpbJEIYa8KUkN1LZ94Dvd\nKbDlr1XSYrD0XhIzKEnb5ciNDklZczgAXqIr2seAdirwAiNLselLAqzXwNiiRQk1\nrAo/lk4bDbx3IyQzcW7YN6qfGQ2rcmdKQo1JKB46LSV3TEmxcy0K+rHL9ryb0glg\n4+hXSSLrlQKBgQCORWXC44i+korWZ1yiY8PSnMrvlYHg04upWpjVZEF/A82jm0EM\n7s8n/rlAVUAYv9uLw/7hFTjLVCW0HOvxP/rAec9/2yPvwYRSqpj6NhMOBHAIKQmL\nwRaegtUAnFk4YmcjNPEJM8w7SeYQJHZ9J/LLL6nZmXDOhpim8e2T/oXtAQKBgEHP\nHd70LXi2uK5UFPLR3hfsv7B4WQIaQ4D8+dpqtS3X651P2E3WTKnXDgg4GkMKb/3n\nMyqjPLiBultLSRjs9gkMQ64FWSH7ATMYlnQo9WuDVogqJAd0ruVDS9COsGu/FH5s\neycOuw/Py5aLpYqAfOsugCXLGJV/YCn4VJ9YctMVAoGAXevPQIShM4hHTHbVY1K9\nay3R7Ae//JnvegWuGyLlicjHnTdYHolW9XIbGP1cmgwx5Noz/3blGj7N0fLRREWc\nPmyUWbh30uIO/EsuytlgmKfwBK+RJX0x1HMfpq8+Pa8RbT5+n6SkMZdyyPrxQA9l\nvPcUXAjA5SWKq7DjciEc1yA=\n-----END PRIVATE KEY-----\n',
        client_email:
          'firebase-adminsdk-tvriv@twd-app-prod.iam.gserviceaccount.com',
        client_id: '111339556490655241816',
        auth_uri: 'https://accounts.google.com/o/oauth2/auth',
        token_uri: 'https://oauth2.googleapis.com/token',
        auth_provider_x509_cert_url:
          'https://www.googleapis.com/oauth2/v1/certs',
        client_x509_cert_url:
          'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-tvriv%40twd-app-prod.iam.gserviceaccount.com',
      };

export const exceptLayoutList = ['/kbank/kborder', '/appcall2c2p'];

export const subStringList: string[] = ['Z'];

export const constructionClass: string[] = ['51'];

export const paintClass: string[] = ['601001', '601002'];

export const wallPaperCatCode: string[] = ['09040302', '09040303'];

export const curtainClass: string[] = ['719900'];

export const chatAndShopUrlThaiwatsadu = 'http://line.me/ti/p/@thaiwatsadu';

export const chatAndShopUrlVfix =
  'https://page.line.me/810rpkgr?openQrModal=true';

export const skuSurvey = '60331589';

export const tailorMadePath = '/category/ผ้าม่านสั่งตัด-719900';

export const longtailAtbFlags = ['ER', 'LR', 'C', 'SH'];

export const defaultMapInit = {
  center: {
    lat: 13.6524,
    lng: 100.6767,
  },
  zoom: 13,
};

export const t1Timeout = 65000;

export const staticLang = ['common', 'errorncpn'];

export const bypassLoginPageList = [
  '/profile/accountsummary',
  '/profile/editinfo',
  '/checkout',
  '/repayment',
];

export const requiredLoginPathList = ['/profile', '/checkout'];
