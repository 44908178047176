import {
  CookieDataConverter,
  CustomerInfoConverter,
  ErrorCodes,
  IdentityResponse,
  T1ErrorConverter,
} from '@/models/Authen.model';
import { postData } from '@/utils/httpClient';

export interface sendNewLoginRequest {
  firstName?: string;
  lastName?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  version?: string;
  consent?: boolean;
}

export const sendNewLogin = async ({
  firstName,
  lastName,
  utmSource,
  utmMedium,
  utmCampaign,
  version,
  consent,
}: sendNewLoginRequest): Promise<IdentityResponse> => {
  const [getDataApi] = await Promise.all([
    postData({
      path: `/api/v2/auth/signin`,
      body: {
        first_name: firstName,
        last_name: lastName,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        version,
        consent,
      },
    }),
  ]);

  const apiData =
    getDataApi.status == 200
      ? getDataApi.data
        ? getDataApi.data
        : undefined
      : undefined;

  let response: IdentityResponse | undefined = apiData;

  if (response) {
    try {
      response = {
        info: apiData.info
          ? CustomerInfoConverter.fromJson(apiData.info)
          : undefined,
        cookies: apiData.cookies
          ? apiData.cookies.map((e: any) => CookieDataConverter.fromJson(e))
          : undefined,
        error: apiData.error
          ? T1ErrorConverter.fromJson(apiData.error)
          : undefined,
      };
    } catch (_) {}
  } else {
    response = {
      error: {
        code: ErrorCodes.unknownError,
        message: getDataApi.data ?? 'Internal Server Error',
      },
    };
  }

  return response;
};
