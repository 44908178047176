import { AllCat } from '@/models/AllCat.model';
import axios from 'axios';

export const getAllCats = async (): Promise<AllCat[] | undefined> => {
  try {
    const data = await axios.get(
      `${process.env.NEXT_PUBLIC_BASE_URL_API}/allcats`,
    );
    return data?.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};
