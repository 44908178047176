import { StoreData } from '@/models/Store.model';

export const getDistance = (
  currentLat: number,
  currentLng: number,
  destinationLat: number,
  destinationLng: number,
): number => {
  const radlat1 = (Math.PI * currentLat) / 180;
  const radlat2 = (Math.PI * destinationLat) / 180;
  const theta = currentLng - destinationLng;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (true) {
    dist = dist * 1.609344;
  }
  return dist;
};

export const branchNameLang = ({
  store,
  lang,
  storeName,
}: {
  store?: StoreData;
  lang?: string;
  storeName?: string;
}): string => {
  let result = store?.name ?? storeName ?? '';
  switch (lang) {
    case 'en':
      const nameEn = store?.nameEN ?? store?.name ?? storeName ?? '';
      result = nameEn ? `${nameEn} branch` : '';
      break;

    default:
      const nameTh = store?.name ?? storeName ?? '';
      result = `สาขา ${nameTh}`;
      break;
  }
  return result;
};

export const mapStoreList = (apiData: { dbcode: boolean; dbitems: any }) => {
  const storeList: StoreData[] = [];
  try {
    if (apiData && apiData.dbcode === true && apiData.dbitems.length > 0) {
      const listData = apiData.dbitems[0].listdata;
      const hybridStore = apiData.dbitems[0].hybridStore;
      const storeStringSets = listData.split('|');
      for (let i = 0; i < storeStringSets.length; i++) {
        const storeStringSet = storeStringSets[i];
        const storeItems = storeStringSet
          .split(',')
          .map((e: any) => e.toString().trim());
        if (storeItems.length >= 12) {
          storeList.push({
            code: storeItems[0],
            name: storeItems[1]?.replace('สาขา', ''),
            latitude: parseFloat(storeItems[2]),
            longitude: parseFloat(storeItems[3]),
            phoneNumber: storeItems[4],
            openTime: storeItems[5],
            closeTime: storeItems[6],
            address: storeItems[7],
            addressEN: storeItems[13] ?? storeItems[7] ?? '',
            pickupTimeStart: storeItems[8],
            pickupTimeEnd: storeItems[9],
            nameEN: storeItems[12] ?? storeItems[1]?.replace('สาขา', '') ?? '',
            isHybrid: hybridStore.includes(storeItems[0]),
            newStoreOpenDate: storeItems[10],
            flag: parseInt(storeItems[11]),
          });
        }
      }
    }
  } catch (_) {}

  return storeList;
};
