import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { RootState } from '@/store/store';
import {
  getOrderhistoryRating,
  saveRating,
} from '@/services/client/orderhistoryService';
import { RatingPageResponse } from '@/models/profile/Rating.model';

interface OrderhistoryRatingState {
  result?: RatingPageResponse | null;
  isLoading: boolean;
  error?: string;
  //submit rating
  isLoadingSubmitRating: boolean;
  errorSubmitRating?: string;
  saveSubmitRatingSuccess?: boolean;
}

const initialState: OrderhistoryRatingState = {
  isLoading: false,
  isLoadingSubmitRating: false,
};

export const profileOrderhistoryRatingQuery = createAsyncThunk(
  'orderhistory/rating/query',
  async ({ lang, order }: { lang?: string; order?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getOrderhistoryRating({
      lang,
      order,
    });
    if (!response || !response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const submitRating = createAsyncThunk(
  'orderhistory/rating/submit',
  async ({
    rating,
    wating,
    isGetCompleteProduct,
    comment,
    order,
    lang,
  }: {
    rating: number;
    wating: number;
    isGetCompleteProduct: boolean;
    comment?: string;
    order: string;
    lang?: string;
  }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const sendData = {
      rating,
      wating,
      isGetCompleteProduct,
      comment: comment ? comment : '',
      order,
    };
    const response = await saveRating({
      order,
      sendData,
      lang,
    });
    if (!response || response.status !== 200) {
      throw new Error(`${response.data ?? 'Error.'}`);
    }
    return response;
  },
);

export const OrderhistoryRatingSlice = createSlice({
  name: 'orderhistoryRating',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
      state.errorSubmitRating = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
    clearSuccess: (state) => {
      state.saveSubmitRatingSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileOrderhistoryRatingQuery.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(profileOrderhistoryRatingQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = action.payload?.data ?? null;
        }
      })
      .addCase(profileOrderhistoryRatingQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
      });
    builder
      .addCase(submitRating.pending, (state) => {
        state.isLoadingSubmitRating = true;
        state.saveSubmitRatingSuccess = undefined;
        state.errorSubmitRating = undefined;
      })
      .addCase(submitRating.fulfilled, (state) => {
        state.isLoadingSubmitRating = false;
        state.saveSubmitRatingSuccess = true;
      })
      .addCase(submitRating.rejected, (state, action) => {
        state.isLoadingSubmitRating = false;
        state.saveSubmitRatingSuccess = undefined;
        state.errorSubmitRating = action.error.message;
      });
  },
});

export const { clearError, clearResult, clearSuccess } =
  OrderhistoryRatingSlice.actions;

export const orderhistoryRatingResultSelector = (
  store: RootState,
): RatingPageResponse | undefined | null => store.orderhistoryRating.result;

export const isLoadingOrderhistoryRatingSelector = (
  store: RootState,
): boolean => store.orderhistoryRating.isLoading;

export const errorOrderhistoryRatingSelector = (
  store: RootState,
): string | undefined => store.orderhistoryRating.error;

export const isLoadingSubmitRatingSelector = (store: RootState): boolean =>
  store.orderhistoryRating.isLoadingSubmitRating;

export const saveSubmitRatingSuccessSelector = (
  store: RootState,
): boolean | undefined => store.orderhistoryRating.saveSubmitRatingSuccess;

export const errorSubmitRatingSelector = (
  store: RootState,
): string | undefined => store.orderhistoryRating.errorSubmitRating;

export default OrderhistoryRatingSlice.reducer;
