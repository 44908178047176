import { modelValue } from '@/utils/modelValue';

export interface GetPDPBadgeModel {
  msg?: any[];
  msgtier?: Msgtier[];
  msgbonus?: MsgBonus[];
}

export interface Msgtier {
  protype?: number;
  ctlid?: number;
  bstep?: number;
  message?: string;
  message2?: string;
  minValue?: number;
  bValue?: number;
  proPrice?: number;
}

export interface MsgBonus {
  protype?: number;
  ctlid?: number;
  bstep?: number;
  message?: string;
  message2?: string;
  minValue?: number;
  bValue?: number;
  proPrice?: number;
}

export class GetPDPBadgeModelConverter {
  public static fromJson(json: any): GetPDPBadgeModel {
    const model: GetPDPBadgeModel = {
      msg: json['msg'],
      msgtier:
        json['msgtier'] == null
          ? []
          : json['msgtier']
              .map((x: any) => MsgtierConverter.fromJson(x))
              ?.sort(
                (a: Msgtier, b: Msgtier) => (b.bstep ?? 0) - (a.bstep ?? 0),
              ),
      msgbonus:
        json['msgbonus'] == null
          ? []
          : json['msgbonus']
              .map((x: any) => MsgBonusConverter.fromJson(x))
              ?.sort(
                (a: MsgBonus, b: MsgBonus) => (b.bstep ?? 0) - (a.bstep ?? 0),
              ),
    };
    return modelValue(model);
  }
}

export class MsgtierConverter {
  public static fromJson(json: any): Msgtier {
    const model: Msgtier = {
      protype: json['PROTYPE'],
      ctlid: json['CTLID'],
      bstep: json['BSTEP'],
      message: json['MESSAGE'],
      message2: json['MESSAGE2'],
      minValue: json['MINVALUE'],
      bValue: json['BVALUE'],
      proPrice: json['proprice'],
    };

    return modelValue(model);
  }
}

export class MsgBonusConverter {
  public static fromJson(json: any): MsgBonus {
    const model: MsgBonus = {
      protype: json['PROTYPE'],
      ctlid: json['CTLID'],
      bstep: json['BSTEP'],
      message: json['MESSAGE'],
      message2: json['MESSAGE2'],
      minValue: json['MINVALUE'],
      bValue: json['BVALUE'],
      proPrice: json['proprice'],
    };

    return modelValue(model);
  }
}
