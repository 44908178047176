import { Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useState, useEffect, useRef } from 'react';
import headerClass from '@/styles/header.module.css';
import { useTranslation } from 'next-i18next';
import AppLink from '@/components/Layout/handler/AppLink';
import HeroMenu from '@/components/homepage/HeroMenu';
import { useRouter } from 'next/router';
import PromotionHover from './PromotionHover';
import Image from 'next/image';

export default function BottomHeader() {
  const exceptPath: string[] = ['/'];
  const { pathname } = useRouter();
  const { t, i18n } = useTranslation();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const timerIdRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setShowMenu(false);
    return () => {
      clearTimeout(timerIdRef.current);
    };
  }, [pathname]);

  const handleMouseEnter = () => {
    if (!showMenu && !exceptPath.includes(pathname)) {
      timerIdRef.current = setTimeout(() => {
        setShowMenu(true);
      }, 500);
    }
  };

  const handleMouseLeave = () => {
    if (showMenu) {
      clearTimeout(timerIdRef.current);
      setShowMenu(false);
    }
  };

  return (
    <div className="hidden lg:flex max-width-container relative">
      <Grid container height="2rem">
        <div className="absolute w-full top-5">
          {showMenu && <HeroMenu mouseLeave={handleMouseLeave} noSkeleton />}
        </div>
        <Grid item xs={2} className="self-center">
          <div
            className="flex"
            onClick={() => {
              if (!exceptPath.includes(pathname)) {
                setShowMenu(!showMenu);
              } else {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                });
              }
            }}
            onMouseLeave={() => {
              clearTimeout(timerIdRef.current);
            }}
            onMouseEnter={handleMouseEnter}
          >
            <MenuIcon
              className="self-center cursor-pointer"
              sx={{ height: 24, color: '#231F20' }}
            />
            <div className="self-center f-kitti75 select-none cursor-pointer">
              {t('allcategories')}
            </div>
          </div>
        </Grid>
        <Grid item xs={10} className="self-center">
          <div className="flex px-2 justify-between items-center leading-4">
            <AppLink className={headerClass.navMenu} href="/brand">
              <div>{t('shopbybrands')}</div>
            </AppLink>
            <AppLink className={headerClass.navMenu} href="/store">
              <div>{t('storelocations')}</div>
            </AppLink>
            <AppLink className={headerClass.navMenu} href="/brochures">
              <div>{t('catalogs')}</div>
            </AppLink>
            <AppLink
              className={headerClass.navMenu}
              href="/category/บริการติดตั้ง-vFIX-ช่างมือ-1-96"
            >
              <div>{t('vfixhomeservices')}</div>
            </AppLink>
            <PromotionHover />
            <AppLink href="/clearance">
              <div
                className={`hover:bg-[#FC261F] hover:from-[#FC261F] bg-gradient-to-l from-[#D4DE23] from-[38.84%] via-[#ff161f55] via-[20%] to-[#ff161f00] to-[100%] w-[175px] flex justify-end rounded-full ${
                  i18n.language === 'th' ? '' : 'pe-1.5'
                } `}
              >
                <Image
                  src={`/images/icons/header/clearance_${
                    i18n.language
                      ? i18n.language === 'th'
                        ? 'th'
                        : 'en'
                      : 'th'
                  }.png`}
                  alt="clearance"
                  width={134}
                  height={32}
                />
              </div>
            </AppLink>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
