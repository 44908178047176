import { postData } from '@/utils/httpClient';

export const sendSignOut = async (): Promise<boolean | undefined> => {
  const [getDataApi] = await Promise.all([
    postData({ path: `/api/v2/auth/signout` }),
  ]);

  const apiData =
    getDataApi.status == 200
      ? getDataApi.data
        ? getDataApi.data
        : undefined
      : undefined;

  let response: boolean = apiData ?? false;

  return response;
};
