import '@/styles/globals.css';
import '@/styles/bootstrap-grid.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'tailwindcss/tailwind.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { store } from '@/store/store';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material';
import Layout from '@/components/Layout';
import AllCatContext from '@/contexts/AllCatContext';
import { SWRConfig } from 'swr';
import Script from 'next/script';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import { AllCat, AllCatConverter } from '@/models/AllCat.model';
import { getAllCats } from '@/services/client/apiService';
import { getGuestID } from '@/utils/algolia';
import '../public/assets/css/smartbanner.css';
import SmartBanner from '../public/assets/js/smartbanner/smartbanner';
import { useRouter } from 'next/router';

function App({ Component, pageProps }: AppProps) {
  const [allcats, setAllcats] = useState<AllCat[]>([]);
  const router = useRouter();
  const theme = createTheme({
    components: {},
    breakpoints: {
      values: {
        xs: 0,
        sm: 480,
        md: 768,
        lg: 976,
        xl: 1360,
      },
    },
    typography: {
      fontFamily: 'kittithada',
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    spacing: 8,
    palette: {
      primary: {
        main: '#B8292F',
      },
      secondary: {
        main: '#FFFFFF',
      },
      background: {
        default: '#FE5000',
      },
    },
  });

  // Global SWR Config
  const swrConfig = {
    // revalidateIfStale: false,
    validateOnFocus: false,
    revalidateOnFocus: false,
    // revalidateOnReconnect: false,
  };

  useEffect(() => {
    getGuestID();
    getAllCats().then((res) => {
      setAllcats((res ?? []).map((e) => AllCatConverter.fromJson(e)));
    });
  }, []);

  useEffect(() => {
    //Start smartbanner
    let smartbanner;
    // const uriScheme = 'powerbuy://';
    smartbanner = new SmartBanner();
    // smartbanner.checkApp();
    smartbanner.publish();
    //End smartbanner
  }, [router]);
  return (
    <>
      <Head>
        <title>THAI WATSADU | ไทวัสดุ ครบเรื่องบ้าน ถูกและดี</title>
        <meta httpEquiv="Expires" content="-1" />
        <meta charSet="uft-8" />
        <link
          rel="manifest"
          href="/manifest.json"
          crossOrigin="use-credentials"
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#B8292F" />
        <meta
          name="google-site-verification"
          content="Zx8ilUEpqBPafWIw0mBkNWLeEJajKJHRDHr-8EjjC7Q"
        />
        <link rel="apple-touch-icon" href="/icon.png" />
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <meta property="og:locale" content="th_TH" />
        <meta property="og:locale:alternate" content="en_US" />

        <meta name="smartbanner:title" content="THAI WATSADU" />
        <meta
          name="smartbanner:author"
          content="ไทวัสดุ ครบเรื่องบ้าน ถูกและดี"
        />
        <meta name="smartbanner:price" content="FREE" />
        <meta
          name="smartbanner:price-suffix-apple"
          content=" - On the App Store"
        />
        <meta
          name="smartbanner:price-suffix-google"
          content=" - In Google Play"
        />
        <meta
          name="smartbanner:icon-apple"
          content="https://is1-ssl.mzstatic.com/image/thumb/Purple122/v4/10/89/25/10892573-4c6b-fcac-96de-18b1643bc593/AppIcon-prod-0-0-1x_U007emarketing-0-7-0-0-85-220.png/230x0w.webp"
        />
        <meta
          name="smartbanner:icon-google"
          content="https://play-lh.googleusercontent.com/NvHy_9WifVLCKxBXdisNT-VO-JVTwNZ3wcy10x00FkYO7Mf4EZRuHNOaHh0OlPzYJ8U=w240-h480-rw"
        />
        <meta name="smartbanner:button" content="Open" />
        <meta
          name="smartbanner:button-url-apple"
          content="https://apps.apple.com/th/app/thaiwatsadu/id1606151700?l=th"
        />
        <meta
          name="smartbanner:button-url-google"
          content="https://play.google.com/store/apps/details?id=com.thaiwatsadu.ecommerce"
        />
        <meta name="smartbanner:enabled-platforms" content="android,ios" />
        <meta name="smartbanner:close-label" content="Close this banner" />
        <meta name="smartbanner:api" content="true" />
        <meta
          name="apple-itunes-app"
          content="app-id=1606151700, affiliate-data=thaiwatsadu, app-argument=https://apps.apple.com/th/app/thaiwatsadu/id1606151700?l=th, app-clip-bundle-id=com.thaiwatsadu.ecommerce, app-clip-display=card"
        />
        {router.asPath.split('/')[1] !== 'product' && (
          <link
            rel="canonical"
            href={`${process.env.NEXT_PUBLIC_BASE_URL_WEB}/${
              router.locale
            }${decodeURIComponent(router.asPath)}`}
          />
        )}
      </Head>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
      `}
      </Script>
      <Script id="appier" type="text/javascript">
        {`!function(q,g,r,a,p,h,js) {
        q.appier=q.qg;
        if(q.qg)return;
        js=q.appier=q.qg=function() {
          js.callmethod ? js.callmethod.call(js, arguments) : js.queue.push(arguments);
        };
        js.queue=[];
        p=g.createElement(r);p.async=!0;p.src=a;h=g.getElementsByTagName(r)[0];
        h.parentNode.insertBefore(p,h);
      } (window,document,'script','https://cdn.qgr.ph/qgraph.c6dc127dfbb8b1e49927.js');
      `}
      </Script>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AllCatContext.Provider value={allcats}>
            <SWRConfig
              value={(validateConfig) => ({
                ...validateConfig,
                ...swrConfig,
              })}
            >
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </SWRConfig>
          </AllCatContext.Provider>
        </ThemeProvider>
      </Provider>
    </>
  );
}
export default appWithTranslation(App);
