import { modelValue } from '@/utils/modelValue';

export interface AllCat {
  node: string;
  catname: string;
  catnameEN: string;
  lv: number;
  havenext: boolean;
  slugname: string;
  subtitle: string;
  description: string;
  showcate: boolean;
  image?: string;
  items?: AllCat[];
  base64img: string;
  sort: number;
  isHybrid?: boolean;
}
export class AllCatConverter {
  public static fromJson(json: any): AllCat {
    const model: AllCat = {
      node: json['node'],
      catname: json['catname'],
      catnameEN: json['catnameEN'] ? json['catnameEN'] : json['catname'],
      lv: json['lv'],
      havenext: json['HAVENEXT'] === true ? true : false,
      slugname: json['slugname'],
      subtitle: json['subtitle'],
      description: json['description'],
      showcate: json['showcate'],
      image: json['image'],
      base64img: json['base64img'],
      sort: json['sort'],
      items: json['items']
        ? json['items'].map((item: any) => AllCatConverter.fromJson(item))
        : undefined,
      isHybrid: json['isHybrid'] === true ? true : false,
    };
    return modelValue(model);
  }
}
