/* eslint-disable no-unused-vars */
import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from './Authen.model';
import { Address, AddressConverter } from './Address.model';
import { ProductModel, ProductModelConverter } from './Product.model';
import { PlppDetail, PlppDetailConverter } from './MyCart.model';
import { VoucherUsed, VoucherUsedConverter } from './profile/MyVoucher.model';
import {
  CheckStockSkuResponse,
  CheckStockSkuResponseConverter,
} from './Stock.model';
import { ShipmentType } from '@/services/server/shipmentPageService';

export enum ErrorNextPay {
  mobile,
  email,
  deliverySlot,
  pickupSlot,
  serviceSlot,
  message,
  deliveryAddress,
  taxinvoiceAddress,
  serviceAddress,
  pleasePinAddress,
}

export enum PaymentMethod {
  credit = '2c2p',
  instllment = '2c2pin',
  qrcode = 'kasikorn',
  t1cp = 'T1CP',
  billpayment = 'TKBN',
}

export interface CallPaymentRequest {
  paymentMethod: PaymentMethod;
  amount: number;
  deliveryInput: string;
  isRequestTax?: boolean;
  receiveWith: 'S' | 'E';
  receiveValue: string;
  markPan?: string | null;
  markSName?: string | null;
  inst?: number;
  plan?: number;
  deliveryType?: string;
  dcsAvailable?: boolean;
  slotSelected: SlotSend[];
  ref?: string;
  lang?: string;
  type?: ShipmentType;
  isRePayment?: boolean;
  stcode?: string;
}

export interface SlotSend {
  slotposition: number;
  slotcode: string;
  slotdlvdate: string;
  slotname: string;
  shiptype: string;
  isLongtail?: 'Y' | 'N';
  goWith?: 0 | 1 | 9;
  sku?: string[];
  upcCode?: string;
  vendor: string;
  homeBranch?: '' | 'H' | 'B';
  isSameday?: boolean;
}

export interface SelectedSlot {
  running?: number;
  slotCode: string;
  timeSlotName: string;
  deliveryDate: string;
  isRemove?: boolean;
  homeBranch?: '' | 'H' | 'B';
  isSameday?: boolean;
}

export interface CallPaymentResponse {
  isError?: boolean;
  messageKey?: string;
  isVerifyPin?: boolean;
  isRedirect?: boolean;
  qrCodeData?: QRCodeData;
  paymentMethod?: PaymentMethod;
  the1RequestId?: string;
  ref?: string;
  products?: ProductModel[];
  billData?: BillData;
  errorCode?: string;
}

export interface BillData {
  ref: string;
  status?: number;
  refGen?: string;
  orderExpireTime?: number;
  totalAmount?: number;
  refNo1?: string;
  refNo2?: string;
  companyCode?: string;
  companyId?: string;
  companyTax?: string;
  customerName?: string;
  orderExpireDateShow?: string;
}

export class BillDataConverter {
  public static fromJson(json: any): BillData {
    const model: BillData = {
      ref: json['ref'],
      status: json['status'],
      refGen: json['refgen'],
      orderExpireTime: json['orderExpireTime'],
      totalAmount: json['tamt'],
      refNo1: json['refNo1'],
      refNo2: json['refNo2'],
      companyCode: json['companyCode'],
      companyId: json['companyId'],
      companyTax: json['companyTax'],
      customerName: json['customerName'],
      orderExpireDateShow: json['orderExpireDateShow'],
    };
    return modelValue(model);
  }
}

export interface QRCodeData {
  ref: string;
  status: number;
  refGen?: string;
  qrGenDate?: string;
  orderExpireTime?: number;
  totalAmount?: string;
  qrImage?: string;
}

export class QRCodeDataConverter {
  public static fromJson(json: any): QRCodeData {
    const model: QRCodeData = {
      ref: json['ref'],
      status: json['status'],
      refGen: json['refgen'],
      qrGenDate: json['qrgendate'],
      orderExpireTime: json['orderExpireTime'],
      totalAmount: json['tamt'],
      qrImage: json['qrimg'],
    };
    return modelValue(model);
  }
}

export interface SamedayChangeResponse {
  products?: ProductModel[];
  discountExpressFee?: number;
  discountBulkyFee?: number;
  shippingDiscount?: number;
  discountVoucher?: number;
  discountStaff?: number;
  discountT1Point?: number;
  sumTotal?: number;
  totalAmount?: number;
  diffShippingCharge?: number;
}

export class SamedayChangeResponseConverter {
  public static fromJson(json: any): SamedayChangeResponse {
    const model: SamedayChangeResponse = {
      products: json['items']
        ? json['items'].map((x: any) => ProductModelConverter.fromJson(x))
        : undefined,
      discountExpressFee: Number(
        json['expressDiscount']
          ?.toString()
          .trim()
          .replace('฿', '')
          .trim()
          .replaceAll('.00', ''),
      ),
      discountBulkyFee: Number(
        json['bulkyDiscount']
          ?.toString()
          .trim()
          .replace('฿', '')
          .trim()
          .replaceAll('.00', ''),
      ),
      shippingDiscount: json['tspDiscount'],
      discountVoucher: json['discountVoucher'],
      discountStaff: json['discountStaff'],
      discountT1Point: json['discountT1Point'],
      sumTotal: json['sumBeforeTotal'],
      totalAmount: json['sumTotal'],
      diffShippingCharge: json['diffTspCharge'],
    };
    return modelValue(model);
  }
}

export interface ErrorShipment {
  message?: string;
  redirect?: string;
  type?: ErrorShipmentType;
}

export enum ErrorShipmentType {
  storeBooking = 1,
  saveTransport = 2,
  serviceBooking = 3,
  reserveVoucher = 4,
  storeBookingChristmas = 5,
}

export enum ShippingType {
  delivery = 'Delivery',
  pickUp = 'Pickup',
  service = 'service',
  curtain = 'curtain',
  withService = 'WithService',
}

export enum AccountType {
  member = 'member',
  preactivated = 'preactivated',
}

export interface ShipmentResponse {
  orderStatus?: string;
  shippingType?: ShippingType;
  dcsAvailable: boolean;
  cookies?: CookieData[];
  info?: CustomerInfo | null;
  bookTimeFlag: number;
  hasAddressList: boolean;
  addressList?: Address[];
  isErrorSaveTrans: boolean;
  isErrorStoreBooking: boolean;
  isServiceError: boolean;
  voucherUsedList?: VoucherUsed[];
  ref?: string;
  freegifts?: ProductModel[];
  products?: ProductModel[];
  sumTotalShow: string;
  totalAmountShow: string;
  totalAmountWithCrChargeShow?: string;
  sumPlppShow: string;
  discountStaffShow?: string;
  discountVoucherShow?: string;
  shippingDiscountShow?: string;
  discountT1PointShow?: string;
  discountExpressFeeShow?: string;
  discountBulkyFeeShow?: string;
  plppDetail: PlppDetail[];
  slots?: Slot[];
  skuWithServiceList?: ProductModel[];
  requiredSlots?: AllSlot[];
  flee?: string;
  expressFee?: string;
  bulkyFee?: string;
  discountExpressFee?: number;
  discountBulkyFee?: number;
  oldExpressFee?: string;
  oldBulkyFee?: string;
  isExpress?: boolean;
  isBulky?: boolean;
  customerJsonDelivery?: { jsndlv: string };
  dlvDate?: string;
  dlvTime?: string;
  ownCards?: OwnCard[];
  accountType?: AccountType;
  the1Point?: number;
  the1PointShow?: string;
  availUseAmountShow?: string;
  availUsePointShow?: string;
  pointByPromotionShow?: string;
  remainAfterUsePointShow?: string;
  redeemedThe1?: RedeemedThe1;
  type?: ShipmentType;
  redeemPromotion?: RedeemPromotion;
  isNotEnoughPoint?: boolean;
  isNotEnoughAmount?: boolean;
  pmcrs?: Pmcr[];
  insts?: Inst[];
  stcode?: string;
  newGtmItem?: any;
  totalVoucher?: number;
  allVoucherName?: string;
  checkStockResponse?: CheckStockSkuResponse;
  customerInfos?: { keyb: string; value: string }[];
  isCurtain?: boolean;
  isChristmas?: boolean;
  diffShippingCharge?: number;
  sumTotalExcludeDelivery?: number;
  isRequestTax?: boolean;
  isBannedUser?: boolean;
  creditFeeAmount?: number;
  creditFeeProduct?: ProductModel;
  canRedeem?: boolean;
  locationChristmasService?: string[];
  christmasStcode?: string[];
}

export class ShipmentResponseConverter {
  public static fromJson(json: any): ShipmentResponse {
    let voucherUsedList: VoucherUsed[] | undefined = undefined;
    try {
      voucherUsedList = json['voureserve']
        ? JSON.parse(json['voureserve']).map((x: any) =>
            VoucherUsedConverter.fromJson(x),
          )
        : undefined;
    } catch (_) {}
    const model: ShipmentResponse = {
      orderStatus: json['checksostatus'] && json['checksostatus']['STATUS'],
      shippingType: json['dlvtype'],
      dcsAvailable: json['dcsAvailable'] == true ? true : false,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      bookTimeFlag: Number(json['booktimeflag'] ?? 0),
      hasAddressList: json['hasmultiaddr'] == true ? true : false,
      addressList: json['multiaddrs']
        ? json['multiaddrs'].map((x: any) => AddressConverter.fromJson(x))
        : undefined,
      isErrorSaveTrans: json['errsavetrans'] == true ? true : false,
      isErrorStoreBooking: json['errstorebooking'] == true ? true : false,
      isServiceError: json['isServiceError'] == true ? true : false,
      voucherUsedList,
      ref: json['cartref'],
      freegifts: json['freegifts']
        ? json['freegifts'].map((x: any) => ProductModelConverter.fromJson(x))
        : undefined,
      products: json['orderinfos']
        ? json['orderinfos'].map((x: any) => ProductModelConverter.fromJson(x))
        : undefined,
      sumTotalShow: (json['sumbeforetot'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      totalAmountShow: (json['sumtot'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      totalAmountWithCrChargeShow: (json['sumtotcrfeeamt'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      sumPlppShow: (json['discountitem'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      discountStaffShow: json['discountstaff']
        ? (json['discountstaff'] ?? '')
            .toString()
            .trim()
            .replace('฿', '')
            .trim()
            .replaceAll('.00', '')
        : undefined,
      discountVoucherShow: json['discountvou']
        ? (json['discountvou'] ?? '')
            .toString()
            .trim()
            .replace('฿', '')
            .trim()
            .replaceAll('.00', '')
        : undefined,
      shippingDiscountShow: json['discounttsp']
        ? (json['discounttsp'] ?? '')
            .toString()
            .trim()
            .replace('฿', '')
            .trim()
            .replaceAll('.00', '')
        : undefined,
      discountT1PointShow: json['discountt1point']
        ? (json['discountt1point'] ?? '')
            .toString()
            .trim()
            .replace('฿', '')
            .trim()
            .replaceAll('.00', '')
        : undefined,
      discountExpressFeeShow: json['discountExpressFee']
        ? (json['discountExpressFee'] ?? '')
            .toString()
            .trim()
            .replace('฿', '')
            .trim()
            .replaceAll('.00', '')
        : undefined,
      discountBulkyFeeShow: json['discountBulkyFee']
        ? (json['discountBulkyFee'] ?? '')
            .toString()
            .trim()
            .replace('฿', '')
            .trim()
            .replaceAll('.00', '')
        : undefined,
      plppDetail: json['plppdetail']
        ? json['plppdetail'].map((e: any) => PlppDetailConverter.fromJson(e))
        : undefined,
      slots: (json['dlvtype'] === 'Delivery'
        ? json['longtailslotarr']
        : json['longtailpickarr']
      )?.map((e: any) => SlotConverter.fromJson(e)),
      skuWithServiceList: json['allskuwithserv']?.map((x: any) =>
        ProductModelConverter.fromJson(x),
      ),
      requiredSlots: json['skuslotall']?.map((e: any) =>
        AllSlotConverter.fromJson(e),
      ),
      flee: json['flee']
        ? json['flee']
            .toString()
            .trim()
            .replace('฿', '')
            .trim()
            .replaceAll('.00', '')
        : undefined,
      expressFee: json['expressFee']
        ? json['expressFee']
            .toString()
            .trim()
            .replace('฿', '')
            .trim()
            .replaceAll('.00', '')
        : undefined,
      bulkyFee: json['bulkyFee']
        ? json['bulkyFee']
            .toString()
            .trim()
            .replace('฿', '')
            .trim()
            .replaceAll('.00', '')
        : undefined,
      discountExpressFee: json['discountExpressFee']
        ? Number(
            json['discountExpressFee']
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', ''),
          )
        : undefined,
      discountBulkyFee: json['discountBulkyFee']
        ? Number(
            json['discountBulkyFee']
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', ''),
          )
        : undefined,
      oldExpressFee: json['oldExpressFee']
        ? json['oldExpressFee']
            .toString()
            .trim()
            .replace('฿', '')
            .trim()
            .replaceAll('.00', '')
        : undefined,
      oldBulkyFee: json['oldBulkyFee']
        ? json['oldBulkyFee']
            .toString()
            .trim()
            .replace('฿', '')
            .trim()
            .replaceAll('.00', '')
        : undefined,
      isExpress: json['extype'] == true ? true : false,
      isBulky: json['whstype'] == true ? true : false,
      customerJsonDelivery: json['customerjsndeli'],
      dlvDate: json['qtdlvdate'],
      dlvTime: json['qtdlvtime'],
      ownCards: json['owncards']?.map((e: any) => OwnCardConverter.fromJson(e)),
      accountType: json['accounttype'],
      the1Point: json['theonepoint'],
      the1PointShow: json['theonepointtext'],
      availUseAmountShow: json['availuseamount']?.toString().trim(),
      availUsePointShow: json['availusepoint']?.toString().trim(),
      pointByPromotionShow: json['pointbypro']?.toString().trim(),
      remainAfterUsePointShow: json['remainpoint']?.toString().trim(),
      redeemedThe1: json['redeemtheone']
        ? RedeemedThe1Converter.fromJson(json['redeemtheone'])
        : undefined,
      redeemPromotion: json['redeempro']
        ? RedeemPromotionConverter.fromJson(json['redeempro'])
        : undefined,
      isNotEnoughPoint: json['notenoughpoint'] == true ? true : false,
      isNotEnoughAmount: json['notenoughamount'] == true ? true : false,
      pmcrs: json['pmcrs']?.map((e: any) => PmcrConverter.fromJson(e)),
      insts: json['insts']?.map((e: any) => InstConverter.fromJson(e)),
      stcode: json['stcode'],
      newGtmItem: json['newgtmitem'],
      totalVoucher: json['isshipvoucher'] ?? 0,
      allVoucherName: json['allvouchername'],
      checkStockResponse: json['checkStockResponse']
        ? CheckStockSkuResponseConverter.fromJson(json['checkStockResponse'])
        : undefined,
      customerInfos: json['custinfos'],
      isCurtain: json['iscurtain'] == true ? true : false,
      isChristmas: json['ischristmas'] == true ? true : false,
      diffShippingCharge: json['difftspcharge'],
      sumTotalExcludeDelivery: json['sumtotExcludeDeli'],
      isRequestTax:
        json['cksttax'] && Number(json['cksttax']) === 1 ? true : false,
      isBannedUser: json['isbanned'] ? true : false,
      creditFeeAmount: json['crfeeamt'],
      creditFeeProduct: json['crfeeSku']
        ? ProductModelConverter.fromJson(json['crfeeSku'])
        : undefined,
      canRedeem: json['canRedeem'] == true ? true : false,
      locationChristmasService: json['locationChristmasService']?.map(
        (e: any) => e?.toString().trim(),
      ),
      christmasStcode: json['christmasstcode']?.map((e: any) =>
        e?.toString().trim(),
      ),
    };
    return modelValue(model);
  }
}

export interface RedeemedThe1 {
  redeemedPoint?: number;
  redeemedAmount?: number;
  redeemedPointShow?: string;
  redeemedAmountShow?: string;
}

export class RedeemedThe1Converter {
  public static fromJson(json: any): RedeemedThe1 {
    const model: RedeemedThe1 = {
      redeemedPoint: Number(
        (json['POINT'] ?? json['redeemPoint'])
          ?.toString()
          .trim()
          .replace('฿', '')
          .trim()
          .replaceAll(',', ''),
      ),
      redeemedAmount: Number(
        (json['AMOUNT'] ?? json['redeemAmount'])
          ?.toString()
          .trim()
          .replace('฿', '')
          .trim()
          .replaceAll(',', ''),
      ),
      redeemedPointShow: (json['POINT'] ?? json['redeemPoint'])
        ?.toString()
        .trim(),
      redeemedAmountShow: (json['AMOUNT'] ?? json['redeemAmount'])
        ?.toString()
        .trim(),
    };
    return modelValue(model);
  }
}

export interface RedeemPromotion {
  point?: number;
  promotionDiscount?: number;
  pointShow?: string;
  promotionDiscountShow?: string;
}

export class RedeemPromotionConverter {
  public static fromJson(json: any): RedeemPromotion {
    const model: RedeemPromotion = {
      point: json['redeempropoint'],
      promotionDiscount: json['redeemprodisc'],
      pointShow: json['redeempropointtext']?.toString().trim(),
      promotionDiscountShow: json['redeemprodisctext']?.toString().trim(),
    };
    return modelValue(model);
  }
}

export interface Slot {
  shortFormProducts?: ProductModel[];
  sumQty?: number;
  isLongtail?: boolean;
  isBackOrder?: boolean;
  isBookTime?: boolean; // booktimeflag เอาไว้เช็คว่ามีการเลือกวันเวลาจัดส่งหรือไม่ ถ้าสินค้าโครงสร้างไม่ต้อง เลือกแค่เป้นวันที่
  masterTimeSlots?: MasterTimeSlot[];
  slotsData?: SlotData[];
  leadtime?: number;
  leadDayBetween?: number[];
  sort?: number;
  homeBranch?: '' | 'H' | 'B';
  slotrunning?: number;
  products?: ProductModel[];
  vendor?: string;
  transportType?: string;
  sameDayObject?: SameDayObject;
  serviceEmbed?: ServiceEmbed[];
  serviceToCall?: ServiceEmbed[];
  dcsAvailable?: boolean;
  goWith?: 0 | 1 | 9;
}

export class SlotConverter {
  public static fromJson(json: any): Slot {
    const model: Slot = {
      shortFormProducts: json['sku']?.map((x: any) =>
        ProductModelConverter.fromJson(x),
      ),
      sumQty: json['sumqnt'],
      isLongtail: json['longtail'] === 'Y' ? true : false,
      isBackOrder:
        json['isbackorder'] == '1' || json['isbackorder'] == true
          ? true
          : false,
      isBookTime: json['booktimeflag'] == 1 ? true : false,
      masterTimeSlots: (
        json['masterTimelongtailslot'] ?? json['masterTimeserviceslot']
      )?.map((e: any) => MasterTimeSlotConverter.fromJson(e)),
      slotsData: (json['longtailslot'] ?? json['serviceslot'])?.map((e: any) =>
        SlotDataConverter.fromJson(e),
      ),
      leadtime: json['leadtime'],
      leadDayBetween: json['ld'],
      sort: json['sort'],
      homeBranch: json['hbList']?.toString().trim(),
      slotrunning: json['slotrunning'] ?? json['position'],
      products: json['skudata']?.map((x: any) =>
        ProductModelConverter.fromJson(x),
      ),
      vendor: json['vendor']?.toString().trim(),
      transportType: json['tsptype']?.toString().trim(),
      sameDayObject: json['samedayobj']
        ? SameDayObjectConverter.fromJson(json['samedayobj'])
        : undefined,
      serviceEmbed: json['withserembed']?.map((x: any) =>
        ServiceEmbedConverter.fromJson(x),
      ),
      serviceToCall: json['withser']?.map((x: any) =>
        ServiceEmbedConverter.fromJson(x),
      ),
      dcsAvailable: json['dcsAvailable'] == true ? true : false,
      goWith: json['goWith'],
    };
    return modelValue(model);
  }
}

export interface AllSlot {
  running?: number;
  shiptype?: string;
  isLongtail?: 'Y' | 'N';
  isbackorder?: '0' | '1';
  upcCode?: string;
  sku?: string[];
  vendor?: string;
  goWith?: 0 | 1 | 9;
}

export class AllSlotConverter {
  public static fromJson(json: any): AllSlot {
    const model: AllSlot = {
      running: json['running'],
      shiptype: json['shiptype'],
      isLongtail: json['isLongtail'],
      isbackorder: json['isbackorder'],
      upcCode: json['upcCode']?.toString().trim(),
      sku: json['sku'],
      vendor: json['vendor'],
      goWith: json['goWith'],
    };
    return modelValue(model);
  }
}

export interface ServiceEmbed {
  shortFormProducts?: ProductModel[];
  slotrunning?: number;
  slot3Days?: Slot;
  slot?: Slot;
  serviceSku?: string;
  upcCode?: string;
}

export class ServiceEmbedConverter {
  public static fromJson(json: any): ServiceEmbed {
    const model: ServiceEmbed = {
      shortFormProducts: json['groupskus']?.map((x: any) =>
        ProductModelConverter.fromJson(x),
      ),
      slotrunning: json['slotrunning'],
      slot3Days: json['slot3days']
        ? SlotConverter.fromJson(json['slot3days'])
        : undefined,
      slot: json['slot'] ? SlotConverter.fromJson(json['slot']) : undefined,
      serviceSku: json['WITHSERV'].toString().trim(),
      upcCode: json['upcCode'].toString().trim(),
    };
    return modelValue(model);
  }
}

export interface MasterTimeSlot {
  timeSlotDesc?: string;
  timeSlotDescShow?: string;
}

export class MasterTimeSlotConverter {
  public static fromJson(json: any): MasterTimeSlot {
    const model: MasterTimeSlot = {
      timeSlotDesc: json['timeslotDesc'],
      timeSlotDescShow: json['timeslotDescShow'],
    };
    return modelValue(model);
  }
}

export interface SlotData {
  deliveryDate: string;
  timeSlot?: TimeSlot[];
  day?: string;
  shortDay?: string;
  dateMonth?: string;
}

export class SlotDataConverter {
  public static fromJson(json: any): SlotData {
    const model: SlotData = {
      deliveryDate: json['deliveryDate'] ?? '',
      timeSlot: json['timeslots']?.map((e: any) =>
        TimeSlotConverter.fromJson(e),
      ),
      day: json['delidays'],
      shortDay: json['delidaysmobile'],
      dateMonth: json['delidaymonth'],
    };
    return modelValue(model);
  }
}

export interface TimeSlot {
  timeSlotCode?: string;
  timeSlotName: string;
  isAvailable?: boolean;
  deliveryCharge?: number;
  deliveryDate?: string;
  timeSlotNameShow?: string;
  isMockup?: boolean;
  isMockupAvailable?: boolean;
}

export class TimeSlotConverter {
  public static fromJson(json: any): TimeSlot {
    const model: TimeSlot = {
      timeSlotCode: json['timeSlotCode'],
      timeSlotName: json['timeSlotName'] ?? '',
      isAvailable: json['isAvailable'] === 'Y' ? true : false,
      deliveryCharge: json['deliveryCharge']
        ? Number(json['deliveryCharge'])
        : undefined,
      deliveryDate: json['deliveryDate'],
      timeSlotNameShow: json['timeSlotNameShow'],
      isMockup: json['isMockup'] === 'Y' ? true : false,
      isMockupAvailable: json['isMockupAvailable'] === 'Y' ? true : false,
    };
    return modelValue(model);
  }
}

export interface SameDayObject {
  data?: TimeSlot;
  status?: number;
  reason?: string[];
  expressFee?: string | null;
  oldExpressFee?: string | null;
  bulkyFee?: string | null;
  oldBulkyFee?: string | null;
}

export class SameDayObjectConverter {
  public static fromJson(json: any): SameDayObject {
    const model: SameDayObject = {
      data: json['data'],
      status: json['status'],
      reason: json['reason'],
      expressFee: json['expressFee'],
      oldExpressFee: json['oldExpressFee'],
      bulkyFee: json['bulkyFee'],
      oldBulkyFee: json['oldBulkyFee'],
    };
    return modelValue(model);
  }
}

export interface NextPayResponse {
  pmcrs?: Pmcr[];
  insts?: Inst[];
  creditFeeAmount?: number;
  creditFeeProduct?: ProductModel;
  sumTotal?: number;
}

export class NextPayResponseConverter {
  public static fromJson(json: any): NextPayResponse {
    const model: NextPayResponse = {
      pmcrs: json['pmcrs']?.map((e: any) => PmcrConverter.fromJson(e)),
      insts: json['insts']?.map((e: any) => InstConverter.fromJson(e)),
      creditFeeAmount: json['crfeeamt'],
      creditFeeProduct: json['crfeeSku']
        ? ProductModelConverter.fromJson(json['crfeeSku'])
        : undefined,
      sumTotal: json['sumtotal'],
    };
    return modelValue(model);
  }
}

export interface Pmcr {
  amount?: number;
  tDiscount?: number;
  tDiscountShow?: string;
  discountType?: string;
  discountRate?: number;
  cardName?: string;
  sumTotal?: number;
  totalAmount?: number;
}

export class PmcrConverter {
  public static fromJson(json: any): Pmcr {
    const model: Pmcr = {
      amount: json['Amount'],
      tDiscount: json['Tdisc'],
      tDiscountShow: json['TdiscDisplay'],
      discountType: json['DISCTYPE'],
      discountRate: json['DISCRATE'],
      cardName: json['CARD_SNAME'],
      sumTotal: json['sumtotcrfeeamt'],
      totalAmount: json['sumcrtotal'],
    };
    return modelValue(model);
  }
}

export interface Inst {
  instRef?: number;
  bankName?: string;
  idPlan?: number;
  planName?: string;
  period?: number;
  maxDiscount?: number;
  discountType?: string;
}
export class InstConverter {
  public static fromJson(json: any): Inst {
    const model: Inst = {
      instRef: json['Inst_Ref'],
      bankName: json['Bank_sName'],
      idPlan: json['IDPlan'],
      planName: json['PLAN_NAME'],
      period: json['PERIOD'],
      maxDiscount: json['MaxDiscount'],
      discountType: json['DiscType'],
    };
    return modelValue(model);
  }
}

export interface OwnCard {
  markPan?: string;
  uuid?: string;
  cardSName?: string;
  paymentName?: string;
  bankSName?: string;
  makPanShow?: string;
}
export class OwnCardConverter {
  public static fromJson(json: any): OwnCard {
    let makPanShow = json['mark_panshow'];
    try {
      makPanShow = json['mark_pan']?.replace(/X/g, '*');
      makPanShow =
        makPanShow.substring(0, 4) +
        ' ' +
        makPanShow.substring(4, 8) +
        ' ' +
        makPanShow.substring(8, 12) +
        ' ' +
        makPanShow.substring(12, 16);
    } catch (error) {}
    const model: OwnCard = {
      markPan: json['mark_pan'],
      uuid: json['uuid'],
      cardSName: json['card_sname'],
      paymentName: json['payment_name'],
      bankSName: json['bank_sname'],
      makPanShow: makPanShow,
    };
    return modelValue(model);
  }
}

export interface PreProcResponse {
  preSum?: PreSum;
  skuCrCharge?: ProductModel;
  cardInfo?: CardSNameResponse;
  installmentPlans?: InstallmentPlan[];
  currentPlan?: InstallmentPlan;
}

export interface PreSum {
  amount?: number;
  discount?: number;
  discountVoucher?: number;
  shippingDiscount?: number;
  discountExpressFee?: number;
  discountBulkyFee?: number;
  discountStaff?: number;
  discountT1Point?: number;
  discountCreditCard?: number;
  discountCrCharge?: number;
}
export class PreSumConverter {
  public static fromJson(json: any): PreSum {
    const model: PreSum = {
      amount: Number(json['amount'] ?? '0'),
      discount: Number(json['discountitem'] ?? json['plppdisc'] ?? '0'),
      discountVoucher: Number(json['discountvou'] ?? json['cpndisc'] ?? '0'),
      shippingDiscount: Number(json['discounttsp'] ?? json['tspdisc'] ?? '0'),
      discountExpressFee: Number(
        json['discounttspexp'] ?? json['tspEXPdisc'] ?? '0',
      ),
      discountBulkyFee: Number(
        json['discounttspwhs'] ?? json['tspWHSdisc'] ?? '0',
      ),
      discountStaff: Number(json['discountstaff'] ?? '0'),
      discountT1Point: Number(json['redeempaymentamount'] ?? '0'),
      discountCreditCard: Number(
        json['discountpmcr'] ??
          json['discountinst'] ??
          (json['pmcrdisc'] != 0 ? json['pmcrdisc'] : json['instaldisc']) ??
          '0',
      ),
      discountCrCharge: Number(
        json['discountcrchargefee'] ?? json['feedisc'] ?? '0',
      ),
    };
    return modelValue(model);
  }
}

export interface CardSNameResponse {
  cardSName?: string;
  bankSName?: string;
  paymentName?: string;
}
export class CardSNameResponseConverter {
  public static fromJson(json: any): CardSNameResponse {
    const model: CardSNameResponse = {
      cardSName: json['CARD_SNAME']?.toString().trim(),
      bankSName: json['BANK_SNAME']?.toString().trim(),
      paymentName: json['PAYMENT_NAME']?.toString().trim(),
    };
    return modelValue(model);
  }
}

export interface InstallmentPlan {
  instRef?: number;
  bankSname?: string;
  idPlan?: number;
  period?: string;
  periodMonth?: number;
  amtxdisc?: number;
  amount?: number;
  fds?: number;
  instDisc?: number;
  instRate?: number;
  instDisctype?: string;
  crCharge?: number;
  discountFee?: number;
  byPeriod?: number;
}

export class InstallmentPlanConverter {
  public static fromJson(json: any): InstallmentPlan {
    const model: InstallmentPlan = {
      instRef: json['INST_REF'],
      bankSname: json['BANK_SNAME']?.toString().trim(),
      idPlan: json['ID_PLAN'],
      period: json['PERIOD']?.toString().trim(),
      periodMonth: json['periodMonth'],
      amtxdisc: json['AMTXDISC'],
      amount: json['AMT'],
      fds: json['FDS'],
      instDisc: json['INST_DISC'],
      instRate: json['INST_RATE'],
      instDisctype: json['INST_DISCTYPE']?.toString().trim(),
      crCharge: json['CRCHARGE'],
      discountFee: json['DISCFEE'],
      byPeriod: json['BYPERIOD'],
    };
    return modelValue(model);
  }
}

export interface InitiateRedeemResponse {
  isError?: boolean;
  status?: number;
  message?: string;
  nextPayResponse?: NextPayResponse;
  redeemInfo?: RedeemedThe1;
  isRefresh?: boolean;
}

export class InitiateRedeemResponseConverter {
  public static fromJson(json: any): InitiateRedeemResponse {
    const model: InitiateRedeemResponse = {
      isError: json['is_error'] == true ? true : false,
      status: json['status'],
      message: json['msg']?.toString().trim(),
      nextPayResponse: json['pmcrinstres']
        ? NextPayResponseConverter.fromJson(json['pmcrinstres'])
        : undefined,
      redeemInfo: json['redeeminfo']
        ? RedeemedThe1Converter.fromJson(json['redeeminfo'])
        : undefined,
      isRefresh: json['isrefresh'] == true ? true : false,
    };
    return modelValue(model);
  }
}

export interface CancelRedeemResponse {
  isError?: boolean;
  status?: number;
  message?: string;
  nextPayResponse?: NextPayResponse;
  cancelRedeemInfo?: CancelRedeemedThe1;
  cookies?: CookieData[];
}

export class CancelRedeemResponseConverter {
  public static fromJson(json: any): CancelRedeemResponse {
    const model: CancelRedeemResponse = {
      isError: json['is_error'] == true ? true : false,
      status: json['status'],
      message: json['msg']?.toString().trim(),
      nextPayResponse: json['pmcrinstres']
        ? NextPayResponseConverter.fromJson(json['pmcrinstres'])
        : undefined,
      cancelRedeemInfo: json['cancelredeeminfo']
        ? CancelRedeemedThe1Converter.fromJson(json['cancelredeeminfo'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}

export interface CancelRedeemedThe1 {
  the1Point?: number;
  the1PointShow?: string;
  availUseAmountShow?: string;
  availUsePointShow?: string;
  remainAfterUsePointShow?: string;
  redeemPromotion?: RedeemPromotion;
  isNotEnoughPoint?: boolean;
  isNotEnoughAmount?: boolean;
  netAmount?: number;
}

export class CancelRedeemedThe1Converter {
  public static fromJson(json: any): CancelRedeemedThe1 {
    const model: CancelRedeemedThe1 = {
      the1Point: json['theonepoint'],
      the1PointShow: json['pointafter'],
      availUseAmountShow: json['availuseamount']?.toString().trim(),
      availUsePointShow: json['availusepoint']?.toString().trim(),
      remainAfterUsePointShow: json['remainpoint']?.toString().trim(),
      redeemPromotion: json['redeempro']
        ? RedeemPromotionConverter.fromJson(json['redeempro'])
        : undefined,
      isNotEnoughPoint: json['notenoughpoint'] == true ? true : false,
      isNotEnoughAmount: json['notenoughamount'] == true ? true : false,
      netAmount: json['netamount'],
    };
    return modelValue(model);
  }
}

export interface ServiceSlotRequest {
  sdate: string;
  arrsendslot: SlotServiceSend[];
}

export interface SlotServiceSend {
  upc: string;
  ws: string;
  position?: number;
}

export interface ServiceSlotResponse {
  status?: number;
  slots?: Slot[];
}

export class ServiceSlotResponseConverter {
  public static fromJson(json: any): ServiceSlotResponse {
    const model: ServiceSlotResponse = {
      status: json['status'],
      slots: json['datas']?.map((e: any) => SlotConverter.fromJson(e)),
    };
    return modelValue(model);
  }
}

export interface BillPaymentBank {
  name: string;
  image: string;
  stepList: string[];
}

export class BillPaymentBankConverter {
  public static fromJson(json: any): BillPaymentBank {
    const model: BillPaymentBank = {
      name: json['name'],
      image: json['image'],
      stepList: json['stepList'],
    };
    return modelValue(model);
  }
}
