import { Box, Grid } from '@mui/material';
import React from 'react';
import footerClass from '@/styles/footer.module.css';
import Image from 'next/image';

export default function TopFooter() {
  return (
    <Box className={`${footerClass.topFooter} bg-transparent lg:bg-primary`}>
      <Box className="block lg:flex max-width-container">
        <Box className="bg-white flex lg:hidden px-5" height="6rem">
          <Image
            src="/images/svg/footer/shop_easy_every_life_style_mobile_th.svg"
            alt="shop_easy_every_life_style_mobile_th"
            className="self-center mx-auto mt-5"
            width={302}
            height={136}
          />
        </Box>
        <Box
          className="bg-primary hidden lg:flex self-center pt-4 pr-5 pl-3"
          height="8rem"
        >
          <Image
            src="/images/svg/footer/shop_easy_every_life_style_desktop_th.svg"
            alt="shop_easy_every_life_style_desktop_th"
            className="self-center"
            width={444}
            height={216}
          />
        </Box>
        <Grid
          container
          columnSpacing={2}
          className={`${footerClass.footerText} self-center text-center text-white bg-primary pr-3 pl-3 lg:pl-0`}
        >
          <Grid item xs={4} lg={2} className="self-center">
            <Image
              src="/images/svg/footer/shop_online.svg"
              alt="shop_online"
              className="mx-auto"
              width={135}
              height={113}
            />
            <Box>ช้อปออนไลน์</Box>
            <Box>ส่งได้ทุกที่</Box>
          </Grid>
          <Grid item xs={4} lg={2} className="self-center">
            <Image
              src="/images/svg/footer/click_and_collect.svg"
              alt="click_and_collect"
              className="mx-auto"
              width={135}
              height={113}
            />
            <Box>ช้อปออนไลน์</Box>
            <Box>รับได้ที่ร้าน</Box>
          </Grid>
          <Grid item xs={4} lg={2} className="self-center">
            <Image
              src="/images/svg/footer/chat_and_shop.svg"
              alt="chat_and_shop"
              className="mx-auto"
              width={135}
              height={113}
            />
            <Box>ช้อปครบ</Box>
            <Box>จบในแชต</Box>
          </Grid>
          <Grid item xs={4} lg={2} className="self-center">
            <Image
              src="/images/svg/footer/call_and_shop.svg"
              alt="call_and_shop"
              className="mx-auto"
              width={135}
              height={113}
            />
            <Box>ช้อปง่าย</Box>
            <Box>โทร 1308</Box>
          </Grid>
          <Grid item xs={4} lg={2} className="self-center">
            <Image
              src="/images/svg/footer/shop_via_fackbook.svg"
              alt="shop_via_fackbook"
              className="mx-auto"
              width={135}
              height={113}
            />
            <Box>ช้อปง่าย</Box>
            <Box>ผ่านเฟซบุ๊ก</Box>
          </Grid>
          <Grid item xs={4} lg={2} className="self-center">
            <Image
              src="/images/svg/footer/e_ordering.svg"
              alt="e_ordering"
              className="mx-auto"
              width={135}
              height={113}
            />
            <Box>เลือกช้อปได้</Box>
            <Box>มากกว่าที่เห็น</Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
