import { Banner } from '@/models/HomePage.model';
import { Grid, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import AppLink from '@/components/Layout/handler/AppLink';
import { customerInfoSelector } from '@/store/slices/authenSlice';

export interface BannerRatio {
  width: number;
  height: number;
  ratio: number;
}

export default function HeroBanner({
  name,
  banners,
  desktopRatio,
  mobileRatio,
  showDotName,
  noSkeleton,
}: {
  name: string;
  banners?: Banner[];
  desktopRatio?: BannerRatio;
  mobileRatio?: BannerRatio;
  showDotName?: boolean;
  noSkeleton?: boolean;
}) {
  const router = useRouter();
  const customerInfo = useSelector(customerInfoSelector);
  const customerId = customerInfo?.custId;
  const desktopWidth = desktopRatio?.width ?? 1280;
  const desktopHeight = desktopRatio?.height ?? 380;
  const mobileWidth = mobileRatio?.width ?? 750;
  const mobileHeight = mobileRatio?.height ?? 380;
  const ratioDesktop = desktopRatio?.ratio ?? desktopWidth / desktopHeight;
  const ratioMobile = mobileRatio?.ratio ?? mobileWidth / mobileHeight;
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const bannerRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<Slider>(null);

  const handleAfterChange = (index: number) => {
    setCurrentIndex(index);
  };

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const goto = (index: number) => {
    setCurrentIndex(index);
    sliderRef.current?.slickGoTo(index);
  };

  const settings = banners
    ? {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        prevArrow: (
          <>
            <div
              className="cursor-pointer"
              style={{
                position: 'absolute',
                zIndex: 9,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              onClick={previous}
            >
              <div
                className="h-6 w-6 sm:h-8 sm:w-8 flex items-center justify-center"
                style={{ lineHeight: '1rem' }}
              >
                <Image
                  src="/images/svg/chevron_left.svg"
                  alt="chevron_left"
                  width={24}
                  height={24}
                  style={{
                    width: '100%',
                    height: '100%',
                    opacity: 0.5,
                  }}
                />
              </div>
            </div>
          </>
        ),
        nextArrow: (
          <>
            <div
              className="cursor-pointer"
              style={{
                position: 'absolute',
                zIndex: 9,
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
              }}
              onClick={next}
            >
              <div
                className="h-6 w-6 sm:h-8 sm:w-8 flex items-center justify-center"
                style={{ lineHeight: '1rem' }}
              >
                <Image
                  src="/images/svg/chevron_right.svg"
                  alt="chevron_right"
                  width={24}
                  height={24}
                  style={{
                    width: '100%',
                    height: '100%',
                    opacity: 0.5,
                  }}
                />
              </div>
            </div>
          </>
        ),
        afterChange: handleAfterChange,
        appendDots: (dots: any) => (
          <div
            style={{
              zIndex: 10,
              marginBottom: '1.03rem',
            }}
          >
            <div
              className={`block ${
                showDotName === true ? 'lg:flex' : ''
              } w-full justify-center`}
              style={{ margin: '0px' }}
            >
              {dots.map((e: any, i: number) => (
                <React.Fragment key={`dot-${i}`}>
                  {showDotName === true ? (
                    <>
                      <div
                        onClick={() => {
                          goto(i);
                        }}
                        className="w-full hidden lg:flex mx-auto items-center justify-center cursor-pointer h-5 leading-4"
                        style={{
                          backgroundColor:
                            currentIndex == i ? '#ffdede' : 'white',
                          overflow: 'hidden',
                        }}
                      >
                        {banners[i].topic}
                      </div>
                      <div
                        onClick={() => {
                          goto(i);
                        }}
                        className="bg-secondaryPurple inline-flex lg:hidden mx-1 cursor-pointer rounded-sm"
                        style={{
                          width: '1rem',
                          height: '.25rem',
                          opacity: currentIndex == i ? 1 : 0.5,
                        }}
                      />
                    </>
                  ) : (
                    <div
                      onClick={() => {
                        goto(i);
                      }}
                      className="bg-secondaryPurple inline-flex mx-1 cursor-pointer rounded-sm"
                      style={{
                        width: '1rem',
                        height: '.25rem',
                        opacity: currentIndex == i ? 1 : 0.5,
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        ),
      }
    : null;

  useEffect(() => {
    const banner = bannerRef.current;
    if (
      !banner ||
      !(window as any).dataLayer ||
      customerInfo === undefined ||
      !banners
    )
      return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (banners && banners[currentIndex]) {
          (window as any).dataLayer.push({
            event: 'promotionView',
            userId: customerId,
            ecommerce: {
              promoView: {
                promotions: [
                  {
                    name: banners[currentIndex].topic,
                    position: `${name}_${currentIndex + 1}`,
                  },
                ],
              },
            },
          });
        }

        observer.disconnect();
      }
    });

    observer.observe(banner);

    return () => {
      if (banner && observer) {
        observer.unobserve(banner);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, customerId, banners]);

  const handleBannerClick = (banner: Banner, index: number) => {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({
        event: 'promotionClick',
        userId: customerId,
        ecommerce: {
          promoClick: {
            promotions: [
              {
                name: banner.topic,
                position: `${name}_${index + 1}`,
              },
            ],
          },
        },
      });
    }
    try {
      if ((window as any).appier) {
        (window as any).appier('event', 'promotion_viewed', {
          promotion_name: banner.topic,
          promotion_link: `${process.env.NEXT_PUBLIC_BASE_URL_WEB}${banner.url}`,
          promotion_image: banner.img,
          env: process.env.NEXT_PUBLIC_NODE_ENV,
        });
      }
    } catch (_) {}
    if (banner.ahref) {
      window.open(`/${router.locale}/${banner.url}`, '_blank');
    } else {
      router.push(`/${router.locale}/${banner.url}`);
    }
  };

  return (
    <div ref={bannerRef}>
      <Grid container position="relative">
        <Grid item xs={12}>
          {banners ? (
            <Slider ref={sliderRef} {...settings}>
              {banners.map((e, i) => (
                <React.Fragment key={`slider-${i}`}>
                  <div className="hidden md:inline">
                    <div
                      className={showDotName === true ? `pb-0 lg:pb-5` : ''}
                      onClick={(event: any) => {
                        event.preventDefault();
                        handleBannerClick(e, i);
                      }}
                    >
                      {process.env.NEXT_PUBLIC_CMS_USE_NEXT_IMAGE !== '1' ? (
                        // eslint-disable-next-line @next/next/no-img-element
                        <img
                          src={e.img?.replaceAll(
                            '/../content',
                            `${process.env.NEXT_PUBLIC_BASE_URL_WEB}/content`,
                          )}
                          alt={e.topic}
                          width={desktopWidth}
                          height={desktopHeight}
                          className="w-full"
                          style={{ height: 'auto' }}
                        />
                      ) : (
                        <Image
                          src={e.img?.replaceAll(
                            '/../content',
                            `${process.env.NEXT_PUBLIC_BASE_URL_WEB}/content`,
                          )}
                          alt={e.topic}
                          width={desktopWidth}
                          height={desktopHeight}
                          className="w-full"
                          style={{ height: 'auto' }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="block md:hidden">
                    <AppLink
                      key={`m-${i}`}
                      href={e.url}
                      target={e.ahref ? '_blank' : ''}
                    >
                      {i === 0 ||
                      process.env.NEXT_PUBLIC_CMS_USE_NEXT_IMAGE !== '1' ? (
                        // eslint-disable-next-line @next/next/no-img-element
                        <img
                          src={e.imgMobile ?? e.img}
                          alt={e.topic}
                          width={mobileWidth}
                          height={mobileHeight}
                          className="w-full"
                          style={{ height: 'auto' }}
                        />
                      ) : (
                        <Image
                          src={e.imgMobile ?? e.img}
                          alt={e.topic}
                          width={mobileWidth}
                          height={mobileHeight}
                          className="w-full"
                          style={{ height: 'auto' }}
                        />
                      )}
                    </AppLink>
                  </div>
                </React.Fragment>
              ))}
            </Slider>
          ) : (
            <>
              <div
                className="hidden md:block"
                style={{
                  height: `${100 / ratioDesktop}vw`,
                  maxHeight: 1360 / ratioDesktop + (showDotName ? 30 : 0),
                }}
              >
                {!noSkeleton && (
                  <Skeleton
                    className="hidden md:block"
                    variant="rectangular"
                    width="100%"
                    height="100%"
                    sx={{
                      maxHeight: 1360 / ratioDesktop + (showDotName ? 30 : 0),
                    }}
                  />
                )}
              </div>
              <div
                className="block md:hidden"
                style={{ height: `${100 / ratioMobile}vw` }}
              >
                {!noSkeleton && (
                  <Skeleton
                    className="block md:hidden"
                    variant="rectangular"
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
