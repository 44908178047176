import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';
import { MyCurtainDetail, MyCurtainDetailConverter } from '../MyCart.model';

export interface MyIdeaPageResponse {
  title?: string;
  sub?: string;
  curtainContents: MyCurtainDetail[];
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
}

export class MyIdeaPageResponseConverter {
  public static fromJson(json: any): MyIdeaPageResponse {
    const model: MyIdeaPageResponse = {
      title: json['title'],
      sub: json['sub'],
      curtainContents: json['curtainContents']
        ? json['curtainContents'].map((x: any) =>
            MyCurtainDetailConverter.fromJson(x),
          )
        : [],
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}

export interface MyIdeaDetailPageResponse {
  title?: string;
  sub?: string;
  curtainContent?: MyCurtainDetail;
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
  slug?: string;
}

export class MyIdeaDetailPageResponseConverter {
  public static fromJson(json: any): MyIdeaDetailPageResponse {
    const model: MyIdeaDetailPageResponse = {
      title: json['title'],
      sub: json['sub'],
      curtainContent: json['contents']
        ? MyCurtainDetailConverter.fromJson(json['contents'])
        : undefined,
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
      slug: json['slug'],
    };
    return modelValue(model);
  }
}
