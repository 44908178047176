import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import TopHeader from './TopHeader';
import CenterHeader from './CenterHeader';
import TopBannerContext from '@/contexts/TopBannerContext';
import Image from 'next/image';
import AppLink from '@/components/Layout/handler/AppLink';
import { useRouter } from 'next/router';
import { Topbanner } from '@/models/VersionLoad.model';
import BottomHeader from './BottomHeader';

export default function Header() {
  const router = useRouter();
  return (
    <>
      {/* TopBanner From Version API */}
      <TopBannerContext.Consumer>
        {({ topBanner }: { topBanner?: Topbanner }) =>
          router.asPath == '/' &&
          (topBanner ? (
            //  ถ้าจะให้แสดงทุกหน้า เอา router.asPath == '/' ออกได้เลย
            <div style={{ backgroundImage: topBanner.color }}>
              <AppLink href={topBanner.url ?? ''}>
                <div className="max-width-container">
                  <Image
                    src={topBanner.img ?? ''}
                    alt={`TopBanner - ${topBanner.version?.toString() ?? ''}`}
                    width={1368}
                    height={130}
                    // className="hidden md:block"
                  />
                  {/* Comment ไว้ก่อนเพราะว่าจะได้ไม่ต้องโหลดภาพซ้ำปกติเค้า set size เดียว */}
                  {/* <Image
                    src={topBanner.img_mobile ?? topBanner.img}
                    alt={topBanner.version.toString()}
                    width={1360}
                    height={113}
                    className="md:hidden block"
                  /> */}
                </div>
              </AppLink>
            </div>
          ) : (
            <div className="max-width-container">
              <div
                style={{
                  aspectRatio: '1368/130',
                }}
              ></div>
            </div>
          ))
        }
      </TopBannerContext.Consumer>
      <AppBar className="appbar" position="sticky" color="secondary">
        <TopHeader />
        <CenterHeader />
        <BottomHeader />
      </AppBar>
      {/* <AppBar position="sticky" color="secondary">
      </AppBar> */}
    </>
  );
}
