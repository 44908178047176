import { fullMonth, shortMonth } from '@/constants/checkout.constant';
import { SkuWithQty } from '@/models/AddToCart.model';

export const mapSkuAddingToCart = ({
  sku,
  skuQtyList,
  setId,
}: {
  sku?: string;
  skuQtyList?: SkuWithQty[];
  setId?: string;
}) => {
  return `${sku ?? (skuQtyList ?? []).map((e) => e.sku).join(',')}${
    setId !== undefined ? `|${setId}` : ''
  }`;
};

export const mapGtmProducts = (x?: string | null) => {
  return x !== null || x !== undefined
    ? JSON.parse(x!).map((e: any) => {
        e.price = e.price?.toString()?.replace(/,/g, '')?.replaceAll('.00', '');
        e.quantity = Number(
          e.quantity?.toString()?.replace(/,/g, '')?.replaceAll('.00', '') ?? 1,
        );
        return e;
      })
    : [];
};

export const numberWithCommas = (
  x?: string | number | null,
  options?: { withDecimal?: boolean },
) => {
  x = x?.toString()?.replaceAll(',', '')?.replaceAll('฿', '')?.trim() ?? '0';
  x = ((Number(x) * 100) / 100).toFixed(2);
  let response =
    x !== null || x !== undefined
      ? (x ?? '0')
          .toString()
          .replaceAll(',', '')
          .replaceAll('฿', '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : '';
  const responseSplit = response.split('.');
  if (responseSplit.length > 1 && responseSplit[1].length === 1) {
    response = response + '0';
  }
  if (options?.withDecimal === true) {
    return response;
  } else {
    return response.replaceAll('.00', '');
  }
};

export const stringToNumber = (x?: string | null) => {
  return x !== null || x !== undefined
    ? Number(
        (x ?? '0').toString().replaceAll(',', '').replaceAll('฿', '').trim(),
      )
    : undefined;
};

export const tranDate = ({
  dateString,
  leadTime,
  isShowYear,
  lang,
}: {
  dateString?: string;
  leadTime?: number;
  isShowYear?: boolean;
  lang?: string;
}) => {
  let date =
    dateString !== undefined
      ? new Date(
          parseInt(dateString.substring(0, 4)),
          parseInt(dateString.substring(4, 6)) - 1,
          parseInt(dateString.substring(6, 8)),
        )
      : new Date();
  if (leadTime || dateString) {
    date.setDate(date.getDate() + (leadTime ?? 0));
    let langUse: 'th' | 'en' = 'th';
    switch (lang) {
      case 'en':
        langUse = 'en';
        break;
      default:
        langUse = 'th';
        break;
    }
    return (
      date.getDate() +
      ' ' +
      shortMonth[langUse][date.getMonth()] +
      (isShowYear ? ` ${date.getFullYear() + 543}` : ``)
    );
  } else {
    return '';
  }
};

export const detectBrower = (): string => {
  let ua = navigator.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
};

export const convertToMarkPan = (pan: string) => {
  if (pan) {
    const panArr = pan.replaceAll('-', '').split('');
    const first6 = panArr.slice(0, 6);
    const last4 = panArr.slice(-4);
    const markPan = first6.concat('XXXXXX', last4).join('');
    return markPan;
  } else {
    return '';
  }
};

export const currentDate = ({ lang }: { lang?: string }) => {
  let date = new Date();
  let langUse: 'th' | 'en' = 'th';
  switch (lang) {
    case 'en':
      langUse = 'en';
      break;
    default:
      langUse = 'th';
      break;
  }
  return (
    (date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()) +
    ' ' +
    fullMonth[langUse][date.getMonth()] +
    ' ' +
    (date.getFullYear() + 543)
  );
};
