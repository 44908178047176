import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';

export interface ConsentRequest {
  email: string;
  consentPrivacyVersion: string;
  consentPrivacyStatus?: boolean;
  consentMarketingStatus: boolean;
}

export class ConsentRequestConverter {
  public static toJson(model: ConsentRequest): any {
    const json: any = {
      email: model.email,
      consent_privacy_version: model.consentPrivacyVersion,
      consent_privacy_status: model.consentPrivacyStatus,
      consent_marketing_status: model.consentMarketingStatus,
    };
    return modelValue(json);
  }
}

export interface ConsentResponse {
  consentMarketingStatus: boolean;
  info?: CustomerInfo | null;
}
export class ConsentResponseConverter {
  public static fromJson(json: any): ConsentResponse {
    const model: ConsentResponse = {
      consentMarketingStatus:
        json['consent_marketing_status'] === true ? true : false,
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface EditInfoResponse {
  title?: string;
  sub?: string;
  theoneName?: string;
  theoneMobile?: string;
  theoneEmail?: string;
  theonePoint?: number;
  theonePointShow?: string;
  consent?: Consent;
  csMarketing?: boolean;
  csPrivacy?: boolean;
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
}

export class EditInfoResponseConverter {
  public static fromJson(json: any): EditInfoResponse {
    const model: EditInfoResponse = {
      title: json['title'],
      sub: json['sub'],
      theoneName: json['theonename'],
      theoneMobile: json['theonemobile'],
      theoneEmail: json['theoneemail'],
      theonePoint: json['theonedatapoint']
        ? Number(json['theonedatapoint'])
        : undefined,
      theonePointShow: json['theonedatapointtext'],
      consent: json['consent']
        ? ConsentConverter.fromJson(json['consent'])
        : undefined,
      csMarketing: json['csmarketing'] === true ? true : false,
      csPrivacy: json['csprivacy'] === true ? true : false,
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}

export interface Consent {
  consentPrivacyStatus?: boolean;
  consentMarketingStatus?: boolean;
  consentPrivacyVersion?: string;
  lastUpdateShow?: string;
  lastUpdate?: Date;
}

export class ConsentConverter {
  public static fromJson(json: any): Consent {
    const model: Consent = {
      consentPrivacyStatus:
        json['consent_privacy_status'] === true ? true : false,
      consentMarketingStatus:
        json['consent_marketing_status'] === true ? true : false,
      consentPrivacyVersion: json['consent_privacy_version'],
      lastUpdateShow: json['last_update'],
      lastUpdate: json['last_update']
        ? new Date(json['last_update'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface ConsentInfoResponse {
  marketingDisplayText?: LangString;
  privacyPolicy?: LangString;
  consentPrivacyVersion?: string;
}

export class ConsentInfoResponseConverter {
  public static fromJson(json: any): ConsentInfoResponse {
    const marketingDisplayText = LangStringConverter.fromJson(
      json['marketing_display_text'],
    );
    const privacyPolicy = LangStringConverter.fromJson(json['privacy_policy']);
    const model: ConsentInfoResponse = {
      marketingDisplayText:
        marketingDisplayText &&
        marketingDisplayText.th &&
        marketingDisplayText.en
          ? marketingDisplayText
          : undefined,
      privacyPolicy:
        privacyPolicy && privacyPolicy.th && privacyPolicy.en
          ? privacyPolicy
          : undefined,
      consentPrivacyVersion: json['consent_privacy_version'],
    };
    return modelValue(model);
  }
}

export interface LangString {
  th?: string;
  en?: string;
}

export class LangStringConverter {
  public static fromJson(json: any): LangString {
    const model: LangString = {
      th: json['th'] && json['th'] !== '' ? json['th'] : undefined,
      en: json['en'] && json['en'] !== '' ? json['en'] : undefined,
    };
    return modelValue(model);
  }
}

export enum ConsentType {
  // eslint-disable-next-line no-unused-vars
  ConsentInfo = 0,
  // eslint-disable-next-line no-unused-vars
  ConsentInfoWithRequired = 1,
}
